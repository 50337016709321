.group-members-page {
	.topbar-header {
		ion-title {
			.title {
				font-family: Metropolis;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 18px;
				text-align: center;
				color: #000000;
			}
		}
		.toolbar-searchbar {
			margin-top: 5px;
		}
	}

	.selected-contacts-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		max-height: 166px;
		background: #efefef;
		margin-top: 141px;
		margin-left: 18px;
		margin-right: 18px;
		overflow: scroll;
		.contact-item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			width: 59px;
			padding: 8px 0 5px;
			// margin: 0 11px;
			position: relative;

			.contact-img {
				width: 48px;
				height: 48px;
				border-radius: 4px;
				background-color: #ffffff;
			}

			.contact-name {
				margin-top: 8px;
				font-family: Metropolis;
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 12px;
				text-align: center;
				color: #000000;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				width: 100%;
			}

			.cross {
				width: 24px;
				position: absolute;
				display: flex;
				justify-content: center;
				position: absolute;
				top: 0px !important;
				right: -5px;
				--padding-start: 0;
				--padding-end: 0;
				height: 22px;
				width: 22px;
				margin: 0;
				ion-icon {
					width: 100%;
					height: 100%;
				}
			}
			.remove-icon {
				position: absolute;
				top: 2px;
				right: -5px;
				--padding-start: 0;
				--padding-end: 0;
				height: 22px;
				width: 22px;
				margin: 0;
				ion-icon {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	.add-remove-btns-container {
		position: fixed;
		bottom: 0;
		right: 0;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: 91px;
		padding-left: 8px;
		padding-right: 13px;
		padding-top: 8px;
		.contact-item {
			margin: 0;
			&:nth-child(2) {
				margin-left: 22px;
			}
		}
	}
}
