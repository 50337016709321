.dashboard-page {
	.topbar-header ion-toolbar.toolbar-heading {
		.toolbar-container {
			display: flex;
			align-items: flex-end;
		}
	}
	.sc-ion-buttons-ios-h {
		display: flex;
	}
	.topbar-header .toolbarTitle {
		padding-top: 0px;
	}
	.sc-ion-buttons-ios-s ion-icon[slot='icon-only'] {
		padding-top: 0px;
	}
	// .notification-badge {
	// 	margin-top: 9px;
	// }
	// .topbar-header ion-toolbar.toolbar-heading .left-btns .dot-circle {
	// 	margin-top: 5px;
	// 	margin-left: -1px;
	// }
	.topbar-header ion-toolbar.toolbar-heading .left-btns .back-button {
		height: auto;
		width: auto;
		margin-left: 18px;
		margin-right: 0;
		ion-icon {
			width: 20px;
			height: 22px;
		}
	}
	ion-refresher {
		top: var(--top-header-bar-with-searchbar-height);
		z-index: 1;
	}
	ion-content {
		height: 100vh;
		--background: var(--app-color-code);
	}
	.tag-tabs {
		background-color: var(--app-color-code);
		display: flex;
		flex-direction: row;
		max-width: 100%;
		overflow-y: auto;
		align-items: center;
		padding: 0;
		padding-left: 18px;
		height: 37px;
		-ms-overflow-style: none;
		&::-webkit-scrollbar {
			display: none;
		}
		.tab-item {
			cursor: pointer;
			padding: 0;
			padding-top: 2.5px;
			padding-bottom: 5px;
			margin: 0;
			white-space: nowrap;
			font-family: Metropolis;
			font-size: 15px;
			line-height: 15px;
			color: #ff5e5e;
			position: relative;
			margin-right: 18px;

			&.active {
				&::after {
					content: '';
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					height: 3px;
					margin-top: 5px;
					border-radius: 6px;
					background-color: #ff5e5e;
				}
				font-weight: 500;
				color: #ff5e5e;
			}
		}
	}
	.conversation-list {
		background: transparent;

		.searched-list-item {
			color: #000000 !important;
			--background: transparent !important;
			--min-height: 72px;
		}
	}

	.no-conversations-view {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 60%;
		max-width: 214px;
		margin: auto;
		text-align: center;
		flex: 1;
		height: calc(100% - 90px);

		.title {
			font-size: 26px;
			line-height: 26px;
			font-weight: bold;
			color: #000000;
		}
		.description {
			margin-top: 22px;
			font-size: 20px;
			color: #585858;
			line-height: 140%;
		}
	}
}

.modal-profile-class {
	background: #ffffff;
	text-align: center;
}

.confirmed-list {
	color: #000000 !important;
	background: #ffffff !important;
	--background-hover: rgb(111, 111, 112);
	--color-hover: #000000;
}

.toggle-menu-pop {
	width: auto;
}

.custom-menu-pop {
	--background-hover: rgb(111, 111, 112);
	--color-hover: #000000;
}

.sc-ion-searchbar-ios-h {
	--background: #ffffff !important;
}

.dbGrid {
	height: 100%;
}
.menu-dropdown list-item {
	color: #000000;
	width: 100px;
}
.dbRow {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.toolbarMain {
	height: 100px;
}

.searchBackground {
	background: #000000;
}

.toolbarTitle {
	text-align: center;
}

.conversationList {
	color: #000000;
	text-align: left;
	font-size: 12px;
}

.thumb-user {
	--border-radius: 5px;
	overflow: hidden;
	width: 48px;
	height: 48px;
}
