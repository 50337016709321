.choose-tags-modal {
	// --margin-top: 9.5% !important;

	--height: 100vh;
	--min-width: 100%;
	--backdrop-opacity: 0.4;
	.topbar-header {
		// .left-btns{
		// 	ion-icon{
		// 		// width: 14px !important;
		// 		// height: 14px !important;
		// 	}
		// }
		.right-btn {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 18px;
			display: flex;
			align-items: center;
			text-align: right;

			--color: #c4c4c4;

			&.right-button-active {
				--color: #ff5e5e;
			}
		}
	}
	ion-searchbar {
		input {
			border-radius: 5px !important;
		}
	}

	ion-backdrop {
		background: rgba(0, 0, 0, 0.4);
	}
	.modal-wrapper {
		min-width: 100% !important;
		max-width: unset !important;
		max-height: unset !important;

		.ion-page {
			justify-content: flex-end;
		}
	}

	.content {
		flex: 1;
		overflow: scroll;
		margin-top: 8px;
		.title {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 14px;
			padding-left: 16px;
			color: #a5a5a5;
		}

		.title {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 14px;
			color: #a5a5a5;
			margin-top: 12px;
		}

		.tag-container {
			display: flex;
			flex-wrap: wrap;
			margin-top: 15px;
			padding: 0 6px;

			.tag-item {
				height: 30px;
				--border-radius: 22px;
				--background: #ffffff;
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 16px;
				color: #a5a5a5;
				margin-left: 10px;
				margin-bottom: 10px;

				&.active-tag {
					color: #ff5e5e;

					--background: rgba(255, 94, 94, 0.25);
				}
			}

			.new-tag {
				width: 110px;
				height: 30px;
				margin-left: 10px;
				border: 1px solid #858484;
				border-radius: 22px;
				--background: transparent;

				border: 1px solid #858484;

				display: flex;
				align-items: center;
				justify-content: center;
				img {
					width: 9px;
					height: 9px;
				}
				span {
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 16px;
					color: #858484;
					padding-left: 5px;
				}
			}
		}
	}

	.footer-toolbar {
		position: absolute;
		bottom: 0;
		z-index: 99;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		width: 100%;
		background: rgba(var(--app-modal-bg-color-code), 0.7);
		box-sizing: border-box;
		backdrop-filter: blur(20px);

		ion-toolbar {
			--min-height: 56px;
			--padding-start: 20px;
			--padding-end: 20px;
			margin-bottom: 30px;
			--background: transparent;

			.cancel-btn {
				transform: none;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 16px;
				--padding-start: 0;
				margin: 0;
				height: 33px;
				--color: #858484;
			}
			// .done-btn {
			//   transform: none;
			//   margin: 0;
			//   height: 33px;
			//   min-width: 59px;
			//   --border-radius: 5px;
			//   font-style: normal;
			//   font-weight: 500;
			//   font-size: 16px;
			//   line-height: 16px;
			//   --background: #c4c4c4;
			//   --color: #e8e8e8;
			//   --padding-start: 9px;
			//   --padding-end: 9px;
			//   &.active {
			//     --background: #ff5e5e;
			//   }
			// }
		}
	}
	.footer-toolbar {
		ion-button {
			background: transparent;
			&.cancel-btn {
				width: 90px;
				height: 40px;
				--background: #dcdcdc;
				border-radius: 6px;
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 16px;
				/* identical to box height */

				--color: #000000;
			}
			&.done-btn {
				width: 82px;
				height: 40px;
				--background: #ff5e5e;
				border-radius: 5px;
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 16px;
				/* identical to box height */

				text-align: center;
				letter-spacing: 0.6px;

				--color: #e8e8e8;
			}
		}
	}
	.search-input {
		width: 100%;
		height: 44px;
		margin-top: 5px;
		background-color: #ffffff;
		align-items: center;
		text-align: center;
	}
	.tag-name {
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 14px;
		color: #858484;
		margin-left: 17px;
	}
}
