.customCard {
	height: 100%;
	text-align: center;
}
.imgDivClass img {
	height: 300px;
	width: 100%;
	object-fit: cover;
}
.groupCustomCard {
	height: 100%;
	text-align: left;
}
.gpToolbarTitle {
	--background: #000000;
}
.gpToolbarSubmit {
	--background: #3fbaff;
}
.gp-footer {
	padding: 0 15px;
}
.cg-toolbar-wrapper {
	--background: #000000 !important;
	color: white;
}
