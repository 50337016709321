.media-preview-modal {
	.modal-wrapper {
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		height: 100% !important;
		background: #000000;
		ion-toolbar,
		ion-content {
			--background: transparent !important;
		}
	}

	ion-toolbar {
		ion-icon {
			color: #e8e8e8 !important;
		}
		&.bottom-toolbar {
			--background: rgba(0, 0, 0, 0.7);
			backdrop-filter: blur(20px);
			--min-height: 32px;
			padding-top: 10px;
			padding-bottom: 46px;
			ion-button {
				text-transform: none;
				font-weight: 500;
				font-size: 16px;
				line-height: 16px;
				--color: #e8e8e8;
				&.send-btn {
					--background: #ff5e5e;
					--border-radius: 5px;
				}
			}

			.full-size-selection {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				ion-item {
					--min-height: 30px;
					--background: transparent;
					ion-checkbox {
						--background: transparent;
						--background-checked: #ff5e5e;
						--border-color: #858484;
						--border-color-checked: transparent;
						--checkmark-width: 3px;
						--checkmark-color: rgba(0, 0, 0, 0.7);
						--border-width: 1px;
						--size: 20px;
						margin-right: 8px;
					}
					ion-label {
						font-size: 17px;
						line-height: 17px;
						color: #e8e8e8;
					}
				}
			}
		}
	}
	.image-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		.swiper {
			width: 100%;
			height: 100%;

			.video-container {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		.longpress-container-img {
			max-width: 100%;
			max-height: 100%;
			height: 100%;
			width: 100%;
			img {
				pointer-events: none;
				max-width: 100%;
				max-height: 100%;
				height: 100%;
				width: 100%;
				object-fit: contain !important;
			}
		}

		.video-react {
			max-width: 100%;
			max-height: 100%;
			height: 100%;
			display: flex;
			width: 100%;
			object-fit: contain !important;
			padding-top: 0 !important;
			align-items: center;
			justify-content: center;

			.video-react-button.video-react-big-play-button {
				width: 60px;
				height: 60px;
				align-items: center;
				justify-content: center;
				padding: 0;
				border-radius: 50%;
				background: rgba(0, 0, 0, 0.15);
				border: 2px solid #efefef;
				top: auto;
				left: auto;
				&:before {
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
			.video-react-control-bar {
				// bottom: 88px;
				height: 30px;
				// margin-bottom: 19px;

				.video-react-duration-display,
				.video-react-current-time {
					font-family: Metropolis;
					font-style: normal;
					color: #efefef;
				}
			}
		}
	}

	.header-btns {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		width: 100%;
		align-items: center;
		background: rgba(0, 0, 0, 0.5);
		// position: absolute;
		top: 0;
		right: 0;
		left: 0;
		z-index: 9;
		padding-top: 20px;
		.right {
			display: flex;
			align-items: center;
			margin-right: 18px;
			height: 70px;

			.btn {
				text-transform: none;
				background: #ff5e5e;
				--color: #efefef;
				font-family: Metropolis;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 14px;
				border-radius: 97px;
				min-width: 145px;
				height: 32px;
				display: flex;
				flex-direction: row;
				align-items: center;
				cursor: pointer;
				--padding-start: 15px;
				--padding-end: 15px;

				&.download-progress-btn {
					text-align: center;
					position: relative;
					text-transform: none;
					--padding-start: 0;
					--padding-end: 0;
					.percent-text {
						flex: 1;
						font-family: Metropolis;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 14px;
						text-align: center;
						color: #efefef;
					}
					ion-button {
						position: absolute;
						right: 12px;
						margin: 0;
						font-size: 12px;
						--padding-start: 0;
						--padding-end: 0;
						--color: #efefef;

						ion-icon {
							width: 15px;
							height: 15px;
							stroke: #fff;
						}
					}
				}
			}
		}
		.album-btn {
			width: 22px;
			height: 22px;
			ion-icon {
				width: 100%;
				height: 100%;
			}
		}
	}

	.editor-btns {
		justify-content: center;
		display: flex;
		flex-direction: row;
		margin-bottom: 30px;
		.editor-btn {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			img {
				width: 22px;
				height: 22px;
				filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(133deg) brightness(102%) contrast(104%);
			}
			span {
				margin-top: 8px;
				text-align: center;
				color: #fff;
				font-size: 14px;
				text-transform: none;
			}
		}
	}

	.photo-options-btns-container {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: flex-end;
		height: 116px;
		// position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.7);
		backdrop-filter: blur(20px);
		.btns {
			display: flex;
			width: 100%;
			flex-direction: row;
			height: 38px;
			margin-bottom: 50px;
			margin-right: 20px;
			ion-button {
				width: 38px;
				height: 38px;
				background: rgba(88, 88, 88, 0.6);
				backdrop-filter: blur(20px);
				border-radius: 50%;
				--padding-start: 0;
				--padding-end: 0;
				margin-left: 14px;
				margin-right: 0;
				ion-icon {
					width: 19px;
					height: 19px;
					&.close-icon {
						stroke: #efefef;
						stroke-width: 2px;
					}
				}
			}
		}
	}
}
