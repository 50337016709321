.my-alias-in-group-popup {
  --backdrop-opacity: 1 !important;

  ion-backdrop {
    opacity: 1;
    // backdrop-filter: blur(10px);
    background: rgba(0, 0, 0, 0.4);
  }
  .modal-shadow {
    background: transparent;
    pointer-events: none;
  }
  .modal-wrapper {
    background: transparent;
    max-width: unset !important;
    max-height: unset !important;
    width: 100% !important;
    height: 100% !important;
    opacity: 1;

    .ion-page {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;

      .container {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        .content-view {
          width: 100%;
          // background: rgba(var(--app-modal-bg-color-code), 0.7);
          background: #efefef;
          backdrop-filter: blur(40px);
          border-radius: 10px 10px 0 0;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 0 32px;
          .title {
            margin-top: 40px;
            font-family: Metropolis;
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 22px;
            color: #000000;
          }

          .tag-input-container {
            margin-top: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 56px;
            width: 100%;

            img {
              width: 40px;
              height: 40px;
              border-radius: 4px;
            }

            .input-view {
              width: 100%;
              border-radius: 5px;
              min-height: 40px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
            }
            .tag-name-input {
              font-family: Metropolis;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 16px;
              color: #000000;
              width: 100%;
              --padding-start: 12px;
              --padding-end: 18px;
            }

            .clear-icon {
              margin-right: 14px;
              width: 18px;
              height: 18px;
            }
          }

          .message {
            font-family: Metropolis;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 120%;
            color: #585858;
            width: 100%;
            margin-top: 17px;
          }
          .btns-view {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            justify-content: space-around;
            width: 100%;
            height: 73px;

            .delete-btn {
              font-family: Metropolis;
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 18px;
              color: #000000;
              text-transform: none;
            }
            .ok-btn {
              text-transform: none;
              font-family: Metropolis;
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 18px;
              color: #ff5e5e;
              &.button-disabled {
                color: #c4c4c4;
              }
            }
          }
        }
      }
    }
  }
}
