.auth-page {
	.inner-content {
		width: 100%;
		min-height: 100%;
		display: flex;
		flex-direction: column;
		position: relative;
	}
	.vertical-space-1 {
		flex: 1;
		min-height: 30px;
	}
	.vertical-space-2 {
		flex: 0.51;
		min-height: 30px;
	}
	.vertical-space-3 {
		flex: 0.7;
		min-height: 30px;
	}
	.vertical-space-4 {
		flex: 0.87;
		min-height: 30px;
	}
	.vertical-space-5 {
		flex: 0.24;
		min-height: 20px;
	}
	.skipbtn {
		font-size: 18px;
		line-height: 18px;
		letter-spacing: -0.015em;
		--color: #858484;
	}

	.formContainer {
		display: flex;
		text-align: start;
		margin: 0 8%;
		position: relative;
		top: unset;
		transform: unset;

		.step-heading {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			h1 {
				margin: 0;
				font-family: Metropolis;
				font-style: normal;
				font-weight: normal;
				font-size: 26px;
				line-height: 26px;
				display: flex;
				align-items: center;
				letter-spacing: -0.015em;
				color: #000000;
			}
			ion-label {
				margin-top: 28px;
				font-family: Metropolis;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 18px;
				letter-spacing: -0.015em;
				color: #787878;
				.heighlight {
					color: #ff5e5e;
				}
			}
			.username-id-view {
				display: flex;
				flex-direction: column;
				width: 100%;
				align-items: center;
				justify-content: center;
				margin-top: 27px;
				.userid-txt {
					font-weight: 900;
					font-size: 30px;
					line-height: 30px;
					color: #000000;
					margin-top: 0;
				}
				.username-txt {
					margin-top: 24px;
					font-size: 20px;
					line-height: 20px;
					color: #000000;
				}
			}
		}

		.input-item-list {
			width: 100%;
			background: transparent !important;

			.auth-title {
				padding: 0;
				font-size: 26px;
				line-height: 26px;
				color: #000000;
			}

			ion-item {
				&.auth-input-item {
					margin-top: 10px;
					--background: #fff;
					border-radius: 7px;
					--min-height: 48px;
					--padding-start: 16px;
					--inner-padding-end: 10px;
					ion-input {
						font-style: normal;
						font-weight: normal;
						font-size: 16px;
						line-height: 16px;
						display: flex;
						align-items: center;
						color: #000000;
						--padding-end: 0;
						input::placeholder {
							color: #a5a5a5;
						}
						caret-color: #ff5e5e;
						&#password {
							--padding-end: 40px !important;
						}
						input::-ms-reveal,
						input::-ms-clear {
							display: none;
						}

						.input-clear-icon {
							display: none;
							width: 16px;
							height: 16px;
							background-size: 18px;
							border-radius: 50%;
							color: #c4c4c4;
						}
						&.has-value {
							.input-clear-icon {
								display: block;
							}
						}
					}
					.visibility-onoff-btn {
						color: #c4c4c4;
						font-size: 16px;
						position: absolute;
						right: 38px;
						z-index: 10;
						margin: 0;
						--padding-start: 0;
						--padding-end: 0;
					}
					ion-label {
						font-style: normal;
						font-weight: normal;
						font-size: 16px;
						line-height: 16px;
						display: flex;
						align-items: center;
						letter-spacing: -0.015em;
						color: #585858;
						margin-right: 0;
						margin-inline-start: 8px;
					}
				}

				&.top-space {
					margin-top: 17.4px;
				}

				&.personal-device-checkbox-item {
					--background: transparent;
					--padding-start: 0;
					margin-top: 26px;
					--min-height: 20px;
					ion-checkbox {
						--background: transparent;
						--background-checked: #ff5e5e;
						--border-color: #858484;
						--border-color-checked: #e8e8e8;
						--checkmark-width: 3px;
						--checkmark-color: #e8e8e8;
						--border-width: 1px;
						--size: 20px;
						margin-right: 12px;
					}
					ion-label {
						font-weight: 500;
						font-size: 14px;
						line-height: 14px;
						display: flex;
						align-items: center;
						letter-spacing: -0.015em;
						color: #000000;
						margin: 0;
					}
				}
				&.show-password-checkbox-item {
					--background: transparent;
					--padding-start: 0;
					margin-top: 12px;
					ion-checkbox {
						--background: transparent;
						--background-checked: transparent;
						--border-color: #858484;
						--border-color-checked: #858484;
						--checkmark-width: 0px;
						--border-width: 1px;
						--size: 20px;
						position: relative;
						margin-right: 12px;
						display: flex;
						align-items: center;
						justify-content: center;
						&.checkbox-checked {
							&::before {
								content: '';
								position: absolute;
								border-radius: 50%;
								background: transparent;
								width: 12px;
								height: 12px;
								background-color: #000000;
							}
						}
					}
					ion-label {
						font-weight: 500;
						font-size: 14px;
						line-height: 14px;
						display: flex;
						align-items: center;
						letter-spacing: -0.015em;
						color: #000000;
					}
				}
			}
			.personal-device-text {
				margin-top: 21.6px;
				padding: 0;
				text-align: left;
				font-size: 14px;
				line-height: 15.5px;
				letter-spacing: -0.015em;
				color: #000000;
				min-height: 38px;
				.typography {
					color: #ff5e5e;
					font-weight: 500;
					cursor: pointer;
				}
			}
		}

		.formFields {
			color: black;
			font-size: 10px;
			border: solid 2px;
			padding-left: 10px !important;
			height: 50px;
		}

		.profile-photo-view {
			display: flex;
			flex-direction: column;
			flex: 1;
			.profile-photo {
				margin-top: 30px;
				width: 90vw;
				height: 90vw;
				max-width: 320px;
				max-height: 320px;
				border-radius: 10px;
				object-fit: contain;
				border: none;
			}

			.photo-btns-container {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				margin-top: 40px;
				ion-button {
					margin: 0 40px;
					width: 60px;
					height: 60px;
					--border-radius: 0;
					img {
						min-width: 60px;
						min-height: 60px;
						object-fit: contain;
					}
				}
			}
		}
	}

	.warnings {
		color: #ff5e5e;
		font-size: 16px;
		padding-left: 0;
		font-weight: 500;
		padding-bottom: 16px;
	}

	.back-btn-icon-row {
		position: absolute;
		right: 0;
		bottom: 0;
		.back-btn-icon {
			font-size: 25px;
			color: #000;
			float: right;
			margin-right: 35px;
			margin-bottom: 35px;
		}
	}

	.backDiv {
		border: solid 1px;
		height: 50px;
	}
	.backIcon {
		--ionicon-stroke-width: 16px;
		float: right;
	}

	ion-toolbar {
		--min-height: 56px;
		&.header-toolbar {
			padding-top: 34px;
		}
	}

	.loading-wrapper .loading-content {
		color: #fff !important;
	}

	.loading-spinner ion-spinner {
		color: #fff !important;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		--webkit-text-fill-color: #000000;
		--webkit-box-shadow: 0 0 0 30px white inset !important;
		transition: background-color 5000s ease-in-out 0s;
	}

	.tos {
		color: #000000;
		line-height: 2px;
	}

	.be-society {
		text-align: center;
		h1 {
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: Comfortaa;
			font-size: 24px;
			font-weight: bold;
			height: 24px;
		}
		ion-label {
			font-family: Comfortaa;
			font-size: 15px;
			color: rgba(0, 0, 0, 0.9);
		}
	}
	.verification-required {
		text-align: center;
		font-size: 25px;
		font-weight: 400;
	}
	.input-text-container {
		display: flex;
		justify-content: center;
		margin-top: 10px;
		.input-text-field {
			width: 48px;
			max-width: 48px;
			height: 48px;
			background: white;
			display: flex;
			text-align: center;
			margin-left: 11px;
			border-radius: 7px;
			text-transform: uppercase;

			input {
				border-radius: 4px;
				font-size: 1rem;
				margin: 0.25rem;
				min-width: 12px !important;

				padding: 0.5rem;
				transition: background-color 0.5s ease-out;
			}
			input:optional {
				border-color: gray;
			}
			input:required {
				border-color: #ff5e5e;
			}
			input:invalid {
				border-color: red;
			}

			&.has-focus {
				border: 2px solid #ff5e5e;
			}
		}
		.input-text-last {
			border: 2px solid #ff5e5e;

			input:optional {
				border-color: #ff5e5e;
			}
		}
	}

	.error-message {
		width: 100%;
		text-align: center;
		color: #ff5e5e;
		margin-top: 26px;
		margin-bottom: 26px;
	}
	.accept-button {
		display: flex;
		justify-content: center;
		align-items: center;
		.send-button-buttom {
			margin: 0;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 18px;
			text-align: right;
			--color: #ffffff;
			width: 200px;
			height: 48px;
			--border-radius: 5px;
			--background: #ff5e5e;
			text-transform: none;
			--box-shadow: unset;
			&.button-disabled {
				--background: #dcdcdc;
				opacity: 1;
			}
		}
	}

	.auth-wrapper {
		--background: var(--app-color-code);
	}

	.input-helper-row {
		ion-col {
			font-size: 14px;
			line-height: 14px;
			color: #ff5e5e;
			padding: 0px 16px;
			margin-top: 10.6px;
		}
	}

	.register-description {
		margin-top: 25px;
		font-size: 14px;
		line-height: 18px;
		letter-spacing: -0.015em;
		color: #000000;
		padding: 0 5px;
		.heighlight {
			color: #ff5e5e;
		}
	}

	.signup-error-view {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 0 12px;
		text-align: center;
		margin-top: 12px;
		ion-icon {
			width: 40px;
			height: 40px;
		}
		ion-label {
			margin-top: 18px;
			font-size: 16px;
			color: #000000;
		}
	}

	.besociety-description {
		font-size: 14px;
		line-height: 14px;
		letter-spacing: -0.015em;
		color: #858484;
		margin-top: 25px;
		padding: 0;
	}

	.auth-button-col {
		text-align: center;
		bottom: 0;
		padding: 0;
		.auth-button {
			font-size: 12px;
			height: 48px;
			padding: 0 44px;
			font-size: 18px;
			line-height: 18px;
			letter-spacing: -0.015em;
			color: #e8e8e8;
			border: solid 1px;
			border-radius: 5px;
			text-transform: none;
			background: #ff5e5e;
			--box-shadow: none;
			margin-bottom: 0;
			min-width: 200px;
			&.button-disabled {
				background: #c4c4c4;
				opacity: 1;
			}
		}
	}

	.auth-register-button {
		justify-content: center;
		display: flex;
		padding: 0;
		margin-top: 23px;
		.register-button {
			height: 24px;
			margin: 0;
			color: #ff5e5e;
			font-weight: 500;
			font-size: 18px;
			text-transform: none;
		}
	}

	.copyright-version-row {
		.copyright-symbol {
			margin: 0 5px;
			object-fit: contain;
			border-radius: 100%;
			border: 0.8px solid #585858;
			min-width: 13px;
			min-height: 13px;
			max-width: 13px;
			max-height: 13px;
			align-items: center;
			justify-content: center;
			display: inline-flex;
			font-size: 10px;
		}
		.version {
			margin-top: 9px;
		}
		ion-col {
			padding: 0;
		}
		p {
			margin: 0 !important;
			line-height: 18px;
		}
	}
}

.screen-loader-container {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	ion-label {
		margin-top: 27px;
		font-size: 18px;
		line-height: 18px;
		color: #000000;
	}
}

.creating-account-modal {
	--background: rgba(0, 0, 0, 0.5);

	.modal-wrapper {
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		height: 100% !important;
	}
	.creating-account-loader {
		// width: 90vw;
		// height: 90vw;
		// max-width: 320px;
		// max-height: 320px;
		background: rgba(255, 255, 255, 0.6);
		backdrop-filter: blur(20px);
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: absolute;

		.title {
			font-size: xx-large;
			margin-bottom: 0px;
			font-family: Comfortaa;
			font-weight: 500;
			margin-top: 40px;
		}
		.description {
			margin-top: 10px;
			font-family: Comfortaa;
			font-size: 15px;
			color: rgba(0, 0, 0, 0.9);
		}
		.loading-text {
			font-size: 18px;
			font-weight: 500;
			color: #000000;
			margin-bottom: 40px;
			margin-top: 0;
		}
	}
}
