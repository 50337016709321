.chat-searchbar {
	--min-width: 100%;
	--background: transparent;
	padding-top: 82px;
	// margin-top: 75px;
	--height: calc(100vh - 75px);
	align-items: unset;
	&.chat-active {
		margin-top: 0;
		--height: 100vh;
	}
	.modal-wrapper {
		min-width: 100% !important;
		background: transparent;
	}

	.container {
		background: #efefef;
		height: 100%;
		display: flex;
		flex-direction: column;
		padding-top: 14px;
		// margin-top: 16px;
		.dashboard-searchbar {
			--padding-start: 10px;
			--padding-end: 10px;
			form {
				display: flex;
				flex-direction: row;
				align-items: center;
				.cancel-search-btn {
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 16px;
					--color: #585858;
					margin: 0;
					--padding-start: 0;
					--padding-end: 0;
				}
				ion-searchbar {
					height: 36px;
					padding: 0;
					margin-right: 10px;
					ion-icon {
						left: 12px;
					}
					&.searchbar-wrapper {
						--background: #ffffff;
						padding-bottom: 0;
						--border-radius: 5px !important;

						.searchbar-input-container {
							border-radius: 5px !important;
							padding-left: 0;
							overflow: hidden;
							.searchbar-input {
								font-size: 16px;
								line-height: 16px;
								color: #000000;
								padding-left: 38px;
								&::placeholder {
									color: #a5a5a5;
								}
							}
						}
					}
				}
			}
		}

		.content {
			flex: 1;
			overflow: scroll;
			width: 100%;
			.searching-view {
				margin-top: 32px;
				width: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;

				ion-spinner {
					margin-right: 10px;
					width: 20px;
					height: 20px;
					--color: #858484;
				}
				.loading-text {
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 400;
					font-size: 15px;
					line-height: 15px;
					color: #858484;
				}
			}

			.category-container {
				padding: 16px 0px;
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				justify-content: center;
				.item {
					display: flex;
					flex-direction: column;
					width: 118px;
					height: 90px;
					align-items: center;
					justify-content: center;
					cursor: pointer;

					&.active {
						opacity: 1;
					}
					&.inactive {
						opacity: 0.3;
					}
					ion-icon {
						height: 20px;
						width: 22px;
						padding-top: 2px;
						padding-bottom: 1px;
					}
					.title {
						margin-top: 8px;
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						line-height: 14px;
						text-align: center;
						color: #000000;
					}
				}
				.item-fill {
					display: flex;
					width: 118px;
					height: 0.1px;
				}
			}

			.search-result-container {
				width: 100%;
				height: auto;
				display: flex;
				flex-direction: column;
				padding-top: 8px;
				cursor: pointer;

				.matching {
					color: #ff5e5e;
				}

				.inner-item {
					display: flex;
					flex-direction: column;
					.group-title {
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						line-height: 16px;
						color: #000000;
						height: 40px;
						padding: 0 18px;
						padding-top: 15px;
					}

					.more-contacts {
						display: flex;
						flex-direction: row;
						align-items: center;
						padding: 0 18px;
						height: 40px;
						cursor: pointer;

						.search-icon {
							width: 16px;
							height: 16px;
						}

						.arrow-icon {
							width: 7px;
							height: 12px;
						}

						.title {
							margin: 0 10px;
							flex: 1;
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 400;
							font-size: 14px;
							line-height: 14px;
							color: #585858;
						}
					}
				}
				.search-app-id-view {
					display: flex;
					flex-direction: row;
					align-items: center;
					height: 56px;
					padding: 0 18px;

					ion-icon {
						width: 40px;
						height: 40px;
						margin-right: 12px;
					}
					.title {
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 400;
						font-size: 18px;
						line-height: 18px;
						color: #000000;
					}
				}

				.ecosystem-view {
					display: flex;
					flex-direction: row;
					align-items: center;
					padding: 0 18px;
					min-height: 92px;
					cursor: pointer;

					.search-icon-view {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 40px;
						min-width: 40px;
						.search-icon {
							width: 26px;
							height: 26px;
						}
					}

					.arrow-icon {
						width: 7px;
						height: 12px;
					}

					.details-view {
						display: flex;
						flex-direction: column;
						margin-left: 14px;

						.title {
							flex: 1;
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 400;
							font-size: 18px;
							line-height: 18px;
							color: #000000;
						}

						.subtitle {
							margin-top: 5px;
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 400;
							font-size: 16px;
							line-height: 16px;
							color: #585858;
						}
					}
				}
			}

			.no-content-found {
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 400;
				font-size: 15px;
				line-height: 15px;
				text-align: center;
				color: #585858;
				.heightlight {
					color: #ff5e5e;
				}
			}
		}

		.option-toggle {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-around;
			width: 100%;
			height: 90px;
			.switch-item {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				ion-label {
					margin-top: 8px;
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 14px;
					text-align: center;
					color: #000000;
				}
			}
		}
	}
}
