.select-main {
	display: flex;
	flex-direction: column;
	background-color: #efefef;
	border-radius: 14px 14px 0px 0px;

	width: 100%;

	.option-content {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: flex-start;
		margin-left: 20px;
		align-items: center;
		z-index: 99999999999;
		margin-bottom: 40px;

		img {
			width: 26px;
			height: 26px;
		}
		p {
			font-family: 'Metropolis';
			font-size: 16px;
			font-weight: 600;
			color: black;
			text-transform: uppercase;
			margin-left: 15px;
			padding: 0px;
		}
	}
}

.option-bottom-popup {
	display: flex;
	flex-direction: column;
	min-width: 414px;
	width: 100%;
	height: auto;
	position: relative;
	right: 0;
	bottom: 50px;
	background: rgba(239, 239, 239, 0.7);
	backdrop-filter: blur(20px);
	border-radius: 0px;
	z-index: 999999;
	.share-files {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 18px;
		color: #585858;
		padding-left: 17px;
		padding-top: 17px;
		padding-bottom: 17px;
		.popup-manage {
			width: 7.8pxpx;
			height: 14px;
			right: 0;

			.arrow-right {
				width: 100%;
				height: 100%;
				right: 0;
				.arrow-right-light {
					width: 100%;
					height: 100%;
				}
			}
		}

		.selected-file {
			padding: 17px;
		}
	}

	.option-Icon-list {
		display: flex;
		justify-content: right;
		align-items: center;
		width: auto;
		height: 38px;
		bottom: 53px;
		flex: 1 1;
		margin-top: 14px;
		margin-right: 17px;
		.popup-media {
			border-radius: 100%;
			width: 38px;
			min-width: 38px;
			height: 38px;
			--padding-start: 0;
			--padding-end: 0;
			margin-left: 14px;
			// .popup-media-image-right{
			//     height: 14px;

			//   }
			.popup-image-container {
				width: 100%;
				height: 100%;
				position: absolute;
				border-radius: 50%;
				background: rgba(88, 88, 88, 0.6);
				backdrop-filter: blur(20px);
				opacity: 0.2;

				.popup-media-image {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
}

.select-all {
	text-transform: capitalize;

	display: flex;
	justify-content: left;
	align-items: center;
	left: 0px;
	font-family: 'Metropolis';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 18px;
	color: #585858;
}

.bottom-manage-send-popup {
	display: flex;
	flex-direction: column;
	min-width: 414px;
	width: 100%;
	height: auto;
	position: relative;
	right: 0;
	bottom: 88px;
	background: rgba(239, 239, 239, 0.7);
	backdrop-filter: blur(20px);
	border-radius: 0px;
	z-index: 999999;

	.select-content {
		width: 100%;
		height: 22px;
		display: flex;
		justify-content: space-evenly;
		margin-left: 26px;
		align-items: center;
		z-index: 99999999999;
		margin-top: 23px;

		.folder-img {
			width: 23px;
			height: 21px;
			fill: #585858;
		}
		p {
			font-family: 'Metropolis';
			font-size: 16px;
			font-weight: 600;
			color: black;
			text-transform: uppercase;
			margin-left: 13px;
			padding: 0px;
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	.file-name {
		width: 100%;
		height: auto;
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 16px;
		color: #000000;
	}

	.share-files {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 18px;
		/* identical to box height */

		color: #585858;
		.selected-send-file {
			width: 100%;
			display: flex;
			justify-content: space-between;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			color: #585858;
			padding-left: 17px;
			padding-top: 17px;
		}

		.popup-manage {
			// width: 7.8px;
			// height: 14px;
			right: 0;

			.arrow-right {
				width: 100%;
				height: 100%;
				right: 0;
				.arrow-right-light {
					width: 100%;
					height: 100%;
				}
			}
		}

		.selected-file {
			padding: 17px;
		}
	}

	.manage-share-top {
		display: flex;
		flex-direction: column;
		margin-left: 10px;
		margin-top: 30px;

		.send {
			display: flex;
			flex-direction: column;
		}
		.manage-files {
			width: 100%;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 18px;
			padding: 17px;
			color: #585858;
		}

		.selected-send-file {
			width: 100%;
			display: flex;
			justify-content: space-between;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			color: #585858;
			padding-left: 17px;
			// padding-top: 17px;
			// padding-bottom: 17px;
			.manage {
				display: flex;
				align-items: center;
			}
		}

		.popup-manage {
			// width: 7.8px;
			// height: 14px;
			right: 0;

			.arrow-right {
				width: 100%;
				height: 100%;
				right: 0;
				.arrow-right-light {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
}

.shared::-webkit-scrollbar {
	display: none;
}

.shared {
	width: 100%;
	height: 80px;
	display: flex;
	flex-direction: row;
	padding-left: 18px;
	overflow-x: auto;
	.shared-image {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 48px;
		margin-right: 16px;
		margin-top: 8px;
		align-items: center;
		ion-icon {
			width: 48px;
			height: 48px;
			border-radius: 4px;
		}
		.icon-name {
			margin-top: 5px;
			color: black;
			padding-bottom: 4.5px;
		}
	}
}

.bottom-media-option-popup {
	display: flex;
	flex-direction: row;
	align-items: center;
	min-width: 414px;
	width: 100%;
	height: 88px;
	justify-content: flex-end;
	position: fixed;
	right: 0;
	bottom: 0;
	background: rgba(239, 239, 239, 0.7);
	backdrop-filter: blur(20px);
	z-index: 999999;
	& .active {
		background-color: red;
	}

	.select-all {
		text-transform: capitalize;
		display: flex;
		justify-content: left;
		align-items: center;
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 16px;
		color: #000000;
	}
	.select-all-active {
		margin-top: 10px;
		color: red;
		text-transform: capitalize;
	}

	.popup-Icon-list-select {
		display: flex;
		justify-content: right;
		align-items: center;
		width: 298px;
		height: 38px;
		flex: 1;
		margin-right: 0px;
		margin-left: 100px;
		.popup-media {
			border-radius: 100%;
			width: 38px;
			min-width: 38px;
			height: 38px;
			--padding-start: 0;
			--padding-end: 0;
			margin-left: 14px;
			.popup-image-container {
				width: 100%;
				height: 100%;
				position: absolute;
				border-radius: 50%;
				background: rgba(88, 88, 88, 0.6);
				backdrop-filter: blur(20px);
				opacity: 0.2;
				.popup-media-image-right {
					height: 14px;
				}
				.popup-media-image {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
	.popup-Icon-list {
		display: flex;
		justify-content: right;
		align-items: center;
		width: 298px;
		height: 38px;
		flex: 1;
		margin-right: 0px;
		margin-left: 100px;

		.popup-media {
			border-radius: 100%;
			width: 38px;
			height: 38px;
			min-width: 38px;
			--padding-start: 0;
			--padding-end: 0;
			margin-left: 14px;
			.popup-image-container {
				width: 100%;
				height: 100%;
				position: absolute;
				border-radius: 50%;
				background: rgba(88, 88, 88, 0.6);
				backdrop-filter: blur(20px);
				opacity: 0.2;
				.popup-media-image-right {
					height: 14px;
				}
				.popup-media-image {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
}

.select-component-toast-view {
	--background: rgba(83, 83, 83, 0.7);
	--backdrop-filter: blur(20px);
	--border-radius: 6px;
	--color: white;
	--button-color: white;
	// --white-space: var(--color);
	// --height: 50px;
	// --width: 50px;
}
.popup-star {
	font-weight: 700;
	color: #000000;
}
