#background-content {
	background-color: #d64747;
}
.toolbarMain {
	height: 100px;
}
.searchBackground {
	background: #000000;
}
.toolbarTitle {
	text-align: center;
}
.searchbar-input {
	background: #ffffff;
	--border-radius: 6px;
	--color: #000000 !important;
}
.contact-star {
	color: #f9b51d;
}
.work-contact {
	.item-native {
		.item-inner {
			border: none;
		}
	}
}
.cont-divider {
	color: #000000;
	border: none;
	background: transparent !important;
	// background: var(--app-color-code);
}
.alpha-contacts {
	ion-item {
		color: #000000;
	}
}
.dbGrid {
	height: 100%;
}
.dbRow {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.contact-page-list {
	padding-top: 0%;
	padding-bottom: 0%;
}

.main-group {
	ion-item {
		color: #000000;
	}
	ion-icon {
		color: #000000;
	}
}
.view-profile-label {
	--min-height: 10px;
	--padding: 0px;
}
.view-profile-list {
	background: transparent;
}

.search-contacts {
	.wrap-contacts {
		--background: var(--app-color-code);
	}

	.search-app-id-view {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0 18px;
		cursor: pointer;

		ion-icon {
			width: 40px;
			height: 40px;
			margin-right: 12px;
		}
		.title {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 18px;
			color: #000000;
		}

		.matching {
			color: #ff5e5e;
		}
	}
	.qr-code-deal {
		display: flex;
		justify-content: center;
	}

	.scanbtns-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-around;
		padding: 16px;
		background-color: var(--app-color-code);
		ion-button {
			opacity: 0.3;
			font-size: 30px;
		}
	}
	.quick-access-modal {
		width: 100%;
		height: 100% !important;
	}
}

.add-contact-col {
	background: white;
	border: solid 1px;
	border-radius: 6px;
}
.send-req-button {
	--border-radius: 5px !important;
}

.contacts-req-sent {
	h2 {
		font-size: medium;
	}
	button {
		font-size: medium;
	}
}
.contact-wrap {
	.formContainer {
		position: relative;
		top: 0;
		transform: translateY(0);
	}
}
.contact-view {
	.formContainer {
		position: relative;
		top: 0;
		transform: translateY(0);
		ion-item {
			--background: #fff !important;
		}
	}

	.bchatList {
		margin: 16px;
	}

	.sec-1 {
		ion-item {
			--min-height: 20px;
			ion-label {
				margin: 5px 0;
			}
		}
	}
	.sec-2 {
		ion-list {
			background: var(--app-color-code) !important;
			--background: var(--app-color-code) !important;
		}
		.label-value-row-item {
			--padding-top: 12px;
			--padding-bottom: 12px;
			--border-color: #f2f2f2;
			--border-width: 0 0 2px 0;

			.label-value-row {
				display: flex;
				flex-direction: row;
			}

			ion-text {
				font-weight: 600;
				min-width: 90px;
			}

			ion-label {
				white-space: normal;
				line-height: 125%;
			}
		}
	}
}

.contacts-page {
	.topbar-header {
		.toolbar-heading {
			--padding-start: 10px;
			--padding-end: 18px;
			// .buttons-first-slot {
			// 	ion-button {
			// 		margin: 0;
			// 		padding: 0 8px;
			// 		ion-icon {
			// 			width: 28px !important;
			// 			height: 20px !important;
			// 		}
			// 	}
			// }
			.buttons-last-slot {
				justify-content: flex-end;
				ion-button {
					margin: 0;
					ion-icon {
						width: 24px;
						height: 24px;
					}
				}
			}
			ion-title {
				font-family: Metropolis;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 18px;
				text-align: center;
				color: #000000;
			}
		}
		.searchbar-wrapper {
			padding-inline-start: 8px;
			padding-inline-end: 8px;
		}
	}
	ion-list {
		background: var(--app-color-code);
		ion-item {
			--min-height: 52px;
		}

		ion-item-devider {
			background: var(--app-color-code);
		}
	}

	ion-list.contact-groups-list {
		padding-bottom: 0;

		ion-item {
			ion-label {
				font-size: 16px;
				line-height: 16px;
				color: #000000;
			}

			.icon-div {
				width: 40px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 13px;
				padding-left: 2px;
				padding-bottom: 1px;
				ion-icon {
					font-size: 1.6em;
				}
			}
			&.recent_friends {
				ion-icon {
					width: 30px;
					height: 25.83px;
				}
			}
			&.group_chats {
				ion-icon {
					width: 40px;
					height: 40px;
				}
			}
			&.tags {
				ion-icon {
					width: 24px;
					height: 24px;
				}
			}
			&.official_accounts {
				ion-icon {
					width: 30px;
					height: 24px;
				}
			}
			&.work_accounts {
				ion-icon {
					width: 24px;
					height: 24px;
				}
			}
			ion-badge {
				margin-left: 20px;
				position: absolute;
				top: 0;
				min-width: 16px;
				min-height: 16px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 12px;
				background: #3ebaff;
				box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
				padding: 0;
				font-size: 9px;
			}
			ion-note {
				font-size: 14px;
				color: #858484;
				margin-right: 4px;
			}
		}
	}

	.starred-title-view {
		height: 30px;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 0 18px;
		ion-icon {
			width: 17px;
			height: 17px;
		}
		.title {
			margin-left: 13px;
			font-family: Metropolis;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 16px;
			color: #585858;
		}
	}

	.selected-contact-mode-footer {
		box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.15);
		background-color: rgba(var(--app-modal-bg-color-code), 0.9);
		position: absolute;
		bottom: 0;
		padding-top: 5px;
		padding-bottom: 35px;
		ion-toolbar {
			--border-width: 0;
			--background: transparent;
		}
	}

	.recent-contacts-divider-title {
		font-weight: normal;
		font-size: 16px;
		line-height: 29px;
		color: #000000;
		--background: #dcdcdc;
		height: 30px;
		padding: 0 30px;
		margin-top: 8px;
		border: none;
	}
}

.chat-contacts-page {
	.header {
		--background: transparent;
		ion-button {
			text-transform: none;
		}
		ion-title {
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 21px;
			text-align: center;
			letter-spacing: -0.333333px;
			color: #000000;
		}
		.done-button {
			--padding-start: 10px;
			--padding-end: 10px;
			--background: #ff5e5e;
			--color: #e8e8e8;
			font-size: 16px;
			text-transform: none;
			--border-radius: 5px;
			&.button-disabled {
				--background: #a5a5a5;
				opacity: 1;
			}
		}
	}
	.searchbar-wrapper {
		--background: #ffffff;
		margin-top: 0px;
		text-align: left;
		padding-bottom: 0;

		--box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
		--border-radius: 5px !important;
		.searchbar-input-container {
			border-radius: 5px !important;
		}
	}

	.contact-groups-list {
		ion-item {
			--min-height: 64px;
		}
	}

	ion-item-divider {
		--min-height: 30px;
		background-color: #dcdcdc;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 29px;
		color: #858484;
		border: none;
	}
}

.search-contacts-tool-modal {
	.ion-page {
		justify-content: flex-start;
		align-items: center;
		background: var(--app-color-code);

		.toolbar-searchbar {
			margin-top: 12px;
			ion-title {
				padding-left: 0;
				padding-right: 70px;
			}
			ion-buttons {
				margin: auto;
			}
		}

		.searchbar-wrapper {
			--background: #ffffff;
			margin-top: 0px;
			text-align: left;
			padding-bottom: 0;

			--box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
			--border-radius: 5px !important;
			.searchbar-input-container {
				border-radius: 5px !important;
			}
		}

		.tools-container {
			margin-top: 30px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			width: 85%;
		}
	}
}

.toolbar-wrapper {
	.send-button {
		border-radius: 8px;
		display: flex;
		align-items: center;
		--padding-start: 10px;
		--padding-end: 10px;
		--background: #ff5e5e;
		--color: #e8e8e8;
		font-size: 16px;
		text-transform: none;
		&.button-disabled {
			//   --background: blue;
			--background: #ff5e5e;

			opacity: 1;
		}
	}
}

.manage-contact-action-sheet .action-sheet-destructive {
	margin-top: 9px;
	font-family: 'Metropolis';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 18px;
	text-align: center;
	color: #ff5e5e;
	margin-bottom: 44px;
}
.add-contact-page,
.manage-contact-page {
	ion-label {
		transform: none !important;
	}
	ion-icon {
		margin-top: 8px !important;
		margin-bottom: 3px !important;
		margin-right: 14px;
		color: #a5a5a5;
	}
	.Greetings {
		margin-top: 15px;
		.greeting-textcount {
			display: flex;
			justify-content: space-between;
			.reaminig-letter-label {
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 14px;
				text-align: right;

				color: #a5a5a5;
			}
		}
		ion-label {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 14px;
			/* identical to box height */

			--color: #858484;
		}
		ion-textarea {
			--padding-top: 10px !important;
			--padding-bottom: 10px !important;
		}
	}
	.alias-input {
		ion-label {
			margin-top: 0px !important;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 14px;
			/* identical to box height */

			--color: #777777;
		}
		ion-input {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 16px;

			--color: #000000;
		}
	}
	.set-tag {
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 14px;
		--color: #858484;
		margin-top: 23px;
	}
	.tags-inner-manage {
		width: 100%;
		--background: #fff;
		--border-radius: 6px;
		.tag-input {
			max-height: 56px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			padding: 19px 14px 19px 19px;
			ion-icon {
				color: #a5a5a5;
			}

			.inner-tag-input {
				display: flex;
				.placeholder-txt {
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 16px;
					color: #a5a5a5;
				}
				.tagname {
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 16px;
					color: #ff5e5e;
				}
				.add-tag {
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 16px;
					color: #a5a5a5;
					margin-left: 5px;
					cursor: pointer;
				}
			}
		}
		ion-input,
		input {
			pointer-events: none !important;
		}
	}

	.toolbar-wrapper {
		height: 90px;
		//  justify-content: flex-end;
		// align-items: flex-end;
		display: flex;
	}
	.send-div {
		display: flex;
		justify-content: center;
		margin-top: 20px;
		.send-button-buttom {
			margin: 0;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 18px;
			text-align: right;
			--color: #ffffff;
			width: 184px;
			height: 48px;
			--border-radius: 8px;
			--background: #ff5e5e;
			text-transform: none;
			--box-shadow: unset;
			&.button-disabled {
				--background: #dcdcdc;
				opacity: 1;
			}
		}
	}
	ion-content {
		--background: #efefef;
	}
	ion-list {
		padding: 18px;
		padding-top: 0;
		padding-bottom: 0;
		// padding-top: none !important;
		// background: transparent;
		// background: #efefef;
		.chat-image {
			width: 40px;
			height: 40px;
			margin-right: 7px;
		}
		ion-item {
			--background: transparent;
			--padding-start: 0;
			--padding-end: 0;
			--inner-padding-end: 0;
			ion-label {
				font-size: 14px;
				font-weight: 500;
				font-size: 14px;
				line-height: 26px;
				margin-bottom: 5px !important;
				color: #535353 !important;
			}

			ion-textarea,
			ion-input {
				min-height: 56px;
				background: #ffffff;
				--padding-start: 16px !important;
				--padding-end: 16px !important;
				border-radius: 6px;
				font-weight: normal;
				font-size: 17px;
				line-height: 20px;
				color: #979797;
			}

			ion-textarea {
				margin-top: 0;
				padding: 12px !important;
				color: #000000;
			}

			.tags-inner-item {
				width: 100%;
				--background: #fff;
				--border-radius: 6px;
				.tag-input {
					max-height: 56px;
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					width: 100%;
					padding: 19px 14px 19px 19px;
					ion-icon {
						color: #a5a5a5;
					}

					.inner-tag-input {
						display: flex;
						.placeholder-txt {
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 400;
							font-size: 16px;
							line-height: 16px;
							color: #a5a5a5;
						}
						.tagname {
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 500;
							font-size: 16px;
							line-height: 16px;
							color: #ff5e5e;
						}
						.add-tag {
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 500;
							font-size: 16px;
							line-height: 16px;
							color: #a5a5a5;
							margin-left: 5px;
							cursor: pointer;
						}
					}
				}
				ion-input,
				input {
					pointer-events: none !important;
				}
			}
		}

		.privacy-title {
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			// color: #535353;
			color: #858484;
		}

		.privacy-item {
			background: #e6e6e6;
			border-radius: 8px;
			padding-left: 19px;
			padding-right: 19px;
			padding-top: 14px;
			padding-bottom: 11px;
			margin-bottom: 16px;
			ion-item {
				ion-icon {
					--color: red;
					-webkit-text-stroke-color: red;
				}
			}

			h2 {
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 16px;
				color: #000000;
			}
			p {
				margin-top: 7px;
				padding: 0;
				white-space: normal;
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 140%;
				color: #585858;
			}

			.profile-container {
				display: flex;
				margin-top: 12px;
				margin-left: 1px;
				.profile-image-container {
					width: 50px;
					height: 50px;
					border-radius: 4px;
					margin-bottom: 16px;
				}
				.profile-title-container {
					display: flex;
					flex-direction: column;
					margin-left: 13px;
					.progile-photo-title {
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						line-height: 16px;
						color: #585858;
						padding-top: 7px;
					}
					.tap-change {
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						line-height: 140%;
						color: #585858;
						margin-top: 7px;
					}
				}
			}
			&.selected {
				ion-item {
					--detail-icon-color: red;
				}
				background: rgba(255, 94, 94, 0.25);
				opacity: 0.88;
				.profile-title-container {
					:first-child {
						padding-top: 7px;
						color: #ff5e5e;
					}
					:last-child {
						color: #ff5e5e;
						margin-top: 7px;
					}
				}
				h2,
				p {
					color: #ff5e5e;
					stroke: red;
				}
			}
		}

		.setting-toggle-container {
			// margin-top: 20px;
			width: 100%;
			height: 64px;
			padding: 16px 0;
			padding-top: 0;
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			.vertical-line {
				height: 44px;
				margin: 0 10px;
				width: 0.5px;
				border-right: 0.5px solid #585858;
			}
			.swtich-item {
				ion-toggle.toggle-customised {
					--background: #dcdcdc;
					--background-checked: #3ebaff;
					--handle-background-checked: white;
					--handle-background: white;
					--handle-height: 20px;
					--handle-width: 20px;
					--handle-border-radius: 50%;
					--handle-max-height: 20px;
					--handle-spacing: 1px;
					width: 40px;
					height: 21px;
				}
			}
		}
		.swtich-item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			ion-label {
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 14px;
				text-align: center;
				color: #000000;
				margin-top: 11px;
				.label {
					font-size: 17px;
					color: #000000;
					margin-top: 5px;
				}
			}

			ion-toggle.toggle-customised {
				--background: #dcdcdc;
				--background-checked: #3ebaff;
				--handle-background-checked: white;
				--handle-background: white;
				--handle-height: 20px;
				--handle-width: 20px;
				--handle-border-radius: 50%;
				--handle-max-height: 20px;
				--handle-spacing: 1px;
				width: 40px;
				height: 21px;
			}
		}
	}

	.title {
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 22px;
		line-height: 22px;
		text-align: center;
		color: #000000;
	}

	.manage-contact-action-sheet .action-sheet-group-cancel {
		--padding-bottom: 10px;
	}
	.user-detail-main {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	// .star {
	// 	width: 20px;
	// 	height: 20px;
	// 	margin-top: 25px;
	// 	margin-right: 20px;
	// }
	.user-detail-container {
		width: 100%;
		height: 128px;
		display: flex;
		align-items: center;
		margin-left: 17px;

		.default-image-container {
			width: 64px;
			height: 64px;
			.default-image {
				border-radius: 4px;
			}
		}
		.user-name-container {
			width: auto;
			display: flex;
			flex-direction: column;
			margin-left: 18px;
			.user-name {
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 600;
				font-size: 22px;
				line-height: 22px;
				color: #000000;
			}
			.user-id {
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 16px;
				color: #858484;
				margin-top: 6px;
				.region {
					margin-top: 4px;
				}
			}
		}
	}
	.manage-contact-options-list {
		background: transparent;
		ion-item {
			--min-height: 56px;
			font-size: 16px;
			font-weight: 400;
			color: #000000;
		}
		.alias-input {
			margin-top: 30px;
			.set-alias {
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 14px;
				color: #777777;
			}
		}
		.tags-inner-item {
			.tag-input {
				width: 100%;
				display: flex;
				flex-direction: row;
				background: white;
				align-items: center;
				margin-bottom: 24px;
				.tag-input-field {
					width: 100%;
				}
				.inner-tag-input {
					width: 100%;
					display: flex;
					align-items: center;
					.input-blanck {
						flex: 1;
						min-width: 19px;
					}
					.placeholder-txt {
						width: 100%;
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						line-height: 16px;
						color: #a5a5a5;
					}
				}
			}
		}
	}

	.action-btn-container {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 56px;
		margin-bottom: 50px;
		// flex: 1;
		// button:focus {
		// 	outline: 0;
		// }

		.action-btn {
			margin-bottom: 12px;
			margin-top: 8%;
			width: 80%;
			--background: transparent;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 18px;
			text-align: center;
			color: #ff5e5e;
			--box-shadow: none;
			text-transform: capitalize;
		}
	}
	.buttons {
		display: flex;
		flex-direction: column;
		.cancel-button-buttom {
			--background: none;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 16px;
			text-align: center;
			color: #585858;
			--border: none;
			margin-top: 9px;
			--box-shadow: none;
			text-transform: capitalize;
		}
	}
}

.manage-contact-action-sheet {
	.action-sheet-title {
		line-height: 20px;
		padding: 0;
		background: null;
		height: 66px;
		text-align: center;
		display: flex;
		align-items: center;
		padding: 0 10px;
		justify-content: center;
		font-size: 13px;
		color: #585858;
	}

	.action-sheet-container {
		padding: 0;
	}

	.action-sheet-group {
		// padding-bottom: 44px 8px !important;
		padding-bottom: 57px !important;
		border-radius: 10px 10px 0 0;
		margin-bottom: 0 !important;
	}

	.action-sheet-button {
		padding: 0 10px;
		text-align: center;
		height: 18px;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 21px;
		color: #000000;

		// padding-top: 26px;
		// padding-bottom: 37px;
	}
	.action-sheet-button.sc-ion-action-sheet-ios:first-child {
		margin-top: 26px;
		margin-bottom: 37px;
		color: black;
	}
	.action-sheet-button.sc-ion-action-sheet-ios:last-child {
		margin: 0;
		color: black;
	}
	.action-sheet-group-cancel {
		border-radius: 0;
		// padding-top: 44px;
		// padding-bottom: 57px !important;
		.cancel {
			width: 50%;
			background-color: #d64747;
			color: #3ebaff;
		}
	}
	.action-sheet-structive {
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 14px;
		/* identical to box height */

		text-align: center;

		color: #585858;
	}

	.action-sheet-destructive {
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 18px;
		text-align: center;
		color: #ff5e5e;
		margin-bottom: 44px;
	}
}

.tags-page {
	.topbar-header {
		.toolbar-heading {
			--padding-start: 10px;
			--padding-end: 18px;
			.buttons-first-slot {
				ion-button {
					margin: 0;
					padding: 0 8px;
					ion-icon {
						width: 10px !important;
						height: 18px !important;
					}
				}
			}
			.buttons-last-slot {
				justify-content: flex-end;
				ion-button {
					padding-right: 3px;
					padding-bottom: 2px;
					margin: 0;

					ion-icon {
						padding-left: 1px;
						width: 24px;
						height: 24px;
					}
				}
			}
			ion-title {
				font-family: Metropolis;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 18px;
				text-align: center;
				color: #000000;
			}
		}
	}

	.deleted-all-msg-view {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		.msg {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 600;
			font-size: 30px;
			line-height: 140%;
			text-align: center;
			color: #a5a5a5;
			margin: 60px;
		}
	}

	.empty-tags-screen-message {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		height: 100%;

		.message {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 140%;
			text-align: center;
			color: #a5a5a5;
			max-width: 80%;
		}

		.add-tag-btn {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 24px;
			line-height: 24px;
			display: flex;
			align-items: center;
			text-align: center;
			color: #ff5e5e;
			--background: rgba(255, 94, 94, 0.25);
			--border-width: 1.5px;
			--border-color: #ff5e5e;
			--border-style: solid;
			--border-radius: 38px;
			margin: 0;
			height: 50px;
			margin-top: 24px;
		}
	}
	ion-list {
		background: transparent;
		.tag-item-container {
			ion-list-header {
				font-size: 18px;
				line-height: 21px;
				--color: #000000;
				font-weight: normal;
				min-height: 30px;
				align-items: flex-start;
				margin-top: 6px;
			}

			.contacts-container {
				--min-height: 48px;
				--padding-start: 10px;
				--inner-padding-end: 0;
				.starred-wrapper {
					padding: 0;
					overflow-x: auto;
					display: flex;
					flex-direction: row;
					cursor: all-scroll;
					-ms-overflow-style: none;
					.select-checkbox-view {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						width: 32px;
						height: 48px;
						min-width: 32px;
						min-height: 48px;
						margin-top: 8px;

						ion-checkbox {
							margin: 0;
							min-width: 24px;
							min-height: 24px;
							width: 24px;
							height: 24px;
							--border-radius: 50%;
							--size: 22px;
							--background: transparent;
							--border-color: #858484;
							--border-color-checked: #ff5e5e;
							--background-checked: #ff5e5e;
							--checkmark-color: #fff;
							margin-right: 20px;
							--checkmark-width: 2px;
						}
					}

					&::-webkit-scrollbar {
						display: none;
					}
					.contact-item {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						margin-right: 8px;
						margin-left: 0;
						margin-top: 0px;
						img {
							min-width: 48px;
							min-height: 48px;
							width: 48px;
							height: 48px;
							border-radius: 4px;
							background-color: #ffffff;
							overflow: hidden;
						}
						.username {
							margin-top: 8px;
							// margin-bottom: 8px;
							min-height: 11px;
							font-family: Metropolis;
							font-style: normal;
							font-weight: normal;
							font-size: 11px;
							line-height: 11px;
							text-align: center;
							color: #000000;
							width: 56px;
							text-overflow: ellipsis;
							white-space: nowrap;
							overflow: hidden;
						}
					}
				}
			}

			.toggle-item {
				// --min-height: 50px;
				--min-height: 38px;
			}
			ion-toggle.toggle-customised {
				--background: #dcdcdc;
				--background-checked: #3ebaff;
				--handle-background: #ffffff;
				--handle-background-checked: #ffffff;
				--handle-height: 22px;
				--handle-width: 22px;
				--handle-border-radius: 50%;
				--handle-max-height: 22px;
				--handle-spacing: 1px;
				width: 45.71px;
				padding-right: 0;
				padding-left: 14px;
				height: 24px;
			}
		}

		.selected-contacts-component {
			max-width: 100%;
			.starred-wrapper {
				padding-bottom: 0px !important;
				padding-left: 0px !important;
				overflow-y: auto;
				flex-wrap: nowrap;
				max-width: 100%;
			}
		}
	}

	.swtich-item {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		ion-label {
			font-size: 16px;
			color: #858484;
		}
	}

	.manage-toggle-item {
		--min-height: 56px;
		--padding-start: 0;
		--background: transparent;
		padding-bottom: 34px;
		background: var(--app-color-code);

		.title {
			--padding-start: 0;
			--padding-end: 0;
			margin: 0;
			margin-left: 16px;
			font-style: normal;
			text-align: center;
			max-width: 108px;
			text-transform: none;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 18px;
			color: #000000;
		}
		ion-toggle.toggle-customised {
			--background: #dcdcdc;
			--background-checked: #ff5e5e;
			--handle-background: #ffffff;
			--handle-background-checked: #ffffff;
			--handle-height: 22px;
			--handle-width: 22px;
			--handle-border-radius: 50%;
			--handle-max-height: 22px;
			--handle-spacing: 1px;
			width: 45.71px;
			padding-right: 0;
			padding-left: 14px;
			height: 24px;
		}
		.delete-tags-btn,
		.add-tag-btn {
			--padding-start: 0;
			--padding-end: 0;
			font-family: Metropolis;
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 18px;
			text-align: right;
			color: #ff5e5e;
			text-transform: none;
			&.button-disabled {
				color: #c4c4c4;
			}
		}

		.cancel-btn {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 18px;
			color: #000000;
			margin-left: 8px;
			text-transform: none;
		}
	}
}

.delete-tags-actionsheet {
	.action-sheet-container {
		padding: 0;
	}

	.action-sheet-group {
		border-radius: 10px 10px 0 0;
		margin-bottom: 0 !important;
		padding: 0 !important;
		padding-top: 18px !important;
		padding-bottom: 37px !important;
		.action-sheet-title {
			font-size: 14px;
			font-weight: 400;
			padding-top: 23px;
		}

		.action-sheet-button {
			padding: 0 10px;
			text-align: center;
			height: 56px;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 22px;
			color: #000000;
			--ripple-color: transparent;
			margin-top: 8px;

			&.thread-menu-button {
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 18px;
				text-align: center;
				color: #ff5e5e;
			}
			span {
				justify-content: center;
			}
		}
	}
}

.taginfo-page {
	.tagname-title-item {
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 14px;
		margin-top: 11px;
		--inner-padding-end: 0 !important;
		/* identical to box height */

		color: #858484;
		.list-grid {
			ion-icon {
				width: 18px;
				height: 14px;
			}
		}
	}

	.topbar-header {
		.toolbar-heading {
			--padding-start: 10px;
			--padding-end: 18px;
			.buttons-first-slot {
				ion-button {
					margin: 0;
					padding: 0 8px;
					ion-icon {
						width: 24.14px !important;
						height: 24.14px !important;
						stroke: #000000;
					}
				}
			}
			.buttons-last-slot {
				justify-content: flex-end;
				ion-button {
					margin: 0;
					font-family: Metropolis;
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 18px;
					display: flex;
					align-items: center;
					text-align: right;
					--color: #ff5e5e;
					&.button-disabled {
						--color: #c4c4c4;
					}
				}
			}
			ion-title {
				font-family: Metropolis;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 18px;
				text-align: center;
				color: #000000;
			}
		}
	}

	ion-list {
		background-color: transparent;
		margin-bottom: 4px;
		.tag-field-item {
			margin-top: 4px;
			--min-height: 44px;
			--background: #ffffff;
			--inner-padding-start: 18px;
			--inner-padding-end: 18px;
			--padding-start: 0;

			ion-label {
				font-family: Metropolis;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				color: #000000;
			}
			ion-button {
				--color: #858484;
				margin: 0;
				--padding-end: 0;
				--padding-start: 0;
				width: 8px;
				height: 13px;
				ion-icon {
					width: 8px;
					height: 13px;
				}
			}
			ion-input {
				--background: transparent !important;
				border-radius: 5px;
				--padding-top: 4px;
				--padding-bottom: 4px;
				--padding-start: 0;
				--padding-end: 0;
				padding-right: 0 !important;
				height: 30px;
				color: #ff5e5e;
				.input-clear-icon {
					width: 16px;
					height: 16px;
					border-radius: 50%;
				}
			}
			.clear-icon {
				width: 18px !important;
				height: 18px !important;
				ion-icon {
					width: 18px !important;
					height: 18px !important;
				}
			}
		}

		.tagname-title-item {
			--min-height: 18px;
			font-size: 16px;
			line-height: 18px;
			color: #000000;
			font-family: Metropolis;
			font-style: normal;
			font-weight: 500;
			color: #858484;
			--padding-start: 17px;
		}
	}

	.list-devider {
		height: 30px;
		font-family: Metropolis;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 30px;
		color: #858484;
		padding: 0 18px;
	}

	.contacts-item-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		padding: 0 3px;

		.contact-item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			width: 59px;
			padding: 8px 0 5px;
			margin: 0 11px;
			position: relative;

			.contact-img {
				width: 48px;
				height: 48px;
				border-radius: 4px;
				background-color: #ffffff;
			}

			.contact-name {
				margin-top: 8px;
				font-family: Metropolis;
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 12px;
				text-align: center;
				color: #000000;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				width: 100%;
			}

			.remove-icon {
				position: absolute;
				top: 2px;
				right: -5px;
				--padding-start: 0;
				--padding-end: 0;
				height: 22px;
				width: 22px;
				margin: 0;
				ion-icon {
					width: 100%;
					height: 100%;
				}
			}
		}
		.new-item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 8px 0 5px;
			margin: 0 11px;

			.add-item {
				--padding-start: 0;
				--padding-end: 0;
				--padding-top: 0;
				--padding-bottom: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px dashed #858484;
				box-sizing: border-box;
				border-radius: 4px;
				width: 50px;
				height: 50px;
				img {
					width: 20px;
					height: 20px;
				}
			}
			.title {
				margin-top: 8px;
				font-family: Metropolis;
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 12px;
				text-align: center;
				color: #858484;
			}
		}
	}
	.contacts-item-container-list {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		padding: 0 3px;
		align-items: flex-start;

		.contact-item {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			width: 59px;
			padding: 8px 0 8px;
			margin: 0 11px;
			position: relative;
			.cross {
				width: 24px;
				position: relative;
				margin-left: 16px;
				margin-right: 16px;
				display: flex;
				justify-content: center;
				&.remove-icon {
					position: absolute;
					top: 0px !important;
					right: -5px;
					--padding-start: 0;
					--padding-end: 0;
					height: 22px;
					width: 22px;
					margin: 0;
					ion-icon {
						width: 100%;
						height: 100%;
					}
				}
			}

			.contact-img {
				width: 40px;
				height: 40px;
				border-radius: 4px;
				background-color: #ffffff;
			}

			.contact-name {
				margin-top: 8px;
				font-family: Metropolis;
				font-style: normal;
				font-weight: normal;
				font-size: 18px;
				line-height: 12px;
				text-align: center;
				color: #000000;
				text-overflow: ellipsis;
				white-space: nowrap;
				// overflow: hidden;
				width: 100%;
				font-weight: 400;
				line-height: 18px;
				padding-left: 13px;

				color: #000000;
			}
		}
		.new-item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 8px 0 5px;
			margin: 0 11px;

			.add-item {
				--padding-start: 0;
				--padding-end: 0;
				--padding-top: 0;
				--padding-bottom: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px dashed #858484;
				box-sizing: border-box;
				border-radius: 4px;
				width: 50px;
				height: 50px;
				img {
					width: 20px;
					height: 20px;
				}
			}
			.title {
				margin-top: 8px;
				font-family: Metropolis;
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 12px;
				text-align: center;
				color: #858484;
			}
		}
	}
	// .contacts-item-container-list {
	//   display: flex;
	//   flex-direction: column;
	//   flex-wrap: wrap;
	//   padding: 0 3px;

	//   .contact-item {
	//     display: flex;
	//     flex-direction: column;
	//     align-items: center;
	//     justify-content: flex-start;
	//     width: 59px;
	//     padding: 8px 0 5px;
	//     margin: 0 11px;
	//     position: relative;

	//     .contact-img {
	//       width: 50px;
	//       height: 50px;
	//       border-radius: 4px;
	//       background-color: #ffffff;
	//     }

	//     .contact-name {
	//       margin-top: 8px;
	//       font-family: Metropolis;
	//       font-style: normal;
	//       font-weight: normal;
	//       font-size: 12px;
	//       line-height: 12px;
	//       text-align: center;
	//       color: #000000;
	//       text-overflow: ellipsis;
	//       white-space: nowrap;
	//       overflow: hidden;
	//       width: 100%;
	//     }

	//     .remove-icon {
	//       position: absolute;
	//       top: 2px;
	//       right: -5px;
	//       --padding-start: 0;
	//       --padding-end: 0;
	//       height: 22px;
	//       width: 22px;
	//       margin: 0;
	//       ion-icon {
	//         width: 100%;
	//         height: 100%;
	//       }
	//     }
	//   }
	//   .new-item {
	//     display: flex;
	//     flex-direction: column;
	//     align-items: center;
	//     justify-content: center;
	//     padding: 8px 0 5px;
	//     margin: 0 11px;

	//     .add-item {
	//       --padding-start: 0;
	//       --padding-end: 0;
	//       --padding-top: 0;
	//       --padding-bottom: 0;
	//       display: flex;
	//       align-items: center;
	//       justify-content: center;
	//       border: 1px dashed #858484;
	//       box-sizing: border-box;
	//       border-radius: 4px;
	//       width: 50px;
	//       height: 50px;
	//       img {
	//         width: 20px;
	//         height: 20px;
	//       }
	//     }
	//     .title {
	//       margin-top: 8px;
	//       font-family: Metropolis;
	//       font-style: normal;
	//       font-weight: normal;
	//       font-size: 12px;
	//       line-height: 12px;
	//       text-align: center;
	//       color: #858484;
	//     }
	//   }
	// }

	.bottom-buttons-view {
		display: flex;
		flex-direction: row;
		height: 90px;
		background: var(--app-color-code);
		.remove-btn {
			font-family: Metropolis;
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 18px;
			color: #000000;
			text-transform: none;
		}
		.add-btn {
			font-family: Metropolis;
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 18px;
			text-align: right;
			color: #ff5e5e;
			text-transform: none;
		}
	}

	.save-cancel-btns-container {
		padding: 12px 0;
		background: var(--app-color-code);
		backdrop-filter: blur(35px);
		position: fixed;
		bottom: 90px;

		z-index: 100;
		width: 100%;
		.save-cancel-btns {
			--background: transparent;
			padding: 0 16px;
			.cancel-btn {
				--background: rgba(196, 196, 196, 0.7);
				color: #858484;
				height: 33px;
				--padding-start: 8px;
				--padding-end: 8px;
				--border-radius: 5px;
				text-transform: none;
			}

			.done-btn {
				--color: #e8e8e8;
				--background: #c4c4c4;
				height: 33px;
				--padding-start: 8px;
				--padding-end: 8px;
				--border-radius: 5px;
				text-transform: none;
				opacity: 1 !important;
				&.active {
					--background: #ff5e5e;
				}
			}
		}
	}
}
