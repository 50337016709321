.like-comment-container {
	display: flex;
	flex-direction: column;
	background-color: #dadada;
	padding: 5px;
	border-radius: 5px;

	.comments-view {
		margin: 4px;
		display: flex;
		flex-direction: row;
		align-items: center;

		.like-icon {
			margin-right: 10px;
		}

		.comment-item {
			.user-img {
				width: 35px;
				height: 35px;
				margin: 2px;
				object-fit: cover;
				border-radius: 3px;
				overflow: hidden;
			}
		}
	}

	.likes-view {
		margin: 4px;
		display: flex;
		flex-direction: column;
		background: transparent !important;
		.likes-item {
			min-height: 20px;
			display: flex;
			flex-direction: row;
			align-items: center;
			--min-height: 20px;
			--padding-start: 0;
			--background: transparent !important;

			.usernametxt {
				font-size: 14px;
				font-weight: 700;
			}
			.comment {
				margin-left: 4px;
				font-size: 14px;
				font-weight: 400;
			}
		}
	}
}

.bottom-input-box {
	padding: 0 5px;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	--background: var(--app-color-code) !important;
	box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.15);

	.messeger-input {
		text-align: left;
		--padding-start: 8px;
		--padding-end: 8px;
		color: #000;
		caret-color: #ff5e5e;
		background-color: rgba(165, 165, 165, 0.6);
	}
}
