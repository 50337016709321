.delete-file-modal {
  .modal-wrapper {
    background: rgba(var(--app-modal-bg-color-code), 0) !important;
    max-width: unset !important;
    max-height: unset !important;
    width: 100% !important;
    height: 100% !important;
  }
  .ion-page {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .content {
    border-radius: 14px;
    width: 100%;
    background-color: #efefefff;
    display: flex;
    flex-direction: column;

    .storage {
      margin-top: 29px;
      font-family: 'Metropolis';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
      color: #000000;
    }
    .storege-msg {
      height: 56px;
      margin-top: 9px;
      font-family: 'Metropolis';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #858484;
      display: flex;
      align-items: center;
      justify-content: center;
      
    }
    .image-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: center;
      // height: 136px;
       .delete-image {
        width: 80px;
        height: 106px;
        margin-top: 18px;
        fill: #585858;
        .delete{
          width: 100%;
          height: 80px;
        }
      }
      .file-image {
        width: 72px;
        height: 56px;
        margin-top: 18px;
        fill: #585858;
      }
      .file-name {
        // margin-top: 11px ;
        width: 180px;
        height: 38px;
        text-align: center;
        font-family: 'Metropolis';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #585858;
        flex-wrap: wrap;
        padding: 11px;

      }
    }
    .move-trash {
      display: flex;
      align-self: center;
      align-items: center;
      justify-content: center;
      margin-top: 19px;
      height: 18px;

      ion-button {
        text-transform: capitalize;
        --background: none;
        --box-shadow: none;
        --border: none;
        color: #ff5e5e;

        font-family: 'Metropolis';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
      }
    }
    .cancel {
      height: 90px;
      text-align: center;
      margin-top: 29px;
      p {
        font-size: 16px;
        font-weight: 500;
        font-family: 'Metropolis';
        font-style: normal;
      color: #000000;

      }
    }

    .sub-modal {
      width: 100%;
      height: 178px;
      .modal-footer {
        margin-top: 40px;

        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        .modal-icons {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 27px;
            height: 24px;
          }
          .folder {
            margin-top: 19px;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) and (min-width: 300px) {
  div#desktopview {
    display: none;
  }
}
@media only screen and (min-width: 1200px) {
  div#mobileview {
    display: none;
  }
}

#desktopview {
  .modal-icons {
    margin-left: 50px;
  }
}
#mobileview {
  .modal-icons {
    margin-left: none;
  }
}
