.formContainer {
	display: flex;
	text-align: start;
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	margin: 5%;
	transform: translateY(-50%);
}

.formContainer p {
	font-size: 16px;
	line-height: 22px;
	color: #8c8c8c;
	margin: 0;
}

.formContainer .bchatList {
	width: 100%;
}

.formContainer .formFields {
	color: black;
	font-size: 10px;
	border: solid 2px;
	--padding-start: 10px;
	height: 50px;
}

.formContainer .bchatSubmitButton {
	--ion-color-primary: #000000;
	font-size: 12px;
	font-weight: 900;
	height: 50px;
	--border-radius: 3px;
	width: 100%;
}

.formContainer .initialRow {
	margin-bottom: 29px;
}

.backDiv {
	border: solid 1px;
	height: 50px;
}
.backIcon {
	--ionicon-stroke-width: 16px;
	float: right;
}
