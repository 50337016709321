.conversation-profile-pic-container {
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	margin-right: 14px;

	ion-badge {
		position: absolute;
		top: 0;
		margin-right: -10px;
	}
}

.conversation-item-sliding {
	ion-item {
		color: #000000 !important;
		--background: var(--app-color-code) !important;
		--min-height: 70px;
		--padding-start: 16px;
		--padding-end: 14px;
		--inner-padding-end: 0;

		ion-label {
			margin: 4px 0 8px;
			.h-row {
				display: flex;
				flex-direction: row;
				max-width: 100%;
				&.secondline {
					margin-top: 8.6px;
				}
			}
			.firstline {
				align-items: center;
			}

			.chatname {
				display: inline-block;
				white-space: nowrap;
				overflow: hidden !important;
				text-overflow: ellipsis;
				font-size: 18px;
				line-height: 18px;
				color: #000000;
				flex: 1;
				margin: 0;
				padding-top: 5px;
			}
			.chatlastmessage {
				font-size: 14px;
				line-height: 20px;
				max-height: 20px;
				color: #858484;
				flex: 1;

				display: inline-block;
				white-space: nowrap;
				overflow: hidden !important;
				text-overflow: ellipsis;

				ion-text {
					color: #858484;
					font-weight: 600;
					font-size: 14px;
					line-height: 14px;
					margin-right: 5px;
				}
				a {
					text-decoration: none;
				}
				span {
					color: #858484;
				}
				.tagged {
					color: #ff5e5e;
				}
			}

			.timestamp {
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 14px;
				color: #858484;
				text-transform: none;
			}

			.mute-icon {
				color: #858484;
				font-size: 12px;
				margin-left: 10px;
			}
		}
	}
}
.conversation-options {
	border: none;
	padding: 1px 0px;

	ion-item-option {
		height: 70px;
		text-transform: none;
		&.confirm-btn {
			width: 252px;
			font-size: 16px;
			line-height: 19px;
			color: #e8e8e8;
			border-radius: 5px 0px 0px 5px;
			&.hide {
				background: #fa9d3b;
			}
			&.delete {
				background: #ff5e5e;
			}
		}
		&.mark-unread-btn {
			width: 92px;
			background: #3ebaff;
			border-radius: 5px 0px 0px 5px;
			font-size: 16px;
			color: #e8e8e8;
		}
		&.hide-btn {
			width: 80px;
			background: #fa9d3b;
			font-size: 16px;
			line-height: 19px;
			color: #e8e8e8;
		}
		&.delete-btn {
			width: 80px;
			background: #ff5e5e;
			font-size: 16px;
			line-height: 19px;
			color: #e8e8e8;
		}
	}
}
