.contact-list-component {
	padding-top: 0%;
	padding-bottom: 0%;
	background: white;
	&.contact-list-component-selected {
		padding-top: 0%;
	}

	.cross {
		width: 24px;
		position: relative;
		margin-left: 16px;
		margin-right: 16px;
		display: flex;
		justify-content: center;

		&.remove-icon {
			position: absolute;
			top: 0px !important;
			right: -5px;
			--padding-start: 0;
			--padding-end: 0;
			height: 22px;
			width: 22px;
			margin: 0;
			ion-icon {
				width: 100%;
				height: 100%;
				fill: red !important;
			}
		}
	}

	ion-item {
		cursor: pointer;
		ion-icon {
			margin-right: 16px !important;
		}
		ion-checkbox {
			--border-radius: 50%;
			--size: 22px;
			--background: transparent;
			--border-color: #858484;
			--border-color-checked: #ff5e5e;
			--background-checked: #ff5e5e;
			--checkmark-color: #fff;
			margin-right: 16px;
			--checkmark-width: 2px;
			--margin-inline-end: 16px !important;
		}
		ion-thumbnail {
			min-width: 40px;
			min-height: 40px;
			width: 40px;
			height: 40px;
		}
		ion-button {
			text-transform: none;
		}
		.view-newrequest {
			--background: #3ebaff;
			color: #fff;
		}

		.view-mostrecent,
		.view-recentlyadded {
			--background: #dadada;
			color: #585858;
		}
		.remove-contact-btn {
			color: #ff5e5e;
			ion-icon {
				font-size: 20px;
			}
		}
	}
	.alpha-contacts {
		ion-item {
			color: #000000;
			ion-label {
				font-size: 16px;
				line-height: 16px;
				color: #000000;
				h3 {
					font-family: Metropolis;
					font-style: normal;
					font-weight: normal;
					font-size: 18px;
					line-height: 18px;
					color: #000000;
					padding-top: 5px;
				}
				p.profession {
					margin-top: 3.5px;
					font-family: Metropolis;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 14px;
					color: #858484;
					padding: 0;
				}
			}
		}
		.cont-divider {
			background: var(--app-color-code);
			border: none;
			height: 30px;
			font-family: Metropolis;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 16px;
			color: #585858;
			&.starred {
				img {
					margin-right: 13px;
					width: 17px;
					height: 17px;
				}
			}
		}
	}
}

.contact-alphabet-view {
	position: fixed;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 99999;
	display: flex;
	align-items: center;
	justify-content: center;
	.container {
		width: 11px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-right: 8px;
		height: 100%;
		overflow-y: auto;
		/* Hide scrollbar for IE, Edge and Firefox */
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		/* Hide scrollbar for Chrome, Safari and Opera */
		&::-webkit-scrollbar {
			display: none;
		}

		ion-button {
			width: 11px;
			margin: 0;
			height: 18px;
			min-height: 18px;
			--padding-start: 0;
			--padding-end: 0;
			font-family: Metropolis;
			font-style: normal;
			font-weight: 500;
			font-size: 10px;
			line-height: 200.5%;
			color: #858484;
		}
	}
}

.has-topbar {
	.contact-alphabet-view {
		padding-top: var(--top-header-bar-height);
	}
}
.has-topbar-with-searchbar {
	.contact-alphabet-view {
		padding-top: var(--top-header-bar-with-searchbar-height);
	}
}
.has-bottom-navbar {
	.contact-alphabet-view {
		padding-bottom: var(--bottom-bar-height);
	}
}

.starred-contacts-component {
	display: flex;
	flex-direction: row;
	align-items: center;
	min-height: 72px;
	.starred-wrapper {
		padding: 0 10px;
		overflow-x: auto;
		display: flex;
		flex-direction: row;
		background-color: var(--app-color-code);

		.starred-item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-right: 20px;
			ion-label {
				color: #000000;
				font-size: 12px;
				margin-top: 2px;
			}
			ion-thumbnail {
				min-width: 40px;
				min-height: 40px;
				max-width: 40px;
				max-height: 40px;
			}
		}
	}
	.star-icon {
		width: 80px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
		ion-icon {
			font-size: 24px;
		}
	}
}

.selected-contacts-component {
	.starred-wrapper {
		padding: 12px 20px;
		overflow-x: auto;
		display: flex;
		flex-direction: row;
		background-color: var(--app-color-code);
		flex-wrap: wrap;

		.contact-item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-right: 20px;
			margin-bottom: 8px;
			max-width: 50px;
			ion-label {
				color: #000000;
				font-size: 11px;
				margin-top: 5px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden !important;
				display: inline-block;
				max-width: 100%;
			}
			ion-thumbnail {
				min-width: 50px;
				min-height: 50px;
				max-width: 50px;
				max-height: 50px;
				--border-radius: 3px;
				&.highlight {
					filter: drop-shadow(0px 0px 3px #ff5e5e);
				}
			}
		}
		.add-contact-item {
			ion-thumbnail {
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px dashed #858484;
				box-sizing: border-box;
				border-radius: 5px;
			}

			ion-icon {
				font-size: 20px;
			}
		}
	}
}
.group-list-component {
	margin-top: 130px;
	.group-length {
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 14px;

		color: #858484;
	}
	ion-item {
		--background: transparent;
		cursor: pointer;
		ion-label {
			h3 {
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 400;
				font-size: 18px;
				line-height: 18px;
			}
			p {
				font-size: 14px;
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 400;
				line-height: 14px;
				padding-top: 4.5px;
			}
		}
	}
}
