.chat-medias-page {
	.topbar-header {
		background: rgba(0, 0, 0, 0.8);
		backdrop-filter: blur(20px);
		ion-title {
			.title {
				font-family: Metropolis;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 18px;
				text-align: center;
				color: #efefef;
			}
		}
		.buttons-first-slot {
			ion-button {
				ion-icon {
					stroke: #efefef;
				}
			}
		}

		.buttons-last-slot {
			ion-button {
				font-family: Metropolis;
				font-style: normal;
				font-weight: normal;
				font-size: 18px;
				line-height: 18px;
				text-align: right;
				--color: #efefef;
			}
		}
	}
	.container {
		overflow-y: auto;
		overflow-x: hidden;
		background-color: rgba(33, 33, 33, 1);
		height: 100%;
		padding-bottom: 36px !important;
		.all-media-container {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 100%;
			overflow: scroll;
			.date-devider {
				position: -webkit-sticky;
				position: sticky;
				top: 0;
				z-index: 9;
				width: 100%;
				background: rgba(0, 0, 0, 0.7);
				backdrop-filter: blur(20px);
				height: 30px;
				padding-left: 12px;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				font-family: Metropolis;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 30px;
				color: #e8e8e8;
			}
			.media-grid {
				display: flex;
				flex-wrap: wrap;
				.grid-item {
					width: 25vw;
					height: 25vw;
					position: relative;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
					.video-thumbnail {
						width: 100%;
						height: 100%;
						border-radius: 4px !important;
						overflow: hidden;
						object-fit: contain;
						display: flex;
						align-items: center;
						justify-content: center;
						.play-btn {
							width: 40px;
							height: 40px;
							position: absolute;
							--padding-start: 0;
							--padding-end: 0;
							ion-icon {
								width: 100%;
								height: 100%;
							}
						}
					}
					.select-checkbox {
						margin: 0;
						z-index: 1;
						position: absolute;
						right: 4px;
						top: 4px;
						height: auto;
						--padding-start: 0;
						--padding-end: 0;
						--border-radius: 50%;
						ion-icon {
							width: 18px;
							height: 18px;
						}
					}
				}
			}
		}
	}
	.footer-view {
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 9;
		background: rgba(0, 0, 0, 0.7);
		backdrop-filter: blur(20px);
		height: 86px;
		.btns {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			padding: 16px 32px;
			ion-button {
				--padding-start: 0;
				--padding-end: 0;
				height: auto;
				ion-icon {
					width: 20px;
					height: 20px;
				}
			}
		}
	}
}
