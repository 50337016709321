.replied-for-view {
	$maskItemWidth: 60.57px;

	display: flex;
	padding: 10px;
	min-height: 52px;
	max-height: 52px;
	background-color: #dcdcdc;
	align-items: center;
	border-radius: 4px;
	max-width: calc(83.73% - $maskItemWidth - 68px);
	margin-bottom: 5px;

	&.left {
		margin-left: 116px;
		flex-direction: row;
	}
	&.right {
		margin-right: 68px;
	}

	.profile-img {
		width: 32px;
		height: 32px;
		border-radius: 4px;
		background-color: #eee;
		object-fit: cover;
	}

	.user-title-msg {
		margin-left: 12px;
		font-size: 14px;
		line-height: 16px;
		color: #585858;
		font-family: Metropolis;
		overflow-y: hidden;
		color: #585858;
		white-space: initial;
		text-overflow: ellipsis;
		overflow: hidden;
		font-weight: 400;
		padding-top: 5px;
		@supports (-webkit-line-clamp: 2) {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
		// &.text-type {
		// }
		// &.file-type {
		// }
		&.image-type {
			align-items: center;
			justify-content: center;
			display: flex;
		}
		.file-type-message {
			transform: scale(0.6) translateX(-34%);
		}
		.giphy-type-message {
			a,
			div {
				width: 30px !important;
				max-height: 30px !important;
			}
		}

		.title {
			text-transform: none;
			font-weight: 700;
			.paranthesis {
				font-weight: 500;
			}
		}
		.image-file {
			object-fit: contain;
			width: 54.74px;
			height: 40px;
			border-radius: 4px;
			margin-left: 12px;
		}

		.video-thumbnail {
			object-fit: cover;
			width: 54.74px;
			height: 40px;
			border-radius: 4px;
			margin-left: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			.image-file {
				margin-left: 0;
			}
			.play-btn {
				width: 16px;
				height: 16px;
				position: absolute;
				--padding-start: 0;
				--padding-end: 0;
				ion-icon {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
}
