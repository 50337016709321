.chat-page {
	.topbar-header {
		&.selection-mode-topbar {
			.buttons-first-slot {
				ion-button {
					margin: 0;
					padding: 0 8px;
					ion-icon {
						width: 15px !important;
						height: 15px !important;
					}
				}
			}
		}

		.toolbar-title-default {
			padding-top: 30px !important;
		}

		background: rgba(var(--app-modal-bg-color-code), 0.7);
		backdrop-filter: blur(20px);
		height: 84px;
		position: fixed;
		z-index: 99;
		width: 100%;
		top: 0;
		display: flex;
		align-items: flex-end;
		flex-direction: row;
		padding-bottom: 10px;

		.synch-loader {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			.custom-spinner {
				width: 20px;
				height: 20px;
			}
			ion-label {
				margin-left: 14px;
				font-style: normal;
				font-weight: normal;
				font-size: 18px;
				line-height: 18px;
				color: #000000;
			}
		}

		@supports not (backdrop-filter: none) {
			background-image: url(./../../assets/blur-gray-bg.png) !important;
			background-size: 100% 100%;
			background-position: top left;
		}

		ion-toolbar.header-toolbar {
			--background: transparent;
			--min-height: unset;
			--padding-start: 0;
			--padding-end: 0;
			.ct-btn-search {
				.ct-icon-search {
					width: 55%;
					height: 55%;
				}
			}

			ion-icon {
				color: #000;
				.ct-icon-search {
					width: 50%;
					height: 22px;
				}
			}

			ion-button {
				text-transform: none;
			}

			.select-all-btn {
				background: #dcdcdc;
				color: #858484;
				width: auto;
				height: auto;
				border-radius: 5px;
				--padding-top: 10px;
				--padding-bottom: 10px;
				font-weight: normal;
			}

			.selection-cancel-btn {
				color: #ff5e5e;
				font-weight: normal;
			}

			.ct-toolbar-title {
				.main-title {
					font-family: Metropolis;
					font-style: normal;
					font-weight: 500;
					font-size: 20px;
					line-height: 20px;
					text-align: center;
					color: #000000;
					width: 100%;
				}
				.profession {
					width: 100%;
					padding-top: 7px;
					font-family: Metropolis;
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 14px;
					text-align: center;
					color: #585858;
				}
			}
		}
	}

	.chat-thread-chat {
		width: 100%;
		position: fixed;
		color: #000;
		z-index: 99;
		background: transparent;
		top: 92px;
		display: flex;
		flex-wrap: wrap;
		margin-left: 12px;
		margin-right: 12px;
		right: 0;
		left: 0;

		.thread-view-chat {
			.thread-button {
				// width: 26px;
				// z-index: 99999999999999999;
				// height: 18px;
				// color: white;
				// display: flex;
				// align-items: flex-start;
				// right: 30px;
				// border-radius: 100%;
				// font-size: 12px;
				// background: #ff5e5e;
				// box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
				// border-radius: 21px;
				// margin-left: 1px;
				// &.maxwidth {
				//   width: 26px;
				// }
				ion-button {
					height: 30px;
					background: #00ff2b;
					--background: none;
					--box-shadow: none;
					border-radius: 25px;
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 600;
					font-size: 16px;
					text-align: center;
					margin-left: 12px;
					margin-bottom: 16px;
					color: #000000;
					text-transform: none;
					margin-top: 0;
					margin-right: 0;
					--padding-start: 8px;
					--padding-end: 8px;
					--padding-top: 8px;
					--padding-bottom: 8px;

					&.active {
						background: #ff5e5e;
						color: white;
					}
				}
			}
			.thread-longpress {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				ion-button {
					height: 30px;
					background: #00ff2b;
					--background: none;
					--box-shadow: none;
					border-radius: 25px;
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 600;
					font-size: 16px;
					text-align: center;
					margin-bottom: 34px;
					color: #000000;
					text-transform: none;
					margin-top: 0;
					margin-right: 0;
					--padding-start: 8px;
					--padding-end: 8px;
					--padding-top: 8px;
					--padding-bottom: 8px;

					&.active {
						background: #ff5e5e;
						color: white;
					}
				}
			}
		}
	}

	.chat-thread-container {
		width: 100%;
		position: fixed;
		color: #000;
		z-index: 99;
		background: transparent;
		top: 92px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin-left: 12px;
		margin-right: 12px;
		right: 0;
		left: 0;

		.thread-button {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			.thread-btn-container {
				position: relative;
				display: flex;
				flex-direction: row;
				padding-right: 9px;

				.notification-grid {
					position: absolute;
					padding-top: 5px;
					padding-bottom: 5px;
					padding-right: 6px;
					padding-left: 6px;
					min-width: 18px;
					height: 18px;
					right: 2px;
					top: -3px;
					z-index: 2;
					color: white;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 12px;
					background: #ff5e5e;
					box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
					border-radius: 21px;
				}
				.notification-grid-3Dot {
					position: absolute;
					padding-top: 7px;
					padding-bottom: 4px;
					padding-right: 6px;
					padding-left: 6px;
					min-width: 18px;
					height: 18px;
					right: 2px;
					top: -3px;
					z-index: 2;
					color: white;
					display: flex;
					justify-content: center;
					align-items: flex-end;
					font-size: 19px;
					background: #ff5e5e;
					box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
					border-radius: 21px;
				}
			}

			ion-button {
				height: 30px;
				background: #00ff2b;
				--background: none;
				--box-shadow: none;
				border-radius: 25px;
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				text-align: center;
				margin-left: 3px;
				margin-bottom: 16px;
				color: #000000;
				text-transform: none;
				margin-top: 0;
				margin-right: 0;
				--padding-start: 8px;
				--padding-end: 8px;
				--padding-top: 3px;

				&.active {
					background: #ff5e5e;
					color: white;
				}
			}
		}
		.thread-longpress {
			display: flex;
			// flex-direction: column;
			justify-content: center;
			align-items: center;
			ion-button {
				height: 30px;
				background: #00ff2b;
				--background: none;
				--box-shadow: none;
				border-radius: 25px;
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				text-align: center;
				margin-bottom: 34px;
				color: #000000;
				text-transform: none;
				margin-top: 0;
				margin-right: 0;
				--padding-start: 8px;
				--padding-end: 8px;
				--padding-top: 8px;
				--padding-bottom: 8px;

				&.active {
					background: #ff5e5e;
					color: white;
				}
			}
			.thread-btn-container {
				position: relative;
				display: flex;
				flex-direction: column;
				padding-right: 9px;

				.notification-list {
					position: absolute;
					padding-top: 5px;
					padding-bottom: 5px;
					padding-right: 6px;
					padding-left: 6px;
					min-width: 18px;
					height: 18px;
					right: 2px;
					top: -3px;
					z-index: 2;
					color: white;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 12px;
					background: #ff5e5e;
					box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
					border-radius: 21px;
				}
				.notification-list-3Dot {
					position: absolute;
					padding-top: 7px;
					padding-bottom: 4px;
					padding-right: 6px;
					padding-left: 6px;
					min-width: 18px;
					height: 18px;
					right: 2px;
					top: -3px;
					z-index: 2;
					color: white;
					display: flex;
					justify-content: center;
					align-items: flex-end;
					font-size: 19px;
					background: #ff5e5e;
					box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
					border-radius: 21px;
				}
			}
		}
	}

	.chat-thread-container-list {
		width: 97%;
		height: calc(100vh - 170px);
		overflow-y: scroll;
		overflow-x: hidden;
		position: fixed;
		color: #000;
		z-index: 99;
		background: transparent;
		top: 92px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		margin-left: 12px;
		margin-right: 12px;
		right: 0;
		left: 0;

		.thread-button {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			.thread-btn-container {
				position: relative;
				display: flex;
				flex-direction: row;
				padding-right: 9px;

				.notification-grid {
					position: absolute;
					padding-top: 5px;
					padding-bottom: 5px;
					padding-right: 6px;
					padding-left: 6px;
					min-width: 18px;
					height: 18px;
					right: 2px;
					top: -3px;
					z-index: 2;
					color: white;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 12px;
					background: #ff5e5e;
					box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
					border-radius: 21px;
				}
				.notification-grid-3Dot {
					position: absolute;
					padding-top: 7px;
					padding-bottom: 4px;
					padding-right: 6px;
					padding-left: 6px;
					min-width: 18px;
					height: 18px;
					right: 2px;
					top: -3px;
					z-index: 2;
					color: white;
					display: flex;
					justify-content: center;
					align-items: flex-end;
					font-size: 19px;
					background: #ff5e5e;
					box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
					border-radius: 21px;
				}
			}

			ion-button {
				height: 30px;
				background: #00ff2b;
				--background: none;
				--box-shadow: none;
				border-radius: 25px;
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				text-align: center;
				margin-left: 3px;
				margin-bottom: 16px;
				color: #000000;
				text-transform: none;
				margin-top: 0;
				margin-right: 0;
				--padding-start: 8px;
				--padding-end: 8px;
				--padding-top: 3px;

				&.active {
					background: #ff5e5e;
					color: white;
				}
			}
		}
		.thread-longpress {
			display: flex;
			// flex-direction: column;
			justify-content: center;
			align-items: center;
			ion-button {
				height: 30px;
				background: #00ff2b;
				--background: none;
				--box-shadow: none;
				border-radius: 25px;
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				text-align: center;
				margin-bottom: 34px;
				color: #000000;
				text-transform: none;
				margin-top: 0;
				margin-right: 0;
				--padding-start: 8px;
				--padding-end: 8px;
				--padding-top: 8px;
				--padding-bottom: 8px;

				&.active {
					background: #ff5e5e;
					color: white;
				}
			}
			.thread-btn-container {
				position: relative;
				display: flex;
				flex-direction: column;
				padding-right: 9px;

				.notification-list {
					position: absolute;
					padding-top: 5px;
					padding-bottom: 5px;
					padding-right: 6px;
					padding-left: 6px;
					min-width: 18px;
					height: 18px;
					right: 2px;
					top: -3px;
					z-index: 2;
					color: white;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 12px;
					background: #ff5e5e;
					box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
					border-radius: 21px;
				}
				.notification-list-3Dot {
					position: absolute;
					padding-top: 7px;
					padding-bottom: 4px;
					padding-right: 6px;
					padding-left: 6px;
					min-width: 18px;
					height: 18px;
					right: 2px;
					top: -3px;
					z-index: 2;
					color: white;
					display: flex;
					justify-content: center;
					align-items: flex-end;
					font-size: 19px;
					background: #ff5e5e;
					box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
					border-radius: 21px;
				}
			}
		}
	}

	.notification {
		min-width: 18px;
		padding-top: 5px;
		padding-bottom: 5px;
		padding-right: 6px;
		padding-left: 6px;
		top: calc(53vh - 10px);
		z-index: 99999999999999999;
		position: fixed;
		height: 18px;
		color: white;
		right: 50px;
		border-radius: 21px;
		background: #ff5e5e;
		font-size: 12px;
		box-shadow: 0px 3px 4px rgb(0 0 0 / 25%);
	}
	.thread-menu {
		width: 60px;
		height: 60px;
		justify-content: center;
		border-radius: 100%;
		background-color: #00ff2b;
		top: calc(50vh - 30px);
		position: fixed;
		z-index: 9999;
		right: 12px;
		padding: 16px;
		ion-icon {
			width: 26px;
			height: 26px;
			padding-bottom: 2px;
		}
	}

	.main-area-gp-me {
		float: right;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		justify-content: center;
		overflow: hidden;
		padding-top: 2px;
		margin-right: 11px;
		// margin-left: 11px;

		ion-card-header {
			ion-card-subtitle {
				font-size: 18px;
				font-weight: bold;
				text-transform: none;
				color: #000;
				font-family: Metropolis;
				.paranthesis {
					font-weight: 500;
				}
			}
		}
		ion-card {
			border-radius: 6px;
			box-shadow: none;
			--background: transparent;
			color: #000000;
			margin: 0;
			max-width: 100%;
			ion-card-content {
				white-space: pre-line;
				padding: 0px;
				font-size: 16px !important;
				line-height: 19px !important;
				overflow-y: auto;
				&.only-emoji {
					line-height: unset !important;
				}

				.txt-msg {
					margin-top: 11px;
				}
				a {
					text-decoration: none;
				}
				span {
					color: #000000;
					max-width: 100%;
				}
				.tagged {
					color: #ff5e5e !important;
				}
			}

			.readmore-less {
				font-size: 16px !important;
				color: #ff5e5e;
				font-weight: bold;
				line-height: 19px !important;
			}

			&.group-message-me {
				// padding-right: 12px;
				ion-card-content {
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					overflow: hidden;
					// text-align: justify !important;
				}
			}
			&.group-message-left {
				padding-left: 0px;
				ion-card-content {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					// text-align: justify !important;
				}
			}
		}
	}

	ion-card.selected {
		border: 1px solid #000;
		border-radius: 5px;
		z-index: 100;
	}

	.timestamp-row {
		justify-content: center;
		text-align: center;
		padding-top: 24px !important;
		padding-bottom: 8px !important;
		margin: unset !important;

		ion-note {
			font-size: 12px;
			font-weight: normal;
			line-height: 12px;
			color: #979797;
			.date-day {
				font-weight: 500;
			}
		}
	}

	ion-content {
		--background: var(--app-color-code);
		// --padding-top: 84px !important;
		// --padding-bottom: 90px;

		// .virtualso {
		// 	padding-top: 84px;
		// }

		*[data-test-id='virtuoso-item-list'] {
			contain: style !important;
		}

		.virtualso {
			#virtualsoHeader {
				width: 100%;
				min-height: 94px;
			}
			#virtualsoFooter {
				width: 100%;
				min-height: 66px;
				height: 66px;
			}
		}

		.chat-item-container {
			&:last-child {
				padding-bottom: 8px;
			}

			.request-confirm-msg {
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 22px;
				text-align: center;
				color: #979797;
				margin: 10px 55px 5px;
			}
			.invited-to-newgroup-msg {
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 22px;
				text-align: center;
				color: #858484;
				margin: 10px 55px 5px;
				p,
				.invited-members {
					display: inline;
				}
			}
		}

		--overflow: hidden;
	}

	.send-friend-request {
		width: 100%;
		height: 66px;
		padding-left: 37px;
		padding-right: 36px;
		padding-top: 15px;
		padding-bottom: 18px;
		text-align: center;
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		text-align: center;
		color: #585858;
		margin-bottom: 84px;
		.send-friend {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 18px;
			text-align: center;
			color: #ff5e5e;
		}
	}
}

.thread-menu-actionsheet {
	.action-sheet-container {
		padding: 0;
	}

	.action-sheet-group {
		height: auto;
		border-radius: 10px 10px 0 0;
		margin-bottom: 0 !important;
		padding: 0 !important;
		padding-top: 18px !important;
		padding-bottom: 32px !important;
		.action-sheet-title {
			font-size: 22px;
			font-weight: 500;
			text-align: center;
			color: black;
			background: none;
		}
		.action-sheet-button {
			padding: 0 10px;
			text-align: center;
			height: 56px;
			// margin-top: 10px;
			font-style: normal;
			font-weight: 500;
			font-size: 22px;
			line-height: 22px;
			color: #000000;
			--ripple-color: transparent;
			&.creat-new-thread {
				color: #ff5e5e;
				font-weight: 500;
				font-size: 18px;
			}
			&.edit-menu-button {
				color: #000000;
				font-size: 18px;
				font-weight: 400;
			}
			&.action-sheet-group-cancel {
				border-radius: 0;
				padding-top: 0;
				border: none;
				font-size: 18px;
				font-weight: 400;
				text-align: start;
				height: 56px;
			}
			span {
				justify-content: center;
			}
		}
	}
}

.contact-card {
	// background-color: transparent;
	width: 255px;
	// border: 1px solid #000000;
	box-sizing: border-box;
	border-radius: 5px;
	text-align: left;
	background: #e6e6e6;

	ion-card-header {
		padding: 0;
		padding-top: 6px;
		border-bottom: 1px solid #858484;
		margin: 0 11px;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 22px;
		color: #858484;
	}

	ion-card-content {
		align-items: flex-start !important;
		justify-content: flex-start !important;
	}
	ion-item {
		--background: transparent;
		--padding-start: 11px;
		ion-thumbnail {
			width: 56px !important;
			height: 50px !important;
			--border-radius: 5px;
			overflow: hidden;
			margin-right: 11px;
			margin-bottom: 12px;
			margin-top: 10px;
			.profileImage {
				width: 85%;
				height: 100%;
			}
		}
		h3 {
			padding: 0;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 29px;
			color: #000000;
			margin-top: 4.5px;
		}
		p {
			padding: 0;
			margin: 0;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			color: #858484;
		}
	}
}

.share-location-thump {
	width: 255px;
	height: 149px;
	border-radius: 6px;
	background-color: rgba(255, 255, 255, 1);
	display: flex;
	flex-direction: column;
	.share-location-thump-content {
		width: 100%;
		height: 53px;
		padding-left: 12px;
		padding-top: 12px;
		display: flex;
		flex-direction: column;

		.share-location-thump-content-text {
			width: 100%;
			height: 17px;
			font-family: Metropolis;
			font-size: 18px;
			font-weight: 400;
			line-height: 18px;
			letter-spacing: 0em;
			text-align: left;
			color: #000000;
		}
		.share-location-thump-content-address {
			height: 14px;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 14px;
			color: #858484;
			margin-top: 7px;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			overflow: hidden;
			max-width: 364px;
			-webkit-line-clamp: 1;
			text-overflow: ellipsis;
			width: 100%;
			letter-spacing: 0em;
			text-align: left;
			// color: rgba(133, 132, 132, 1);
		}
	}
	.share-location-thump-image {
		width: 100%;
		height: 96px;
		margin-top: 3px;
		.img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
.ct-btn-back {
	--padding-start: 18px !important;
	--padding-end: 18px !important;
	margin: 0 !important;
	margin-inline-start: 0 !important;
	margin-inline-end: 0 !important;
	.ct-icon-back {
		width: 10px;
		height: 18px;
	}
}

.ct-btn-search {
	// --padding-start: 8px !important;
	--padding-end: 8px !important;
	margin: 0 !important;
	margin-inline-start: 0 !important;
	// margin-inline-end: 10px !important;
	.ct-icon-search {
		width: 17.5px;
		height: 10px;
	}
}
.ct-btn-option {
	--padding-start: 8px !important;
	--padding-end: 8px !important;
	margin: 0 !important;
	margin-inline-start: 0 !important;
	margin-inline-end: 10px !important;
	.ct-icon-option {
		width: 17.5px;
		height: 3.5px;
	}
	.ct-icon-option-search {
		width: 30px;
		height: 10px;
	}
}

.bottom-toolbar {
	padding: 2px;
}

.chat-avatar {
	margin: 0px 5px;
}

.col-two {
	color: #000000;
	background: #eee;
}

.chat-db-row {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	h1 {
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		line-height: 20px;
		text-align: center;
		color: #000000;
	}
}

.chat-db-grid {
	height: 100%;
}

.message-p {
	white-space: normal !important;
}
.messeger-input {
	color: rgb(255, 255, 255);
	background: rgb(83, 83, 83);
	border-radius: 6px;
	padding: 0px 0px 0px 3px;
	white-space: normal !important;
	padding-left: 5px !important;
}

.emoji-popover-wrap ion-backdrop {
	background: rgba(var(--app-modal-bg-color-code), 0.95);
	--backdrop-opacity: var(--ion-backdrop-opacity, 0.95);
	cursor: default;
}

.emoji-popover-wrap .popover-content {
	--background: none;
	--backdrop-opacity: 0.2;
	--box-shadow: none;
	font-size: medium;
	width: 60%;
	min-width: 250px;
}

.emoji-action-icons {
	cursor: pointer;
}

.emoji-popover-row-top {
	text-align: right;
}
.emoji-popover-row-bottom {
	text-align: right;
	float: right;
}

.col-one {
	color: #000000;
	background: #eee;
}

.image-prv {
	display: contents;
}

.searched-list {
	--background: var(--app-color-code);
	padding: 0;
}

.full-background {
	--background: var(--app-color-code);
}
.hideEmoj {
	display: none;
}
.showEmoj {
	width: 100% !important;
	display: block;
}

.showEmoj aside {
	width: 100% !important;
	display: block;
}

.chat-option-view {
	position: fixed;
	width: 100%;
	height: 100%;
	background: rgba(var(--app-modal-bg-color-code), 0.8);
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 99;
}

.chat-option-view-message {
	--background: rgba(var(--app-modal-bg-color-code), 1);
	margin: 5px;
	box-shadow: none;
	border: 1px solid #000000;
	color: #000;
	ion-card-content {
		white-space: pre-line;
	}
}

.emoji-popup-container-modal {
	width: 100%;
	height: 100%;
	.modal-wrapper {
		background: transparent;
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		height: 100% !important;
	}

	.emoji-popup-container {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(var(--app-modal-bg-color-code), 0.95);
		z-index: 99999;
		&.enlarge {
			display: flex;
			align-items: center;
			justify-content: center;
			backdrop-filter: blur(20px);

			ion-grid {
				// position: relative !important;

				top: 0 !important;
				bottom: 0 !important;
				left: 0 !important;
				right: 57px !important;
				min-width: 100vw !important;
				justify-content: center !important;
			}

			.message {
				max-height: 100vh !important;
			}
			.message * {
				max-height: unset !important;
			}
			.message,
			.message * {
				max-width: 100% !important;
				margin-left: 0 !important;
				padding: 10px 5vw !important ;
				font-size: 30px !important;
			}
		}

		ion-grid {
			padding: 0;
			.emoji-popover-row {
				width: 100%;
				display: flex;
				flex-direction: row;
				padding: 8px 0;
				justify-content: flex-end;
				max-width: 414px;
				.popup-item {
					width: 48px;
					height: 48px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					margin-right: 20px;
					margin-left: 5px;
					&:last-child {
						margin-right: 0;
					}
					ion-text {
						display: none;
					}
				}
			}
			.message {
				span {
					&.emoj-xl {
						font-size: 40px !important;
						line-height: unset !important;
						display: inline-block;
						vertical-align: middle;
					}
					&.emoj-lg {
						font-size: 30px !important;
						line-height: unset !important;
						display: inline-block;
						vertical-align: middle;
					}
					&.emoj-md {
						font-size: 26px !important;
						line-height: unset !important;
						display: inline-block;
						vertical-align: middle;
					}
				}
			}

			.message,
			.message * {
				font-size: 16px;
				font-weight: 500;
				color: #000;
				line-height: 1.4;
				white-space: pre-line;
				overflow: auto;
				-webkit-touch-callout: all; /* iOS Safari */
				-webkit-user-select: all; /* Safari */
				-khtml-user-select: all; /* Konqueror HTML */
				-moz-user-select: all; /* Firefox */
				-ms-user-select: all; /* Internet Explorer/Edge */
				user-select: all;
				&::-moz-selection {
					background: rgba(255, 94, 94, 0.3) !important;
				}

				&::selection {
					background: rgba(255, 94, 94, 0.3) !important;
				}
				// }
				.image-file {
					border-radius: 5px;
					overflow: hidden;
					max-width: 200px;
					max-height: 180px;
					object-fit: contain;
				}
				ion-img::part(image) {
					height: auto !important;
				}
			}
		}

		&.left {
			.message {
				text-align: left;
			}
			.emoji-popover-row {
				justify-content: flex-start;
			}
		}
		&.right {
			.message {
				text-align: right;
				padding-right: 10px;
				height: auto;
				max-height: 455px !important;
				overflow-y: scroll !important;
			}
			::-webkit-scrollbar {
				display: none;
			}
			.emoji-popover-row {
				justify-content: flex-end;
			}
		}
	}
}

.photo-preview-modal {
	.modal-wrapper {
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		height: 100% !important;
		background: #000000;
		ion-toolbar,
		ion-content {
			--background: transparent !important;
		}
	}

	ion-toolbar {
		ion-icon {
			color: #e8e8e8 !important;
		}
		&.bottom-toolbar {
			--background: rgba(0, 0, 0, 0.7);
			backdrop-filter: blur(20px);
			--min-height: 32px;
			padding-top: 10px;
			padding-bottom: 46px;
			ion-button {
				text-transform: none;
				font-weight: 500;
				font-size: 16px;
				line-height: 16px;
				--color: #e8e8e8;
				&.send-btn {
					--background: #ff5e5e;
					--border-radius: 5px;
				}
			}

			.full-size-selection {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				ion-item {
					--min-height: 30px;
					--background: transparent;
					ion-checkbox {
						--background: transparent;
						--background-checked: #ff5e5e;
						--border-color: #858484;
						--border-color-checked: transparent;
						--checkmark-width: 3px;
						--checkmark-color: rgba(0, 0, 0, 0.7);
						--border-width: 1px;
						--size: 20px;
						margin-right: 8px;
					}
					ion-label {
						font-size: 17px;
						line-height: 17px;
						color: #e8e8e8;
					}
				}
			}
		}
	}
	.image-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		img {
			max-width: 100%;
			max-height: 100%;
			height: 100%;
			width: 100%;
			object-fit: contain !important;
		}
	}

	.header-btns {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		width: 100%;
		align-items: center;
		background: rgba(0, 0, 0, 0.5);
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		z-index: 9;
		padding-top: 20px;
		.right {
			display: flex;
			align-items: center;
			margin-right: 18px;
			height: 70px;

			.btn {
				text-transform: none;
				background: #ff5e5e;
				--color: #efefef;
				font-family: Metropolis;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 14px;
				border-radius: 97px;
				min-width: 145px;
				height: 32px;
				display: flex;
				flex-direction: row;
				align-items: center;
				cursor: pointer;
				--padding-start: 15px;
				--padding-end: 15px;

				&.download-progress-btn {
					text-align: center;
					position: relative;
					text-transform: none;
					--padding-start: 0;
					--padding-end: 0;
					.percent-text {
						flex: 1;
						font-family: Metropolis;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 14px;
						text-align: center;
						color: #efefef;
					}
					ion-button {
						position: absolute;
						right: 12px;
						margin: 0;
						font-size: 12px;
						--padding-start: 0;
						--padding-end: 0;
						--color: #efefef;

						ion-icon {
							width: 15px;
							height: 15px;
							stroke: #fff;
						}
					}
				}
			}
		}
		.album-btn {
			width: 22px;
			height: 22px;
			ion-icon {
				width: 100%;
				height: 100%;
			}
		}
	}

	.editor-btns {
		justify-content: center;
		display: flex;
		flex-direction: row;
		margin-bottom: 30px;
		.editor-btn {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			img {
				width: 22px;
				height: 22px;
				filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(133deg) brightness(102%) contrast(104%);
			}
			span {
				margin-top: 8px;
				text-align: center;
				color: #fff;
				font-size: 14px;
				text-transform: none;
			}
		}
	}

	.photo-options-btns-container {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: flex-end;
		height: 116px;
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.7);
		backdrop-filter: blur(20px);
		.btns {
			display: flex;
			flex-direction: row;
			height: 38px;
			margin-bottom: 50px;
			margin-right: 20px;
			ion-button {
				width: 38px;
				height: 38px;
				background: rgba(88, 88, 88, 0.6);
				backdrop-filter: blur(20px);
				border-radius: 50%;
				--padding-start: 0;
				--padding-end: 0;
				margin-left: 14px;
				margin-right: 0;
				ion-icon {
					width: 19px;
					height: 19px;
				}
			}
		}
	}
}
.video-preview-modal {
	.modal-wrapper {
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		height: 100% !important;
		background: #000000;
		ion-toolbar,
		ion-content {
			--background: transparent !important;
		}
	}

	ion-toolbar {
		ion-icon {
			color: #e8e8e8 !important;
		}
		&.bottom-toolbar {
			--background: rgba(0, 0, 0, 0.7);
			backdrop-filter: blur(20px);
			--min-height: 32px;
			padding-top: 10px;
			padding-bottom: 46px;
			ion-button {
				text-transform: none;
				font-weight: 500;
				font-size: 16px;
				line-height: 16px;
				--color: #e8e8e8;
				&.send-btn {
					--background: #ff5e5e;
					--border-radius: 5px;
				}
			}

			.full-size-selection {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				ion-item {
					--min-height: 30px;
					--background: transparent;
					ion-checkbox {
						--background: transparent;
						--background-checked: #ff5e5e;
						--border-color: #858484;
						--border-color-checked: transparent;
						--checkmark-width: 3px;
						--checkmark-color: rgba(0, 0, 0, 0.7);
						--border-width: 1px;
						--size: 20px;
						margin-right: 8px;
					}
					ion-label {
						font-size: 17px;
						line-height: 17px;
						color: #e8e8e8;
					}
				}
			}
		}
	}
	.image-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}

	.editor-btns {
		justify-content: center;
		display: flex;
		flex-direction: row;
		margin-bottom: 30px;
		.editor-btn {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			img {
				width: 22px;
				height: 22px;
				filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(133deg) brightness(102%) contrast(104%);
			}
			span {
				margin-top: 8px;
				text-align: center;
				color: #fff;
				font-size: 14px;
				text-transform: none;
			}
		}
	}

	.photo-options-btns-container {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: flex-end;
		height: 88px;
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.7);
		backdrop-filter: blur(20px);
		.btns {
			flex: 1;
			display: flex;
			flex-direction: row;
			height: 38px;
			margin-bottom: 50px;
			margin-right: 20px;
			margin-left: 20px;
			ion-button {
				width: 38px;
				height: 38px;
				background: rgba(88, 88, 88, 0.6);
				backdrop-filter: blur(20px);
				border-radius: 50%;
				--padding-start: 0;
				--padding-end: 0;
				margin-left: 14px;
				margin-right: 0;
				&:first-child {
					margin-left: 0;
				}
				ion-icon {
					width: 19px;
					height: 19px;
					&.close-icon {
						stroke: #efefef;
						stroke-width: 2px;
					}
				}
			}
		}
	}
}

.action-sheet-wrapper {
	margin: 0 !important;
	min-width: 100% !important;
	.action-sheet-group {
		padding-bottom: 22px !important;
		.action-sheet-title {
			background: none;
		}
	}
}

// .thread-menu-actionsheet {
// 	.action-sheet-container {
// 		padding: 0;
// 	}

// 	.action-sheet-group {
// 		height: auto;
// 		border-radius: 10px 10px 0 0;
// 		margin-bottom: 0 !important;
// 		padding: 0 !important;
// 		padding-top: 18px !important;
// 		padding-bottom: 32px !important;

// 		.action-sheet-button {
// 			padding: 0 10px;
// 			text-align: center;
// 			height: 56px;
// 			margin-top: 10px;
// 			font-style: normal;
// 			font-weight: 500;
// 			font-size: 22px;
// 			line-height: 22px;
// 			color: #000000;
// 			--ripple-color: transparent;
// 			&.creat-new-thread {
// 				color: #ff5e5e;
// 			}
// 			&.thread-menu-button {
// 				color: black;
// 			}
// 			span {
// 				justify-content: center;
// 			}
// 		}
// 	}

// 	.action-sheet-group-cancel {
// 		border-radius: 0;
// 		padding-top: 0;
// 		border: none;
// 		height: 66px;
// 		font-size: 18px;
// 		font-weight: 400;
// 	}
// }

.photo-preview-menu-actionsheet {
	.action-sheet-container {
		padding: 0;
	}

	.action-sheet-group {
		border-radius: 10px 10px 0 0;
		margin-bottom: 0 !important;
		padding: 0 !important;
		padding-top: 8px !important;
		padding-bottom: 32px !important;
	}

	.action-sheet-button {
		padding: 0 10px;
		text-align: center;
		height: 56px;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 21px;
		color: #000000;
		span {
			justify-content: center;
		}
	}

	.action-sheet-group-cancel {
		border-radius: 0;
		padding-top: 0;
		border: none;
		height: 66px;
		font-size: 18px;
		font-weight: 400;
	}
}

.preview-link-view {
	width: 100%;
	height: auto;
	border-bottom-right-radius: 4px !important;
	overflow: hidden;
	a {
		width: 100%;
		height: 156px;
		display: inline-block;
		img {
			width: 100%;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
		}
	}
	.title {
		font-weight: 500;
		font-size: 15px;
		line-height: 18px;
		color: #000000;
		background: rgba(220, 220, 220, 0.7);
		text-align: left;
		margin-top: -7px;
		padding: 5px;
	}
	.url {
		font-weight: 500;
		font-size: 15px;
		line-height: 18px;
		text-align: left;
		color: #555555;
		background: rgba(220, 220, 220, 0.7);
		margin-top: -5px;
		padding: 5px 5px 1px 5px;
		font-family: Metropolis;
		font-style: normal;
		font-weight: 100;
	}
}

aside.emoji-picker-react .content-wrapper {
	height: inherit;
}
