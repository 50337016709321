.manage-contacts-modal {
	--min-width: 100%;
	--background: transparent;
	--height: 100vh;
	align-items: unset;
	.modal-wrapper {
		min-width: 100% !important;
		background: transparent;
	}

	.container {
		background: #efefef;
		height: 100%;
		display: flex;
		flex-direction: column;
		padding-top: 16px;

		.header-toolbar {
			.close-btn {
				width: 24px;
				height: 24px;
				--padding-start: 0;
				--padding-end: 0;
				margin: 0;
				margin-left: 18px;
			}

			ion-title {
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 20px;
				text-align: center;
				color: #000000;
			}
		}
		.content {
			flex: 1;
			overflow: scroll;
			margin-top: 8px;
		}

		.bottom-view {
			display: flex;
			flex-direction: row;
			justify-content: center;
			height: 80px;
			.delete-btn {
				--padding-start: 0;
				--padding-end: 0;
				margin: 0;
				margin-top: 16px;
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 18px;
				text-align: center;
				color: #ff5e5e;
				opacity: 0.4;
				&.active {
					opacity: 1;
				}
			}
		}
	}
}
