.item-contact {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	min-height: 56px;
	width: 100%;
	padding: 0 18px;
	cursor: pointer;

	ion-checkbox {
		--border-radius: 50%;
		--size: 22px;
		--background: transparent;
		--border-color: #858484;
		--border-color-checked: #ff5e5e;
		--background-checked: #ff5e5e;
		--checkmark-color: #fff;
		margin-right: 20px;
		--checkmark-width: 2px;
	}

	.img-view {
		background: #ffffff;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
	}
	.detail {
		flex: 1;
		display: flex;
		flex-direction: column;
		margin-left: 12px;
		.name {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 18px;
			color: #000000;
			.matching {
				color: #ff5e5e;
			}
		}
		.profession {
			margin-top: 5px;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 14px;
			color: #858484;
		}
	}

	.more-option-btn {
		width: 20px;
		height: 20px;
		--padding-start: 0;
		--padding-end: 0;
		margin: 0;
	}
}
