.thread-options {
	align-items: center;
	.reply-btn {
		background-color: transparent;
		padding-left: 16px;
		padding-right: 21px;
		width: 60.57px;
		ion-img {
			width: 28.57px;
			height: 26px;
			opacity: 0;
		}
	}
}
