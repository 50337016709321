.select-file-modal {
	.modal-wrapper {
		background: rgba(var(--app-modal-bg-color-code), 0) !important;
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		height: 100% !important;
	}
	.ion-page {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.content {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: flex-end;
	}

	.select-main {
		display: flex;
		flex-direction: column;
		background-color: #efefef;
		border-radius: 14px 14px 0px 0px;

		width: 100%;

		.option-content {
			width: 100%;
			height: auto;
			display: flex;
			justify-content: flex-start;
			margin-left: 20px;
			align-items: center;
			z-index: 99999999999;
			margin-bottom: 40px;

			img {
				width: 26px;
				height: 26px;
			}
			p {
				font-family: 'Metropolis';
				font-size: 16px;
				font-weight: 600;
				color: black;
				text-transform: uppercase;
				margin-left: 15px;
				padding: 0px;
			}
		}
	}
}
.toast-container {
	--background: red;
	backdrop-filter: blur(20px);
	border-radius: 6px;
	font-family: 'Metropolis';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	--color: black;
	// fill: white;
	margin-bottom: 98px;
}
::ng-deep {
	.toast-container {
		--background: red;
		backdrop-filter: blur(20px);
		border-radius: 6px;
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		--color: black;
		// fill: white;
		margin-bottom: 98px;
	}
	.select-component-toast-view {
		.toast-wrapper.toast-bottom {
			--bottom: calc(98px + var(--ion-safe-area-bottom, 0px)) !important;
		}
		.toast-container {
			--background: red;
			backdrop-filter: blur(20px);
			border-radius: 6px;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 20px;
			--color: black;
			// fill: white;
			--margin-bottom: 98px !important;
		}
	}
}
:host ::ng-deep .select-component-toast-view .ui-toast-message {
	display: flex;
	width: 500px; // whatever width you need
	color: black;
	background-color: red;
	--margin-bottom: 98px !important;
}
