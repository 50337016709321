.showEmoj {
	.emoji-sticker-choose-view {
		margin-top: 19px;
		position: relative;
		display: flex;
		flex-direction: row;
		padding: 0 8px;
		ion-button {
			width: 44px;
			height: 44px;
			--padding-start: 0;
			--padding-end: 0;
			--padding-top: 0;
			--padding-bottom: 0;
			ion-icon {
				width: 24px;
				height: 24px;
			}
		}
	}
	aside.emoji-picker-react {
		background: transparent;
		border: none;
		.emoji {
			color: #aaa !important;
		}
		.emoji-scroll-wrapper {
		}
		.emoji-search {
			display: none;
		}
		.emoji-categories {
			display: none;
		}
		.emoji-group {
			padding: 0 11px;
			&::before {
				// position: sticky;
				position: relative;
				background: transparent;
				text-transform: none;
				font-family: Metropolis;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				color: #000000;
				margin-left: 8px;
			}
			.native {
				width: 30px;
				height: 30px;
			}
		}
	}

	.send-cancel-btns {
		position: fixed;
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		justify-content: flex-end;
		padding-bottom: 34px;
		padding-right: 12px;
		bottom: 0;
		right: 0;
		z-index: 9999;
		width: 108px;
		height: 111px;
		background: rgba(239, 239, 239, 0.7);

		.clear-btn {
			margin: 0;
			--padding-start: 0;
			--padding-end: 0;
			--padding-top: 0;
			--padding-bottom: 0;
			border-radius: 4px;
			width: 56px;
			height: 44px;
			background: #ffffff;
			margin-right: 8px;
		}
		// .send-btn {
		// 	margin: 0;
		// 	--padding-start: 0;
		// 	--padding-end: 0;
		// 	--padding-top: 0;
		// 	--padding-bottom: 0;
		// 	width: 56px;
		// 	height: 44px;
		// 	background: #ff5e5e;
		// 	border-radius: 4px;
		// 	font-family: Metropolis;
		// 	font-style: normal;
		// 	font-weight: 500;
		// 	font-size: 16px;
		// 	color: #efefef;
		// }
	}

	.stickers-view-container {
		position: relative;
		padding: 8px 8px;
		height: 320px;
		overflow-y: scroll;

		.title-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			.title {
				flex: 1;
				font-family: Metropolis;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 14px;
				color: #000000;
				margin: 0 8px;
			}
			.add-remove-sticker-btn {
				--padding-start: 0;
				--padding-end: 0;
				--padding-top: 0;
				--padding-bottom: 0;
				box-sizing: border-box;
				border-radius: 2px;
				width: 24px;
				height: 24px;
				margin-right: 10px;
				ion-icon {
					width: 20px;
					height: 20px;
					color: rgba(88, 88, 88, 1);
				}
			}
		}
		.stickers {
			margin-top: 16px;
			padding: 0 7px;
		}
	}
}
