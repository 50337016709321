.chat-input-box {
	width: 100%;
	padding-top: 2px;
	padding-bottom: 0px;
	position: fixed;
	bottom: 0;
	z-index: 9;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(var(--app-modal-bg-color-code), 0.7);
		backdrop-filter: blur(20px);
		z-index: 1;
		@supports not (backdrop-filter: none) {
			background-image: url(../../../../assets/blur-gray-bg.png) !important;
			background-size: 100% 100%;
			background-position: top left;
		}
	}

	/* The animation code */
	@keyframes showUserListPopup {
		from {
			bottom: -140px;
		}
		to {
			bottom: 66px;
		}
	}

	@keyframes hideUserListPopup {
		from {
			bottom: 66px;
		}
		to {
			bottom: -140px !important;
		}
	}

	.user-list-popup {
		position: absolute;
		// bottom: -140px;
		width: 100%;
		z-index: 1;
		box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.15);
		padding: 16px 0 !important;
		background: rgba(var(--app-modal-bg-color-code), 0.7);
		backdrop-filter: blur(20px);
		opacity: 0;
		max-height: 75vh;
		overflow-y: auto;
		border-radius: 20px 20px 0 0;

		&.show {
			animation-name: showUserListPopup;
			animation-duration: 200ms;
			bottom: 66px;
			opacity: 1;
		}
		&.hide {
			animation-name: hideUserListPopup;
			animation-duration: 200ms;
			bottom: -140px !important;
			opacity: 0;
		}

		.header-toolbar {
			width: 100%;
			position: absolute;
			top: 0;
			z-index: 99;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			border-radius: 10px 10px 0px 0px;

			ion-toolbar {
				--min-height: 60px;
				--background: transparent;
				padding: 0 20px;

				.count-label-btn {
					font-style: normal;
					font-weight: normal;
					font-size: 18px;
					line-height: 18px;
					--color: #ff5e5e;
				}
				.title {
					font-weight: 500;
					font-size: 24px;
					line-height: 24px;
					/* identical to box height */
					--color: #000000;
					text-align: left;
					padding-inline-start: 36px;
				}
				.all-btn {
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 18px;
					--color: #858484;
					&.active {
						--color: #ff5e5e;
					}
				}
			}
		}

		ion-list {
			background: transparent;
			padding: 0;
			margin-top: 45px;

			ion-item {
				--background: transparent;
				--min-height: 50px;
				--padding-start: 20px;
				--padding-end: 20px;
				--inner-padding-end: 0;
				--inner-padding-start: 0;

				ion-thumbnail {
					width: 40px;
					height: 40px;
					--border-radius: 3px;
					border-radius: 3px;
				}
				ion-label {
					font-style: normal;
					font-weight: normal;
					font-size: 18px;
					line-height: 18px;
					color: #000000;
				}
				ion-checkbox {
					--checkmark-color: #e5e5e5;
					--background-checked: #ff5e5e;
					--background: transparent;
					--border-color: #858484;
					--border-color-checked: transparent;
					--border-radius: 50%;
					--checkmark-width: 2px;
					--size: 20px;
				}
			}
		}
	}

	.toolbar-view {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		padding: 8px 7px 0px;
		z-index: 2;

		ion-icon {
			width: 24px;
			height: 24px;
			padding-left: 2px;
			padding-bottom: 2px;
			padding-right: 4px;
			margin: 0 6px;
			margin-top: 1px;
			margin-right: 4px;
			&.send-icon {
				margin: 0 8px;
			}
		}

		.clear-chat-icon {
			color: #979797;
			font-size: 24px;
			min-width: 18px;
			min-height: 18px;
			width: 18px;
			height: 18px;
			margin-bottom: 2px;
		}
	}

	.messeger-chat-input {
		background: #e6e6e6;
		flex: 1;
		border-radius: 10px;
		padding: 8px 14px;
		margin: 0 7px;
		overflow-x: hidden;
		overflow-y: auto;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: flex-end;
		z-index: 2;
		.clear-chat-icon,
		.keyboard-open-icon,
		.emoji-open-icon {
			margin-right: 0;
		}
		.editable-input {
			flex: 1;
			max-width: 100%;
			min-height: 22px;
			padding-left: 0 !important;
			color: #000000;
			outline: none;
			max-height: 100px !important;
			overflow-y: auto;
			caret-color: #ff5e5e;

			&:focus-visible {
				outline: none;
			}
			a {
				text-decoration: none;
			}
			span {
				color: #000000;
			}
			.tagged {
				color: #ff5e5e;
			}
		}

		ion-textarea {
			color: #000000;
			background: transparent;
			white-space: normal !important;
			padding-left: 8px !important;
			--padding-top: 0;
			--padding-bottom: 0;
			--padding-start: 0;
			--padding-end: 0;
			max-height: 100px !important;
			line-height: 20px !important;
			margin-top: 0 !important;
		}

		.icon-inside-chat-input {
			width: 15px;
			height: 24px;
			min-width: 24px;
			margin: 0;
			min-height: 24px;
			--padding-start: 0;
			--padding-end: 0;
			&.right {
				margin-left: 12px;
				margin-right: 0;
			}
			&.left {
				margin-left: 0;
				margin-right: 12px;
			}

			ion-icon {
				padding-top: 2px;
				width: 100%;
				margin: 0;
				height: 100%;
				color: #858484;
			}
		}
	}

	.showEmoj {
		width: 100% !important;
		display: block;
		position: relative;
		&::before {
			position: absolute;
			content: '';
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			// background-color: var(--app-color-code);
			z-index: 1;
		}
		* {
			z-index: 2;
		}
	}

	.showEmoj aside {
		width: 100% !important;
		display: flex;
	}

	ion-buttons {
		margin-bottom: 9px;
	}

	ion-icon {
		color: #858484;
	}
}

.chat-select-view {
	width: 100%;
	height: 88px;
	padding-bottom: 8px;
	// background: var(--app-color-code);
	// box-shadow: 0px -4px 4px rgba(77, 71, 71, 0.15);
	// position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 2px;
		box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.15);
	}

	ion-toolbar {
		--background: transparent;
		.itemCount {
			font-size: 16px !important;
			font-weight: 500 !important;
			color: #000000 !important;
			letter-spacing: -0.333333px !important;
			padding-inline-start: 18px !important;
		}
		ion-icon {
			width: 28px;
			height: 28px;
		}
		.image-container {
			display: flex;
			justify-content: flex-end;
			margin-right: 18px;
			.image-button {
				width: 40px;
				height: 40px;
				background: rgba(88, 88, 88, 0.2);
				backdrop-filter: blur(10px);
				margin-left: 14px;
				border-radius: 50%;
			}
		}
		ion-title {
			// text-align: center;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 33px;
			color: #858484;
		}
		.popup-Icon-list {
			display: flex;
			justify-content: right;
			align-items: center;
			width: 298px;
			height: 38px;
			flex: 1;
			margin-right: 0px;
			margin-left: 100px;

			.popup-media {
				border-radius: 100%;
				width: 40px;
				height: 40px;
				min-width: 38px;
				--padding-start: 0;
				--padding-end: 0;
				margin-left: 14px;
				background: rgba(88, 88, 88, 0.6);
				opacity: 0.2;
				backdrop-filter: blur(10px);

				.popup-image-container {
					width: 100%;
					height: 100%;
					position: absolute;
					border-radius: 50%;
					backdrop-filter: blur(20px);
					opacity: 0.2;
					display: flex;
					justify-content: center;
					align-items: center;
					.popup-media-image-right {
						height: 14px;
					}
					.popup-media-image {
						width: 28px;
						height: 28px;
					}
				}
			}
		}
	}
}
// .bottom-manage-sendThread-popup {
// 	display: flex;
// 	flex-direction: column;
// 	min-width: 300px;
// 	width: 100%;
// 	height: auto;
// 	position: relative;
// 	right: 0;
// 	bottom: 88px;
// 	background: rgba(239, 239, 239, 0.7);
// 	backdrop-filter: blur(20px);
// 	border-radius: 0px;
// 	z-index: 999999;

// 	.select-content {
// 		width: 100%;
// 		display: flex;
//     flex-direction: column;
// 		margin-left: 18px;
// 		z-index: 99999999999;
// .move-to{
//   width: 100%;
//   height: 42px;
// font-family: 'Metropolis';
// font-style: normal;
// font-weight: 500;
// font-size: 18px;
// line-height: 18px;
// color:#585858;
// padding-top: 15px;
// padding-bottom: 9px;
// }
// .thread-container{
//   width: 100%;
//   padding-top: 8px;
//   padding-bottom: 8px;
//   display: flex;
//   justify-content: center;
//   flex-wrap: wrap;
//   .chat-button{
//     background: #D8D8D8;
//     width: 59px;
//     height: 30px;
//     --background: none;
//     --box-shadow: none;
//     border-radius: 25px;
//     font-family: 'Metropolis';
//     font-style: normal;
//     font-weight: 600;
//     font-size: 16px;
//     text-align: center;
//     margin-left: 3px;
//     margin-bottom: 16px;
//     color: #efefef;
//     text-transform: none;
//     margin-top: 0;
//     margin-right: 0;
//     padding-top: 8px;
//     padding-bottom: 7px;
//     padding-left: 8px;
//     padding-right: 8px;

//   }
//   ion-button {
//     height: 30px;
//     background: #00ff2b;
//     --background: none;
//     --box-shadow: none;
//     border-radius: 25px;
//     font-family: 'Metropolis';
//     font-style: normal;
//     font-weight: 600;
//     font-size: 16px;
//     text-align: center;
//     margin-left: 12px;
//     margin-bottom: 16px;
//     color: #000000;
//     text-transform: none;
//     margin-top: 0;
//     margin-right: 0;
//     --padding-start: 8px;
//     --padding-end: 8px;
//     --padding-top: 3px;
// .chat-button{
//   background: #D8D8D8;
// }
//     &.active {
//       background: #ff5e5e;
//       color: white;
//     }
//   }

// .chat-thread-container {
//   width: 100%;
//   position: fixed;
//   color: #000;
//   z-index: 99;
//   background: transparent;
//   top: 92px;
//   display: flex;
//   justify-content: center;
//   flex-wrap: wrap;
//   margin-left: 12px;
//   margin-right: 12px;
//   right: 0;
//   left: 0;

//   .thread-button {
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     .thread-btn-container {
//       position: relative;
//       display: flex;
//       flex-direction: row;
//       padding-right: 9px;

//       .notification-grid {
//         position: absolute;
//         padding-top: 5px;
//         padding-bottom: 5px;
//         padding-right: 6px;
//         padding-left: 6px;
//         min-width: 18px;
//         height: 18px;
//         right: 2px;
//         top: -3px;
//         z-index: 2;
//         color: white;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         font-size: 12px;
//         background: #ff5e5e;
//         box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
//         border-radius: 21px;
//       }
//       .notification-grid-3Dot {
//         position: absolute;
//         padding-top: 7px;
//         padding-bottom: 4px;
//         padding-right: 6px;
//         padding-left: 6px;
//         min-width: 18px;
//         height: 18px;
//         right: 2px;
//         top: -3px;
//         z-index: 2;
//         color: white;
//         display: flex;
//         justify-content: center;
//         align-items: flex-end;
//         font-size: 19px;
//         background: #ff5e5e;
//         box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
//         border-radius: 21px;
//       }
//     }

//     ion-button {
//       height: 30px;
//       background: #00ff2b;
//       --background: none;
//       --box-shadow: none;
//       border-radius: 25px;
//       font-family: 'Metropolis';
//       font-style: normal;
//       font-weight: 600;
//       font-size: 16px;
//       text-align: center;
//       margin-left: 3px;
//       margin-bottom: 16px;
//       color: #000000;
//       text-transform: none;
//       margin-top: 0;
//       margin-right: 0;
//       --padding-start: 8px;
//       --padding-end: 8px;
//       --padding-top: 3px;

//       &.active {
//         background: #ff5e5e;
//         color: white;
//       }
//     }
//   }
//   .thread-longpress {
//     display: flex;
//     // flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     ion-button {
//       height: 30px;
//       background: #00ff2b;
//       --background: none;
//       --box-shadow: none;
//       border-radius: 25px;
//       font-family: 'Metropolis';
//       font-style: normal;
//       font-weight: 600;
//       font-size: 16px;
//       text-align: center;
//       margin-bottom: 34px;
//       color: #000000;
//       text-transform: none;
//       margin-top: 0;
//       margin-right: 0;
//       --padding-start: 8px;
//       --padding-end: 8px;
//       --padding-top: 8px;
//       --padding-bottom: 8px;

//       &.active {
//         background: #ff5e5e;
//         color: white;
//       }
//     }
//     .thread-btn-container {
//       position: relative;
//       display: flex;
//       flex-direction: column;
//       padding-right: 9px;

//       .notification-list {
//         position: absolute;
//         padding-top: 5px;
//         padding-bottom: 5px;
//         padding-right: 6px;
//         padding-left: 6px;
//         min-width: 18px;
//         height: 18px;
//         right: 2px;
//         top: -3px;
//         z-index: 2;
//         color: white;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         font-size: 12px;
//         background: #ff5e5e;
//         box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
//         border-radius: 21px;
//       }
//       .notification-list-3Dot {
//         position: absolute;
//         padding-top: 7px;
//         padding-bottom: 4px;
//         padding-right: 6px;
//         padding-left: 6px;
//         min-width: 18px;
//         height: 18px;
//         right: 2px;
//         top: -3px;
//         z-index: 2;
//         color: white;
//         display: flex;
//         justify-content: center;
//         align-items: flex-end;
//         font-size: 19px;
//         background: #ff5e5e;
//         box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
//         border-radius: 21px;
//       }
//     }
//   }
// }
// }
// 		.folder-img {
// 			width: 23px;
// 			height: 21px;
// 			fill: #585858;
// 		}
// 		p {
// 			font-family: 'Metropolis';
// 			font-size: 16px;
// 			font-weight: 600;
// 			color: black;
// 			text-transform: uppercase;
// 			margin-left: 13px;
// 			padding: 0px;
// 			margin-top: 0;
// 			margin-bottom: 0;
// 		}
// 	}

// 	.file-name {
// 		width: 100%;
// 		height: auto;
// 		font-family: 'Metropolis';
// 		font-style: normal;
// 		font-weight: 600;
// 		font-size: 16px;
// 		line-height: 16px;
// 		color: #000000;
// 	}

// 	.share-files {
// 		display: flex;
// 		flex-direction: column;
// 		justify-content: space-between;
// 		align-items: center;
// 		width: 100%;
// 		font-family: 'Metropolis';
// 		font-style: normal;
// 		font-weight: 500;
// 		font-size: 18px;
// 		line-height: 18px;
// 		/* identical to box height */

// 		color: #585858;
// 		.selected-send-file {
// 			width: 100%;
// 			display: flex;
// 			justify-content: space-between;
// 			font-family: 'Metropolis';
// 			font-style: normal;
// 			font-weight: 500;
// 			font-size: 18px;
// 			color: #585858;
// 			padding-left: 17px;
// 			padding-top: 17px;
// 		}

// 		.popup-manage {
// 			// width: 7.8px;
// 			// height: 14px;
// 			right: 0;

// 			.arrow-right {
// 				width: 100%;
// 				height: 100%;
// 				right: 0;
// 				.arrow-right-light {
// 					width: 100%;
// 					height: 100%;
// 				}
// 			}
// 		}

// 		.selected-file {
// 			padding: 17px;
// 		}
// 	}

// 	.manage-share-top {
// 		display: flex;
// 		flex-direction: column;
// 		margin-left: 10px;

// 		.send {
// 			display: flex;
// 			flex-direction: column;
// 		}
// 		.manage-files {
// 			width: 100%;
// 			font-family: 'Metropolis';
// 			font-style: normal;
// 			font-weight: 500;
// 			font-size: 18px;
// 			line-height: 18px;
// 			padding: 17px;
// 			color: #585858;
// 		}

// 		.selected-send-file {
// 			width: 100%;
// 			display: flex;
// 			justify-content: space-between;
// 			font-family: 'Metropolis';
// 			font-style: normal;
// 			font-weight: 500;
// 			font-size: 18px;
// 			color: #585858;
// 			padding-left: 17px;
// 			// padding-top: 17px;
// 			// padding-bottom: 17px;
// 			.manage {
// 				display: flex;
// 				align-items: center;
//         .right-icon{
//           margin-right: 18.5px;
//         }
// 			}
// 		}

// 		.popup-manage {
// 			// width: 7.8px;
// 			// height: 14px;
// 			right: 0;

// 			.arrow-right {
// 				width: 100%;
// 				height: 100%;
// 				right: 0;
// 				.arrow-right-light {
// 					width: 100%;
// 					height: 100%;
// 				}
// 			}
// 		}
// 	}
// }

.chat-reply-view {
	display: flex;
	z-index: 2;
	position: relative;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 10px 20px;
	background: #e6e6e6;
	.profile-img {
		border-radius: 3px;
		width: 32px;
		height: 32px;
		object-fit: cover;
	}
	.user-title-msg {
		margin-left: 13px;
		flex: 1;
		font-size: 14px;
		line-height: 14px;
		overflow-y: hidden;
		color: #585858;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		padding-top: 5px;
		padding-bottom: 5px;
		@supports (-webkit-line-clamp: 2) {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: initial;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
		// &.text-type {
		// }
		// &.file-type {
		// }
		&.image-type {
			align-items: center;
			justify-content: flex-start;
			display: flex;
		}

		.tagged {
			color: #ff5e5e !important;
		}

		.title {
			text-transform: none;
			font-weight: bold;
			.paranthesis {
				font-weight: 500;
			}
		}
		.msg-image {
			object-fit: cover;
			width: 54.74px;
			height: 40px;
			border-radius: 4px;
			margin-left: 12px;
		}
	}

	.giphy-type-message {
		margin-left: 5px;
	}

	.close-button {
		margin-left: 20px;
		ion-icon {
			width: 24px;
			height: 24px;
			stroke: #585858;
		}
	}
}
