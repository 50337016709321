.create-folder-popup {
	.modal-wrapper {
		background: rgba(var(--app-modal-bg-color-code), 0) !important;
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		height: 100% !important;
	}
	.ion-page {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.content {
		border-radius: 10px;
		width: 282px;
		height: 184px;
		background-color: #efefef;
		margin-bottom: 50px;

		.newfolder {
			text-align: center;
			margin-top: 18px;
			font-size: 20px;
			font-weight: 500;
		}

		.modal-head {
			display: flex;
			flex-direction: column;
			border: 1px;
			height: 60px;
			width: 244px;
			margin: auto;
			background-color: #e8e8e8;
			border-top-right-radius: 8px;
			border-top-left-radius: 8px;

			margin-top: 18px;
			border-bottom: 1px solid #ff5e5e;

			label {
				color: #ff5e5e;
				margin-top: 10px;
				margin-bottom: 14px;
				font-size: 12px;
				margin-left: 21px;
			}
			input {
				border: none;
				font-size: 17px;
				background-color: #e8e8e8;
				margin-left: 21px;
				outline: none;
			}
		}

		.buttons {
			display: flex;
			justify-content: center;
			width: 100%;
			margin-top: 32px;
			.inner-btn {
				width: 244px;
				display: flex;
				justify-content: space-between;
				.create {
					color: #ff5e5e;
				}
			}

			button {
				font-size: 16px;
				font-weight: 500;
			}
		}
	}
}
