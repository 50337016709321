.group-manager-page {
	.topbar-header {
		.toolbar-heading {
			--padding-start: 10px;
			--padding-end: 18px;
			--background: #efefef;

			.buttons-first-slot {
				ion-button {
					margin: 0;
					padding: 0 8px;
					text-transform: none;
					ion-icon {
						width: 10px !important;
						height: 18px !important;
					}
				}
			}
			.buttons-last-slot {
				justify-content: flex-end;
				ion-button {
					margin: 0;
					font-family: Metropolis;
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 18px;
					display: flex;
					align-items: center;
					text-align: right;
					--color: #ff5e5e;
					text-transform: none;
					&.button-disabled {
						--color: #c4c4c4;
					}
				}
			}
			ion-title {
				.title {
					font-family: Metropolis;
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 18px;
					text-align: center;
					color: #000000;
				}
				.description {
					margin-top: 6px;
					font-family: Metropolis;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 14px;
					text-align: center;
					color: #585858;
				}
			}
		}
	}

	.toggle-customised {
		--background: #dcdcdc;
		--background-checked: #3ebaff;
		--handle-background: #ffffff;
		--handle-background-checked: #ffffff;
		--handle-height: 22px;
		--handle-width: 22px;
		--handle-border-radius: 50%;
		--handle-max-height: 22px;
		--handle-spacing: 1px;
		width: 45px;
		height: 24px;
	}
	.fields-list {
		background: #efefef;
		margin: 0;

		.group-field-item {
			display: flex;
			flex-direction: column;
			padding: 0 18px;
			min-height: 44px;
			justify-content: center;

			ion-button.value {
				--padding-start: 0;
				--padding-end: 0;
				height: auto;
				margin: 0;
				padding: 0;
				text-transform: none;
				--border-radius: 0 !important;
			}

			.row-first {
				display: flex;
				flex-direction: row;
				align-items: center;
				min-height: 37px;
				justify-content: space-between;
				.title {
					flex: 1;
					font-family: Metropolis;
					font-style: normal;
					font-weight: normal;
					font-size: 17px;
					line-height: 17px;
					color: #000000;
					max-width: 88%;
				}

				.value {
					display: flex;
					align-items: center;
					font-family: Metropolis;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 16px;
					text-align: right;

					img {
						width: 36px;
						height: 36px;
						border-radius: 4px;
					}

					ion-icon {
						width: 7px;
						height: 13px;
						margin-left: 12px;
						fill: #a5a5a5;
					}

					.placeholder {
						color: #a5a5a5;
					}
					.placeholder:hover {
						background: none;
					}
					.data {
						color: #858484;
						font-weight: 500;
					}
				}
				.value:hover {
					background: none;
				}
			}
			.row-second {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				.title {
					font-family: Metropolis;
					font-style: normal;
					font-weight: normal;
					font-size: 13px;
					line-height: 16px;
					color: #858484;
					max-width: 88%;
				}
				.value {
					ion-icon {
						width: 7px;
						height: 13px;
						margin-left: 12px;
						fill: #a5a5a5;
					}
				}
				// .value:hover {
				// 	--background-hover: none !important;
				// }

				.arrow-adjust-view {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					max-height: 33px;
					height: auto;
					margin-top: -6px;
				}
			}
		}

		ion-item {
			--min-height: 60px;
			--background: transparent;
			ion-label {
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 29px;

				color: #000000;
				p {
					padding: 0;
					color: #000000;
				}
				h3 {
					color: #000000;
					padding: 0;
				}
			}
			ion-note {
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 26px;
				color: #858484;
			}
		}

		.groupname-item {
			--min-height: 86px;
			ion-thumbnail {
				background: #dcdcdc;
				border-radius: 5px;
				width: 60px;
				height: 60px;
				align-items: center;
				justify-content: center;
				display: flex;
				margin-right: 20px;
				overflow: hidden;
				ion-img.placeholder {
					width: 25px;
					height: 20px;
				}
			}

			ion-input {
				font-family: Metropolis;
				font-style: normal;
				font-weight: normal;
				font-size: 18px;
				line-height: 164.8%;
				color: #000000;
				::placeholder {
					color: #a5a5a5;
				}
				caret-color: #ff5e5e;
			}
		}

		.group-notice-item {
			p {
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 26px;
				color: #858484;
			}
		}

		.my-alias-group-item {
			ion-note {
				margin-right: 20px;
			}
		}

		.swtich-item {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			ion-label {
				font-size: 12px;
				color: #000000;
				margin-top: 5px;
			}
		}
	}

	.group-members-devider {
		background: transparent;
		height: 30px;
		border: none;
		padding-left: 18px;
		background: #efefef;
		ion-label {
			font-family: Metropolis;
			font-style: normal;
			font-weight: 500;
			font-size: 17px;
			line-height: 17px;
			color: #585858;
		}
	}

	.selected-contacts-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		max-height: 166px;
		position: relative;
		background: #efefef;
		.contact-item {
			display: flex;
			flex-direction: column;
			width: 60px;
			min-width: 60px;
			align-items: center;
			justify-content: flex-start;
			margin: 8px 11px 5px;
			img {
				background: #ffffff;
				border-radius: 4px;
				width: 50px;
				height: 50px;

				object-fit: cover;
			}
			.title {
				margin-top: 7px;
				font-family: Metropolis;
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 14px;
				text-align: center;
				color: #585858;
				box-sizing: content-box;
				width: 100%;
				white-space: normal;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.contact-add-remove-item {
				width: 50px;
				height: 50px;
				border: 1px dashed #858484;
				box-sizing: border-box;
				border-radius: 4px;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				.horizontal-line,
				.vertical-line {
					position: absolute;
					height: 1px;
					width: 20px;
					background-color: #858484;
					border-radius: 4px;
				}
				.vertical-line {
					transform: rotateZ(90deg);
				}
			}
		}
	}

	.view-more-contact-btn-view {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		height: 67px;
		margin-right: 18px;
		.title {
			font-family: Metropolis;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 164.8%;
			display: flex;
			align-items: center;
			text-align: right;
			color: #858484;
			margin-right: 12px;
		}
		ion-icon {
			width: 7px;
			height: 13px;
			fill: #858484;
		}
	}
	.blank {
		min-height: 100px;
		background: #efefef;
		flex: 1;
	}

	.encryption-view {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		min-height: 94px;
		padding: 14px 18px;
		h3 {
			font-family: Metropolis;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 16px;
			text-align: center;
			color: #000000;
			margin: 0;
		}
		p {
			margin: 0;
			margin-top: 10px;
			font-family: Metropolis;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 14px;
			text-align: center;
			color: #858484;
		}
	}
}

.group-manager-action-sheet {
	.action-sheet-container {
		padding-inline-start: 0;
		padding-inline-end: 0;
		.action-sheet-group {
			margin-bottom: 0;
			border-radius: 10px 10px 0px 0px;
			padding-bottom: 32px !important;
			.action-sheet-title {
				font-family: Metropolis;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 18px;
				text-align: center;
				padding: 0;
				justify-content: center;
				align-items: center;
				display: flex;
				color: #ff5e5e;
				height: 56px;
				margin-top: 8px;
			}
			button {
				height: 56px;
				font-family: Metropolis;
				font-style: normal;
				font-weight: normal;
				font-size: 18px;
				line-height: 18px;
				color: #000000;
				&:last-child {
					height: 66px;
				}
			}
		}
	}
}
