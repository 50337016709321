.create-post-page {
	.post-header {
		* {
			/*font-family: ArialBlack;*/
			font-weight: 700;
		}

		padding-top: 20px;
		--background: var(--app-color-code);
		--color: #fff;

		.post-btn {
			--padding-start: 16px;
			--padding-end: 16px;
		}
	}

	ion-content {
		--background: var(--app-color-code);

		* {
			/*font-family: ArialBlack;*/
			font-weight: 700;
		}

		button:focus {
			outline: none;
		}

		ion-textarea {
			background-color: var(--app-color-code);
			width: auto;
			caret-color: red;
			min-height: 164px;
			margin: 30px 16px 0px;
			padding: 8px;
			font-size: 18px !important;
		}

		.media-container {
			display: flex;
			flex-wrap: wrap;
			padding: 0 16px;
			align-items: center;
			justify-content: space-between;

			.media-item-size {
				width: 29vw;
				height: 29vw;
				margin: 0;
				margin-bottom: 8px;
			}

			.media-item {
				object-fit: cover;
				box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
			}

			.add-media-btn {
				--border-radius: 0;
				--background: #dcdcdc;
				ion-icon {
					font-size: 40px;
				}
			}
		}

		ion-list {
			background: transparent !important;
			margin-bottom: 40px;
			ion-item {
				--padding-start: 16px;
				--background: transparent;
				background-color: transparent;
				--min-height: 57px;

				ion-button {
					--padding-start: 0px;
					--padding-end: 0px;
				}

				ion-label {
					font-size: 18px !important;
				}
			}
		}
	}
}
