.toast-stylish-modal {
	pointer-events: none;

	.ion-page {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.modal-wrapper {
		background: transparent !important;
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		height: 100% !important;
	}
	ion-icon {
		color: #e8e8e8;
		stroke: #e8e8e8;
		fill: #e8e8e8;
		font-size: 40px;
		margin-bottom: 20px;
	}
	ion-text {
		font-family: Arial;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 21px;
		color: #e8e8e8;
	}
	.toast-container {
		min-width: 136px;
		min-height: 136px;
		background: #535353;
		border-radius: 12px;
		padding: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
}
.container {
	width: 100%;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	.media-icon-container-video {
		width: 72px;
		height: 72px;
		display: flex;
		margin-left: 42px;
		justify-content: center;
		align-items: center;
		.media-icon-message {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.media-icon-video {
			width: 34px;
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.media-icon {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		ion-icon {
			width: 34px;
			height: 32px;
		}
	}
	.media-icon-container-message {
		width: 72px;
		height: 72px;
		display: flex;
		margin-left: 57px;
		margin-right: 57px;
		justify-content: center;
		align-items: center;
		.media-icon-message {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.media-icon-video {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.media-icon {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		ion-icon {
			width: 34px;
			height: 32px;
		}
	}
	.media-icon-container-phoncall {
		width: 72px;
		height: 72px;
		display: flex;
		margin-right: 42px;
		justify-content: center;
		align-items: center;
		.media-icon-message {
			width: 34px;
			height: 34px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.media-icon-video {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.media-icon {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		ion-icon {
			width: 34px;
			height: 32px;
		}
	}
}

ion-item.multi-input-list-item:last-child .input-field-container {
	width: 200px;
}
ion-item.multi-input-list-item {
	--min-height: 56px;
	--border-color: #fff;
	--background: transparent;

	ion-label {
		font-style: normal;
		font-weight: normal;
		font-size: 17px !important;
		line-height: 29px;
		color: #000000;
	}

	.edit-profile-p {
		min-width: 100px;
		text-align: right;
		max-width: 50%;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		color: #858484;
	}

	.input-field-container {
		width: auto;
		// height: 30px;
		min-height: 30px;
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
	}

	.edit-profile-info {
		background-color: #dcdcdc;
		text-align: left;
		border-radius: 5px;
		color: #535353;
		// height: auto;
	}
	.edit-profile-bio {
		background-color: #dcdcdc;
		text-align: left;
		border-radius: 5px;
		color: #535353;
		height: auto;
		max-height: 300px;
	}
	ion-input {
		padding: 0 8px;
		--padding-top: 8px;
		--padding-bottom: 8px;
		--padding-start: 6px;
		--padding-end: 6px;
	}

	ion-textarea {
		padding: 0px 20px !important;
		// height: 100px;
		// max-height: 100px;
		line-height: 24px;
		overflow: hidden;
		.native-textarea {
			--padding-top: 3px !important;
			--padding-bottom: 3px !important;
		}
	}
	.reaminig-letter-label {
		position: absolute;
		z-index: 1111;
		padding-right: 8px;
		padding-bottom: 13px;
		font-size: 14px;
		color: #535353;
	}
	ion-select {
		max-width: none;
	}

	.detail-arrow-icon-view {
		width: 20px;
		height: 20px;
		margin: 0 !important;
		margin-left: 3px !important	;
		ion-icon {
			margin: 0;
			font-size: 20px;
			opacity: 0.25;
		}
	}
}

.photo-input-item {
	--min-height: 90px;
	ion-label {
		font-style: normal;
		font-weight: normal;
		font-size: 15px !important;
		line-height: 29px;
		color: #000000;
	}

	p {
		padding: 0;
		color: #858484;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 22px;
	}

	ion-thumbnail {
		width: 66px;
		height: 66px;
		--border-radius: 4px;
	}
	.detail-arrow-icon-view {
		width: 20px;
		height: 20px;
		margin: 0 !important;
		margin-left: 3px !important;
		ion-icon {
			margin: 0;
			font-size: 20px;
			opacity: 0.25;
		}
	}
}

.custom-spinner {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 22px;
	height: 22px;
	border-radius: 50%;
	position: relative;
	transform-origin: center center;

	img {
		width: 100%;
		height: 100%;
		-webkit-animation: rotating 750ms linear infinite;
		-moz-animation: rotating 750ms linear infinite;
		-ms-animation: rotating 750ms linear infinite;
		-o-animation: rotating 750ms linear infinite;
		animation: rotating 1000ms linear infinite;

		@-webkit-keyframes rotating /* Safari and Chrome */ {
			from {
				-webkit-transform: rotate(0deg);
				-o-transform: rotate(0deg);
				transform: rotate(0deg);
			}
			to {
				-webkit-transform: rotate(360deg);
				-o-transform: rotate(360deg);
				transform: rotate(360deg);
			}
		}

		@keyframes rotating {
			from {
				-ms-transform: rotate(0deg);
				-moz-transform: rotate(0deg);
				-webkit-transform: rotate(0deg);
				-o-transform: rotate(0deg);
				transform: rotate(0deg);
			}
			to {
				-ms-transform: rotate(360deg);
				-moz-transform: rotate(360deg);
				-webkit-transform: rotate(360deg);
				-o-transform: rotate(360deg);
				transform: rotate(360deg);
			}
		}
	}
}

.notification-badge {
	--background: #3ebaff;
	--color: #ffffff;
	width: 16px;
	height: 16px;
	min-width: unset;
	max-width: unset;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 10px;
	padding: 0;
	font-family: Metropolis;
	box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
	img {
		width: 11.05px;
		height: 1.94px;
		object-fit: contain;
	}
}
