.alert-button.sc-ion-alert-ios {
	border-top: none !important;
	border-right: none !important;
	font-size: 18px;
	font-weight: 500;
}
.alert-message.sc-ion-alert-ios {
	font-size: 18px;
	font-weight: 500;
}
.alert-wrapper.sc-ion-alert-ios {
	background: #efefef;
	border-radius: 10px;
}
.alertShow-controller-class {
	--background: #fff;
	--height: 160px;
	--width: 320px;

	.alert-wrapper.sc-ion-alert-ios {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 320px;
		height: 160px;

		.alert-message {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 120%;
			text-align: center;
			color: #000000;
			margin-top: -26px;
		}
		.alert-button-group {
			width: 105px;
			height: 18px;
			padding-top: 15px;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 18px;
			text-align: center;
			color: #ff5e5e;
		}
	}
}

.add-members-page {
	$selectedContactsViewHeight: 90px;
	--background: #efefef;

	.topbar-header {
		background: #efefef;
		&.selection-mode-topbar {
			.buttons-first-slot {
				ion-button {
					margin: 0;
					padding: 0 8px;
					// ion-icon {
					// 	width: 24px !important;
					// 	height: 24px !important;
					// 	stroke: #000000;
					// }
				}
			}
		}
		.toolbar-heading {
			--padding-start: 10px;
			--padding-end: 18px;

			.buttons-last-slot {
				ion-button {
					margin: 0;
					font-family: Metropolis;
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 18px;
					display: flex;
					align-items: center;
					text-align: right;
					--color: #ff5e5e;
					&.button-disabled {
						--color: #c4c4c4;
					}
				}
			}
			ion-title {
				font-family: Metropolis;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 18px;
				text-align: center;
				color: #000000;
			}
		}

		.selected-contacts-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			height: $selectedContactsViewHeight;
			margin-left: 18px;
			margin-right: 18px;
			overflow-x: scroll;
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
			background: rgba(239, 239, 239, 0.7);
			&::-webkit-scrollbar {
				display: none;
			}
			.new-project-container {
				width: 100%;
				display: flex;
				justify-content: space-between;
				.contact-item-new-project {
					width: auto;
					min-width: 40px;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					margin: 0;
					margin-right: 18px;
					img {
						background: #ffffff;
						border-radius: 4px;
						width: 40px;
						height: 40px;
						object-fit: cover;
					}
					.user-name {
						display: flex;
						flex-direction: column;
						padding-left: 13px;
						.name {
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 400;
							font-size: 18px;
							line-height: 18px;
							color: #000000;
						}
						.profession {
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 400;
							font-size: 14px;
							line-height: 14px;
							color: #858484;
							margin-top: 4.5px;
						}
					}
				}

				// .close-icon {
				// 	margin-right: 18.88px;
				// 	ion-icon {
				// 		stroke: #000000;
				// 		width: 25px !important;
				// 		height: 25px !important;
				// 	}
				// }
			}
			.contact-item {
				width: 50px;
				min-width: 50px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: flex-start;
				margin: 0;
				margin-right: 32px;
				img {
					background: #ffffff;
					border-radius: 4px;
					width: 50px;
					height: 50px;
					object-fit: cover;
				}
				.title {
					margin-top: 3px;
					font-family: Metropolis;
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 14px;
					text-align: center;
					color: #585858;
					max-lines: 2;
					box-sizing: content-box;
					white-space: normal;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
		// .selected-contacts-forward {
		// 	display: flex;
		// 	flex-wrap: wrap;
		// 	height: auto;
		// 	margin-right: 0px;
		// 	height: 189px;

		// 	&.contact-item {
		// 		width: 50px;
		// 		height: 50px;
		// 	}
		// }

		.chat-type-selection {
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;
			// background: white;
			background: rgba(239, 239, 239, 0.7);
			backdrop-filter: blur(20px);
			padding-bottom: 5px;

			.type-item {
				display: flex;
				flex-direction: column;
				align-items: center;
				img {
					width: 40px;
					height: 40px;
					object-fit: contain;
				}
				.title {
					padding: 0;
					margin: 0;
					font-family: Metropolis;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 14px;
					color: #000000;
				}
			}
			.type-active {
				display: flex;
				flex-direction: column;
				align-items: center;
				opacity: 0.2;
				object-fit: contain;
				width: auto;
				flex-wrap: wrap;
				img {
					width: 40px;
					height: 40px;
					object-fit: contain;
				}

				.title {
					padding: 0;
					margin: 0;
					font-family: Metropolis;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 14px;
					color: #000000;
				}
			}
			// &.contacts-press {
			// 	background: #ffffff;
			// }
		}
	}

	.create-new-tag-list {
		background: transparent;
		margin-bottom: 4px;
		padding-bottom: 0;
		padding-top: 0;

		.tag-field-item {
			margin-top: 4px;
			--min-height: 44px;
			--background: #ffffff;
			--inner-padding-start: 18px;
			--inner-padding-end: 18px;
			--padding-start: 0;

			ion-label {
				font-family: Metropolis;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				color: #000000;
			}
			ion-button {
				--color: #858484;
				margin: 0;
				--padding-end: 0;
				--padding-start: 0;
				width: 8px;
				height: 13px;
				ion-icon {
					width: 8px;
					height: 13px;
				}
			}
			ion-input {
				--background: transparent !important;
				border-radius: 5px;
				--padding-top: 4px;
				--padding-bottom: 4px;
				--padding-start: 0;
				--padding-end: 0;
				padding-right: 0 !important;
				height: 30px;
				color: #000000;
				.input-clear-icon {
					width: 16px;
					height: 16px;
					border-radius: 50%;
				}
			}
			.clear-icon {
				width: 18px !important;
				height: 18px !important;
				ion-icon {
					width: 18px !important;
					height: 18px !important;
				}
			}
		}

		.tagname-title-item {
			--background: transparent;
			--min-height: 18px;
			font-size: 14px;
			line-height: 14px;
			color: #000000;
			font-family: Metropolis;
			font-style: normal;
			font-weight: 500;
			color: #858484;
			--padding-start: 17px;
		}
	}

	ion-content {
		--background: #ffffff !important;
		--padding-top: 195px;
		&.for-chats-only {
			--padding-top: 195px;
		}
		&.for-chat-only-contact {
			--padding-top: 195px;
		}
		&.for-chat-only-contact-with-selected-contact {
			--padding-top: 281px;
		}
		&.for-add-tag-selected-contacts {
			--padding-top: 285px;
		}
		&.for-forward-sketchpad {
			--padding-top: 161px;
		}
		&.selected-contact {
			--padding-top: 286px;
		}
		&.for-share-conatct {
			--padding-top: 142px !important;
		}
		&.for-share-conatct-with-selected-contact {
			--padding-top: 230px;
		}
		&.for-forward-message {
			--padding-top: 144px;
		}
		&.forward-message-with-selected-contact {
			--padding-top: 240px;
		}
		&.choose-contact-for-ownership {
			--padding-top: 144px;
		}
		&.choose-contact-for-secondaryadmin {
			--padding-top: 150px;
		}
		&.selected-contact-for-secondaryadmin {
			--padding-top: 210px;
		}
		&.for-create-new-tag {
			--padding-top: 218px;
		}
		&.for-create-new-tag-with-selected-contact {
			--padding-top: 299px;
		}

		.selected-contacts-space-fill {
			height: $selectedContactsViewHeight;
		}

		.recent-container-forword-message {
			height: 36px;
			background: #ffffff;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-left: 17.5px;
			padding-right: 18px;
			.recent {
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 16px;
				color: #000000;
			}

			.add-contact {
				width: 17.67px;
				height: 16px;
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}

		.recent-container {
			height: 30px;
			background: #ffffff;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-left: 17.5px;
			padding-right: 18px;
			.recent {
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 16px;
				color: #585858;
			}
			.add-contact {
				width: 17.67px;
				height: 16px;
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}

		.chat-only-view-container {
			display: flex;
			align-items: center;
			justify-content: center;
			// padding: 9px 17px;
			// margin-top: 22px;

			.chats-only-msg {
				width: 100%;
				height: 51px;
				padding-left: 17px;
				padding-right: 17px;
				padding-top: 9px;
				padding-bottom: 9px;
				font-family: Metropolis;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 16px;
				color: #ff5e5e;
				display: flex;
				align-items: center;
				// margin-top: 10px;
			}
		}

		.chat-only--view-selected {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 9px 17px;
			// margin-top: 52px;

			.chats-only-msg {
				width: 100%;
				height: 51px;
				padding-left: 17px;
				padding-right: 17px;
				padding-top: 9px;
				padding-bottom: 9px;
				font-family: Metropolis;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 16px;
				color: #ff5e5e;
				display: flex;
				align-items: center;
			}
		}

		.contact-list-component-selected {
			.contact-list-component {
				padding-top: 0;
			}
		}
		.contact-list-component-forward {
			.contact-list-component {
				padding-top: 0;
				background: #ffffff;
			}

			&.selected-contact {
				padding-top: 14%;
			}

			&.active {
				opacity: 0.5 !important;
			}

			ion-item-divider {
				background: transparent;
			}
			ion-item {
				--background: transparent;
			}

			.item-disabled {
				opacity: 0.5 !important;
			}
		}
		.contact-list-component {
			background: #ffffff;

			&.selected-contact {
				padding-top: 14%;
			}

			&.active {
				opacity: 0.5 !important;
			}

			ion-item-divider {
				background: transparent;
			}
			ion-item {
				--background: transparent;
			}

			.item-disabled {
				opacity: 0.5 !important;
			}
		}
		.alertShow {
			.alertShow-controller-class {
				--background: #fff;
				--height: 160px;
				--width: 320px;

				.alert-wrapper.sc-ion-alert-ios {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 320px;
					height: 160px;

					.alert-title {
						margin-top: 12px;
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 500;
						font-size: 18px;
						line-height: 18px;
						text-align: center;
						color: #000000;
					}
					.alert-message {
						padding-top: 24px;
						padding-left: 17px;
						padding-right: 17px;
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						line-height: 120%;
						text-align: center;
						color: #585858;
					}
					.alert-button-group {
						width: 105px;
						height: 18px;
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 500;
						font-size: 18px;
						line-height: 18px;
						text-align: center;
						margin-bottom: 19px;
					}
				}
			}
		}
	}
}
