.chatonly-page {
	$selectedContactsViewHeight: 95px;
	background: var(--app-color-code);
	ion-content {
		.selected-contacts-space-fill {
			height: $selectedContactsViewHeight;
			width: 100%;
		}
		ion-list {
			background: var(--app-color-code) !important;
			// --background: var(--app-color-code) !important;
		}
		.chat-type-selection {
			display: flex;
			flex-direction: row;
			justify-content: center;
			height: 54px;
			.type-item {
				display: flex;
				flex-direction: row;
				align-items: center;
				img {
					width: 40px;
					height: 40px;
					margin-right: 12px;
					object-fit: contain;
				}
				.title {
					padding: 0;
					margin: 0;
					font-family: Metropolis;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 14px;
					color: #000000;
				}
			}
		}
		.chats-only-msg {
			font-family: Metropolis;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 16px;
			font-weight: 400;
			color: #585858;
			margin: 0 18px;
			height: 51px;
			display: flex;
			align-items: center;
		}

		.contact-list-component {
			ion-item-divider {
				background: transparent;
			}
			ion-item {
				--background: transparent;
			}

			.item-disabled {
				opacity: 0.5 !important;
			}
		}
	}

	.bottom-button {
		height: 90px;
		display: flex;
		justify-content: space-between;
		ion-button {
			margin-top: 16px;
			--background: transparent;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			text-transform: none;
			line-height: 18px;
			--color: #000000;
			--border: none;
			&.add {
				--color: #ff5e5e;
				margin-right: 15px;
			}
			&.active {
				--color: #ff5e5e;
				margin-right: 15px;
			}
			&.done {
				--color: #c4c4c4;
				margin-right: 15px;
			}
			&.remove {
				margin-left: 15px;
			}
			&.cancel-after {
				margin-left: 15px;
			}
		}
	}
}
.remove-warning-alert {
	.alert-wrapper.sc-ion-alert-ios {
		background: #ffffff;
		min-width: 320px;
		min-height: 173px;
	}
	.alert-message {
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 120%;
		color: #000000;
		margin: 14px 21px 10px 22px;
	}
	.alert-button {
		height: 18px;
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 18px;
		color: #000000;
		border: none;
		margin-bottom: 18px;
		border-top: none !important;
		border-right: none !important;
		&.ok {
			color: #ff5e5e;
		}
	}
}
.viewas-chatonly {
	ion-content {
		--background: #efefef;
	}

	.user-name-item {
		display: flex;
		flex-direction: column;
		--padding-start: 0;
		margin: 9px 16px 0px;
		margin-right: 0;
		cursor: pointer;
		align-items: flex-start;

		.profile-row {
			width: 100%;
			display: flex;
			align-items: center;
			flex-direction: row;

			.profile-photo {
				width: 120px;
				height: 120px;
				margin: 0px;
				border-radius: 5px;
				overflow: hidden;
			}

			.half-rounded-btns {
				flex: 1;
				display: flex;
				align-items: flex-end;
				justify-content: flex-end;
				flex-direction: column;
				ion-button {
					--border-radius: 44px 0 0 44px;
					--padding-start: 24px;
					--padding-end: 24px;
					--border-width: 2px 0 2px 2px;
					font-size: 18px;
					height: 55px;
					width: 80px;
					&.wallet {
						--background: #ff5e5e;
					}
					&.favorite {
						--border-color: #ff5e5e;
						--color: #ff5e5e;
					}
				}
			}
		}

		.name {
			margin-top: 15px;
			line-height: 38px !important;
			font-weight: 700;
			font-size: 26px;
			line-height: 26px !important;
		}

		.usernametxt {
			margin: 0;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 16px;
			color: #858484;
			margin-top: 13px;
		}

		.professiontxt {
			margin: 0;
			font-size: 16px;
			color: #858484;
			margin-top: 10px;
			//   line-height: 28px;
		}

		.region {
			font-size: 14.5px;
			color: #858484;
			margin-bottom: 9px;
			margin-top: 10px;
			//   line-height: 28px;
		}

		.quote {
			margin: 0;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 400;
			font-size: 14.5px;
			line-height: 18px;
			letter-spacing: -0.025em;
			color: #000000;
			margin-top: 6px;
			max-width: 300px;
		}

		.website {
			margin: 0;
			color: black;
			margin-top: 2px;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 400;
			font-size: 14.5px;
			line-height: 18px;
			letter-spacing: -0.025em;
			color: #100072;
		}
	}

	.topbar-header ion-toolbar.toolbar-heading {
		background: #efefef;
	}
	ion-content.has-bottom-navbar {
		--padding-bottom: 0 !important;
	}
	.blanck {
		min-height: 100px;
		flex: 1;
	}
	.viewas-chat-only {
		width: 100%;
		background: #efefef;
		ion-content.has-bottom-navbar {
			--padding-bottom: 0 !important;
		}

		.profile-placeholder-viewas {
			width: 100%;
			height: 138px;
			margin-top: 88px;
			margin-left: 17px;
			display: flex;
			align-items: center;
			.profile-placeholder {
				width: 120px;
				height: 120px;
			}
		}
		.profile-name {
			width: 100%;
			height: 38px;
			margin-left: 16px;
			display: flex;
			align-items: center;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 700;
			font-size: 26px;
			line-height: 26px;
			color: #000000;
		}
		.region-name {
			height: 22px;
			margin-left: 16px;
			display: flex;
			align-items: center;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 400;
			font-size: 14.5px;
			line-height: 14px;
			color: #858484;
		}
		.userprofilemedai {
			display: flex;
			flex: 1;
			min-height: 50px;
			justify-content: center;
		}
		.searchid-container {
			width: 100%;
			height: 40px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-top: 30px;
			margin-bottom: 46px;
			.searchid {
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 18px;
				text-align: center;
				color: #858484;
			}
		}
	}
}
