.modal-wrapper {
	min-width: 100% !important;
	// .ion-backdrop{
	// 	// --margin-top: 19%;
	// }
}

.emoji-search-modal {
	// --margin-top: 9.5% !important;
	--height: 100vh;
	--min-width: 100%;
	--background: transparent;
	--backdrop-opacity: 0.4;

	ion-backdrop {
		background: rgba(0, 0, 0, 0.4);
	}
	.modal-wrapper {
		background: transparent;
		--min-height: 100%;

		.ion-page {
			justify-content: flex-end;
		}
	}
	.emoji-picker-react {
		width: 100%;
		height: 100%;
		font-weight: 500;
		color: #000000;
		background-color: transparent;
		.emoji-categories {
			display: none;
		}
		.emoji-group:before {
			background-color: #efefef;
			text-transform: none !important;
			font-weight: 500;
			// position: absolute;
			// left: 3.62%;
			// right: 68.12%;
			// top: 30%;
			// bottom: 23.33%;

			// font-family: 'Metropolis';
			// font-style: normal;
			// font-weight: 500;
			// font-size: 14px;
			// line-height: 14px;
			/* identical to box height */

			color: #000000;
		}
		.emoji-search {
			border-radius: 5px;
			background-color: #e6e6e6;
		}
		// 		.emoji-picker-react .emoji-group:before{
		// \		};
	}
	#ion-react-wrapper {
		background: transparent;
		// background-color: #EFEFEF;
		width: 100%;
		height: 100vh;
		display: flex;
		// align-items: flex-end;
		justify-content: flex-end;
	}
	.ion-page {
		justify-content: flex-end;
	}
	.ion-page .ion-header .top-toolbar .ion-title {
		font-weight: bold;
	}
	.content {
		background: #efefef;
		backdrop-filter: blur(40px);
		border-radius: 10px 10px 0px 0px;
		max-height: calc(100vh - 85px);
		height: calc(100% - 85px);
		display: flex;
		flex-direction: column;

		.header {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 53px;
			min-height: 53px;
			ion-button {
				ion-icon {
					width: 17px;
					height: 9px;
				}
			}
		}

		.searchbar {
			width: 100%;
			padding: 0 16px;
			height: 36px;
			min-height: 36px;
			border-radius: 5px;
			.searchbar-input-container {
				border-radius: 5px;
				background-color: #e6e6e6 !important;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;

				.searchbar-search-icon {
					left: 20px;
					width: 16px;
					top: unset;
				}
				.searchbar-clear-button {
					background-color: transparent !important;
					width: 46px;
					.searchbar-clear-icon {
						width: 18px;
					}
				}
				.searchbar-input {
					padding-inline-start: 45px;
					font-family: Metropolis;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 16px;
					color: #000000;
				}
				input,
				ion-icon {
					background-color: transparent !important;
				}
			}
		}

		.searching-view {
			margin-top: 10px;
			width: 100%;
			height: 30px;
			display: flex;
			font-weight: 500;
			font-size: 14px;
			line-height: 14px;
			color: #585858;
		}
	}
	.content-title {
		width: 100%;
		font-family: Metropolis;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 14px;
		color: #000000;
		padding: 0 16px;
		margin: 19px 0;
	}
	.emoji-container {
		width: 100%;
		flex: 1;
		overflow-y: auto;
		padding: 0 16px;
		min-height: 60vh;

		// a {
		// 	max-width: 64px !important;
		// 	max-height: 64px !important;
		// 	div,
		// 	img {
		// 		max-width: 64px !important;
		// 		max-height: 64px !important;
		// 	}
		// }
	}
}
