.group-setting-page {
	ion-item {
		ion-thumbnail {
			width: 50px;
			height: 50px;
			--border-radius: 4px;
		}
	}
	.clear-delete-btns {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-bottom: 40px;
		ion-button {
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 29px;
			--color: #ff5e5e;
		}
	}
}

.manage-group-page {
	.alert-button-group.sc-ion-alert-ios {
		border-top: none !important;
		border-right: none !important;
	}
	.upload-photo-option-action-sheet {
		.action-sheet-container {
			border-top: none !important;
			border-right: none !important;
			padding: 0;
		}

		.action-sheet-group {
			padding-bottom: 0;
			border-radius: 10px 10px 0 0;
			margin-bottom: 0;
			padding-top: 12px;
			border-top: none !important;
			border-right: none !important;

			&.action-sheet-group-cancel {
				padding-top: 8px;
				padding-bottom: 44px !important;
				padding-top: 20x !important;
			}
		}

		.action-sheet-button {
			text-align: center;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 21px;
			color: #000000;
			padding-top: 26px;
			padding-bottom: 37px;
			border-top: none !important;
			border-right: none !important;
		}

		.action-sheet-button.sc-ion-action-sheet-ios:last-child {
			margin: 0;
			color: black;
			border-top: none !important;
			border-right: none !important;
		}
		.action-sheet-group-cancel {
			border-radius: 0;
			padding-top: 0;
			padding-bottom: 0;
			border-top: none !important;
			border-right: none !important;
		}

		.action-sheet-destructive {
			border-top: none !important;
			border-right: none !important;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 18px;
			text-align: center;
			color: #ff5e5e;
		}
	}

	.blanck {
		min-height: 100px;
		flex: 1;
	}
	.encryption-view {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		min-height: 94px;
		padding: 14px 18px;
		h3 {
			font-family: Metropolis;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 16px;
			text-align: center;
			color: #000000;
			margin: 0;
		}
		p {
			margin: 0;
			margin-top: 10px;
			font-family: Metropolis;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 14px;
			text-align: center;
			color: #858484;
		}
	}
	.manage-disband-action-sheet {
		.ok {
			border-top: none;
			border-right: none;
		}

		.action-sheet-container {
			padding: 0;
		}

		.action-sheet-group {
			padding-bottom: 57px !important;
			border-radius: 10px 10px 0 0;
			margin-bottom: 0 !important;
		}

		.action-sheet-button {
			padding: 0 10px;
			text-align: center;
			height: 18px;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 21px;
			color: #000000;
		}
		.action-sheet-button.sc-ion-action-sheet-ios:first-child {
			margin-top: 26px;
			margin-bottom: 37px;
			color: black;
		}
		.action-sheet-button.sc-ion-action-sheet-ios:last-child {
			margin: 0;
			color: black;
		}
		// .action-sheet-group-cancel {
		// 	border-radius: 0;
		// 	.cancel {
		// 		width: 50%;
		// 		background-color: #d64747;
		// 		color: #3ebaff;
		// 	}

		.action-sheet-destructive {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 18px;
			text-align: center;
			color: #ff5e5e;
			// margin-bottom: 44px;
		}
	}
}

.group-guideline-page {
	.security-alert {
		width: 100%;
		height: 87px;
		margin-top: 6px;
		padding-top: 17px;
		padding-bottom: 16px;
		padding-left: 37px;
		padding-right: 37px;
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 150%;
		text-align: center;
		color: #858484;
	}
	.point-one {
		width: 100%;
		height: 82px;
		padding-left: 31px;
		padding-top: 12px;
		padding-bottom: 10px;
		padding-right: 41px;
		margin-top: 40px;
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 140%;
		color: #000000;
	}
	.point-two {
		width: 100%;
		height: 62px;
		padding-left: 31px;
		padding-top: 12px;
		padding-bottom: 10px;
		padding-right: 41px;
		margin-top: 40px;
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 140%;
		color: #000000;
	}
	.join-button {
		width: 100%;
		height: 56px;
		margin-top: 79px;
		margin-bottom: 52px;
		display: flex;
		padding-left: 115px;
		padding-right: 115px;
		padding-top: 7px;
		align-items: center;
		justify-content: center;
		padding-bottom: 9px;
		.join {
			width: 184px;
			height: 40px;
			--background: #ff5e5e;
			border-radius: 6px;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 140%;
			color: #efefef;
			text-transform: capitalize;
		}
	}
	.encryption-view {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		min-height: 94px;
		padding: 14px 18px;
		h3 {
			font-family: Metropolis;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 16px;
			text-align: center;
			color: #000000;
			margin: 0;
		}
		p {
			margin: 0;
			margin-top: 10px;
			font-family: Metropolis;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 14px;
			text-align: center;
			color: #858484;
		}
	}
	.non-admin-container {
		height: 140px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		.nonadmin-img {
			width: 100px;
			height: 100px;
		}
	}
	.non-architecture {
		width: 100%;
		height: 40px;
		padding-top: 7px;
		padding-bottom: 7px;
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 600;
		font-size: 30px;
		line-height: 30px;
		text-align: center;
		color: #000000;
	}
	.total-member {
		width: 100%;
		height: 20px;
		align-items: center;
		justify-content: center;
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 600;
		font-size: 22px;
		line-height: 140%;
		text-align: center;
		color: #585858;
	}
}

.group-admin-page {
	.admin-container {
		width: 100%;
		height: 174px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #efefef;
	}
	.blank {
		min-height: 40px;
		flex: 1;
	}
	.admin-permissions {
		width: 100%;
		height: 197px;
		padding-left: 18px;
		padding-right: 19px;
		padding-top: 12px;
		padding-bottom: 15px;
		.permission-heading {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 160%;
			color: #ff5e5e;
		}
		.one {
			font-weight: 500;
			font-size: 14px;
			color: #585858;
			padding-top: 12px;
		}
	}
	.participants {
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 18px;
		color: #858484;
		margin-left: 18px;
		margin-top: 5px;
	}
	.secondary {
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 18px;
		color: #858484;
		margin-left: 18px;
		margin-top: 11px;
	}
	.add {
		margin-top: 14px;
		margin-left: 18px;
	}
	.tertiary {
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 18px;
		color: #858484;
		margin-left: 18px;
		margin-top: 31px;
	}
	.admin-pic {
		margin-left: 18px;
		width: 50px;
		height: 50px;
		margin-top: 12px;
		.admin-img {
			width: 50px;
			height: 50px;
		}
	}
	.name {
		width: 59px;
		height: 13px;
		margin-left: 14px;
		margin-top: 7px;
		font-size: 12px;
	}
}
.swtich-item {
	ion-toggle.toggle-customised {
		--background: #dcdcdc;
		--background-checked: #3ebaff;
		--handle-background-checked: white;
		--handle-background: white;
		--handle-height: 20px;
		--handle-width: 20px;
		--handle-border-radius: 50%;
		--handle-max-height: 20px;
		--handle-spacing: 1px;
		width: 40px;
		height: 21px;
	}
}
.architecture-text {
	display: flex;
	width: 100%;
	height: 77px;
	padding-left: 17px;
	padding-top: 9px;
	padding-bottom: 8px;
	.architecture {
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 140%;
		color: #585858;
	}
	.archi-arrow {
		width: 7px;
		height: 13px;
		margin-left: 12px;
		fill: #a5a5a5;
	}
}
.group-field-item {
	display: flex;
	flex-direction: column;
	padding: 0 18px;
	min-height: 44px;
	justify-content: center;

	ion-button.value {
		--padding-start: 0;
		--padding-end: 0;
		height: auto;
		margin: 0;
		padding: 0;
		text-transform: none;
		--border-radius: 0 !important;
	}

	.row-first {
		display: flex;
		flex-direction: row;
		align-items: center;
		min-height: 37px;
		justify-content: space-between;
		.title-architecture {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 20px;
			color: #000000;
		}
		.title {
			flex: 1;
			font-family: Metropolis;
			font-style: normal;
			font-weight: normal;
			font-size: 17px;
			line-height: 17px;
			color: #000000;
			max-width: 88%;
		}

		.value {
			display: flex;
			align-items: center;
			font-family: Metropolis;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 16px;
			text-align: right;

			img {
				width: 36px;
				height: 36px;
				border-radius: 4px;
			}

			ion-icon {
				width: 7px;
				height: 13px;
				margin-left: 12px;
				fill: #a5a5a5;
			}

			.placeholder {
				color: #a5a5a5;
			}
			.data {
				color: #858484;
				font-weight: 500;
			}
		}
	}
	.row-second {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		.title {
			font-family: Metropolis;
			font-style: normal;
			font-weight: normal;
			font-size: 13px;
			line-height: 16px;
			color: #858484;
			max-width: 88%;
		}
		.value {
			ion-icon {
				width: 7px;
				height: 13px;
				margin-left: 12px;
				fill: #a5a5a5;
			}
		}

		.arrow-adjust-view {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			max-height: 33px;
			height: auto;
			margin-top: -6px;
		}
	}
}

ion-list {
	padding: 0;
	background: transparent;
	p {
		padding: 0;
		white-space: normal;
	}

	ion-item {
		--min-height: 60px;
		ion-label {
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 29px;
			color: #000000;
		}

		ion-note {
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 29px;
			margin-left: 17px;
			width: 50px;
			height: 50px;
			margin-top: 12px;
			color: #858484;
		}
		h3 {
			font-style: normal;
			font-weight: normal;
			font-size: 15px;
			line-height: 29px;
			color: #000000;
		}
		p {
			color: #858484;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 22px;
		}
	}

	.qr-code-photo {
		ion-thumbnail {
			width: 30px;
			height: 30px;
		}
	}

	.group-name {
		p {
			color: #000;
		}
	}
}

.bottom-btns {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-bottom: 20px;
	ion-button {
		min-height: 64px;
		font-style: normal;
		font-weight: 500;
		font-size: 17px;
		line-height: 29px;
		--color: #ff5e5e;
	}
}
.creater-container {
	width: 100%;
	height: 94px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	ion-text {
		margin-top: 14px;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 26px;
		color: #858484;
	}
	.creater-name {
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 14px;
		text-align: center;

		color: #858484;
	}
}
