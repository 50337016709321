.personal-storage {
	.topbar-header .toolbarTitle .title {
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 20px;
		text-align: center;
		color: #000000;
	}
	.topbar-header .toolbarTitle .description {
		font-size: 14px;
		line-height: 16px;
		color: #585858;
		margin-top: 6px;
	}
	.topbar-header {
		ion-searchbar {
			background-color: transparent !important;
			background: transparent !important;

			input {
				background: transparent !important;
				background-color: transparent !important;
			}

			.searchbar-search-icon {
				background-color: transparent !important;
			}
			&.searchbar-wrapper {
				margin-top: 12px;
				margin-bottom: 12px;
				.searchbar-input-container {
					background-color: transparent !important;
					margin-left: 9px;
					.searchbar-input {
						background-color: transparent !important;
						margin-left: 21px;

						&::placeholder {
							color: #585858;
						}
					}
					.searchbar-clear-button {
						background-color: transparent !important;
						.searchbar-clear-icon {
							background-color: transparent !important;
						}
					}
				}
			}
		}
	}

	ion-content {
		background-color: #efefefb2;
	}

	.div-first {
		top: 88px;
		width: 100%;
		height: 70px;
		display: flex;
		flex-direction: column;

		.graph {
			display: flex;
			margin-left: 24px;
			margin-right: 24px;
			height: 16px;
			background-color: #dadada;
			margin-top: 26.35px;

			.box1 {
				display: flex;
				background-color: #ff5e5e;
				width: 59px;
				z-index: 1;
			}
			.smallbox {
				width: 35px;
				background-color: #3ebaff;
				z-index: 1;
			}
		}
		.searchbar-wrapper {
			--background: #ffffff;
			padding-bottom: 0;
			--border-radius: 5px !important;

			.searchbar-input-container {
				border-radius: 5px !important;

				.searchbar-input {
					font-size: 16px;
					line-height: 16px;
					color: #000000;
					margin-left: 21px;

					&::placeholder {
						color: #a5a5a5;
					}
				}
			}
		}

		.category-items {
			display: flex;
			margin-left: 24px;
			margin-right: 4%;
			height: 16px;
			margin-top: 7px;
			IonLabel {
				padding-left: 3px;
			}

			.cat-item {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				margin-right: 10px;
				.square {
					width: 13px;
					height: 13px;
					margin-right: 3px;
				}
				.cat-title {
					border-radius: nullpx;
					margin-left: 3px;
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 500;
					font-size: 13px;
					line-height: 13px;
					display: flex;
					align-items: flex-end;
					letter-spacing: -0.02em;
					color: #858484;
				}
			}
		}
	}
	.smallbox1 {
		background-color: black;

		height: 10px;
		width: 10px;
		left: 24px;
		top: 42px;
		border-radius: 1px;
		margin-left: 5px;
	}
	.smallbox2 {
		background-color: black;

		height: 10px;
		width: 10px;
		left: 24px;
		top: 42px;
		border-radius: 1px;
		margin-left: 5px;
	}

	.tabs {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 30px;

		.tabitem {
			display: flex;
			flex: 1;
			justify-content: center;
			align-items: center;
			ion-button {
				font-size: 16px;
				font-weight: 600;
				align-self: center;
				color: #585858;
				margin: 0;
				text-transform: none;
				height: unset;
				padding-bottom: 8px;
				&.active {
					color: #ff5e5e;

					border-bottom: 3px solid #ff5e5e;
				}
			}
		}
	}

	.images {
		margin-top: 22px;
		margin-left: 17px;
		font-weight: 600;
		font-size: 14px;
		color: #585858;
	}
	.image {
		display: flex;
		flex-direction: row;
		padding-left: 15px;
		width: 100%;
		overflow-x: auto;

		.img {
			width: 80px;
			min-width: 80px;
			height: 80px;
			margin-top: 19px;
			position: relative;
			margin-right: 15px;
			.inner-img {
				display: flex;
				width: 100%;
				object-fit: cover;
			}
			.shared-star-view {
				display: flex;
				flex-direction: row;
				position: absolute;
				bottom: 2px;
				right: 2px;
				.inner-shared {
					display: flex;
					width: 10.5px;
					height: 10px;
				}
				.inner-star {
					display: flex;
					width: 10.5px;
					height: 10px;
				}
			}
			.folder_name {
				margin-top: 9.5px;
				margin-bottom: 9.5px;
			}
		}
		.text {
			font-size: 15px;
		}
		.innerdiv-play {
			display: flex;
			justify-content: center;
			align-items: center;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			position: absolute;
			.inner-play {
				width: 34px;
				height: 34px;
				position: relative;
			}
		}
	}

	.videoimage {
		margin-top: 19px;
		margin-left: 17px;
		font-weight: 600;
		font-size: 14px;
		color: #585858;
	}

	.files {
		display: flex;
		padding-left: 15px;
		width: 100%;
		overflow-x: auto;
		flex-direction: row;
		height: 156px;
		margin-right: 12px;

		.file {
			width: 80px;
			min-width: 80px;
			height: 128px;
			margin-top: 19px;
			position: relative;
			margin-right: 15px;

			.image-view {
				width: 80px;
				height: 80px;
				display: flex;
				position: relative;

				.inner-file {
					display: flex;
					width: 100%;
					height: 100%;
					position: relative;
					fill: #c4c4c4;
				}
				.shared-star-view {
					display: flex;
					flex-direction: row;
					position: absolute;
					bottom: 2px;
					right: 2px;
					.inner-shared {
						display: flex;
						width: 10.5px;
						height: 10px;
					}
					.inner-star {
						display: flex;
						width: 10.5px;
						height: 10px;
					}
				}
			}

			.folder_name {
				color: #585858;
				line-height: 15px;
				font-size: 13px;
				margin-top: 9.5px;
				margin-bottom: 9.5px;
			}
		}
	}

	.folders {
		display: flex;
		padding-left: 15px;
		width: 100%;
		overflow-x: auto;
		flex-direction: row;
		height: 156px;
		margin-right: 12px;

		.folder-item {
			width: 84px;
			min-width: 84px;
			height: 118px;
			margin-top: 19px;
			position: relative;
			margin-right: 15px;

			.image-view {
				width: 72px;
				height: 56px;
				display: flex;
				position: relative;

				.inner-file {
					display: flex;
					width: 100%;
					height: 100%;
					position: relative;
					fill: #c4c4c4;
				}
				.shared-star-view {
					display: flex;
					flex-direction: row;
					position: absolute;
					bottom: 2px;
					right: 2px;
					.inner-shared {
						display: flex;
						width: 10.5px;
						height: 10px;
					}
					.inner-star {
						display: flex;
						width: 10.5px;
						height: 10px;
					}
				}
			}

			.folder_name {
				color: #000000;
				line-height: 15px;
				font-size: 13px;
				margin-top: 9.5px;
				margin-bottom: 9.5px;
			}
		}
	}

	.files::-webkit-scrollbar {
		display: none;
	}
	.image::-webkit-scrollbar {
		display: none;
	}

	.folders::-webkit-scrollbar {
		display: none;
	}
}
