.discover-icn {
	color: #000;
}


.discover-page {
	ion-list {
		background: transparent;
		ion-item {
			--min-height: 72px;

			.profile-thumb-user-edit {
				height: 43px;
				width: 58px;
				ion-img {
					--border-radius: 4px;
					object-fit: cover;
				}
			}
		}
	}
}
