.unread-msg-access-btns-view {
	position: fixed;
	right: 0;
	bottom: 60px;
	display: flex;
	flex-direction: column;
	z-index: 99;

	.nav-button {
		min-width: 46px;
		height: 40px;
		background: #ff5e5e;
		border-radius: 31px 0px 0px 31px;
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: center;
		margin-bottom: 8px;
		padding: 0 13px;

		img {
			width: 14px;
			height: 15.27px;
			object-fit: contain;
		}

		span {
			margin-left: 10px;
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 18px;
			color: #e8e8e8;
		}
	}
}

.thread-item-sliding {
	$maskItemWidth: 60.57px;

	.receiver-audio-type-msg-container {
		display: flex;
		flex-direction: row;
		align-items: center;

		.audio-convert-to-text-btn {
			display: flex;
			flex-direction: row;
			align-items: center;
			.red-dot {
				width: 8px;
				height: 8px;
				border-radius: 50%;
				margin: 0 12px;
				background: #ff5e5e;
			}
			.convert-btn {
				background: #e6e6e6;
				border-radius: 13px;
				font-family: Metropolis;
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 12px;
				height: 24px;
				margin: 0;
				color: #858484;
			}
		}
	}

	.thread-container {
		display: flex;
		flex-direction: row;
		--background: transparent;
		--background-hover: transparent;
		--background-focused: transparent;
		--background-activated: transparent;
		--ripple-color: transparent;
		--padding-start: 0;
		--padding-end: 0;
		--inner-padding-start: 0;
		--inner-padding-end: 0;
		/*margin-top: 16px;*/
		padding-top: 16px;
		margin-left: calc($maskItemWidth * -1 + 16px);

		.item-inner-div {
			width: 100%;
			display: flex;
			flex-direction: column;
		}

		&.left {
			.item-inner-div {
				align-items: flex-start;
			}
		}

		&.right {
			.item-inner-div {
				align-items: flex-end;
			}
		}

		.thread-item {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			width: 100%;
			.mask-item {
				padding-left: 16px;
				padding-right: 5px;
				ion-img {
					width: 28.57px;
					/*height: 26px;
					margin-top: 12px;*/
					height: 38px;
					padding-top: 12px;
				}
			}

			&.left {
				justify-content: flex-start;
				.gp-message-other {
					max-width: calc(83.73% - $maskItemWidth);
				}
			}
			&.right {
				justify-content: flex-start;
				// .gp-message-me {
				// 	max-width: calc(83.73% - $maskItemWidth);
				// 	margin-left: calc($maskItemWidth * -1);
				// }
			}

			.avatar-group {
				margin-right: 12px;
			}

			ion-thumbnail {
				width: 40px;
				/*height: 40px;
				margin-top: 2px;(*/
				height: 42px;
				padding-top: 2px;
			}

			.avatar-group-me {
				margin-right: 16px;
				float: right;
			}

			.gp-message-other {
				display: inline-flex;
				margin: 0;
				padding-left: 15px;
			}

			.gp-message-me {
				margin: 0;
				text-align: right;
				width: 100%;
				display: inline-flex;
				flex-direction: row-reverse;
				padding-left: 56px;
			}

			.image-file {
				border-radius: 4px !important;
				overflow: hidden;
				max-width: 154px;
				max-height: 154px;
				object-fit: contain;
			}

			.message-card-content {
				justify-content: center;
				min-height: 40px;
			}

			.video-thumbnail {
				border-radius: 4px !important;
				overflow: hidden;
				max-width: 154px;
				max-height: 154px;
				object-fit: contain;
				display: flex;
				align-items: center;
				justify-content: center;
				.play-btn {
					width: 40px;
					height: 40px;
					position: absolute;
					--padding-start: 0;
					--padding-end: 0;
					ion-icon {
						width: 100%;
						height: 100%;
					}
				}
			}

			.video-file {
				border-radius: 4px !important;
				overflow: hidden;
				width: 154px;
				height: 154px;
				object-fit: contain;
				&.video-react {
					max-width: 100%;
					max-height: 100%;
					display: flex;
					object-fit: contain !important;
					padding-top: 0 !important;
					align-items: center;
					justify-content: center;

					.video-react-button.video-react-big-play-button {
						width: 60px;
						height: 60px;
						align-items: center;
						justify-content: center;
						padding: 0;
						border-radius: 50%;
						background: rgba(0, 0, 0, 0.15);
						border: 2px solid #efefef;
						top: auto;
						left: auto;
						&:before {
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}
					.video-react-control-bar {
						bottom: 88px;
						height: 30px;
						margin-bottom: 19px;

						.video-react-duration-display,
						.video-react-current-time {
							font-family: Metropolis;
							font-style: normal;
							color: #efefef;
						}
					}
				}
			}

			.urllink {
				text-decoration: underline !important;
			}

			.media-loader-mask {
				border-radius: 4px !important;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				background: rgba(144, 144, 144, 0.7);
				display: flex;
				align-items: center;
				justify-content: center;
				.CircularProgressbar {
					width: 32px;
					height: 32px;
				}

				.video-play-icon {
					width: 40px;
					height: 40px;
				}
			}

			.translatedtxt-card-content {
				font-size: 12px !important;
				font-family: Metropolis;
				font-weight: 400;
				line-height: 14px;
				letter-spacing: 0em;
				margin-top: 6px;
				span {
					color: #858484 !important;
				}

				.translated-tag {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: flex-end;
					font-size: 12px;
					color: #585858;
					ion-icon {
						color: #ff5e5e;
						margin-left: 5px;
						font-size: 14px;
					}
				}
			}

			.group-message-left {
				.translatedtxt-card-content .translated-tag {
					justify-content: flex-start;
				}
			}

			.group-message-me {
				.translatedtxt-card-content .translated-tag {
					justify-content: flex-end;
				}
			}
			.group-message-left,
			.group-message-me {
				span {
					background-color: transparent !important;
					font-size: unset !important;
					white-space: unset !important;
					i {
						letter-spacing: 0.5px;
					}
					&.emoj-xl {
						font-size: 30px !important;
						line-height: 40px !important;
						display: inline-block;
						vertical-align: middle;
					}
					&.emoj-lg {
						font-size: 30px !important;
						line-height: 40px !important;
						display: inline-block;
						vertical-align: middle;
					}
					&.emoj-md {
						font-size: 26px !important;
						line-height: 40px !important;
						display: inline-block;
						vertical-align: middle;
					}
				}
			}

			.recall-message-view {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				width: 100%;
				padding-bottom: 8px;
				/*margin-top: 24px;*/
				padding-top: 24px;

				ion-text {
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 26px;
					color: #858484;
				}
				ion-button {
					color: #ff5e5e;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					text-transform: none;
					line-height: 22px;
					--padding-start: 3px;
					--padding-end: 3px;
				}
			}

			ion-checkbox {
				/*margin: 8px;
				min-height: 22px;
				min-width: 22px;*/

				padding: 8px;
				min-height: 38px;
				min-width: 38px;

				--border-radius: 50%;
				--size: 22px;
				--background: transparent;
				--border-color: #858484;
				--border-color-checked: #ff5e5e;
				--background-checked: #ff5e5e;
				--checkmark-color: #fff;
				--checkmark-width: 2px;
			}
		}
	}
}

.message-send-resend-view {
	display: flex;
	align-items: center;
	justify-content: center;
	/*height: 40px;
	margin-top: 2px;*/
	height: 42px;
	padding-top: 2px;
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 10px;
	.failed-icon {
		width: 22px;
		height: 22px;
	}
}

.video-message-icon-view {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100%;
	max-height: 100%;
	/*margin-top: 2px;*/
	padding-top: 2px;
	margin-right: 10px;
	position: relative;
	.video-pause-icon {
		width: 20px;
		height: 20px;
	}
}

.file-type-message {
	position: relative;
	width: 200px;
	/*height: 80px;
	margin-top: 2px;*/
	height: 82px;
	padding-top: 2px;
	display: flex;
	flex-direction: column;

	.file-bg {
		position: absolute;
		right: 0;
		left: 0;
		top: 0;
		bottom: 0;
		z-index: -1;
	}
	.file-name {
		flex: 1;
		font-family: Metropolis;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 20px;
		color: #585858 !important;
		margin: 8px 36px 0 9px;
		text-align: left;

		overflow-y: hidden;
		white-space: initial;
		text-overflow: ellipsis;
		overflow: hidden;
		@supports (-webkit-line-clamp: 2) {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	}
	.size-type-view {
		display: flex;
		flex-direction: row;
		margin: 9px;
		font-family: Metropolis;
		font-style: normal;
		font-weight: normal;
		font-size: 12px !important;
		line-height: 12px;
		color: #858484 !important;
		.size {
			font-size: 12px !important;
			margin-right: 2px;
			color: #858484 !important;
		}
		.type {
			font-size: 12px !important;
			margin-left: 2px;
			color: #858484 !important;
		}
	}
}

.audio-type-message {
	/*height: 40px;
	margin-top: 2px;*/
	height: 40px;
	background: #ffffff;
	border-radius: 3px;
	width: 116px;
	padding: 0 15px 0px;
	display: flex;
	flex-direction: row;
	align-items: center;
	.audiogif {
		max-width: 20%;
	}
	.icon {
		width: 16px;
		height: 16px;
	}

	.file-name {
		margin-left: 12px;
		font-family: Metropolis;
		font-style: normal;
		font-weight: 500;
		font-size: 14px !important;
		line-height: 14px;
		color: #000000 !important;
	}
}
.audio-type-message-isplay {
	/*height: 40px;
	margin-top: 2px;*/
	height: 40px;
	background: rgba(240, 243, 244);
	border-radius: 3px;
	width: 400px;
	padding: 0 15px 0px;
	display: flex;
	flex-direction: row;
	align-items: center;
	.playControl {
		width: 100%;
		height: 100%;
	}
	.audiogif {
		max-width: 9%;
	}
	.icon {
		width: 16px;
		height: 16px;
	}

	.file-name {
		margin-left: 12px;
		font-family: Metropolis;
		font-style: normal;
		font-weight: 500;
		font-size: 14px !important;
		line-height: 14px;
		color: #000000 !important;
	}
}
