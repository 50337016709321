.scketchpad-shapeStyle-popover-style {
	.popover-wrapper {
		.popover-content {
			background: rgba(239, 239, 239, 0.7);
			backdrop-filter: blur(10px);
			ion-list {
				padding: 9px 0;
				background-color: transparent;
				ion-item {
					--height: 47.7px !important;
					--min-height: 47.7px !important;
					--background: transparent;
					ion-icon {
						margin-inline-end: 11px;
					}

					&.active {
						ion-icon {
							stroke: black;
						}
						ion-label {
							font-weight: 600;
						}
					}
					ion-label {
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 400;
						font-size: 18px;
						line-height: 18px;
						color: #585858;
						margin: 0;
						display: flex;
						align-items: center;
						height: 100%;
					}
				}
			}
		}
	}
}
