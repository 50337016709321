.sketchpad-modal {
	ion-header {
		height: 88px;

		.top-toolbar {
			--background: #efefef;
			position: fixed;
			width: 100%;
			top: 0;
			--min-height: 44px;
			--padding-end: 0;
			--padding-start: 13px;
			padding-top: 40px;
			padding-bottom: 4px;
			.shape-title-view {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;

				ion-input {
					input[type='number']::-webkit-inner-spin-button,
					input[type='number']::-webkit-outer-spin-button {
						-webkit-appearance: none;
						-moz-appearance: none;
						appearance: none;
						margin: 0;
					}

					max-width: 32px;
					height: 24px;
					border-radius: 3px;
					background-color: #fff;
					padding: 0 !important;
					font-size: 16px;
					text-align: center;
					display: flex;
					align-items: center;
					justify-content: center;
					--padding-end: 0 !important;
					--padding-start: 0 !important;
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 500;
					line-height: 16px;
					color: #000000;
				}
			}

			.buttons-first-slot {
				ion-button {
					ion-icon {
						stroke: #ffffff;
						stroke-width: 4px;
						font-size: 24px;
					}
				}
			}

			ion-button {
				padding-bottom: 4px;
				--color: #ffffff;
				font-family: Metropolis;
				font-style: normal;
				font-weight: normal;
				font-size: 17px;
				line-height: 17px;
				text-transform: none;

				&.undo-btn {
					margin-right: 13px;

					ion-icon {
						width: 24px;
						height: 26px;
						stroke: unset;
						margin-left: 22px;
					}
				}
			}

			ion-title {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				text-align: center;
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 20px;
				color: #000000;

				ion-button {
					&.text-style-button {
						ion-icon {
							width: 22px;
							height: 18px;
						}
						.text {
							font-size: 14px;
							font-weight: 400;
							color: #000000;
							margin-left: 28px;
						}
					}
					&.shape-button {
						margin: 0;
						margin-left: 18px;
						--padding-start: 0;
						--padding-end: 0;
						ion-icon {
							width: 24px;
							height: 24px;
							color: black;
						}
					}
					&.shape-style-button {
						margin: 0;
						margin-left: 8px;
						--padding-start: 0;
						--padding-end: 0;
						ion-icon {
							width: 16px;
							height: 16px;
							color: black;
							font-size: 16px;
						}
					}
				}
			}
		}
	}
	.modal-wrapper {
		min-height: 100%;
		min-width: 100% !important;
		border-radius: 0 !important;
	}
	ion-content {
		.sketchpad-canvas {
			width: 100%;
			height: 100%;

			.canvas-container,
			canvas {
				width: 100% !important;
				height: 100% !important;
			}
		}
	}

	ion-footer {
		background: rgba(239, 239, 239, 0.7);

		.chat-btn-types {
			display: flex;
			flex-direction: row;
			width: 100%;
			height: 46px;
			justify-content: space-around;

			ion-button {
				flex: 1;
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 18px;
				color: black;
				&.active {
					color: #ff5e5e;
				}
			}
		}
		.arrowup-container {
			height: 46px;
			display: flex;
			align-items: center;
			justify-content: center;

			ion-button {
				margin: 0;
				ion-icon {
					width: 17px;
					height: 9px;
				}
			}
		}

		.delete-container {
			ion-icon {
				color: #efefefb2;
			}
			background: #efefefb2;

			width: 100%;
			height: 134px;
			top: 762px;
			display: flex;
			align-items: center;
			flex-direction: column;
			padding-top: 30px;

			// justify-content: center;
		}
		.btdelete {
			height: 28px;
			width: 26.875999450683594px;
			// left: 194px;
			// top: 30px;
			border-radius: 0px;
		}

		.bottom-tools-view {
			background: rgba(239, 239, 239, 0.7);
			backdrop-filter: blur(20px);
			/* Note        : backdrop-filter has minimal browser support */
			padding-bottom: 46px;

			.colors-container {
				.text-bg-change-btn {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 22px;
					height: 22px;
					border: 1px solid #000000;
					box-sizing: border-box;
					border-radius: 2px;
					margin-right: 27px;

					&.active {
						border: none;
						background-color: #ffffff;
					}

					ion-icon {
						width: 9.5px;
						height: 11.5px;
					}
				}

				display: flex;
				flex-direction: row;
				// padding: 0 28px;
				margin-left: 20px;
				margin-right: 20px;
				height: 46px;
				align-items: center;
				overflow-y: scroll;
				justify-content: space-between;
				-ms-overflow-style: none;
				scrollbar-width: none;
				&::-webkit-scrollbar {
					display: none;
				}

				.t-select {
					// margin-right: 26px;

					ion-icon {
						width: 22px;
						height: 22px;
					}
				}

				.color-circle {
					border: 4px solid #ffffff;
					border-radius: 50%;
					width: 22px;
					height: 22px;
					min-width: 22px;
					min-height: 22px;
					// margin-right: 26px;
				}
			}

			.container {
				margin-top: 10px;
				height: 32px;
				display: flex;
				flex-direction: row;
				padding-left: 20px;
				padding-right: 20px;
				// justify-content: space-between;

				.edit-options {
					flex: 1;
					display: flex;
					flex-direction: row;
					// justify-content: space-evenly;
					justify-content: space-between;

					.edit-type-btn {
						text-transform: none;
						--padding-start: 0;
						--padding-end: 0;
						height: auto;
						margin: 0;
						// margin-right: 10px;

						&.active {
							opacity: 1;
						}
						&:last-child {
							margin-right: 20px;
						}
					}
				}

				.send-btn {
					text-transform: none;
					margin: 0;
					--background: #ff5e5e;
					--border-radius: 5px;
					min-width: 64px;
					height: 32px;
					--padding-start: 0;
					--padding-end: 0;
					font-family: Metropolis;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 16px;
					--color: #e8e8e8;
					&.button-disabled {
						opacity: 1;
						--background: #c4c4c4;
					}
				}
			}
		}
	}
}
.sketchpad-navigation-modal {
	.modal-wrapper {
		background: rgba(var(--app-modal-bg-color-code), 0) !important;
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		// --backdrop-opacity: 0.32;
		height: 100% !important;

		.ion-page {
			display: flex;
			// align-items: center;
			justify-content: flex-end;
			--backdrop-opacity: 0.32;

			.selected-component-container {
				width: 100%;
				height: 353px;
				border-top-left-radius: 15px;
				border-top-right-radius: 15px;
				background: #efefef;
				.send-to {
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 14px;
					height: 26px;
					padding-top: 7px;
					padding-left: 15px;
					padding-bottom: 5px;
					margin-top: 18px;
				}
				.shared {
					width: 100%;
					height: 102px;
					display: flex;
					flex-direction: row;
					padding-left: 18px;
					overflow-x: auto;
					.shared-image-skeched {
						display: flex;
						flex-direction: column;
						justify-content: center;
						width: 48px;
						margin-right: 24px;
						margin-top: 10px;
						align-items: center;
						ion-icon {
							width: 48px;
							height: 48px;
							border-radius: 4px;
						}
						.name-container {
							display: flex;
							flex-direction: column;
							font-size: 12px;
							line-height: 14px;
							width: 62px;
							height: 24px;
							margin-top: 3px;
							font-weight: 400;
							text-align: center;
							.icon-name {
								color: black;
							}
						}
					}
				}

				.icon-contain-container {
					display: flex;
					flex-direction: column;
					width: 100%;
					height: 107px;
					padding-top: 14px;
					padding-bottom: 21px;
					.icon-container {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: space-evenly;
						.icon-name-container {
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							.add-drive {
								width: 50px;
								height: 50px;
								display: flex;
								align-items: center;
								justify-content: center;
								background: #ffffff;
								border-radius: 12px;
								ion-icon {
									width: 24px;
									height: 25px;
								}
							}
							.icon-name {
								width: 62px;
								height: 24px;
								margin-top: 7.5px;
								font-family: 'Metropolis';
								font-style: normal;
								font-weight: 400;
								font-size: 12px;
								line-height: 14px;
								text-align: center;
								color: #585858;
							}
						}
					}
					// .icon-name-container {
					// 	width: 100%;
					// 	height: 70px;
					// 	display: flex;
					// 	// align-items: center;
					// 	justify-content: space-evenly;

					// }
				}
				.cancel {
					width: 100%;
					height: 90px;
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 14px;
					text-align: center;
					color: #000000;
					padding-top: 18px;
				}
				.option-bottom-popup {
					bottom: 0px !important;
				}
			}
		}
	}
}
