.emoji-sticker-search-modal {
	ion-backdrop {
		background: rgba(0, 0, 0, 0.4);
	}
	.modal-wrapper {
		background: transparent;
		--min-height: 100%;
		.ion-page {
			justify-content: flex-end;
		}
		.content {
			background: #efefef;
			backdrop-filter: blur(40px);
			border-radius: 10px 10px 0px 0px;
			max-height: calc(100vh - 85px);
			height: calc(100% - 85px);
			display: flex;
			flex-direction: column;
			.header {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 53px;
				min-height: 53px;
			}

			.searchbar {
				width: 100%;
				padding: 0 16px;
				height: 36px;
				min-height: 36px;
				margin-bottom: 18px;
				.searchbar-input-container {
					border-radius: 5px;
					background-color: #e6e6e6 !important;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;

					.searchbar-search-icon {
						left: 20px;
						width: 16px;
						top: unset;
					}
					.searchbar-clear-button {
						background-color: transparent !important;
						width: 46px;
						.searchbar-clear-icon {
							width: 18px;
						}
					}
					.searchbar-input {
						padding-inline-start: 45px;
						font-family: Metropolis;
						font-style: normal;
						font-weight: normal;
						font-size: 16px;
						line-height: 16px;
						color: #000000;
					}
					input,
					ion-icon {
						background-color: transparent !important;
					}
				}
			}

			.searching-view {
				margin-top: 10px;
				width: 100%;
				height: 30px;
				display: flex;
				align-items: center;
				flex-direction: row;
				padding: 0 26px;
				.search-text {
					margin-left: 9px;
					font-family: Metropolis;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 14px;
					color: #585858;
				}
			}
			.content-title {
				width: 100%;
				font-family: Metropolis;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 14px;
				color: #000000;
				padding: 0 16px;
				margin-bottom: 19px;
			}
			.emoji-container {
				height: 154px;
				width: 100%;
				overflow-x: auto;
				padding-left: 26px;
				.row {
					display: flex;
					flex-direction: row;
					width: 100%;
					&:first-child {
						margin-bottom: 34px;
					}
					.emoji-item {
						cursor: pointer;
						margin-right: 34px;
						font-size: 40px;
						min-width: 40px;
						width: 40px;
						height: 40px;
					}
				}
			}

			.stickers-container {
				width: 100%;
				flex: 1;
				overflow-y: auto;
				padding: 0 16px;

				// a {
				// 	max-width: 64px !important;
				// 	max-height: 64px !important;
				// 	div,
				// 	img {
				// 		max-width: 64px !important;
				// 		max-height: 64px !important;
				// 	}
				// }
			}
		}
	}
}
