.chat-other-options {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	padding: 8px 12px 3px;
	&::before {
		position: absolute;
		content: '';
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		background-color: var(--app-color-code);
		z-index: 1;
	}

	div {
		z-index: 2;
	}
}
