.search-contacts {
	display: flex;
	flex-direction: column;
	.title-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0 18px;
		.group-title {
			flex: 1;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 16px;
			color: #000000;
		}
		ion-button {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 16px;
			text-align: right;
			color: #000000;
			--padding-start: 0;
			--padding-end: 0;
			height: 40px;
			margin: 0;
		}
	}
}
