.multiple-image-editor-modal {
	background-color: #000;
	padding: 0;
	.modal-wrapper {
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		height: 100% !important;
	}
	.modal-wrapper,
	ion-page {
		background-color: transparent !important;
	}

	.modal-content-container {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
	}

	.top-toolbar {
		--background: rgba(0, 0, 0, 0.5) !important;
		width: 100%;
		top: 0;
		--min-height: 70px;
		--padding-end: 0;
		--padding-start: 13px;

		.buttons-first-slot {
			ion-button {
				.revertIcon {
					--padding-start: 0;
					--padding-end: 0;
					stroke: unset;
					width: 28px !important;
					height: 28px !important;
					ion-icon {
						width: 28px;
						height: 28px;
					}
				}
				// ion-icon {
				//  stroke: #ffffff;
				//  stroke-width: 2px;
				//  font-size: 30px;
				// }
			}
		}
		ion-title {
			ion-button {
				ion-icon {
					width: 22px;
					height: 18px;
				}
				&.text-style-button {
					ion-icon {
						width: 22px;
						height: 18px;
					}
					.text {
						font-size: 14px;
						font-weight: 400;
						color: #ffffff;
						margin-left: 28px;
					}
				}
			}
			text-align: center;
			.full-image-btn {
				margin: 0;
				--padding-start: 8px;
				--padding-end: 8px;
				ion-checkbox {
					margin-left: 8px;
				}
			}
		}
		.buttons-last-slot {
			margin-right: 18px;
			ion-button {
				.revertIcon {
					--padding-start: 0;
					--padding-end: 0;
					width: 28px !important;
					height: 28px !important;

					stroke: unset;
					ion-icon {
						width: 28px;
						height: 28px;
					}
				}
			}
			.send-btn {
				text-transform: none;
				margin: 0;
				--background: #ff5e5e;
				--border-radius: 5px;
				min-width: 64px;
				height: 32px;
				--padding-start: 8px;
				--padding-end: 8px;
				font-family: Metropolis;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 16px;
				--color: #e8e8e8;
			}
		}
		ion-button {
			--color: #ffffff;
			font-family: Metropolis;
			font-style: normal;
			font-weight: normal;
			font-size: 17px;
			line-height: 17px;
			text-transform: none;
			ion-icon {
				stroke: #ffffff;
				stroke-width: 1px;
			}
			ion-checkbox {
				--checkmark-color: rgba(0, 0, 0, 0.9);
				--background-checked: #ff5e5e;
				--background: transparent;
				--border-color: #ffffff;
				--border-color-checked: transparent;
				--border-radius: 50%;
				--checkmark-width: 1px;
				--size: 18px;
			}

			&.add-more-media-btn {
				border-radius: 4px;
				border: 1.5px solid #ffffff;
				width: 24px;
				height: 24px;
				--padding-start: 0;
				--padding-end: 0;
				padding: 0;
				margin: 0;
				margin-right: 18px;

				ion-icon {
					font-size: 20px;
					color: white;
				}
			}

			&.revert-btn {
				margin-right: 18px;
				ion-icon {
					width: 28px;
					height: 28px;
					stroke: unset;
				}
			}
			&.done-btn {
				--background: #ff5e5e;
				font-weight: 500;
				--color: #000000;
				font-family: Metropolis;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 16px;
				--color: #efefef;
				min-width: 63px;
				height: 33px;
				margin: 0;
				margin-right: 18px;
				--border-radius: 5px;
			}

			&.text-align-btn {
				--padding-start: 0;
				--padding-end: 0;
				margin: 0;
				height: auto;
				--border-radius: 0;
				ion-icon {
					width: 22px;
					height: 18px;
				}
			}
			&.text-style-change-btn {
				--padding-start: 0;
				--padding-end: 0;
				margin-left: 28px;
				text-align: left;
				font-family: Metropolis;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 14px;
				--color: #efefef;
			}
		}
	}

	.bottom-tools-view {
		background: rgba(0, 0, 0, 0.7);
		backdrop-filter: blur(20px);
		/* Note: backdrop-filter has minimal browser support */
		padding-bottom: 46px;

		.delte-btn-container {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 50px;
			ion-button {
				ion-icon {
					width: 20px;
					height: 20px;
				}
			}
		}

		.colors-container {
			display: flex;
			flex-direction: row;
			padding: 0 28px;
			height: 42px;
			align-items: center;
			overflow-y: scroll;
			/* Hide scrollbar for IE, Edge and Firefox */
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
			/* Hide scrollbar for Chrome, Safari and Opera */
			&::-webkit-scrollbar {
				display: none;
			}
			.t-select {
				margin-right: 26px;
				ion-icon {
					width: 22px;
					height: 22px;
				}
			}
			.color-circle {
				border: 2px solid #ffffff;
				border-radius: 50%;
				width: 22px;
				height: 22px;
				min-width: 22px;
				min-height: 22px;
				margin-right: 26px;
				&.active {
					width: 28px;
					height: 28px;
					min-width: 28px;
					min-height: 28px;
				}
			}
		}
		.container {
			margin-top: 38px;
			height: 32px;
			display: flex;
			flex-direction: row;
			padding-left: 11px;
			padding-right: 20px;

			.edit-options {
				flex: 1;
				display: flex;
				flex-direction: row;
				.edit-type-btn {
					text-transform: none;
					--padding-start: 0;
					--padding-end: 0;
					height: auto;
					margin: 0 15px;
					opacity: 0.3;
					&.drawing-btn {
						stroke: #fff;
					}
					&.active {
						opacity: 1;
					}
				}
			}
			.send-btn {
				text-transform: none;
				margin: 0;
				--background: #ff5e5e;
				--border-radius: 5px;
				min-width: 64px;
				height: 32px;
				--padding-start: 8px;
				--padding-end: 8px;
				font-family: Metropolis;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 16px;
				--color: #e8e8e8;
			}
		}
	}

	ion-toolbar {
		--background: #000 !important;
		color: #fff;
	}

	.editor-container {
		width: 100%;
		flex: 1;
		padding-top: 0px;
		background-color: #000 !important;
		position: relative;
		display: flex;
		flex-direction: column;
		overflow-y: hidden;

		.media-list-container {
			display: flex;
			flex-direction: row;
			overflow-x: auto;
			padding: 6px 4px;
			.item {
				min-width: 54px;
				min-height: 54px;
				width: 54px;
				height: 54px;
				margin-right: 4px;
				position: relative;
				&.active {
					border: 3px solid #ff5e5e;
				}
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}

				.mask-view {
					position: absolute;
					left: 0;
					bottom: 0;
					right: 0;
					top: 0;
					background: rgba(0, 0, 0, 0.5);
				}

				video {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
				.video-player-icon {
					position: absolute;
					left: 0px;
					bottom: 0px;
					width: 22px;
					height: 22px;
					object-fit: contain;
				}
			}
		}

		.slider-container {
			flex: 1;
			overflow-y: hidden;
			ion-slides {
				width: 100%;
				height: 100%;
				ion-slide {
					width: 100%;
					height: 100%;
					.slider-div {
						max-height: 100%;
						width: 100%;
						height: 100%;
						// padding: 16px 0;
						display: flex;
						justify-content: center;

						ion-content {
							.sketchpad-canvas {
								width: 100%;
								height: 100%;
							}
						}

						img {
							max-width: unset;
							max-height: unset;
							width: 100%;
							height: 100%;
							margin: 0;
							max-height: 100%;
							object-fit: contain;
						}
					}
				}
			}
		}

		// .awssld--fill-parent {
		//  position: relative !important;
		//  img {
		//      object-fit: contain;
		//      background-color: #000;
		//  }
		// }

		.PinturaNav.PinturaNavMain {
			display: none !important;
			width: 0px !important;
		}
		.PinturaUtilFooter {
			width: 0px !important;
			height: 0px !important;
		}
		.PinturaRoot[data-env~='landscape'][data-env~='is-compact'][data-env~='has-navigation'] {
			grid-template-columns: 0em auto;
		}
		.PinturaRoot[data-env~='landscape'][data-env~='has-navigation']:not([data-env~='has-navigation-preference-right']) .PinturaUtilMain {
			padding: 0px !important;
		}

		.PinturaUtilMain {
			padding: 0px !important;
		}
		.PinturaScrollable.PinturaControlListScroller {
			opacity: 0;
			position: fixed;
			z-index: -1;
		}
		.editor {
			width: 100%;
			height: 100%;
			color: #fff;
			background-color: #000 !important;
			.PinturaRoot[data-env*='is-disabled'] .PinturaCanvas {
				filter: none !important;
			}
			.PinturaEditorOverlay {
				opacity: 0 !important;
			}
			.pintura-editor {
				color: #fff;
				--color-background: 0, 0, 0;
				--color-foreground: 255, 255, 255;
				background-color: #000 !important;
				--editor-max-width: 100vw;
				--editor-max-height: 100vh;
				--editor-inset-top: 0px;
				// div.PinturaRectManipulator[aria-label^='Drag corner'] {
				//  --size: 18px;
				//  width: 18px;
				//  height: 18px;
				//  border-width: 4px;

				//  &[aria-label^='Drag corner tl'] {
				//      margin-left: -4px;
				//      margin-top: -4px;
				//  }
				//  &[aria-label^='Drag corner tr'] {
				//      margin-top: -4px;
				//  }
				//  &[aria-label^='Drag corner bl'] {
				//      margin-left: -4px;
				//  }
				//  // &[aria-label^='Drag corner br'] {
				//  // }
				// }
			}
		}
	}

	.bottom-view-for-croponly {
		display: flex;
		flex-direction: column;
		height: 186px;
		justify-content: space-between;

		.tool-containers {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;
			width: 100%;
			height: 86px;
			ion-button {
				--padding-end: 0;
				--padding-start: 0;
				height: 40px;
				width: 40px;
				margin-right: 25px;
				margin-bottom: 40px;
				text-transform: none;
				ion-icon {
					height: 20px;
					width: 20px;
				}
			}
		}

		.save-cancel-btns {
			--background: var(--app-color-code);
			padding: 0 18px;
			--min-height: 36px;
			margin-bottom: 32px;
			ion-title {
				text-align: center;
			}

			.action-btn {
				text-transform: none;
				margin: 0;
				height: 32px;
				min-width: 64px;

				--padding-start: 0;
				--padding-end: 0;
				--border-radius: 5px;
				font-family: Metropolis;
				font-style: normal;
				font-weight: normal;
				font-size: 18px;
				line-height: 18px;
			}
			.edit-cancel-btn {
				--color: #ffffff;
			}

			.undo-btn {
				font-weight: 500;
				--color: #a5a5a5;
				&.button-disabled {
					--color: #ffffff;
				}
			}

			.edit-save-btn {
				--background: #ff5e5e;
				font-weight: 500;
				--color: #000000;
			}
		}
	}
}
