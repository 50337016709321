.add-location-page {
	.post-header {
		* {
			/*font-family: ArialBlack;*/
			font-weight: 700;
		}

		padding-top: 20px;
		--background: var(--app-color-code);
		--color: #fff;

		.post-btn {
			--padding-start: 16px;
			--padding-end: 16px;
		}
	}

	.searchbar-container {
		background: var(--app-color-code);
		width: 100%;
		ion-searchbar {
			font-size: 15px !important;
		}
	}

	ion-content {
		--background: var(--app-color-code);
		* {
			/*font-family: ArialBlack;*/
			font-weight: 700;
		}

		button:focus {
			outline: none;
		}

		ion-list {
			background: transparent !important;
			margin-bottom: 40px;
			ion-item {
				--border-color: #fff;
				--padding-start: 16px;
				--background: transparent;
				background-color: transparent;
				--min-height: 44px;

				ion-button {
					--padding-start: 0px;
					--padding-end: 0px;
				}

				ion-label {
					font-size: 18px !important;
					line-height: 1 !important;
				}
				h3 {
					margin: 0 !important;
				}

				ion-note {
					font-size: 12px;
					color: #979797;
				}
			}
		}
	}
}
