.editThread-page {
	.topbar-header {
		.toolbarTitle .title {
			font-weight: 600;
		}
		.toolbar-heading {
			--padding-start: 10px;
			--padding-end: 18px;
			.buttons-first-slot {
				ion-button {
					margin: 0;
					padding: 0 8px;
					// ion-icon {
					// 	width: 24px !important;
					// 	height: 24px !important;
					// 	stroke: #000000;
					// }
				}
			}
			.buttons-last-slot {
				justify-content: flex-end;
				ion-button {
					width: auto;
					height: 34px;
					padding-top: 2px;
					margin: 0;
					padding-right: 1px;
					font-family: Metropolis;
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 18px;
					display: flex;
					align-items: center;
					text-align: right;
					--color: #c4c4c4;
					&.button-disabled {
						--color: #c4c4c4;
					}
				}
			}
		}
	}
	.folderlist-container {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		margin-bottom: 13px;
		justify-content: space-around;
		position: relative;

		.folderlist-item {
			display: flex;
			flex-direction: row;
			align-items: center;
			// justify-content: center;
			position: relative;
			width: 100%;
			height: 70px;
			margin-left: 17px;
			.check {
				width: 24px;
				height: 24px;
				margin-right: 12.5px;
				margin-top: 7px;
				--border-radius: 100%;
				position: relative;
				--border-color-checked: #ff5e5e;
				--background-checked: #ff5e5e;
				--background: transparent;
				--border-width: 1px;
				--border-color: #a5a5a5;
				&.after-select {
					.check {
						--border-width: 5px;
					}
				}
			}

			// ion-checkbox {
			// 	margin: 0;
			// 	min-width: 24px;
			// 	min-height: 24px;
			// 	width: 24px;
			// 	height: 24px;
			// 	--border-radius: 50%;
			// 	--size: 22px;
			// 	--background: transparent;
			// 	--border-color: #858484;
			// 	--border-color-checked: #ff5e5e;
			// 	--background-checked: #ff5e5e;
			// 	--checkmark-color: #fff;
			// 	margin-right: 20px;
			// 	--checkmark-width: 2px;
			// }

			&.after-select {
				// font-size: 16px;
				// font-weight: 400;
				.check {
					--border-width: 5px;
				}
			}

			.folderlist-img-div {
				width: 40px;
				height: 40px;
				position: relative;
			}

			.imagelist-img {
				width: 100%;
				height: 100%;
			}
			.folder-list {
				display: flex;
				flex-direction: column;
				width: 100%;
				.like {
					bottom: 0;
					display: flex;
					align-items: baseline;
					margin-top: 8.5px;
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 14px;
					color: #858484;

					.folderlist_name {
						position: relative;
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 14px;

						color: #858484;
					}
				}
			}

			.folderlist-dot {
				position: absolute;
				right: 3.62%;
				margin-top: 18px;
				margin-right: 18px;
			}
			.folderlist_name {
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 400;
				font-size: 18px;
				line-height: 18px;
				color: #000000;
			}

			&.folderlist-item-active {
				border: 1px solid #a5a5a5;
				border-radius: 6px;

				.check {
					width: 24px;
					height: 24px;
					min-width: 24px;
					min-height: 24px;
					--border-radius: 100%;
					position: absolute;
					margin-right: 14px;
					--border-color-checked: #ff5e5e;
					--background-checked: #ff5e5e;
					--background: transparent;
					--border-color: #a5a5a5;
					--border-width: 1px;
				}
				&.after-select {
					// font-size: 16px;
					// font-weight: 400;
					.check {
						--border-width: 5px;
					}
				}
			}
		}
	}

	.swtich-item {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		ion-label {
			font-size: 16px;
			color: #858484;
			font-weight: 500;
		}
	}
	.manage-toggle-item {
		--min-height: 56px;
		--padding-start: 0;
		--background: transparent;
		padding-bottom: 34px;

		.title {
			--padding-start: 0;
			--padding-end: 0;
			margin: 0;
			margin-left: 16px;
			font-style: normal;
			text-align: center;
			max-width: 108px;
			text-transform: none;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 18px;
			color: #000000;
		}
		ion-toggle.toggle-customised {
			--background: #dcdcdc;
			--background-checked: #ff5e5e;
			--handle-background: #ffffff;
			--handle-background-checked: #ffffff;
			--handle-height: 22px;
			--handle-width: 22px;
			--handle-border-radius: 50%;
			--handle-max-height: 22px;
			--handle-spacing: 1px;
			width: 45.71px;
			padding-right: 0;
			padding-left: 14px;
			height: 24px;
		}
		.delete-tags-btn,
		.add-tag-btn {
			--padding-start: 0;
			--padding-end: 0;
			font-family: Metropolis;
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 18px;
			text-align: right;
			color: #ff5e5e;
			text-transform: none;
			&.button-disabled {
				opacity: 0.5;
				color: #ff5e5e;
			}
		}

		.cancel-btn {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 18px;
			color: #000000;
			margin-left: 8px;
			text-transform: none;
		}
	}
}
.alert-controller-class {
	--background: #fff;
	--height: 180px;
	--width: 348px;
	.alert-head.sc-ion-alert-ios {
		padding-bottom: 0;
	}
	.alert-wrapper.sc-ion-alert-ios {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 322px;
		height: 180px;

		.alert-title {
			margin-top: 12px;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 18px;
			text-align: center;
			color: #000000;
		}
		.alert-message {
			padding-top: 23px;
			padding-left: 17px;
			padding-right: 17px;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 120%;
			text-align: center;
			color: #585858;
		}
		.alert-button.sc-ion-alert-ios:last-child {
			width: 105px;
			height: 18px;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 18px;
			text-align: center;
			margin-bottom: 19px;
		}
	}
}
.manage-contact-action-sheet {
	.action-sheet-wrapper .action-sheet-group {
		padding-bottom: 0 !important;
	}
	.action-sheet-title {
		line-height: 20px;
		padding: 0;
		background: null;
		height: 66px;
		text-align: center;
		display: flex;
		align-items: center;
		padding: 0 10px;
		justify-content: center;
		font-size: 13px;
		color: #585858;
	}

	.action-sheet-container {
		padding: 0;
	}
	.action-sheet-group {
		height: 216px !important;
		padding-bottom: 57px !important;
		border-radius: 10px 10px 0 0;
		margin-bottom: 0 !important;
	}

	.action-sheet-button {
		padding: 0 10px;
		text-align: center;
		height: 18px;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 21px;
		color: #000000;

		// padding-top: 26px;
		// padding-bottom: 37px;
	}
	.action-sheet-button.sc-ion-action-sheet-ios:first-child {
		height: 34px;
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 120%;
		text-align: center;
		color: #858484 !important;
		margin-top: 22px !important;
		margin-bottom: 24px !important;
	}

	.action-sheet-button.sc-ion-action-sheet-ios:last-child {
		padding-top: 48px !important;
		padding-bottom: 14px !important;
		font-size: 16px !important;
		font-weight: 500 !important;
	}
	.action-sheet-group-cancel {
		border-radius: 0;
		// padding-top: 44px;
		padding-bottom: 58px !important;
		.cancel {
			width: 50%;
			background-color: #d64747;
			color: #3ebaff;
		}
	}
	.action-sheet-structive {
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500 !important;
		font-size: 16px !important;
		line-height: 16px !important;
		text-align: center;
		color: #ff5e5e !important;
	}

	.action-sheet-destructive {
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 18px;
		text-align: center;
		color: #ff5e5e;
	}
}
