.quick-access-popup,
.quick-access-modal {
	flex: 1;

	@media only screen and (min-width: 768px) and (min-height: 600px) {
		--width: 100vw;
		--height: 100vh;
		--min-width: 100vw;
		--min-height: 100vh;
		--background: transparent !important;

		.modal-wrapper {
			--background: transparent !important;
			--width: 100vw;
			--height: 100vh;
			--min-width: 100vw;
			--min-height: 100vh;
		}
	}
	--background: transparent;
	//  --background: var(--app-color-code);

	&.blurbackground {
		--background: rgba(239, 239, 239, 0.7);
		backdrop-filter: blur(5px);
	}
	&.nonebackground {
		--background: none;
		// --background: rgba(184, 184, 184, 0.1);
		backdrop-filter: none;
	}

	ion-backdrop {
		--background: transparent !important;
		margin-bottom: 82px;
	}

	ion-content {
		--background: transparent;
		// --background: var(--app-color-code);
	}
	ion-modal {
		color: #ff5e5e;
		background: transparent !important;
	}
	.save-scan-container {
		display: flex;
		justify-content: space-between;
		margin-top: 45px;
		padding-right: 30px;
		padding-left: 30px;
		background: var(--app-color-code);
		width: 100%;
		.thumbnail {
			width: 50px;
			height: 50px;
			border-radius: 100%;
			background: rgba(133, 132, 132, 0.3);
			backdrop-filter: blur(17.5px);
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				width: 22px;
				height: 22px;
			}
		}
		.fill-space {
			flex: 1;
		}
		.thumbnail-first {
			width: 50px;
			height: 50px;
			border-radius: 100%;
			background: rgba(133, 132, 132, 0.3);
			backdrop-filter: blur(17.5px);
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 22px;
				height: 22px;
			}
		}
	}

	.hotkey-container {
		flex: 1;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		background: rgba(239, 239, 239, 0.7) !important;

		.hotkey-card {
			display: flex;
			background: var(--app-color-code);
			border-radius: 12px;
			max-width: 276px;
			flex-wrap: wrap;
			justify-content: space-around;
			align-items: center;
			padding: 10px 0;
			box-shadow: -10px -10px 30px #ffffff, 10px 10px 30px rgba(174, 174, 192, 0.4);
			ion-label {
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 14px;
				text-align: center;
				color: red;
			}
		}

		.hotkey-content-container {
			flex: 1;
			// margin-bottom: 20px;
			margin-bottom: 10px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			flex-direction: column;
			color: blue;
			ion-label {
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				color: red;
			}

			.card-title {
				font-size: 20px;
				font-weight: 900;
				color: black;
				text-align: center;
				width: 100%;
				padding: 16px 0;
				display: inline-block;
				text-transform: uppercase;
				ion-label {
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 14px;
					text-align: center;
					color: red;
				}
			}

			.card {
				width: 315px;
				color: red;
				border-radius: 16px;
			}

			.component-expand-full {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 100%;
				height: 100%;
				justify-content: flex-end;
				padding: 0 7%;
				&.nonebackground {
					--background: none;
					backdrop-filter: none;
				}
			}
			ion-item {
				width: 74%;
			}
			.deadline {
				--background: rgba(165, 165, 165, 0.5);
				backdrop-filter: blur(20px);
				border-radius: 4px;
			}

			.qrcode-component {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 100%;
				.qrimage {
					width: 100%;
					height: 100%;
					display: flex;
					// align-items: center;
					justify-content: center;
					.userdiv {
						display: flex;
						position: absolute;
						flex-direction: column;

						.userimage {
							width: 30px;
							height: 30px;
							border-radius: 3px;
							display: flex;
							align-self: center;
							margin: 134px;
							z-index: 1;
						}
					}

					.qrcode {
						display: flex;
						flex-direction: column;
						width: 100%;
						height: 100%;
					}
				}

				ion-label {
					font-size: 30px;
					font-weight: 900;
					color: black !important;
				}

				ion-text {
					margin-top: 5px;
					font-size: 16px;
					font-weight: 400;
					color: #000;
				}

				ion-img {
					background: #fff;
					border-radius: 8px;
					box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.35);
					left: 6.03%;
					right: 6.35%;
					display: inline-block;
					margin-top: 19px;
					cursor: pointer;
					width: 66vw;
					height: 66vw;
					max-width: 276px;
					max-height: 276px;
				}
			}

			.projects-creat-component {
				width: 276px;
				display: flex;
				flex-direction: column;
				background: #efefef;
				// backdrop-filter: blur(5px);
				// border-radius: 7px;
				// justify-content: center;
				// align-items: center;
				// box-shadow: 0px 0px 13px gray, 10px 10px 30px rgba(184, 184, 184, 0.1);
				.card-title-project {
					font-size: 24px;
					font-weight: 900;
					color: black;
					padding-left: 12px;
					// text-align: center;
					width: 100%;
					// padding: 16px 0;
					// display: inline-block;
					text-transform: uppercase;
					// ion-label {
					// 	font-family: 'Metropolis';
					// 	font-style: normal;
					// 	font-weight: 500;
					// 	font-size: 14px;
					// 	line-height: 14px;
					// 	text-align: center;
					// 	color: red;
					// }
				}
				.create {
					width: 276px;
					--background: #ff5e5e;
					height: 44px;
					// --background: rgba(133, 92, 92, 0.3);
					--background-activated: transparent;
					border-radius: 8px;
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 500;
					font-size: 20px;
					line-height: 20px;
					color: white;
					margin: 0;
					text-transform: capitalize;
				}

				.card {
					width: 100%;
					display: flex;
					flex-direction: column;
					margin-top: 13px;
					margin-bottom: 20px;
					// align-items: center;
					// overflow-y: hidden;
					// max-height: 220px;
					// justify-content: center;
					// flex-flow: wrap;

					ion-item {
						// border-radius: 8px;
						// margin: 8px 0;
						width: 100%;
						height: 44px;
						--background: transparent;

						ion-label {
							font-size: 20px;
							color: #000000;
						}

						ion-text {
							font-size: 14px;
							color: #ff5e5e;
						}
					}
				}
			}

			.new-project-create-component {
				.card-title {
					font-size: 22px;
					font-weight: 900;
					color: black;
					text-align: center;
					width: 100%;
					padding: 16px 0;
					display: inline-block;
					text-transform: uppercase;
					ion-label {
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 14px;
						text-align: center;
						color: red;
					}
				}
				.nobutton {
					display: none;
				}
				.bottombutton {
					margin-top: 10px;
					display: flex;
					justify-content: space-around;
					--background: transparent;
					width: 100%;
					.cancel {
						width: 74px;
						height: 30px;
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						line-height: 16px;
						background: rgba(184, 184, 184, 0.1);
						backdrop-filter: blur(35px);
						border-radius: 5px;

						text-transform: none;
						color: #000000;
					}
					.done {
						width: 64px;
						height: 30px;
						background: rgba(255, 94, 94, 0.4);
						backdrop-filter: blur(35px);
						border-radius: 5px;
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						line-height: 16px;
						background: rgba(255, 94, 94, 0.4);
						backdrop-filter: blur(35px);
						border-radius: 5px;
						color: #000000;
						text-transform: none;
					}
				}
				.card {
					display: flex;
					flex-direction: column;
					align-items: center;
					padding: 0;
					padding-bottom: 5%;
					overflow-y: hidden;
					max-height: 370px;
					flex-flow: wrap;
					border-radius: 7px;
					box-shadow: 0px 0px 13px gray, 10px 10px 30px rgba(184, 184, 184, 0.1);
					.number115 {
						width: 88%;
						display: flex;
						align-items: center;
						justify-content: flex-end;
						height: 20px;
						.numberlast {
							top: 6px;
							font-size: 12px;
							color: #a5a5a5;
						}
					}
					ion-item {
						--background: transparent;
						.number {
							top: 6px;
							position: absolute;
							right: 0;
							font-size: 10px;
							color: #a5a5a5;
						}

						ion-label {
							margin: 0;
						}
						&.task-item {
							width: 100%;
							--padding-top: 0;
							margin-bottom: 16px;
							--border-color: #a5a5a5;

							.thumbnail {
								display: flex;
								flex-direction: column;
								align-items: center;
								// min-height: 100%;

								img {
									width: 28px;
									height: 28px;
									margin-bottom: 4px;
									border-radius: 3px;
									overflow: hidden;
								}
								ion-text {
									color: #ff5e5e;
									font-size: 8px;
									font-weight: 400;
								}
							}

							.task-item-label {
								padding: 0 5px;
								.inner-item {
									--padding-start: 0;
									--border-color: #a5a5a5;

									ion-checkbox {
										--background: #dcdcdc;
										--background-checked: #dcdcdc;
										--border-color: #000000;
										--border-width: 1px;
										--border-color-checked: #000000;
										--border-radius: 3px;
										--checkmark-color: #ff5e5e;
										--checkmark-width: 1px;
										--size: 26px;
									}
								}
							}
						}
					}
				}
			}

			.projects-component {
				ion-toolbar {
					width: 87%;
					ion-label {
						padding-left: 12px;
						text-align: left;
						padding-bottom: 0;
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 900;
						font-size: 24px;
						line-height: 24px;
						color: #000000;
					}
				}
				.addbuttons {
					ion-button {
						.add_icon {
							padding-bottom: 2px;
						}
						margin: 0;
					}
				}

				.card {
					display: flex;
					flex-direction: column;
					align-items: center;
					overflow-y: hidden;
					max-height: 222px;
					justify-content: center;
					flex-flow: wrap;

					ion-item {
						border-radius: 8px;
						margin: 0;
						width: 87%;
						margin-top: 8px;
						--background: transparent;

						ion-label {
							font-size: 20px;
							color: #000000;
						}

						ion-text {
							font-size: 14px;
							color: #ff5e5e;
							font-weight: 500;
						}
					}
				}
			}

			.project-task-component {
				.createcard {
					display: flex;
					flex-direction: column;
					align-items: center;
					overflow-y: hidden;
					max-height: 250px;
					justify-content: center;
					flex-flow: wrap;

					ion-item {
						border-radius: 8px;
						margin: 8px 0;
						width: 63%;
						--background: transparent;

						ion-label {
							font-size: 20px;
							color: #000000;
						}

						ion-text {
							font-size: 14px;
							color: #ff5e5e;
						}
					}
				}
				.bottombutton {
					--background: transparent;
					.cancel {
						width: 74px;
						height: 30px;
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						line-height: 16px;
						background: rgba(184, 184, 184, 0.1);
						backdrop-filter: blur(35px);
						border-radius: 5px;

						text-transform: none;
						color: #000000;
					}
					.done {
						width: 64px;
						height: 30px;
						background: rgba(255, 94, 94, 0.4);
						backdrop-filter: blur(35px);
						border-radius: 5px;
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						line-height: 16px;
						background: rgba(255, 94, 94, 0.4);
						backdrop-filter: blur(35px);
						border-radius: 5px;
						color: #000000;
						text-transform: none;
					}
				}
				.card {
					display: flex;
					flex-direction: column;
					align-items: center;
					padding: 0;
					padding-bottom: 5%;
					overflow-y: hidden;
					max-height: 370px;
					flex-flow: wrap;
					box-shadow: 0px 0px 13px gray, 10px 10px 30px rgba(184, 184, 184, 0.1);

					ion-item {
						--background: transparent;

						ion-label {
							margin: 0;
						}
						&.task-item {
							width: 100%;
							--padding-top: 0;
							margin-bottom: 16px;
							--border-color: #a5a5a5;

							.thumbnail {
								display: flex;
								flex-direction: column;
								align-items: center;
								margin-bottom: 25px;
								img {
									width: 28px;
									height: 28px;
									margin-bottom: 10px;
									border-radius: 3px;
									overflow: hidden;
								}
								ion-text {
									color: #ff5e5e;
									font-size: 8px;
									font-weight: 400;
								}
							}

							.task-item-label {
								padding: 0 5px;
								.inner-item {
									width: 100%;
									display: flex;
									margin-top: 5px;
									--padding-start: 0;
									--border-color: #a5a5a5;

									ion-checkbox {
										--background: #dcdcdc;
										--background-checked: #dcdcdc;
										--border-color: #000000;
										--border-width: 1px;
										--border-color-checked: #000000;
										--border-radius: 3px;
										--checkmark-color: #ff5e5e;
										--checkmark-width: 1px;
										--size: 26px;
									}
									.quick-access-paint {
										width: 100%;
										margin-left: 10px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.quick-access-modal {
	bottom: 82px;

	.modal-wrapper {
		margin-bottom: 0px;
	}
	.modal-shadow {
		bottom: 0px;
	}

	ion-backdrop {
		background-color: var(--app-color-code);
	}

	.hotkey-content-container {
		background-color: var(--app-color-code);
		margin-bottom: 0px !important;
		flex: 1;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between !important;
		flex-direction: column;
	}
}

.qrcode-scan-modal {
	@media only screen and (min-width: 768px) and (min-height: 600px) {
		--width: 100vw;
		--height: 100vh;
		--min-width: 100vw;
		--min-height: 100vh;
		.modal-wrapper {
			--width: 100vw;
			--height: 100vh;
			--min-width: 100vw;
			--min-height: 100vh;
		}
	}

	.qr-container {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: -1;
		background: #000;
	}

	.content-view {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		.qrview-container {
			flex: 1;
			position: relative;
			overflow: hidden;
			.backicon {
				width: 26px;
				height: 26px;
				// background: white;
				display: flex;
				align-items: center;
				border-radius: 50%;
				justify-content: center;
				margin-top: 64px;
				position: fixed;
				margin-left: 26px;
				.back {
					width: 100%;
					height: 100%;
				}
			}
			.qrreader {
				width: 100%;
			}
			.scan-btns {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;

				.scan-qr-barcode-title {
					font-size: 14px;
					line-height: 19px;
					text-align: center;
					color: #e8e8e8;
					margin: 10px;
				}

				ion-toolbar {
					--background: transparent;
					ion-fab-button {
						margin: 30px;
						width: 50px;
						height: 50px;
						--background: rgba(133, 132, 132, 0.3);
						backdrop-filter: blur(35px);
						border-radius: 50%;
						--border-radius: 50%;
					}
				}
			}

			.scanning-animated-line {
				position: absolute;
				width: 100vw;
				z-index: 999;
				object-fit: contain;
				animation: updown 4s ease infinite;
				top: 0;
				@keyframes updown {
					0% {
						top: 22vh;
					}

					50% {
						top: 70vh;
					}

					100% {
						top: 22vh;
					}
				}
			}
		}
		.bottom-view {
			display: flex;
			flex-direction: row;
			background-color: #000;
			height: 106px;

			.scan-options-row {
				display: flex;
				flex-direction: row;
				margin: 20px 0;
				width: 100vw;
				overflow-y: auto;
				.scan-option-item {
					display: inline-table;
					font-weight: 500;
					font-size: 18px;
					line-height: 18px;
					color: #e8e8e8;
					height: 26px;
					min-width: 60px;
					margin: 0 8px;
					text-align: center;
					cursor: pointer;
					&:first-child {
						margin-left: calc(50vw - 34px);
					}
					&.active {
						border-bottom: 2px solid #e8e8e8;
					}
				}
			}
		}
	}
}
