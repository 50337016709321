.personal-folder {
	.inner-scroll scroll-y {
		--padding-top: 0px !important;
	}
	ion-content.has-bottom-navbar {
		--padding-top: none !important;
		--padding-bottom: none !important;
	}
	.topbar-header {
		ion-searchbar {
			background-color: transparent !important;
			background: transparent !important;

			input {
				background: transparent !important;
				background-color: transparent !important;
			}

			.searchbar-search-icon {
				background-color: #efefef !important;
			}
			&.searchbar-wrapper {
				margin-top: 12px;
				margin-bottom: 12px;
				.searchbar-input-container {
					background-color: #efefef !important;
					margin-left: 9px;
					.searchbar-input {
						background-color: #efefef !important;
						margin-left: 21px;

						&::placeholder {
							color: #585858;
						}
					}
				}
			}
		}

		ion-toolbar {
			&.toolbar-searchbar {
				--background: #efefef;
			}
		}

		ion-button {
			&.search-icon-btn {
				width: 21px;
				height: 21px;
				margin-right: 19px;
			}
			&.search-icon-btn:hover {
				background-color: none;
			}
		}
	}

	ion-content {
		margin-top: 90px !important;
		--background: #efefef;
	}
	.thread-title {
		width: 100%;
		height: 30px;
		align-items: flex-start;
		z-index: 99999;
		margin-top: 88px;
		background: rgba(239, 239, 239, 0.7);
		backdrop-filter: blur(10px);
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 14px;
		padding-left: 11px;
		padding-top: 8px;
		padding-bottom: 8px;
		color: #000000;
	}
	.select-filter-mode {
		width: 100%;
		height: 80px;
		background: rgba(239, 239, 239, 0.7);
		backdrop-filter: blur(10px);
		display: flex;
		align-items: baseline;
		justify-content: space-between;
		padding-left: 13px;
		padding-right: 13px;
		padding-top: 16px;
		.select-all {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 16px;
			color: #000000;
		}
		.filter-container {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 16px;
			text-align: center;
			color: #000000;
			display: flex;
			flex-direction: column;
			.all-by-size {
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 14px;
				text-align: center;
				color: #ff5e5e;
				margin-top: 3px;
			}
		}
		.clear {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 18px;
			text-align: right;
			color: rgba(255, 94, 94, 0.5);
		}
	}

	.div-first {
		top: 88px;
		width: 100%;
		height: 70px;
		display: flex;
		flex-direction: column;
	}
	.tabs-hide {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 58px;
		opacity: 0;
	}
	.tabs {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 58px;
		margin-top: 90px;

		.tabitem {
			display: flex;
			flex: 1;
			justify-content: center;
			align-items: center;
			ion-button {
				font-size: 14px;
				font-weight: 500;
				align-self: center;
				color: #585858;
				margin: 0;
				text-transform: none;
				height: unset;
				padding-bottom: 8px;
				&.active {
					color: #ff5e5e;
					font-weight: 600;
					border-bottom: 3px solid #ff5e5e;
				}
			}
		}
	}

	.images {
		margin-top: 22px;
		margin-left: 4%;
		font-weight: 600;
		font-size: 14px;
		color: #585858;
	}
	.image {
		display: flex;
		flex-direction: row;
		padding-left: 15px;
		width: 100%;
		overflow-x: hidden;

		.img {
			width: 80px;
			min-width: 80px;
			height: 80px;
			margin-top: 19px;
			position: relative;
			margin-right: 15px;
			.inner-img {
				display: flex;
				width: 100%;
				object-fit: cover;
			}
			.folder_name {
				margin-top: 9.5px;
				margin-bottom: 9.5px;
			}
		}
		.text {
			font-size: 15px;
		}
	}

	.first {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 38px;
		padding: 0 17px;

		.videoimage {
			display: flex;
			align-items: center;
			font-weight: 600;
			font-size: 14px;
			color: #585858;

			.container {
				margin-left: 12px;
				display: flex;
				justify-content: center;
				-ms-flex-align: center;
				-webkit-align-items: center;
				-webkit-box-align: center;
				align-items: center;
				height: 90vh;

				.arrowup {
					display: block;
					font-size: 100px;
					transition: all 0.4s ease;
				}
				.arrowdown {
					transform: rotateZ(180deg);
					transition: all 0.4s ease;
				}
			}

			.menu {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
			}
		}
	}
	.folder-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-top: 18px;
		margin-bottom: 13px;
		justify-content: space-around;
		.folder-item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			position: relative;
			max-width: 195px;
			width: 47%;
			height: 128px;
			margin-bottom: 8px;

			.folder_name {
				font-size: 16px;
				font-weight: 400;
				margin-top: 11px;
			}
			.file-image-container {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				position: relative;
				max-width: 195px;
				width: 72px;
				height: 56px;
				margin-bottom: 8px;
				margin-top: 15px;
				.file-img {
					width: 80px;
					height: 80px;
				}
			}

			.folder-image-container {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				position: relative;
				max-width: 195px;
				width: 72px;
				height: 56px;
				margin-bottom: 8px;
				margin-top: 7px;

				.folder-img {
					fill: #979797;
					width: 71.5px;
					height: 56px;
				}

				.like {
					display: flex;
					position: absolute;
					bottom: 3px;
					right: 4px;

					.inner-shared {
						width: 10.5px;
						height: 10px;
						position: relative;
					}
					.inner-star {
						width: 10.5px;
						height: 10px;
						position: relative;
					}
				}
			}

			&.folder-item-active {
				border: 1px solid #a5a5a5;
				border-radius: 6px;

				.check {
					width: 24px;
					height: 24px;
					--border-radius: 100%;
					position: absolute;
					right: 7px;
					top: 7px;
					--border-color-checked: #ff5e5e;
					--background-checked: #ff5e5e;
					--background: transparent;
					--border-width: 1px;
					--border-color: #a5a5a5;
				}
				&.after-select {
					border: 1px solid #ff5e5e;
					border-radius: 6px;
					background-color: #f4c4c4;
					color: #ff5e5e;
					font-size: 16px;
					font-weight: 400;
					.check {
						--border-width: 5px;
					}
					.folder-img {
						fill: #ff5e5e;
					}
				}
			}
		}

		.folder-img {
			width: 72px;
			height: 56px;
		}

		.folder-dot {
			padding: 6px;
			position: absolute;
			right: 0px;
			top: 0px;
		}
		.folder-extra-bank {
			width: 47%;
			max-width: 195px;
			height: 0.01px;
		}
	}

	.image-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100vw;
		// margin-top: 10px;

		.image-item {
			display: flex;
			flex-direction: column;
			align-items: center;
			position: relative;
			width: 33.33vw;
			height: 33.33vw;

			.image-img {
				width: 100%;
				height: 100%;
			}
			.image-dot {
				position: absolute;
				right: 8.25px;
				top: 8.25px;
				// background-color:#F4C4C4;
				// background-color:#F4C4C4;
			}
			.like {
				position: absolute;
				bottom: 0;
				right: 0;

				.inner-shared {
					position: relative;
				}
				.inner-star {
					position: relative;
				}
			}
			&.image-item-active {
				border: 1px solid #a5a5a5;
				border-radius: 6px;

				.check {
					width: 20px;
					height: 20px;
					--border-radius: 100%;
					position: absolute;
					right: 4.35%;
					top: 4.35%;
					--border-color-checked: #ff5e5e;
					--background-checked: #ff5e5e;
					--background: transparent;
					--border-color: white;

					--border-width: 1px;
				}
				&.after-select {
					font-size: 16px;
					font-weight: 400;
					.check {
						--border-width: 5px;
					}
				}
			}
		}
	}
	.video-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100vw;
		margin-top: 10px;

		.video-item {
			display: flex;
			flex-direction: column;
			align-items: center;
			position: relative;
			width: 33.33vw;
			height: 33.33vw;
			.video-img {
				width: 100%;
				height: 100%;
			}
			.duration {
				position: absolute;
				top: 5px;
				left: 5px;
				color: white;
				font-size: 12px;
				font-weight: 400;
			}
			.innerdiv-play {
				display: flex;
				justify-content: center;
				align-items: center;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				position: absolute;
				.inner-play {
					width: 34px;
					height: 34px;
					position: relative;
				}
			}
			.video-dot {
				position: absolute;
				right: 8.25px;
				top: 8.25px;
			}
			.like {
				position: absolute;
				bottom: 0;
				right: 0;

				.inner-shared {
					position: relative;
				}
				.inner-star {
					position: relative;
				}
			}

			&.video-item-active {
				border: 1px solid #a5a5a5;
				border-radius: 6px;

				.check {
					width: 20px;
					height: 20px;
					--border-radius: 100%;
					position: absolute;
					right: 4.35%;
					top: 4.35%;
					--border-color-checked: #ff5e5e;
					--background-checked: #ff5e5e;
					--background: transparent;
					--border-color: white;
					--border-width: 1px;
					--border-color: white;
				}
				&.after-select {
					font-size: 16px;
					font-weight: 400;
					.check {
						--border-width: 5px;
					}
				}
			}
		}
	}

	.folderlist-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		// margin-top: 18px;
		margin-bottom: 13px;
		justify-content: space-around;
		padding-left: 23px;

		.folderlist-item {
			display: flex;
			flex-direction: row;
			align-items: center;
			position: relative;
			width: 100%;
			height: 70px;

			.folderlist-img-div {
				width: 40px;
				height: 40px;
				position: relative;
			}

			.imagelist-img {
				width: 100%;
				height: 100%;
			}
			.folder-list {
				display: flex;
				flex-direction: column;
				margin-left: 16.5px;
				width: 100%;
				.like {
					// position: absolute;
					bottom: 0;
					display: flex;
					align-items: baseline;
					margin-top: 9.5px;

					.inner-shared {
						position: relative;
						padding-right: 5px;
					}
					.inner-star {
						position: relative;
						padding-right: 5px;
					}

					.foldersize_name {
						position: relative;
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 14px;

						color: #858484;
					}
				}
			}
			.innerdiv-play {
				display: flex;
				justify-content: center;
				align-items: center;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				position: absolute;
				.inner-play {
					width: 24px;
					height: 24px;
					position: relative;
				}
			}

			.folderlist-img {
				width: 100%;
				height: 100%;
				fill: #c4c4c4;
			}

			.folderlist-dot {
				height: 20px;
				width: 20px;
				position: absolute;
				right: 3.62%;
				// top: 25%;
			}
			.folderlist_name {
				font-size: 16px;
				font-weight: 400;
				// margin-bottom: 24.6px;
				top: 2px;
			}

			&.folderlist-item-active {
				// border: 1px solid #a5a5a5;
				// border-radius: 6px;

				.check {
					width: 24px;
					height: 24px;
					min-width: 24px;
					min-height: 24px;
					--border-radius: 100%;
					position: relative;
					margin-right: 14px;
					--border-color-checked: #ff5e5e;
					--background-checked: #ff5e5e;
					--background: transparent;
					--border-color: #a5a5a5;
					--border-width: 1px;
				}
				&.after-select {
					// font-size: 16px;
					// font-weight: 400;
					.check {
						--border-width: 5px;
					}
				}
			}
		}
	}
	.bottom-manage-access-popup {
		display: flex;
		flex-direction: column;
		min-width: 414px;
		width: 100%;
		height: 116px;
		position: fixed;
		right: 0;
		bottom: 88px;
		background: rgba(239, 239, 239, 0.7);
		backdrop-filter: blur(20px);
		border-radius: 0px;
		z-index: 999999;

		.manage-share-top {
			display: flex;
			// width: 414px;
			height: 36px;
			justify-content: space-between;
			flex: 1;

			.selectAll-Button {
				background-color: none;

				.select-all {
					text-transform: capitalize;
					display: flex;
					justify-content: left;
					align-items: center;
					left: 0px;
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 18px;
					color: #585858;
				}
			}
			.popup-manage {
				// border-radius: 100%;
				width: 7.8pxpx;
				height: 14px;
				// --padding-start: 0;
				// --padding-end: 0;
				// margin-left: 14px;
				right: 0;

				.arrow-right {
					width: 100%;
					height: 100%;
					right: 0;
					.arrow-right-light {
						width: 100%;
						height: 100%;
					}
				}
			}
		}

		.share-image-container::-webkit-scrollbar {
			display: none;
		}

		.share-image-container {
			display: flex;
			align-items: center;
			width: 100%;
			height: 80px;
			top: 36px;
			overflow-x: auto;
			float: right;
			// flex: 1;
			// margin-right: 20px;

			.manage-media {
				// border-radius: 100%;
				// width: 432px;
				height: 48px;
				// margin-left: 18px;
				.manage-image-container {
					width: 48px;
					height: 48px;
					position: absolute;
					border-radius: 4px;
					background: rgba(88, 88, 88, 0.6);
					backdrop-filter: blur(20px);
					opacity: 0.2;
				}

				.manage-media-image {
					width: 48px;
					height: 48px;
				}
			}
		}
	}

	.bottom-media-option-popup {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
		min-width: 414px;
		width: 100%;
		height: 88px;
		position: fixed;
		right: 0;
		bottom: 0;
		background: rgba(239, 239, 239, 0.7);
		backdrop-filter: blur(20px);
		z-index: 999999;

		.selectAll-Button {
			background-color: none;

			.select-all {
				text-transform: capitalize;
				display: flex;
				justify-content: left;
				align-items: center;
				left: 0px;

				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 16px;
				color: #000000;
				margin-top: 10px;
			}
		}

		.popup-Icon-list {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			width: 298px;
			height: 38px;
			flex: 1;
			margin-right: 20px;
			margin-left: 0;
			margin-top: 10px;

			.popup-media {
				border-radius: 100%;
				width: 38px;
				min-width: 38px;
				height: 38px;
				--padding-start: 0;
				--padding-end: 0;
				margin-left: 14px;
				.popup-image-container {
					width: 100%;
					height: 100%;
					position: absolute;
					border-radius: 50%;
					background: rgba(88, 88, 88, 0.6);
					backdrop-filter: blur(20px);
					opacity: 0.2;
					.popup-media-image {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
}
