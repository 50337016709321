.right-button-active {
	ion-icon {
		stroke: #ff5e5e;
		fill: #ff5e5e;
	}
}
// .searchbar-clear-button.sc-ion-searchbar-ios{
//   background: transparent;
// }
// .searchbar-clear-icon.sc-ion-searchbar-ios{
//   background: transparent;
// }

.has-topbar-with-searchbar {
	--padding-top: var(--top-header-bar-with-searchbar-height);
	padding-top: var(--top-header-bar-with-searchbar-height);
}

.has-topbar {
	--padding-top: var(--top-header-bar-height);
	padding-top: var(--top-header-bar-height);
}

.topbar-header {
	background: rgba(var(--app-modal-bg-color-code), 0.7);
	backdrop-filter: blur(20px);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 99;
	@supports not (backdrop-filter: none) {
		background-image: url(./../../../assets/blur-gray-bg.png) !important;
		background-size: 100% 100%;
		background-position: top left;
	}
	ion-toolbar {
		--background: transparent;
		&.toolbar-heading {
			--padding-end: 18.55px;
			--padding-start: 0;

			.left-btns {
				.back-button {
					width: 50px;
					height: 50px;
					// padding-top: 15px;
				}
				.dot-circle {
					margin-bottom: auto;
				}
			}
		}

		&.toolbar-searchbar {
			--min-height: unset;
			margin-bottom: 10px;
			margin-top: 2px;
		}
	}

	.toolbar-title-default {
		padding-top: 34px;
		--min-height: 54px;
	}

	ion-searchbar {
		height: 36px;

		ion-icon {
			left: 12px !important;
			color: #a5a5a5 !important;
		}
		input {
			padding-left: 52px !important;
		}
	}
	.searchbar-wrapper {
		--background: #ffffff;
		padding-bottom: 0;
		--border-radius: 5px !important;

		.searchbar-input-container {
			border-radius: 5px !important;
			display: flex;
			align-items: center;
			justify-content: center;

			.searchbar-input {
				font-size: 16px;
				line-height: 16px;
				display: flex;
				justify-content: center;
				color: #000000;

				&::placeholder {
					text-align: center;
					color: #a5a5a5;
				}

				.searchbar-search-icon {
					align-items: center;
				}
			}
		}
	}
	.toolbarTitle {
		text-align: center;
		padding: 0;

		.title {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			min-height: 20px;
			padding-top: 7px;
			text-align: center;
			color: #000000;
		}
		.description {
			margin-top: 3px;
			font-family: Metropolis;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			min-height: 14px;
			text-align: center;
			color: #585858;
		}

		.loading-view {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			ion-label {
				color: #000000;
				font-size: 17px;
				font-weight: 500;
				margin-left: 20px;
			}
		}
	}

	.sc-ion-buttons-ios-s ion-button {
		height: 40px;
		// padding-top: 19px;
	}

	ion-button {
		text-transform: none;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 21px;
		text-align: center;
		letter-spacing: -0.333333px;
		color: #000000;
		--padding-start: 0px;
		--padding-end: 0px;
		ion-img {
			width: 40px;
			height: 40px;
		}
		// ion-icon {
		// 	font-size: 22px !important;
		// }
		&.search-icon-btn {
			width: 18px;
			height: 20px;
			margin-right: 15px;
			margin-top: 4px;
			ion-icon {
				fill: #000;
			}
		}
		&.search-icon-btn-active {
			margin-top: 4px;
			ion-icon {
				fill: #ff5e5e;
				stroke: #ff5e5e;
			}
		}
	}
}
.popover-wrapper.sc-ion-popover-md {
	left: 413 px;
}
