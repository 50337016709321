.emoji-popup-container-modal {
	width: 100%;
	height: 100%;
	.modal-wrapper {
		background: transparent;
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		height: 100% !important;
	}

	.emoji-popup-container {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(var(--app-modal-bg-color-code), 0.95);
		z-index: 99999;
		&.enlarge {
			display: flex;
			align-items: center;
			justify-content: center;
			backdrop-filter: blur(20px);

			ion-grid {
				// position: relative !important;

				top: 0 !important;
				bottom: 0 !important;
				left: 0 !important;
				right: 57px !important;
				min-width: 100vw !important;
				justify-content: center !important;
			}

			.message {
				max-height: auto !important;
			}
			.message * {
				max-height: unset !important;
			}
			.message,
			.message * {
				max-width: 100% !important;
				margin-left: 0 !important;
				padding: 10px 5vw !important ;
				font-size: 30px !important;
			}
		}

		ion-grid {
			padding: 0px;
			right: 8px !important;
			.emoji-popover-row {
				width: 100%;
				display: flex;
				flex-direction: row;
				padding: 8px 0;
				justify-content: flex-end;
				max-width: 416px;
				margin-left: 20px;
				// background-color	: red;
				margin-right: 20px;
				s .popup-item {
					width: 54px;
					height: 54px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					// margin-right: 5px;
					&:last-child {
						margin-right: 0;
					}
					ion-text {
						display: none;
					}
				}
			}
			.main-row {
				display: flex;
				flex-direction: row;
				margin-left: 14px;
				.user-image {
					width: 40px;
					height: 40px;
					border-radius: 3px;

					.image {
						border-radius: 3px;
					}
					ion-image {
						border-radius: 3px !important;
					}
				}

				.name-msg {
					display: flex;
					flex-direction: column;
					margin-left: 12px;
					justify-content: center;
					max-width: 80%;

					.name {
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 700;
						font-size: 18px;
						line-height: 18px;
						color: #000000;
					}
					.message {
						span {
							&.emoj-xl {
								font-size: 40px !important;
								line-height: unset !important;
								display: inline-block;
								vertical-align: middle;
							}
							&.emoj-lg {
								font-size: 30px !important;
								line-height: unset !important;
								display: inline-block;
								vertical-align: middle;
							}
							&.emoj-md {
								font-size: 26px !important;
								line-height: unset !important;
								display: inline-block;
								vertical-align: middle;
							}
						}
					}
				}
			}
			.message,
			.message * {
				font-size: 16px;
				font-weight: 500;
				color: #000;
				line-height: 1.4;
				white-space: pre-line;
				overflow: auto;
				-webkit-touch-callout: all; /* iOS Safari */
				-webkit-user-select: all; /* Safari */
				-khtml-user-select: all; /* Konqueror HTML */
				-moz-user-select: all; /* Firefox */
				-ms-user-select: all; /* Internet Explorer/Edge */
				user-select: all;
				&::-moz-selection {
					background: rgba(255, 94, 94, 0.3) !important;
				}

				&::selection {
					background: rgba(255, 94, 94, 0.3) !important;
				}
				// }
				.image-file {
					border-radius: 5px;
					overflow: hidden;
					max-width: 200px;
					max-height: 180px;
					object-fit: contain;
				}
				ion-img::part(image) {
					height: auto !important;
				}
			}
		}

		&.left {
			.message {
				text-align: left;
			}
			.emoji-popover-row {
				justify-content: flex-start;
			}
		}
		&.right {
			.message {
				text-align: right;
				padding-right: 10px;
				height: auto;
				max-height: 455px !important;
				overflow-y: scroll !important;
			}
			::-webkit-scrollbar {
				display: none;
			}
			.emoji-popover-row {
				justify-content: flex-end;
			}
		}
	}
}
