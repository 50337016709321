.voicerecord-modal {
	--background: rgba(239, 239, 239, 0.5);
	.modal-wrapper {
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		height: 100% !important;
	}
	&::before {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		content: '';
		background: linear-gradient(180deg, #efefef 40.19%, rgba(239, 239, 239, 0) 100%);
		transform: rotate(-180deg);
	}
	.ion-page {
		justify-content: flex-end;
		align-items: center;

		.content {
			width: 100%;
			.recording-info-view {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin-bottom: 80px;

				.waves {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;

					.wave-line {
						background: #585858;
						border-radius: 5px;
						width: 4.27px;
						height: 47px;
						margin: 0 1.07px;
						min-height: 12px;
						max-height: 48px;
					}
				}

				ion-img {
					width: 158px;
					height: 47px;
					object-fit: contain;
				}
				.duration {
					margin-top: 45px;
					font-size: 20px;
					line-height: 116%;
					text-align: center;
					color: #858484;
				}
			}
			.actions-btns {
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: center;
				margin-top: -30px;
				position: absolute;
				z-index: 9;
				.record-btn {
					width: 60px;
					height: 60px;
					margin: 0 44px;
					--padding-start: 0;
					--padding-end: 0;
					ion-icon {
						width: 100%;
						height: 100%;
					}
				}
				.action-fab-btn {
					margin: 0;
					height: 84px;
					&:last-child {
						margin-top: 20px
					}
					.container {
						pointer-events: none;
						width: 84px;
						height: 84px;
						display: flex;
						margin-top: 8px;
						align-items: center;
						justify-content: center;
						position: relative;

						img {
							position: absolute;
							width: 100%;
							height: 100%;
							object-fit: contain;
						}
					}
					&.close {
						ion-icon {
							width: 24px;
							height: 24px;
						}
					}
					&.txt {
						ion-icon {
							width: 32px;
							height: 23px;
						}
					}
				}

				.convert-btn-progress-container {
					display: flex;
					flex-direction: column;
					align-items: center;
					margin-top: -12px;
					.title {
						position: absolute;
						font-family: Metropolis;
						font-style: normal;
						font-weight: normal;
						font-size: 14px;
						line-height: 16px;
						color: #858484;
					}
					.convert-btn-progress {
						margin: 0;
						margin-top: 8px;
						height: auto;

						.container {
							pointer-events: none;
							width: 84px;
							height: 84px;
							display: flex;
							margin-top: 8px;
							align-items: center;
							justify-content: center;
							position: relative;

							.circle-progress {
								width: 72px;
								height: 72px;
								position: absolute;
								z-index: 9;
								transform: rotate(180deg);

								span {
									display: none;
								}
							}

							img {
								position: absolute;
								width: 100%;
								height: 100%;
								object-fit: contain;
							}

							ion-icon {
								width: 25px;
								height: 23px;
							}
						}
					}
				}
			}
			.bottom-mic-view {
				width: 100%;
				max-width: 700px;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: auto;
				.bottom-mask {
					width: 100%;
				}
				.mic-btn {
					position: absolute;
					bottom: 50px;
					height: auto;
					margin: 0;
					ion-icon {
						height: 30px;
						width: 18.42px;
					}
				}

				.recorded-view-btn {
					position: absolute;
					margin: 0;
					margin-top: 53px;
					height: auto;
					--background-hover: transparent;

					.recorded-view {
						display: flex;
						flex-direction: column;
						align-items: center;
						.title {
							font-size: 14px;
							font-weight: 400;
							line-height: 16px;
							color: #858484;
							text-transform: none;
						}
						ion-icon {
							width: 18.42px;
							height: 30px;
							margin-top: 17px;
						}
					}
				}
			}
		}
	}
}
