/* DOES NOT WORK - not specific enough */
.action-sheet-group {
	background: #e5e5e5 !important;
	--button-background: #e5e5e5 !important;
	--button-background-selected: #e5e5e5 !important;
}
.chat-only-msg {
	height: 120px;
	font-family: 'Metropolis';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	color: #585858;
	padding: 20px;
}

.upload-photo-option-action-sheet {
	.action-sheet-container {
		padding: 0;
	}

	.action-sheet-group {
		padding-bottom: 0;
		border-radius: 10px 10px 0 0;
		margin-bottom: 0;
		padding-top: 12px;

		&.action-sheet-group-cancel {
			padding-top: 8px;
			padding-bottom: 44px !important;
			padding-top: 20x !important;
		}
	}

	.action-sheet-button {
		text-align: center;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 21px;
		color: #000000;
		padding-top: 26px;
		padding-bottom: 37px;
	}

	.action-sheet-button.sc-ion-action-sheet-ios:last-child {
		margin: 0;
		color: black;
	}
	.action-sheet-group-cancel {
		border-radius: 0;
		padding-top: 0;
		padding-bottom: 0;
	}

	.action-sheet-destructive {
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 18px;
		text-align: center;
		color: #ff5e5e;
	}
}

.main-content-profile {
	--background: var(--app-color-code);
	// padding-bottom: 60px !important;
	ion-item {
		--background: var(--app-color-code);
		--margin-left: 14px;
		--padding-start: 23px;
	}
}

.profile-page {
	.topbar-header .toolbarTitle .title {
		font-weight: 600;
	}
	ion-content.has-bottom-navbar {
		--padding-bottom: 0 !important;
	}

	ion-content {
		.blank {
			display: flex;
			flex: 1;
			min-height: 124px;
		}
		.add-button {
			width: 220px;
			height: 48px;
			--background: #ff5e5e;
			margin-top: 170px;
			margin-bottom: inherit;
			--border-radius: 10px;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 18px;
			text-align: center;
			text-transform: none;
			color: #e8e8e8;
		}
		.cancel {
			width: 100%;
			height: 70px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 16px;
			text-align: center;
			cursor: pointer;
			color: #585858;
		}
		.inner-content {
			display: flex;
			flex-direction: column;
			min-height: 100%;
			.block-msg {
				display: flex;
				align-items: center;
				width: 100%;
				height: 66px;
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 18px;
				color: #585858;
				.not-recived {
					width: 26px;
					height: 26px;
					padding-top: 20px;
					padding-bottom: 20px;
					padding-left: 17px;
				}
				.block-text {
					margin-left: 13px;
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 18px;
					color: #585858;
					span {
						color: #ff5e5e;
						font-weight: 600;
						font-size: 14px;
					}
				}
			}
			.qrimage {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				margin-top: 51px;
			}

			.user-name-item {
				display: flex;
				flex-direction: column;
				--padding-start: 0;
				margin: 9px 16px 0px;
				margin-right: 0;
				cursor: pointer;
				align-items: flex-start;

				.profile-row {
					width: 100%;
					display: flex;
					align-items: center;
					flex-direction: row;

					.profile-photo {
						width: 120px;
						height: 120px;
						margin: 0px;
						border-radius: 5px;
						overflow: hidden;
					}

					.half-rounded-btns {
						flex: 1;
						display: flex;
						align-items: flex-end;
						justify-content: flex-end;
						flex-direction: column;
						ion-button {
							--border-radius: 44px 0 0 44px;
							--padding-start: 24px;
							--padding-end: 24px;
							--border-width: 2px 0 2px 2px;
							font-size: 18px;
							height: 55px;
							width: 80px;
							&.wallet {
								--background: #ff5e5e;
							}
							&.favorite {
								--border-color: #ff5e5e;
								--color: #ff5e5e;
							}
						}
					}
				}

				.name {
					margin-top: 15px;
					line-height: 38px !important;
					font-weight: 700;
					font-size: 26px;
					line-height: 26px !important;
				}

				.usernametxt {
					margin: 0;
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 16px;
					color: #858484;
					margin-top: 13px;
				}

				.professiontxt {
					margin: 0;
					font-size: 16px;
					color: #858484;
					margin-top: 10px;
					//   line-height: 28px;
				}

				.region {
					font-size: 14.5px;
					color: #858484;
					margin-bottom: 9px;
					margin-top: 10px;
					//   line-height: 28px;
				}

				.quote {
					margin: 0;
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 400;
					font-size: 14.5px;
					line-height: 18px;
					letter-spacing: -0.025em;
					color: #000000;
					margin-top: 6px;
					max-width: 300px;
				}
				.website {
					margin: 0;
					color: black;
					margin-top: 2px;
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 400;
					font-size: 14.5px;
					line-height: 18px;
					letter-spacing: -0.025em;
					color: #100072;
				}
			}

			.moment-label {
				font-size: 14px;
				font-weight: 400;
				margin-top: 0px;
				margin-top: 8px;
				margin-bottom: 8px;
				margin-left: 16px;
				color: #585858;
			}

			.starred-wrapper {
				--padding-end: 0;
				--padding-start: 16px;
				margin-bottom: 0px;
				.starred {
					min-width: 62px;
					width: 62px;
					height: 62px;
					margin-right: 10px;
					ion-thumbnail {
						width: 100%;
						height: 100%;
					}
				}
			}

			.my-qr-view {
				flex: 1;
				margin: 62px auto;
				align-items: center;
				display: flex;
				flex-direction: column;
				justify-content: center;
				p {
					color: #000000;
					text-align: center;
					margin-top: 0;
					margin-bottom: 10px;
					font-size: 14px;
					font-weight: 400;
					text-transform: uppercase;
				}
				img {
					width: 200px;
					height: 200px;
					display: inline-block;
					margin: 0 auto;
					cursor: pointer;
					border-radius: 8px !important;
				}
			}
		}
	}

	.communication-btns-view {
		display: flex;
		flex: 1;
		min-height: 50px;
		justify-content: center;
		flex-direction: column;
		.blanck {
			min-height: 150px;
			width: 100%;
			flex: 1;
		}
		.container {
			width: 100%;
			height: 100px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: row;
			.media-icon-container-message {
				width: 72px;
				height: 72px;
				display: flex;
				margin-left: 57px;
				margin-right: 57px;
				justify-content: center;
				align-items: center;
				.media-icon-message {
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.media-icon-video {
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.media-icon {
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				ion-icon {
					width: 34px;
					height: 32px;
				}
			}
		}
		// flex: 1;
		// margin: 0px auto;
		// align-items: flex-end;
		// display: flex;
		// flex-direction: row;
		// justify-content: space-around;
		// padding-bottom: 20px;
		// width: 80%;
		ion-button {
			font-size: 40px;
			--padding-start: 0;
			--padding-end: 0;
			height: auto;
		}
		.searchid-container {
			width: 100%;
			height: 40px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-top: 30px;
			margin-bottom: 46px;
			.searchid {
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 18px;
				text-align: center;
				color: #858484;
			}
		}
	}

	.friendrequest-view-bottom-content {
		display: flex;
		align-items: center;
		flex: 1;
		flex-direction: column;

		.action-btn {
			width: 50%;
			height: 48px;
			margin: 0;
			margin-top: 66px;
			--border-radius: 10px;
			--background: #ff5e5e;
			--box-shadow: none !important;
			text-transform: none;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 18px;
			text-align: center;
			color: #e8e8e8;
		}
		.personal-msg {
			// margin: 0px 20px;
			width: calc(100% - 48px);
			background-color: #e8e8e8;
			border-radius: 3px;
			padding: 11px;
			font-size: 13px;
			font-weight: 400;
			color: #585858;
		}
		.block-report {
			height: 70px;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;

			.block-report-btns {
				// margin-bottom: 16px;
				ion-button {
					text-transform: none;
					// font-size: 14px;
					// line-height: 26px;
					--color: #535353;
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 16px;
					text-align: center;
				}
			}
		}
	}
}

.profile-edit-page {
	ion-content.has-bottom-navbar {
		--padding-bottom: 0 !important;
	}
	.blanck {
		min-height: 100px;
		flex: 1;
	}
	.view-as-container {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-bottom: 100px;
		.view-as {
			width: 200px;
			height: 48px;
			--background: #e6e6e6;
			border-radius: 5px;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 18px;
			display: flex;
			align-items: center;
			letter-spacing: -0.015em;
			color: #858484;
		}
	}
	ion-list {
		padding: 0;
		background: transparent;
		p {
			padding: 0;
		}
	}

	.save-cancel-btns {
		.edit-cancel-btn {
			--background: #dcdcdc;
			border-radius: 5px;
			color: #000000;
			height: 30px;
			min-width: 55px;
		}

		.edit-save-btn {
			--background: #ff5e5e;
			--color: #e8e8e8;
			height: 30px;
			width: 55px;
		}
	}
}

.my-qrcode-page {
	background-color: var(--app-color-code);
	overflow-y: auto;

	.scan-btn {
		height: auto !important;
		width: auto !important;
	}
	.qrcode-type-btns-container {
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		text-align: center;
		ion-button {
			color: #858484;
			font-size: 14px;
			height: 24px;
			&.active {
				color: #000000;
				padding-bottom: 0;
				border-bottom: 2px solid #000;
			}
		}
	}

	.qr-share-option-btns {
		text-align: center;
		width: 80vw;
		ion-icon {
			width: 25px;
			height: 25px;
		}
	}

	.inner-content {
		display: flex;
		flex-direction: column;
		min-height: 100%;
		align-items: center;
		justify-content: center;

		.usernametxt {
			font-weight: normal;
			font-size: 30px;
			line-height: 34px;
			color: #000000;
		}
	}
}

.upload-photo-page {
	height: 100%;
	width: 100%;
	background-color: #000000;
	overflow-y: auto;
	height: auto;
	.topbar-header .toolbarTitle .title {
		color: white;
	}

	.upload-chatonly-header {
		.toolbarTitle .title {
			color: black !important;
		}
		ion-toolbar {
			--background: #efefef;

			ion-icon,
			.toolbarTitle {
				color: white;
				stroke: white;
			}
		}
	}
	.upload-chat-only {
		--background: #efefef;
	}
	.upload-chatonly-toolbar {
		// --background: #efefef;
		color: black;
	}
	.chat-only {
		width: 100%;
		height: 120px;
		padding: 20px;
		margin-top: 105px;
		margin-bottom: 16px;
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 20px;
		text-align: center;
		color: #585858;
	}
	.topbar-header {
		background: transparent;
		.toolbarTitle {
			font-size: 18px;
			font-weight: 400;
		}
		.left-btns {
			ion-icon {
				font-size: 16px !important;
			}
		}
	}
	.inner-scroll.scroll-y {
		display: flex;
		align-items: center;
	}
	ion-content.has-bottom-navbar {
		--padding-top: none !important;
		--padding-bottom: none !important;
		display: flex;
		align-items: center;
		.inner-scroll.scroll-y {
			display: flex;
			align-items: center;
		}
	}

	ion-content {
		height: 50%;
		.swiper-container {
			width: 100%;
			height: calc(100% - 200px);
			margin-top: 100px;
		}
		.inner-scroll.scroll-y {
			display: flex;
			align-items: center;
		}

		inner-scroll scroll-y {
			--overflow-y: hidden !important;
			--padding-bottom: none !important;
		}

		.inner-scroll {
			.scroll-y {
				--overflow-y: hidden !important;
				--padding-bottom: none !important;
			}
		}
		--background: #000;
		ion-slide {
			width: 100% !important;
			height: 100% !important;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
		}
	}

	ion-toolbar {
		--background: black;

		ion-icon,
		.toolbarTitle {
			color: white;
			stroke: white;
		}
	}

	.profile-placeholder-container {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
		position: relative;
		background: #000000;
		// margin-top: 241px;
		// margin-bottom: 241px;
		.back-icon {
			position: absolute;
			z-index: 99999999;
			width: 80px;
			height: 100%;
			opacity: none;
			display: flex;
			align-items: center;
			left: 0px;
			opacity: 0;
		}
		.next-icon {
			position: absolute;
			z-index: 99999999;
			width: 80px;
			height: 100%;
			opacity: none;
			display: flex;
			align-items: center;
			right: 0px;
			opacity: 0;
		}

		.profile-placeholder {
			width: 100%;
			height: 100%;
			max-width: 100vh;
			max-height: 100vh;
			margin: auto 0;
			object-fit: contain;
		}

		.page-title {
			margin-bottom: 40px;
			position: absolute;
			width: 100%;
			text-align: center;
		}
	}

	ion-list {
		background: transparent;
		ion-item {
			--min-height: 55px;
		}
		.cancel-btn {
			margin-top: 20px;
		}
	}

	.action-sheet-wrapper {
		margin: 0 !important;
		min-width: 100% !important;
		.action-sheet-group {
			padding-bottom: 44px !important;
			.action-sheet-title {
				background: none;
			}
		}
	}
}

.upload-photo-chatonly {
	background-color: #000000;
	overflow-y: auto;

	.topbar-header {
		background: #efefef;
		.toolbarTitle {
			font-size: 18px;
			font-weight: 400;
		}
	}

	.profile-placeholder-container {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 414px;
		width: 100%;
		position: relative;
		background: #efefef;
		.back-icon {
			position: absolute;
			z-index: 99999999;
			width: 80px;
			height: 74%;
			opacity: none;
			display: flex;
			align-items: center;
			left: 10px;
			opacity: 0;
		}
		.next-icon {
			position: absolute;
			z-index: 99999999;
			width: 80px;
			height: 74%;
			opacity: none;
			display: flex;
			align-items: center;
			right: 10px;
			opacity: 0;
		}

		.profile-placeholder {
			object-fit: contain;
			width: 100%;
			height: 100%;
			max-width: 100vh;
			max-height: 100vh;
			margin: auto 0;
			// background-color: var(--app-color-code);
		}

		.page-title {
			margin-bottom: 40px;
			position: absolute;
			width: 100%;
			text-align: center;
		}
	}

	.blanck {
		min-height: 200px;
		width: 100%;
		flex: 1;
	}
	.topbar-header {
		color: black;
		--background: #efefef;
	}

	.buttons {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 60px;
		margin-bottom: 90px;
		.add-button-buttom {
			margin: 0;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 18px;
			text-align: right;
			--color: #ffffff;
			width: 220px;
			height: 48px;
			--border-radius: 8px;
			--background: #ff5e5e;
			text-transform: none;
			--box-shadow: unset;
		}
		.another-button-buttom {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 16px;
			text-align: center;
			color: #585858;
			height: auto;
			--box-shadow: none;
			--background: none;
			margin-top: 27px;
			text-transform: capitalize;
		}
	}
}
.uoload-chatonly-header {
	.toolbarTitle .title {
		color: black;
	}
	ion-toolbar {
		--background: #efefef;

		ion-icon,
		.toolbarTitle {
			color: black;
			stroke: black;
		}
	}
}
.upload-chat-only {
	--background: #efefef;
}
.upload-chatonly-toolbar {
	// --background: #efefef;
	color: black;
}
.chat-only {
	width: 100%;
	height: 120px;
	padding: 20px;
	margin-top: 105px;
	margin-bottom: 16px;
	font-family: 'Metropolis';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	color: #585858;
}

ion-content {
	--background: white;
	ion-slide {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
}

ion-toolbar {
	--background: #efefef;

	ion-icon,
	.toolbarTitle {
		color: black;
		// stroke: #fff;
	}
}

ion-list {
	background: transparent;
	ion-item {
		--min-height: 55px;
	}
	.cancel-btn {
		margin-top: 20px;
	}
}

.action-sheet-wrapper {
	margin: 0 !important;
	min-width: 100% !important;
	.action-sheet-group {
		padding-bottom: 44px !important;
		.action-sheet-title {
			background: none;
		}
	}
}

.profile-label {
	p {
		padding: 0;
	}
}
.profile-top-col-2 {
	text-align: -webkit-center;
}
.profile-list-sec2 {
	padding: 0;
	p {
		padding: 0;
	}
}
.ct-icon-heart {
	color: #ff5e5e !important;
}
.profile-divider {
	background: #ffffff;
	height: 1px;
	min-height: 2px;
	padding: 0 0;
	border: none;
}
.button-logout {
	margin: 0;
	--background: #d8d8d8 !important;
	cursor: pointer;
	button {
		.item-inner {
			ion-icon {
				opacity: 0;
			}
		}
	}
}

.qr-code-sec {
	text-align: center;
	ion-img {
		img {
			display: inline-block;
			width: auto !important;
		}
	}
}
.starred-wrapper {
	width: 100%;
	overflow-x: auto;
	display: flex;
}
.moment-label {
	text-align: -webkit-center;
	display: flex;
	align-items: center;
}
.sec-3-label {
	font-size: medium;
	color: #404346 !important;
	padding: 0 20px;
}
.footer-grid {
	text-align: center;
}
.profile-footer-button {
	margin: 20px;
	ion-icon {
		color: #fff;
	}
}
.button-grid {
	background-color: #000000;
}
.bottom-label {
	color: #fff;
}
.profile-thumb-user {
	--border-radius: 3px;
	height: 90px;
	width: 90px;
}
.profile-thumb-user-edit {
	--border-radius: 3px;
	height: 60px;
	width: 60px;
}
.thumb-user-edit {
	height: 30px;
	width: 30px;
}
.edit-profile-p {
	color: #000000;
}
.upload-photo-text {
	text-align: center;
}
.upload-canvas {
	max-width: 100%;
	max-height: 100%;
}
.edit-profile-info {
	background-color: #d8d8d8;
}
.user_name {
	padding: 20px;
}
.upload-content-area {
	text-align: center;
	--background: #f1f1f1 !important;
}
ion-content {
	#background-content {
		background-color: var(--app-color-code) !important;
	}
}
.mainContent-profile {
	position: relative;
	.wrap-div {
		transform: translate(-50%, -50%);
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
	}
}
.noDetailIcon {
	.item-detail-icon {
		opacity: 0 !important;
	}
}
@media screen and (min-width: 630px) {
	.mainContent-profile {
		.wrap-div {
			width: 60%;
		}
	}
}

.my-dropdown-popover-style {
	--background: var(--app-color-code);
	--max-width: 70vw;
	--width: auto;
	--box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
	--ion-safe-area-right: 10px;

	.popover-content {
		top: 98px !important;
		padding: 90;
		// left: 323.312px !important;
		background: #efefef;
		box-shadow: -10px -10px 30px #ffffff, 10px 10px 30px rgba(174, 174, 192, 0.4);
		border-radius: 12px;

		ion-list {
			padding-top: 10px;
			padding-right: 10px;
			padding-bottom: 8px;
			background: transparent;
			ion-item {
				--min-height: 55px;
				--padding-start: 0;
				--padding-end: 20px;
				--inner-padding-end: 0;
				.icon-container {
					width: 40px;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-left: 12px;
					margin-right: 12px;
					ion-icon {
						padding-top: 1px;
						padding-bottom: 2px;
						font-size: 20px;
					}
				}

				--background: transparent;
				.buy-storage {
					width: 133px;
					height: 33px;
					display: flex;
					justify-content: center;
					align-items: center;
					background: #ff5e5e;
					border-radius: 5px;
					margin-bottom: 26px;
					margin-left: 26px;
					margin-right: 26px;
					top: 0;
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 18px;
					text-align: center;
					color: #efefef;
					text-transform: capitalize;
				}

				ion-label {
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 400;
					font-size: 18px;
					line-height: 18px;
					color: #585858;
				}
			}
		}
	}
}
