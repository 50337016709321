.favourite-sticker-remove-modal {
	ion-backdrop {
		background: rgba(0, 0, 0, 0.4);
	}
	.modal-wrapper {
		background: transparent;
		--min-height: 100%;

		.ion-page {
			justify-content: flex-end;
		}
		.content {
			background: #efefef;
			backdrop-filter: blur(40px);
			height: 100%;
			display: flex;
			flex-direction: column;
			.header {
				margin-top: 46px;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				.title {
					font-family: Metropolis;
					font-size: 20px;
					font-weight: 500;
					line-height: 20px;
					letter-spacing: 0em;
					text-align: center;
					color: rgba(0, 0, 0, 1);
				}
				.subtitle {
					margin-top: 5px;
					font-family: Metropolis;
					font-size: 14px;
					font-weight: 400;
					line-height: 14px;
					letter-spacing: 0em;
					text-align: center;
					color: rgba(88, 88, 88, 1);
				}
			}

			.searchbar {
				width: 100%;
				padding: 0 16px;
				height: 36px;
				min-height: 36px;
				.searchbar-input-container {
					border-radius: 5px;
					background-color: #e6e6e6 !important;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;

					.searchbar-search-icon {
						left: 20px;
						width: 16px;
						top: unset;
					}
					.searchbar-clear-button {
						background-color: transparent !important;
						width: 46px;
						.searchbar-clear-icon {
							width: 18px;
						}
					}
					.searchbar-input {
						padding-inline-start: 45px;
						font-family: Metropolis;
						font-style: normal;
						font-weight: normal;
						font-size: 16px;
						line-height: 16px;
						color: #000000;
					}
					input,
					ion-icon {
						background-color: transparent !important;
					}
				}
			}

			.searching-view {
				margin-top: 10px;
				width: 100%;
				height: 30px;
				display: flex;
				align-items: center;
				flex-direction: row;
				padding: 0 26px;
				.search-text {
					margin-left: 9px;
					font-family: Metropolis;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 14px;
					color: #585858;
				}
			}
			.content-title {
				width: 100%;
				font-family: Metropolis;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 14px;
				color: #000000;
				padding: 0 16px;
				margin: 19px 0;
			}
			.stickers-container {
				width: 100%;
				flex: 1;
				overflow-y: auto;
				padding: 16px;

				.giphy-gif {
					width: 83px;
					height: 83px;
					display: flex;
					align-items: flex-end;
					.giphy-close-icon {
						width: 22px;
						height: 22px;
						position: absolute;
						top: 3px;
						right: 3px;
						border-radius: 50%;
						border: 2px solid #fff;
						background: rgba(0, 0, 0, 0.15);
					}
					.giphy-close-icon-checked {
						width: 22px;
						height: 22px;
						position: absolute;
						top: 3px;
						right: 3px;
						border-radius: 50%;
						background: #fff;
					}
				}
				// a {
				// 	max-width: 64px !important;
				// 	max-height: 64px !important;
				// 	div,
				// 	img {
				// 		max-width: 64px !important;
				// 		max-height: 64px !important;
				// 	}
				// }
			}
			.bottom-buttons {
				background-color: rgba(239, 239, 239, 1);
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				padding: 0 17px;
				padding-top: 0;
				padding-bottom: 30px;
				.close-btn {
					--padding-start: 0;
					--padding-end: 0;
					margin: 0;
					font-family: Metropolis;
					font-size: 18px;
					font-weight: 400;
					line-height: 18px;
					letter-spacing: 0em;
					color: rgba(0, 0, 0, 1);
				}
				.delete-btn {
					--padding-start: 0;
					--padding-end: 0;
					margin: 0;
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 18px;
					color: #ff5e5e;
					&.button-disabled {
						opacity: 1;
						color: rgba(196, 196, 196, 1);
					}
				}
			}
		}
	}
}
