.project-created-modal {
	.modal-wrapper {
		background: rgba(var(--app-modal-bg-color-code), 0) !important;
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		height: 100% !important;
	}
	.ion-page {
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(128, 255, 0, 0.1);
		backdrop-filter: blur(25px);
	}

	.content-view {
		width: 300px;
		background: rgba(255, 255, 255, 0.6);
		backdrop-filter: blur(20px);
		border-radius: 10px;
		box-sizing: border-box;
		display: flex;
		overflow: hidden;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 40px 36px;
	}

	.project-created-component {
		.celender-row {
			display: flex;
			justify-content: space-between;
			margin-right: 29px;
			.blank {
				display: flex;
				flex: 1;
			}

			.celender-container {
				width: 68px;
				height: 68px;
				background: #ffffff;
				display: flex;
				align-items: center;
				flex-direction: column;
				background: #ffffff;
				border-radius: 9px;
				justify-content: center;
				.FEb-month {
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 700;
					font-size: 14px;
					line-height: 14px;
					color: #ff5e5e;
					text-align: center;
					margin-top: 6.38px;
				}
				.date {
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 400;
					font-size: 40px;
					line-height: 40px;
					color: #000000;
					margin-top: 5px;
					text-align: center;
				}
			}
		}
		.project-image-container {
			display: flex;
			flex-direction: column;
			padding-left: 28px;
			padding-right: 28px;
			margin-top: 6px;
			.deadline-row {
				display: flex;
				justify-content: space-between;
				.deadline {
					width: 50%;
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 600;
					font-size: 20px;
					line-height: 20px;
					color: #ff5e5e;
				}
				.project-time {
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 18px;
					/* identical to box height */

					text-align: center;

					color: #000000;
				}
			}
			.image-container {
				display: flex;
				margin-top: 4px;
				.image-thumbnail {
					width: 100%;
					height: 170px;
					background: #c4c4c4;
					border-radius: 10px;
					img {
						border-radius: 10px;
					}
				}
			}
		}
		.group-detail-container {
			height: 88px;
			display: flex;
			background: #efefef;
			backdrop-filter: blur(2px);
			border-radius: 10px;
			margin-top: 17px;
			margin-left: 28px;
			margin-right: 28px;
			padding-left: 12px;
			.group-image {
				padding-top: 12px;
				width: 30px;
				height: 30px;
				// background: #efefef;
				// backdrop-filter: blur(2px);
				border-radius: 10px;
			}
			.name-note-contaoner {
				display: flex;
				flex-direction: column;
				padding-top: 11px;
				padding-bottom: 8px;
				margin-left: 13px;
				.group-name {
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 400;
					font-size: 18px;
					line-height: 18px;
					color: #000000;
				}
				.note-container {
					margin-top: 10px;
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 14px;
					color: #858484;
					margin-right: 15;
				}
			}
		}
		.add-task-container {
			width: 170px;
			height: 170px;
			display: flex;
			justify-content: space-between;
			margin-left: 28px;
			background: #efefef;
			backdrop-filter: blur(2px);
			border-radius: 10px;
			margin-top: 16px;
			padding-right: 17px;
			.title {
				padding-left: 12px;
				padding-top: 14px;
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 700;
				font-size: 16px;
				line-height: 16px;
				/* identical to box height */

				color: #000000;
			}
			.plus-icon {
				width: 20px;
				height: 20px;
				padding-top: 12px;
			}
		}
		.open-close-container {
			width: 100%;
			height: 170px;
			//   background: #efefef;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			.open-text {
				width: 170px;
				height: 170px;
				display: flex;
				justify-content: space-between;
				margin-left: 28px;
				background: #efefef;
				backdrop-filter: blur(2px);
				border-radius: 10px;
				margin-top: 16px;
				padding-right: 17px;
				.title {
					padding-left: 12px;
					padding-top: 14px;
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 700;
					font-size: 16px;
					line-height: 16px;
					/* identical to box height */

					color: #000000;
				}
				.plus-icon {
					width: 20px;
					height: 20px;
					padding-top: 12px;
				}
			}
			.close-text {
				width: 170px;
				height: 170px;
				display: flex;
				justify-content: space-between;
				margin-right: 26px;
				background: #efefef;
				backdrop-filter: blur(2px);
				border-radius: 10px;
				margin-top: 16px;
				padding-right: 17px;
				.title {
					padding-left: 12px;
					padding-top: 14px;
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 700;
					font-size: 16px;
					line-height: 16px;
					/* identical to box height */

					color: #000000;
				}
				.plus-icon {
					width: 20px;
					height: 20px;
					padding-top: 12px;
				}
			}
		}
	}
}

.new-project-modal {
	height: 100%;
	.topbar-header .sc-ion-buttons-ios-s ion-button {
		font-size: 18px;
		font-weight: 600;
	}

	.topbar-header {
		.toolbar-heading {
			--padding-start: 18px;
			--padding-end: 18px;
			--background: #efefef;

			.buttons-first-slot {
				ion-button {
					margin: 0;
					padding: 0 8px;
					text-transform: none;
					ion-icon {
						width: 20px !important;
						height: 20px !important;
					}
				}
			}
			.buttons-last-slot {
				justify-content: flex-end;
				ion-button {
					margin: 0;
					font-family: Metropolis;
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 18px;
					display: flex;
					align-items: center;
					text-align: right;
					--color: #ff5e5e;
					text-transform: none;
					&.button-disabled {
						--color: #c4c4c4;
					}
				}
			}
			ion-title {
				// .title {
				// font-family: Metropolis;
				// font-style: normal;
				// font-weight: 500;
				// font-size: 18px;
				// line-height: 18px;
				// text-align: center;
				// color: #000000;
				// }
				.description {
					margin-top: 6px;
					font-family: Metropolis;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 14px;
					text-align: center;
					color: #585858;
				}
			}
		}
	}
	.new-project-create-component {
		overflow: scroll;
		height: auto;
		.header-row {
			position: absolute;
			background-color: rgba(239, 239, 239, 0.7);
			backdrop-filter: blur(10px);
			width: 100%;
			z-index: 9999999;

			.header-text {
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 500;
				font-size: 24px;
				line-height: 24px;
				color: #000000;
				margin-left: 68px;
				margin-top: 58px;
				padding-bottom: 13px;
			}
		}
		.z-text {
			font-family: 'Metropolis';
			font-style: normal;
			color: #000000;
			font-weight: 500;
			font-size: 24px;
			line-height: 24px;
			margin-left: 68px;
			margin-top: 58px;
		}
		.card-title {
			font-size: 22px;
			font-weight: 900;
			color: black;
			text-align: center;
			width: 100%;
			padding: 16px 0;
			display: inline-block;
			text-transform: uppercase;
			ion-label {
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 14px;
				text-align: center;
				color: red;
			}
		}
		.nobutton {
			display: none;
		}
		.bottombutton {
			margin-top: 10px;
			display: flex;
			justify-content: space-around;
			--background: transparent;
			width: 100%;
			.cancel {
				width: 74px;
				height: 30px;
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 16px;
				background: rgba(184, 184, 184, 0.1);
				backdrop-filter: blur(35px);
				border-radius: 5px;

				text-transform: none;
				color: #000000;
			}
			.done {
				width: 64px;
				height: 30px;
				background: rgba(255, 94, 94, 0.4);
				backdrop-filter: blur(35px);
				border-radius: 5px;
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 16px;
				background: rgba(255, 94, 94, 0.4);
				backdrop-filter: blur(35px);
				border-radius: 5px;
				color: #000000;
				text-transform: none;
			}
		}
		.card {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 0;
			padding-bottom: 5%;
			overflow-y: hidden;
			flex-flow: wrap;
			border-radius: 7px;
			margin-top: 90px;
			margin-bottom: 90px;
			.field-item-project-image {
				width: 100%;
				height: 189px;
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-bottom: 6px;
				padding-top: 17px;
				padding-bottom: 17px;
				padding-right: 18px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.field-item {
				width: 100%;
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				min-height: 60px;
				// .createNote {
				// 	// margin-top: 30px;
				// }
				.notes {
					--padding-top: 20px;
					--padding-bottom: 0px;
				}
				.assignContact {
					--padding-bottom: 0px;
					--padding-top: 18px;
					display: flex;
					flex-direction: row;
					.profile_photo {
						width: 40px;
						height: 40px;
						border-radius: 4px;
					}
					.contact-profession-container {
						display: flex;
						flex-direction: column;
						margin-left: 5px;
						.contact-name {
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 400;
							font-size: 18px;
							line-height: 18px;
							color: #000000;
							padding-bottom: 5px;
						}
						.profession-name {
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 400;
							font-size: 14px;
							line-height: 14px;
							color: #858484;
							padding-top: 5px;
							padding-bottom: 5px;
						}
					}
				}
				// .tack-pic {
				// 	margin-top: 24px;
				// }

				.pdf-div {
					display: flex;
					flex-direction: column;
					width: 65px;
					height: 102px;
					justify-content: center;
					.label-text {
						font-size: 11px;
						text-align: center;
						margin-top: 6px;
						line-height: 12px;
					}
					.pdf-img {
						width: 50px;
						height: 50px;
						margin-left: 6px;
					}
				}
				.execl-div {
					display: flex;
					flex-direction: column;
					width: 130px;
					height: 102px;

					justify-content: center;
					.execl-img {
						width: 50px;
						height: 50px;
						margin-left: 31px;
					}
					.label-execl {
						font-size: 9px;
						text-align: center;
						margin-top: 6px;
						// line-height: 12px;
					}
				}
				.picp-img {
					width: 66px;
					height: 66px;
					margin-left: 14px;
					margin-bottom: 10px;
					margin-top: 17px;
				}
				.plusp-img {
					width: 66px;
					height: 66px;
					margin-left: 14px;
					margin-bottom: 10px;
					margin-top: 17px;
				}
				.date-container {
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 400;
					font-size: 20px;
					line-height: 20px;
					color: #a5a5a5;
					margin-left: 17px;
				}
				.time-container {
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 400;
					font-size: 20px;
					line-height: 20px;
					color: #a5a5a5;
					--padding-bottom: 0px;
					--padding-top: 20px;
				}

				.new-project-container {
					width: auto;
					min-width: 40px;
					display: flex;
					flex-direction: row;
					align-items: center;
					// justify-content: space-between;
					margin: 0;
					margin-right: 18px;
					img {
						background: #ffffff;
						border-radius: 4px;
						width: 40px;
						height: 40px;
						object-fit: cover;
					}
					.user-name {
						display: flex;
						flex-direction: column;
						padding-left: 13px;
						.name {
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 400;
							font-size: 18px;
							line-height: 18px;
							color: #000000;
						}
						.profession {
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 400;
							font-size: 14px;
							line-height: 14px;
							color: #858484;
							margin-top: 4.5px;
						}
					}
				}

				.title {
					font-family: Metropolis;
					font-style: normal;
					font-weight: 400;
					font-size: 18px;
					line-height: 18px;
					color: #000000;
					--padding-bottom: 0px;
					--padding-top: 20px;
				}
				.number {
					top: 34px;
					position: absolute;
					right: 0;
					font-size: 10px;
					color: #a5a5a5;
				}
				.button_deadline {
					display: flex;
					flex-direction: column;
					.deadline {
						--background: #a5a5a5;
						--background-hover: none;
					}
					.active {
						--background: #ff5e5e;
						--background-hover: none;
					}
				}
				.color-container {
					display: flex;
					justify-content: space-around;
					width: 100%;
					.color {
						width: 34px;
						height: 34px;
						border-radius: 4px;
						background: #00c2ff;
						margin-right: 14.6px;
					}
					.color1 {
						width: 34px;
						height: 34px;
						border-radius: 4px;
						background: #00ffc2;
						margin-right: 14.6px;
					}
					.color2 {
						width: 34px;
						height: 34px;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 4px;
						background: #80ff00;
						margin-right: 14.6px;
					}
					.color3 {
						width: 34px;
						height: 34px;
						border-radius: 4px;
						background: #faff00;
						margin-right: 14.6px;
					}
					.color4 {
						width: 34px;
						height: 34px;
						border-radius: 4px;
						background: #ff7133;
						margin-right: 14.6px;
					}
					.color5 {
						width: 34px;
						height: 34px;
						border-radius: 4px;
						background: #ff2e00;
						margin-right: 14.6px;
					}
					.color6 {
						width: 34px;
						height: 34px;
						border-radius: 4px;
						background: #f460e9;
					}
				}
			}
			.note {
				text-align: center;
			}

			.number115 {
				width: 100%;
				display: flex;
				justify-content: flex-end;
				height: 14px;
				margin-top: -7px;
				margin-right: 18px;
				.numberlast {
					font-size: 12px;
					color: #a5a5a5;
				}
			}
			.number115-note {
				width: 100%;
				display: flex;
				justify-content: flex-end;
				height: 14px;
				margin-right: 18px;
				.numberlast {
					font-size: 12px;
					color: #a5a5a5;
				}
			}
			.sc-icon-textarea-ios-h {
				--padding-bottom: 0px;
			}

			ion-item {
				--background: transparent;
				width: 100%;
				margin-right: 18px;
				--padding-start: 0px;
				ion-label {
					margin: 0;
				}
				&.task-item {
					width: 100%;
					--padding-top: 0;
					margin-bottom: 16px;
					--border-color: #a5a5a5;

					.thumbnail {
						display: flex;
						flex-direction: column;
						align-items: center;
						// min-height: 100%;

						img {
							width: 28px;
							height: 28px;
							margin-bottom: 4px;
							border-radius: 3px;
							overflow: hidden;
						}
						ion-text {
							color: #ff5e5e;
							font-size: 8px;
							font-weight: 400;
						}
					}

					.task-item-label {
						padding: 0 5px;
						.inner-item {
							--padding-start: 0;
							--border-color: #a5a5a5;

							ion-checkbox {
								--background: #dcdcdc;
								--background-checked: #dcdcdc;
								--border-color: #000000;
								--border-width: 1px;
								--border-color-checked: #000000;
								--border-radius: 3px;
								--checkmark-color: #ff5e5e;
								--checkmark-width: 1px;
								--size: 26px;
							}
						}
					}
				}
			}
		}
	}
}
.allRow {
	background-color: rgba(239, 239, 239, 0.7);
	backdrop-filter: blur(9px);
	position: absolute;
	bottom: 0px;
	width: 100%;
	height: 90px;
	z-index: 99999999;

	.btn-row {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		justify-content: space-between;
		padding-top: 21px;

		.cancel {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 18px;
			color: #000000;
			margin-left: 13px;
			background: none;
		}
		.create-task {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 18px;
			color: #ff5e5e;
			margin-right: 18px;
			background: none;
		}
		.active {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 18px;
			margin-right: 18px;
			color: #c4c4c4;
			background: none;
		}
	}
}
.my-custom-class {
	color: red;
}

.favorite-search-modal {
	.header {
		ion-title {
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 33px;

			color: #000000;
		}
	}

	ion-searchbar {
		--background: #ffffff;
		margin-top: 0px;
		--box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		padding-bottom: 0;
		--border-radius: 5px !important;
		.searchbar-input-container {
			border-radius: 5px !important;
		}
	}

	.tools-container {
		padding: 20px 4px;
		flex: 1;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-content: flex-start;
		.tool-item {
			text-align: center;
			padding: 16px;
			height: 40px;
			width: 25%;
		}
	}
}

.share-message-modal {
	.modal-wrapper {
		background: rgba(0, 0, 0, 0.5);
		// background: rgba(var(--app-modal-bg-color-code), 0.75) !important;
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		height: 100% !important;
	}
	.ion-page {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	ion-item {
		--background: transparent;

		&.contact-item {
			ion-thumbnail {
				width: 40px;
				height: 40px;
			}
			ion-label {
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 29px;
				color: #000000;
				margin-right: 12px;
			}
		}
		.native-textarea.sc-ion-textarea-md {
			height: 100% !important;
		}
		&.item-input {
			ion-textarea {
				background: #dcdcdc;
				border-radius: 5px;
				margin-top: 8px;
				min-height: 35px;
				max-height: 60px !important;
				--padding-start: 16px;
				// --padding-end: 16px;
				font-size: 14px;
				color: #979797;
				caret-color: #ff5e5e;
			}
		}
	}
	.content-view-container {
		background: var(--app-color-code);
		box-sizing: border-box;
		width: 320px;
		height: 260px;
		border-radius: 10px;
		display: flex;
		overflow: hidden;
		flex-direction: column;
		box-shadow: 0px 0px 10px grey, 0px 0px 0px;

		.send {
			height: 36px;
			width: 100%;
			margin-top: 20px;
			.title-label {
				padding: 11px 17px 7px;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 21px;
				letter-spacing: -0.333333px;
				color: #000000;
			}
		}

		.selected-contacts-forward {
			display: flex;
			flex-direction: row;
			height: 95px;
			align-items: center;
			// height: $selectedContactsViewHeight;
			overflow-x: auto;
			padding: 0;
			padding-left: 18px;
			/* Hide scrollbar for IE, Edge and Firefox */
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
			/* Hide scrollbar for Chrome, Safari and Opera */
			background: rgba(239, 239, 239, 0.7);

			&::-webkit-scrollbar {
				display: none;
			}
			.selected-contact-item {
				width: 40px !important;
				height: 40px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: flex-start;
				margin: 0;
				margin-right: 9px;
			}

			.contact-item {
				width: auto;
				min-width: 40px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: flex-start;
				margin: 0;
				margin-right: 10px;
				img {
					background: #ffffff;
					border-radius: 4px;
					width: 40px;
					height: 40px;
					object-fit: cover;
				}
			}
		}

		.selected-contacts-container {
			display: flex;
			flex-direction: row;
			height: 95px;
			align-items: center;
			// height: $selectedContactsViewHeight;
			overflow-x: auto;
			padding: 0;
			padding-left: 18px;
			/* Hide scrollbar for IE, Edge and Firefox */
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
			/* Hide scrollbar for Chrome, Safari and Opera */
			background: rgba(239, 239, 239, 0.7);

			&::-webkit-scrollbar {
				display: none;
			}
			.selected-contact-item {
				width: 50px !important;
				height: 50px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: flex-start;
				margin: 0;
				margin-right: 9px;
			}

			.contact-item {
				width: auto;
				min-width: 40px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: flex-start;
				margin: 0;
				margin-right: 10px;
				img {
					background: #ffffff;
					border-radius: 4px;
					width: 40px;
					height: 40px;
					object-fit: cover;
				}
			}
		}

		.contact-view {
			display: flex;
			height: 56px;
			width: 100%;
			padding-top: 8px;
			padding-bottom: 8px;
			padding-left: 18px;

			.profile-container {
				display: flex;
				.selected-image-container {
					.selected-image {
						width: 40px;
						height: 40px;
						--border-radius: 4px;
					}
				}

				.user-name {
					display: flex;
					flex-direction: column;
					padding-left: 13px;
					.name {
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 400;
						font-size: 18px;
						line-height: 18px;
						color: #000000;
					}
					.profession {
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						line-height: 14px;
						color: #858484;
						margin-top: 4.5px;
					}
				}
			}
		}

		.message {
			margin: 11px 0;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: -0.333333px;
			color: #585858;
			padding: 0 17px;
			height: 36px;
			.image-file {
				max-width: 100%;
				height: 100px;
				object-fit: cover;
				object-position: top;
			}
			.txt-msg {
				margin-top: 10px;
				display: inline-block;
			}
		}
		ion-item {
			--background: transparent;

			&.contact-item {
				ion-thumbnail {
					width: 40px;
					height: 40px;
				}
				ion-label {
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 29px;
					color: #000000;
					margin-right: 12px;
				}
			}
			.native-textarea.sc-ion-textarea-md {
				height: 100% !important;
			}
			&.item-input {
				ion-textarea {
					background: #dcdcdc;
					border-radius: 5px;
					margin-top: 0;
					min-height: 35px;
					max-height: 60px !important;
					--padding-start: 16px;
					// --padding-end: 16px;
					font-size: 14px;
					color: #979797;
					caret-color: #ff5e5e;
				}
			}
		}
	}
	.share-message-bottom-bar {
		display: flex;
		align-items: center;
		justify-content: space-around;
		.cancel {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 18px;
			text-align: center;
			letter-spacing: -0.333333px;
			color: #000000;
			text-transform: capitalize;
		}
		.send-button {
			text-transform: capitalize;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 18px;
			text-align: center;
			letter-spacing: -0.333333px;
			color: #ff5e5e;
		}
	}
	.shar-contact-username {
		width: 90%;
		height: 36px;
		padding-left: 17px;
		display: flex;
		align-items: center;
		color: #000000;
		font-size: 14px;
		font-weight: 400;
	}
	.content-view-container-active {
		background: var(--app-color-code);
		box-sizing: border-box;
		width: 320px;
		height: auto;
		border-radius: 10px;
		display: flex;
		overflow: hidden;
		flex-direction: column;
		box-shadow: 0px 0px 10px grey, 0px 0px 0px;
		ion-toolbar {
			.sc-ion-buttons-md-s ion-button:not(.button-round) {
				text-transform: capitalize !important;
				font-size: 18px;
			}
			padding: 0px 20px 0;
		}

		.send {
			width: 100%;
			height: 36px;
			margin-top: 20px;
			padding-left: 17px;
			display: flex;
			align-items: center;
			.title-label {
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 21px;
				letter-spacing: -0.333333px;
				color: #000000;
			}
		}

		.selected-contacts-container {
			display: flex;
			flex-direction: row;
			width: 50px;
			height: 56px;
			align-items: center;
			padding-top: 3px;
			padding-bottom: 3px;
			// height: $selectedContactsViewHeight;
			// overflow-x: auto;
			// padding: 0;
			// padding-left: 18px;
			margin-left: 17px;
			margin-right: 17px;
			/* Hide scrollbar for IE, Edge and Firefox */
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
			/* Hide scrollbar for Chrome, Safari and Opera */
			background: rgba(239, 239, 239, 0.7);

			&::-webkit-scrollbar {
				display: none;
			}

			.selected-contact-item {
				width: 50px;
				min-width: 50px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: flex-start;
				margin: 0;
				margin-right: 9px;
				img {
					background: #ffffff;
					border-radius: 4px;
					width: 40px;
					height: 40px;
					object-fit: cover;
				}
			}
		}
		// .selected-contacts-forward {
		// 	display: flex;
		// 	flex-wrap: wrap;
		// 	width: 100%;
		// 	height: 112px;
		// 	margin-right: 17px;
		// 	margin-left: 17px;

		// 	.selected-contact-item {
		// 		width: 50px !important;
		// 		height: 50px;
		// 		display: flex;
		// 		flex-direction: column;
		// 		align-items: center;
		// 		justify-content: flex-start;
		// 		margin: 0;
		// 		margin-right: 9px;
		// 	}
		// }

		.contact-view {
			display: flex;
			height: 56px;
			width: 100%;
			padding-top: 8px;
			padding-bottom: 8px;
			padding-left: 18px;

			.profile-container {
				display: flex;
				.selected-image-container {
					.selected-image {
						width: 40px;
						height: 40px;
						--border-radius: 4px;
					}
				}

				.user-name {
					display: flex;
					flex-direction: column;
					padding-left: 13px;
					.name {
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 400;
						font-size: 18px;
						line-height: 18px;
						color: #000000;
					}
					.profession {
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						line-height: 14px;
						color: #858484;
						margin-top: 4.5px;
					}
				}
			}
		}
		.sketch-image {
			width: 100%;
			height: 136px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding-top: 8px;
			padding-bottom: 8px;
			.sketch {
				object-fit: contain;
				height: 100%;
				border-radius: 4px;
				overflow: hidden;
			}
		}
		.forwordedText {
			width: 320px;
			height: 40px !important;
			background: #efefef !important;
			padding-top: 8px;
			padding-left: 17.5px;
			padding-bottom: 5.5px;
			padding-right: 22px;
			margin-top: 8px;
			margin-bottom: 8px;

			.text-msg {
				max-width: 280.5px;
				color: #585858;
				font-size: 12px;
				height: 23px;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.video-thumbnail {
			width: 100%;
			height: 136px;
			padding-top: 8px;
			padding-bottom: 16px;
			border-radius: 4px;
			display: flex;
			justify-content: center;
			.temp {
				height: 100%;
				border-radius: 4px;
				overflow: hidden;
				position: relative;
				display: flex;
				justify-content: center;
				.image-file {
					width: 100%;
					height: 100%;
				}
				.video-btn {
					width: 17px;
					height: 10px;
					position: absolute;
					left: 6px;
					bottom: 14px;
				}
			}
		}
		.audio-type-message {
			height: 40px;
			background: #ffffff;
			border-radius: 3px;
			width: 116px;
			padding: 0 15px 0px;
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-top: 8px;
			margin-bottom: 16px;
			margin-left: 18px;
			.icon {
				width: 16px;
				height: 16px;
			}

			.file-name {
				margin-left: 12px;
				font-family: Metropolis;
				font-style: normal;
				font-weight: 500;
				font-size: 14px !important;
				line-height: 14px;
				color: #000000 !important;
			}
		}
		.file-type-message-container {
			width: 320px;
			height: 96px;
			padding-top: 8px;
			padding-bottom: 16px;
			display: flex;
			justify-content: center;
			padding-left: 18px;

			.file-type-message {
				display: flex;
				flex-direction: column;
				.file-bg {
					width: 200px;
					height: 80px;
					z-index: 9999;
				}
				.file-name {
					flex: 1;
					font-family: Metropolis;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 20px;
					color: #585858 !important;
					margin: 8px 36px 0 9px;
					text-align: left;

					overflow-y: hidden;
					white-space: initial;
					text-overflow: ellipsis;
					overflow: hidden;
					@supports (-webkit-line-clamp: 2) {
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
					}
				}
				.size-type-view {
					display: flex;
					flex-direction: row;
					margin: 9px;
					font-family: Metropolis;
					font-style: normal;
					font-weight: normal;
					font-size: 12px !important;
					line-height: 12px;
					color: #858484 !important;
					.size {
						font-size: 12px !important;
						margin-right: 2px;
						color: #858484 !important;
					}
					.type {
						font-size: 12px !important;
						margin-left: 2px;
						color: #858484 !important;
					}
				}
			}

			.message {
				margin: 11px 0;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 16px;
				letter-spacing: -0.333333px;
				color: #585858;
				padding: 0 17px;
				height: 36px;
				.image-file {
					max-width: 100%;
					height: 100px;
					object-fit: cover;
					object-position: top;
				}
				.txt-msg {
					margin-top: 10px;
					display: inline-block;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					max-width: 50px;
				}
			}
			ion-item {
				--background: transparent;

				&.contact-item {
					ion-thumbnail {
						width: 40px;
						height: 40px;
					}
					ion-label {
						font-style: normal;
						font-weight: normal;
						font-size: 16px;
						line-height: 29px;
						color: #000000;
						margin-right: 12px;
					}
				}
				.native-textarea.sc-ion-textarea-md {
					height: 100% !important;
				}
				&.item-input {
					ion-textarea {
						background: #dcdcdc;
						border-radius: 5px;
						margin-top: 0;
						min-height: 35px;
						max-height: 60px !important;
						--padding-start: 16px;
						// --padding-end: 16px;
						font-size: 14px;
						color: #979797;
						caret-color: #ff5e5e;
					}
				}
			}

			ion-toolbar {
				.sc-ion-buttons-md-s ion-button:not(.button-round) {
					text-transform: capitalize !important;
					font-size: 18px;
					font-weight: 400;
				}
				padding: 0px 20px 0;
				.cancel {
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 400;
					font-size: 18px;
					line-height: 18px;
					text-align: center;
					letter-spacing: -0.333333px;
					color: #000000;
					text-transform: capitalize;
				}
				.send-button {
					text-transform: capitalize;
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 18px;
					text-align: center;
					letter-spacing: -0.333333px;
					color: #ff5e5e;
				}
			}
		}

		.content-view-container {
			background: var(--app-color-code);
			box-sizing: border-box;
			width: 320px;
			height: 260px;
			border-radius: 10px;
			display: flex;
			overflow: hidden;
			flex-direction: column;
			box-shadow: 0px 0px 10px grey, 0px 0px 0px;

			.send {
				height: 36px;
				width: 100%;
				margin-top: 20px;
				.title-label {
					padding: 11px 17px 7px;
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 21px;
					letter-spacing: -0.333333px;
					color: #000000;
				}
			}

			.selected-contacts-container {
				display: flex;
				flex-direction: row;
				height: 95px;
				align-items: center;
				// height: $selectedContactsViewHeight;
				overflow-x: auto;
				padding: 0;
				padding-left: 18px;
				/* Hide scrollbar for IE, Edge and Firefox */
				-ms-overflow-style: none; /* IE and Edge */
				scrollbar-width: none; /* Firefox */
				/* Hide scrollbar for Chrome, Safari and Opera */
				background: rgba(239, 239, 239, 0.7);

				&::-webkit-scrollbar {
					display: none;
				}

				.contact-item {
					width: auto;
					min-width: 40px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: flex-start;
					margin: 0;
					margin-right: 10px;
					img {
						background: #ffffff;
						border-radius: 4px;
						width: 40px;
						height: 40px;
						object-fit: cover;
					}
				}
			}

			.contact-view {
				display: flex;
				height: 56px;
				width: 100%;
				padding-top: 8px;
				padding-bottom: 8px;
				padding-left: 18px;

				.profile-container {
					display: flex;
					.selected-image-container {
						.selected-image {
							width: 40px;
							height: 40px;
							--border-radius: 4px;
						}
					}

					.user-name {
						display: flex;
						flex-direction: column;
						padding-left: 13px;
						.name {
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 400;
							font-size: 18px;
							line-height: 18px;
							color: #000000;
						}
						.profession {
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 400;
							font-size: 14px;
							line-height: 14px;
							color: #858484;
							margin-top: 4.5px;
						}
					}
				}
			}

			.message {
				margin: 11px 0;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 16px;
				letter-spacing: -0.333333px;
				color: #585858;
				padding: 0 17px;
				height: 36px;
				.image-file {
					max-width: 100%;
					height: 100px;
					object-fit: cover;
					object-position: top;
				}
				.txt-msg {
					margin-top: 10px;
					display: inline-block;
				}
			}
			ion-item {
				--background: transparent;

				&.contact-item {
					ion-thumbnail {
						width: 40px;
						height: 40px;
					}
					ion-label {
						font-style: normal;
						font-weight: normal;
						font-size: 16px;
						line-height: 29px;
						color: #000000;
						margin-right: 12px;
					}
				}
				.native-textarea.sc-ion-textarea-md {
					height: 100% !important;
				}
				&.item-input {
					ion-textarea {
						background: #dcdcdc;
						border-radius: 5px;
						margin-top: 0;
						min-height: 35px;
						max-height: 60px !important;
						--padding-start: 16px;
						// --padding-end: 16px;
						font-size: 14px;
						color: #979797;
						caret-color: #ff5e5e;
					}
				}
			}
		}
		.share-message-bottom-bar {
			display: flex;
			align-items: center;
			justify-content: space-around;
			.cancel {
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 400;
				font-size: 18px;
				line-height: 18px;
				text-align: center;
				letter-spacing: -0.333333px;
				color: #000000;
				text-transform: capitalize;
			}
			.send-button {
				text-transform: capitalize;
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 18px;
				text-align: center;
				letter-spacing: -0.333333px;
				color: #ff5e5e;
			}
		}
	}
}
.app-update-popup-modal {
	.modal-wrapper {
		background: rgba(var(--app-modal-bg-color-code), 0) !important;
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		height: 100% !important;
	}
	.ion-page {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.content-view {
		width: 300px;
		background: rgba(255, 255, 255, 0.6);
		backdrop-filter: blur(20px);
		border-radius: 10px;
		box-sizing: border-box;
		display: flex;
		overflow: hidden;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 40px 36px;

		.heading-label {
			font-family: Comfortaa;
			font-style: normal;
			font-weight: normal;
			font-size: 30px;
			text-align: right;
			color: #000000;
		}

		.subheading-label {
			margin-top: 20px;
			font-family: Metropolis;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 16px;
			text-align: right;
			color: #858484;
		}

		.message-container {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			flex-direction: column;
			margin-top: 30px;
			.message-title {
				font-family: Metropolis;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 16px;
				text-align: right;
				color: #000000;
			}

			.message {
				margin-top: 20px;
				font-family: Metropolis;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 16px;
				color: #000000;
				white-space: pre-wrap;
			}
		}

		.action-btn {
			margin-top: 30px;
			--background: #ff5e5e;
			text-transform: none;
			--border-radius: 5px;
			font-family: Metropolis;
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 18px;
			text-align: right;
			color: #e8e8e8;
			width: 180px;
			height: 40px;
		}

		.loading-container {
			display: flex;
			flex-direction: column;
			width: 100%;
			.loading-view {
				width: 100%;
				height: 128px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}
			.loading-title {
				font-family: Metropolis;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 18px;
				text-align: center;
				color: #000000;
				width: 100%;
			}
		}
	}
}

.group-members-tag-modal {
	align-items: flex-end;
	--ion-backdrop-color: rgba(0, 0, 0, 0.5);
	--backdrop-opacity: 1;
	.modal-shadow {
		pointer-events: none;
	}
	.modal-wrapper {
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		height: 100% !important;
		background: transparent;
		.ion-page {
			width: 100% !important;
			height: 100% !important;
		}

		.popup-view-container {
			width: 100% !important;
			height: 100% !important;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}

		ion-toolbar,
		ion-list,
		ion-item {
			--min-height: unset;
			background: transparent;
			--background: transparent;
		}

		.popup-view {
			min-height: 200px;
			position: relative;
			background: transparent;
			border-radius: 10px 10px 0px 0px;
			overflow: hidden;
			.header-toolbar {
				width: 100%;
				position: absolute;
				top: 0;
				z-index: 99;
				background: rgba(var(--app-modal-bg-color-code), 0.7);
				box-sizing: border-box;
				backdrop-filter: blur(20px);
				display: flex;
				flex-direction: column;
				border-radius: 10px 10px 0px 0px;

				ion-toolbar {
					--min-height: 60px;
					--background: transparent;
					padding: 0 20px;

					.count-label-btn {
						font-style: normal;
						font-weight: normal;
						font-size: 18px;
						line-height: 18px;
						--color: #ff5e5e;
					}
					.title {
						font-weight: 500;
						font-size: 24px;
						line-height: 24px;
						/* identical to box height */
						--color: #000000;
						text-align: left;
						padding-inline-start: 36px;
					}
					.all-btn {
						font-style: normal;
						font-weight: 500;
						font-size: 18px;
						line-height: 18px;
						--color: #858484;
						&.active {
							--color: #ff5e5e;
						}
					}
				}
				.active-name-letter-view {
					width: 100%;
					height: 36px;
					display: flex;
					justify-content: center;
					flex-direction: column;
					padding: 0 25px;
					.letter {
						font-weight: 500;
						font-size: 16px;
						line-height: 16px;
						color: #ff5e5e;
					}
				}
			}

			.footer-toolbar {
				position: absolute;
				bottom: 0;
				z-index: 99;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				width: 100%;
				background: rgba(var(--app-modal-bg-color-code), 0.7);
				box-sizing: border-box;
				backdrop-filter: blur(20px);

				ion-toolbar {
					--min-height: 56px;
					--padding-start: 20px;
					--padding-end: 20px;
					margin-bottom: 30px;
					--background: transparent;

					.cancel-btn {
						transform: none;
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						line-height: 16px;
						--padding-start: 0;
						margin: 0;
						height: 33px;
						--color: #858484;
					}
					.done-btn {
						transform: none;
						margin: 0;
						height: 33px;
						min-width: 59px;
						--border-radius: 5px;
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						line-height: 16px;
						--background: #c4c4c4;
						--color: #e8e8e8;
						--padding-start: 9px;
						--padding-end: 9px;
						&.active {
							--background: #ff5e5e;
						}
					}
				}
			}

			.content {
				background: var(--app-color-code);
				max-height: 78.8vh;
				overflow-y: auto;
				display: flex;
				flex-direction: column;
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				/* Hide scrollbar for IE, Edge and Firefox */
				-ms-overflow-style: none; /* IE and Edge */
				scrollbar-width: none; /* Firefox */
				/* Hide scrollbar for Chrome, Safari and Opera */
				&::-webkit-scrollbar {
					display: none;
				}
				ion-list {
					padding: 0;
					width: 100%;
					margin-top: 65px;
					margin-bottom: 91px;
					.tag-user-item {
						display: flex;
						flex-direction: row;
						align-items: center;
						min-height: 50px;
						padding-left: 20px;
						padding-right: 20px;

						ion-thumbnail {
							width: 40px;
							height: 40px;
							--border-radius: 3px;
							border-radius: 3px;
							margin-left: 16px;
							margin-right: 12px;
						}
						ion-label {
							font-style: normal;
							font-weight: normal;
							font-size: 18px;
							line-height: 18px;
							color: #000000;
						}
						ion-checkbox {
							--checkmark-color: #e5e5e5;
							--background-checked: #ff5e5e;
							--background: transparent;
							--border-color: #858484;
							--border-color-checked: transparent;
							--border-radius: 50%;
							--checkmark-width: 2px;
							--size: 20px;
						}
					}
				}

				.alphabet-view-container {
					right: 0;
					position: absolute;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					z-index: 999;

					.selected-alphabet {
						width: 50px;
						height: 50px;
						border-radius: 50%;
						background: #a5a5a5;
						display: flex;
						align-items: center;
						justify-content: center;
						margin-left: -130px;
						position: absolute;
						font-size: 34px;
						line-height: 16px;
						color: #e8e8e8;
						z-index: 99;
						&::after {
							content: '';
							position: absolute;
							width: 0;
							height: 0;
							border-top: 20.12px solid transparent;
							border-bottom: 20.12px solid transparent;
							border-left: 24.12px solid #a5a5a5;
							left: 40px;
						}
					}
					.alphabet-view {
						min-height: 16px;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						.long-press-container {
							padding-right: 7.5px;
							padding-left: 20px;
							margin: 2px 0;
							.alphabet {
								cursor: pointer;
								font-weight: 500;
								font-size: 10px;
								line-height: 16px;
								height: 16px;
								width: 16px;
								display: flex;
								align-items: center;
								justify-content: center;
								color: #585858;
								&.active {
									border-radius: 50%;
									background-color: #ff5e5e;
									color: #e8e8e8;
								}
							}
						}
					}
				}
			}
		}
	}
}

.chatmedia-share-option-modal {
	align-items: flex-end;
	--ion-backdrop-color: rgba(0, 0, 0, 0.5);
	--backdrop-opacity: 1;
	.modal-shadow {
		pointer-events: none;
	}
	.modal-wrapper {
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		height: 100% !important;
		background: transparent;
		.ion-page {
			width: 100% !important;
			height: 100% !important;
		}

		.popup-view-container {
			width: 100% !important;
			height: 100% !important;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}

		ion-toolbar,
		ion-list,
		ion-item {
			--min-height: unset;
			background: transparent;
			--background: transparent;
		}

		.popup-view {
			min-height: 200px;
			position: relative;
			border-radius: 10px 10px 0px 0px;
			overflow: hidden;
			background: var(--app-color-code);

			.title {
				font-family: Metropolis;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 14px;
				color: #000000;
				height: 26px;
				padding: 0 16px;
				line-height: 26px;
				margin-top: 18px;
			}
			.contacts-container {
				display: flex;
				flex-direction: row;
				overflow-y: auto;
				height: 102px;
				align-items: flex-start;
				/* Hide scrollbar for IE, Edge and Firefox */
				-ms-overflow-style: none; /* IE and Edge */
				scrollbar-width: none; /* Firefox */
				/* Hide scrollbar for Chrome, Safari and Opera */
				&::-webkit-scrollbar {
					display: none;
				}
				.contact-item {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					ion-img {
						width: 50px;
						height: 50px;
						border-radius: 4px;
						overflow: hidden;
						background-color: #ffffff;
					}
					.contact-name {
						font-family: Metropolis;
						font-style: normal;
						font-weight: normal;
						font-size: 12px;
						line-height: 14px;
						text-align: center;
						color: #585858;
						margin-top: 8px;
					}
				}
			}

			.more-options {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 0 16px;
				width: 100%;
				justify-content: space-between;
				min-height: 107px;
				margin-top: 10px;
				.more-option-item {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					.image-container {
						display: flex;
						align-items: center;
						justify-content: center;
						background: #ffffff;
						border-radius: 12px;
						width: 50px;
						height: 50px;
					}
					.option-title {
						margin-top: 8px;
						font-family: Metropolis;
						font-style: normal;
						font-weight: normal;
						font-size: 12px;
						line-height: 14px;
						text-align: center;
						color: #585858;
						max-width: 62px;
						min-height: 28px;
					}
				}
			}
			.cancel-btn-container {
				width: 100%;
				height: 90px;
				display: flex;
				justify-content: center;
				margin-top: 10px;
				ion-button {
					height: 50px;
					font-family: Metropolis;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 14px;
					--color: #000000;
				}
			}
		}
	}
}

.recall-popup-modal {
	align-items: flex-end;
	--backdrop-opacity: 1 !important;

	ion-backdrop {
		opacity: 1;
		background: rgba(0, 0, 0, 0.25);
		backdrop-filter: blur(10px);
	}
	.modal-shadow {
		background: transparent;
		pointer-events: none;
	}
	.modal-wrapper {
		background: transparent;
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		height: 100% !important;
		opacity: 1;

		.ion-page {
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;
			flex-direction: column;

			.content-view {
				width: 100%;
				background: rgba(var(--app-modal-bg-color-code), 0.7);
				backdrop-filter: blur(60px);
				border-radius: 10px 10px 0px 0px;
				padding: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;

				.title {
					font-weight: normal;
					font-size: 13px;
					line-height: 13px;
					text-align: center;
					color: #585858;
					margin-bottom: 20px;
				}

				.action-btn {
					padding: 20px 0;
					font-style: normal;
					font-weight: normal;
					font-size: 18px;
					line-height: 18px;
					text-align: center;
					color: #ff5e5e;
					cursor: default;
				}

				.cancel-btn {
					padding: 20px 0;
					font-style: normal;
					font-weight: normal;
					font-size: 18px;
					line-height: 18px;
					text-align: center;
					color: #000000;
					cursor: default;
				}
			}
		}
	}

	.recall-timer {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		img {
			width: 138px;
			height: 138px;
		}
		.time {
			margin-top: 25px;
			font-size: 36px;
			line-height: 36px;
			text-align: center;
			color: #000000;
		}
	}
}

.resend-message-popup-modal {
	align-items: flex-end;
	--backdrop-opacity: 1 !important;

	ion-backdrop {
		opacity: 1;
		// backdrop-filter: blur(10px);
		background: rgba(0, 0, 0, 0.4);
	}
	.modal-shadow {
		background: transparent;
		pointer-events: none;
	}
	.modal-wrapper {
		background: transparent;
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		height: 100% !important;
		opacity: 1;

		.ion-page {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			flex-direction: column;

			.container {
				margin-bottom: 90px;
				max-width: 320px;
				width: 77%;
				display: flex;
				flex-direction: column;
				.content-view {
					width: 100%;
					background: rgba(var(--app-modal-bg-color-code), 0.7);
					backdrop-filter: blur(40px);
					border-radius: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;

					.title {
						font-family: Metropolis;
						font-style: normal;
						font-weight: normal;
						font-size: 13px;
						line-height: 40px;
						height: 40px;
						text-align: center;
						color: #585858;
					}

					.message {
						font-family: Metropolis;
						font-style: normal;
						font-weight: 500;
						font-size: 20px;
						line-height: 56px;
						color: #000000;
						height: 56px;
					}

					.btns-view {
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;
						justify-content: space-around;
						width: 100%;
						height: 56px;
						.delete-btn {
							font-family: Metropolis;
							font-style: normal;
							font-weight: 500;
							font-size: 18px;
							line-height: 18px;
							text-align: right;
							color: #000000;
							text-transform: none;
						}
						.ok-btn {
							text-transform: none;
							font-family: Metropolis;
							font-style: normal;
							font-weight: 500;
							font-size: 18px;
							line-height: 18px;
							text-align: right;
							color: #ff5e5e;
						}
					}
				}
				.cancel-btn {
					margin-top: 8px;
					height: 56px;
					line-height: 56px;
					text-align: center;
					width: 100%;
					font-family: Metropolis;
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					color: #000000;
					background: rgba(var(--app-modal-bg-color-code), 0.7);
					backdrop-filter: blur(40px);
					border-radius: 10px;
				}
			}
		}
	}
}

.add-new-tag-modal {
	align-items: flex-end;
	--backdrop-opacity: 1 !important;

	ion-backdrop {
		opacity: 1;
		// backdrop-filter: blur(10px);
		background: rgba(0, 0, 0, 0.4);
	}
	.modal-shadow {
		background: transparent;
		pointer-events: none;
	}
	.modal-wrapper {
		background: transparent;
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		height: 100% !important;
		opacity: 1;

		.ion-page {
			display: flex;
			align-items: center;
			flex-direction: column;

			.container {
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				.topbar-header {
					.toolbar-heading {
						--padding-start: 10px;
						--padding-end: 18px;
						.buttons-first-slot {
							ion-button {
								margin: 0;
								padding: 0 8px;
								ion-icon {
									width: 24.14px !important;
									height: 24.14px !important;
								}
							}
						}
						.buttons-last-slot {
							justify-content: flex-end;
							ion-button {
								margin: 0;
								font-family: Metropolis;
								font-style: normal;
								font-weight: 600;
								font-size: 18px;
								line-height: 18px;
								display: flex;
								align-items: center;
								text-align: right;
								--color: #ff5e5e;
								&.button-disabled {
									--color: #c4c4c4;
								}
							}
						}
						ion-title {
							font-family: Metropolis;
							font-style: normal;
							font-weight: 500;
							font-size: 18px;
							line-height: 18px;
							text-align: center;
							color: #000000;
						}
					}
				}
				.content-view {
					width: 100%;
					height: 100%;
					// background: rgba(var(--app-modal-bg-color-code), 0.7);
					background: #efefef;
					backdrop-filter: blur(40px);
					border-radius: 0;
					display: flex;
					flex-direction: column;

					.tag-input-container {
						margin-top: 14px;
						display: flex;
						justify-content: center;
						flex-direction: column;
						width: 100%;

						.title-label {
							margin-left: 18px;
							font-family: Metropolis;
							font-style: normal;
							font-weight: 500;
							font-size: 14px;
							line-height: 14px;
							color: #858484;
						}

						.input-view {
							margin-top: 5px;
							width: 100%;
							background: #ffffff;
							min-height: 44px;
							display: flex;
							flex-direction: row;
							align-items: center;
							justify-content: center;
						}
						.tag-name-input {
							font-family: Metropolis;
							font-style: normal;
							font-weight: normal;
							font-size: 16px;
							line-height: 16px;
							color: #000000;
							width: 100%;
							--padding-start: 18px;
							--padding-end: 18px;
						}

						.clear-icon {
							margin-right: 14px;
							width: 8px;
							height: 13px;
						}
					}

					.section-title {
						margin-top: 4px;
						font-family: Metropolis;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 14px;
						color: #858484;
						height: 30px;
						display: flex;
						padding: 0 18px;
						align-items: center;
					}

					.members-container {
						width: 100%;
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						padding: 8px 11px 5px;
						.member-item {
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							.add-item {
								display: flex;
								align-items: center;
								justify-content: center;
								border: 1px dashed #858484;
								box-sizing: border-box;
								border-radius: 4px;
								width: 50px;
								height: 50px;
								img {
									width: 20px;
									height: 20px;
								}
							}
							.title {
								margin-top: 8px;
								font-family: Metropolis;
								font-style: normal;
								font-weight: normal;
								font-size: 12px;
								line-height: 12px;
								text-align: center;
								color: #858484;
							}
						}
					}
				}
			}
		}
	}
}

.add-new-tag-popup {
	align-items: flex-end;
	--backdrop-opacity: 1 !important;

	ion-backdrop {
		opacity: 1;
		// backdrop-filter: blur(10px);
		background: rgba(0, 0, 0, 0.4);
	}
	.modal-shadow {
		background: transparent;
		pointer-events: none;
	}
	.modal-wrapper {
		background: transparent;
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		height: 100% !important;
		opacity: 1;

		.ion-page {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			flex-direction: column;

			.container {
				width: 100%;
				height: 207px;
				display: flex;
				flex-direction: column;
				.content-view {
					width: 100%;
					// background: rgba(var(--app-modal-bg-color-code), 0.7);
					background: #efefef;
					backdrop-filter: blur(40px);
					border-radius: 10px 10px 0 0;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;

					.title {
						margin-top: 38px;
						font-family: Metropolis;
						font-style: normal;
						font-weight: 500;
						font-size: 22px;
						line-height: 22px;
						height: 20px;
						color: #000000;
					}

					.message {
						margin-top: 22px;
						font-family: Metropolis;
						font-style: normal;
						font-weight: 500;
						font-size: 13px;
						line-height: 120%;
						text-align: center;
						color: #585858;
						min-height: 36px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
					.msg-tag {
						margin-top: 27px;
						font-family: Metropolis;
						font-style: normal;
						font-weight: 400;
						font-size: 13px;
						line-height: 120%;
						text-align: center;
						color: #585858;
						display: flex;
						height: 18px;
						justify-content: center;
					}
					.tag-input-container {
						display: flex;
						justify-content: center;
						align-items: center;
						height: 56px;
						padding: 0px 32px;
						width: 100%;
						margin-top: 22px;

						.input-view {
							width: 100%;
							background: #ffffff;
							border-radius: 5px;
							display: flex;
							flex-direction: row;
							align-items: center;
							justify-content: center;
						}
						.tag-name-input {
							font-family: Metropolis;
							font-style: normal;
							font-weight: normal;
							font-size: 16px;
							line-height: 16px;
							color: #000000;
							width: 100%;
							--padding-start: 18px;
							--padding-end: 18px;
						}

						.clear-icon {
							margin-right: 14px;
							width: 18px;
							height: 18px;
						}
					}

					.btns-view {
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: space-around;
						width: 100%;
						height: 74px;
						margin-bottom: 28px;

						.delete-btn {
							font-family: Metropolis;
							font-style: normal;
							font-weight: 500;
							font-size: 18px;
							line-height: 18px;
							text-align: right;
							color: #000000;
							text-transform: none;
						}
						.create-btn {
							text-transform: none;
							font-family: Metropolis;
							font-style: normal;
							font-weight: 600;
							font-size: 18px;
							line-height: 18px;
							text-align: right;
							color: #ff5e5e;
							&.button-disabled {
								color: #c4c4c4;
							}
						}

						.ok-btn {
							text-transform: none;
							font-family: Metropolis;
							font-style: normal;
							font-weight: 500;
							font-size: 18px;
							line-height: 18px;
							text-align: right;
							color: #ff5e5e;
							&.button-disabled {
								color: #c4c4c4;
							}
						}
					}
				}
			}
			.container-thread {
				width: 100%;
				display: flex;
				flex-direction: column;
				.content-thread-view {
					width: 100%;
					// height: 400px;
					// background: rgba(var(--app-modal-bg-color-code), 0.7);
					background: #efefef;
					backdrop-filter: blur(40px);
					border-radius: 14px 14px 0 0;
					display: flex;
					align-items: center;
					// justify-content: center;
					flex-direction: column;
					.chat-text {
						font-family: Metropolis;
						font-size: 13px;
						font-weight: 400;
						line-height: 16px;
						letter-spacing: 0em;
						text-align: center;
						margin-top: 28px;
					}
					.title {
						margin-top: 40px;
						font-family: Metropolis;
						font-style: normal;
						font-weight: 500;
						font-size: 22px;
						line-height: 22px;
						height: 20px;
						color: #000000;
					}

					.message {
						margin-top: 22px;
						font-family: Metropolis;
						font-style: normal;
						font-weight: 500;
						font-size: 13px;
						line-height: 120%;
						text-align: center;
						color: #585858;
						min-height: 36px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
					.msg-tag {
						margin-top: 27px;
						font-family: Metropolis;
						font-style: normal;
						font-weight: 400;
						font-size: 13px;
						line-height: 120%;
						text-align: center;
						color: #585858;
						display: flex;
						height: 18px;
						justify-content: center;
					}
					.tag-thread-input-container {
						display: flex;
						justify-content: center;
						align-items: center;
						height: 40px;
						padding: 0px 32px;
						width: 100%;
						margin-top: 22px;

						.input-view {
							width: 100%;
							background: #ffffff;
							border-radius: 5px;
							display: flex;
							flex-direction: row;
							align-items: center;
							justify-content: center;
						}
						.tag-thread-name-input {
							font-family: Metropolis;
							font-style: normal;
							font-weight: normal;
							font-size: 16px;
							line-height: 16px;
							color: #000000;
							width: 100%;
							--padding-start: 17px;
							--padding-end: 18px;
						}

						.clear-icon {
							margin-right: 14px;
							width: 18px;
							height: 18px;
						}
					}

					.thread-btns-view {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						// justify-content: space-around;
						width: 100%;
						// height: 73px;
						margin: 0;
						// margin-top: 20px;

						.delete-btn {
							font-family: Metropolis;
							font-style: normal;
							font-weight: 400;
							font-size: 18px;
							line-height: 18px;
							text-align: right;
							color: #ff5e5e;
							text-transform: none;
							margin: 0;
						}
						.create-btn {
							text-transform: none;
							font-family: Metropolis;
							font-style: normal;
							font-weight: 400;
							font-size: 18px;
							line-height: 18px;
							text-align: right;
							color: #000000;
							margin: 0;
							margin-top: 20px;
							&.button-disabled {
								color: #c4c4c4;
							}
						}
						.cancel-add-container {
							display: flex;
							justify-content: space-between;
							width: 100%;
							.cancel {
								text-transform: none;
								font-family: Metropolis;
								font-style: normal;
								font-weight: 400;
								font-size: 18px;
								line-height: 18px;
								text-align: right;
								color: #000000;
								margin: 0;
								margin-top: 33px;
								margin-bottom: 48px;
								width: 50%;
							}

							.ok-btn {
								text-transform: none;
								font-family: Metropolis;
								font-style: normal;
								font-weight: 500;
								font-size: 18px;
								line-height: 18px;
								text-align: right;
								color: #ff5e5e;
								width: 50%;
								margin-top: 33px;
								margin-bottom: 48px;
								&.button-disabled {
									color: #c4c4c4;
								}
							}
						}
					}
				}
			}
		}
	}
}

.block-popup {
	align-items: flex-end;
	--backdrop-opacity: 1 !important;

	ion-backdrop {
		opacity: 1;
		// backdrop-filter: blur(10px);
		background: rgba(0, 0, 0, 0.4);
	}
	.modal-shadow {
		background: transparent;
		pointer-events: none;
	}
	.modal-wrapper {
		background: transparent;
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		height: 100% !important;
		opacity: 1;

		.ion-page {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			flex-direction: column;

			.container {
				width: 100%;
				display: flex;
				flex-direction: column;
				.content-view {
					width: 100%;
					// background: rgba(var(--app-modal-bg-color-code), 0.7);
					background: #efefef;
					backdrop-filter: blur(40px);
					border-radius: 10px 10px 0 0;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					.block-container {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;

						.block-content {
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 400;
							font-size: 12px;
							line-height: 14px;
							color: #585858;
							margin-top: 22px;
							padding-left: 38px;
							padding-right: 37px;
							text-align: center;
						}
					}

					.title {
						margin-top: 40px;
						font-family: Metropolis;
						font-style: normal;
						font-weight: 500;
						font-size: 22px;
						line-height: 22px;
						height: 20px;
						color: #000000;
					}

					.message {
						margin-top: 22px;
						font-family: Metropolis;
						font-style: normal;
						font-weight: 500;
						font-size: 13px;
						line-height: 120%;
						text-align: center;
						color: #585858;
						min-height: 36px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
					.msg-tag {
						margin-top: 27px;
						font-family: Metropolis;
						font-style: normal;
						font-weight: 400;
						font-size: 13px;
						line-height: 120%;
						text-align: center;
						color: #585858;
						display: flex;
						height: 18px;
						justify-content: center;
					}
					.tag-input-container {
						display: flex;
						justify-content: center;
						align-items: center;
						height: 56px;
						padding: 0px 32px;
						width: 100%;

						.input-view {
							width: 100%;
							background: #ffffff;
							border-radius: 5px;
							display: flex;
							flex-direction: row;
							align-items: center;
							justify-content: center;
						}
						.tag-name-input {
							font-family: Metropolis;
							font-style: normal;
							font-weight: normal;
							font-size: 16px;
							line-height: 16px;
							color: #000000;
							width: 100%;
							--padding-start: 18px;
							--padding-end: 18px;
						}

						.clear-icon {
							margin-right: 14px;
							width: 18px;
							height: 18px;
						}
					}

					.btns-view {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						justify-content: space-around;
						width: 100%;
						// margin-top: 27px;
						margin-bottom: 57px;
						// height: 73px;

						.delete-btn {
							font-family: Metropolis;
							font-style: normal;
							font-weight: 500;
							font-size: 18px;
							line-height: 18px;
							text-align: right;
							color: #000000;
							text-transform: none;
							margin-bottom: 39px;
						}
						.create-btn {
							text-transform: none;
							font-family: Metropolis;
							font-style: normal;
							font-weight: 500;
							font-size: 18px;
							line-height: 18px;
							text-align: right;
							color: #ff5e5e;
							margin-bottom: 26px;
							margin-top: 22px;
							&.button-disabled {
								color: #c4c4c4;
							}
						}

						.ok-btn {
							text-transform: none;
							font-family: Metropolis;
							font-style: normal;
							font-weight: 500;
							font-size: 18px;
							line-height: 18px;
							text-align: right;
							color: #ff5e5e;
							&.button-disabled {
								color: #c4c4c4;
							}
						}
					}
				}
			}
		}
	}
}

.delete-file-modal {
	.modal-wrapper {
		background: rgba(var(--app-modal-bg-color-code), 0) !important;
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		// height: 100% !important;

		.ion-page {
			display: flex;
			align-items: center;
			// justify-content: flex-end;

			.content {
				border-radius: 14px;
				width: 100%;
				background-color: #efefefff;
				display: flex;
				flex-direction: column;
				align-items: center;
				.user-name {
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 14px;
					text-align: center;
					color: #000000;
					margin-top: 7px;
				}
				.image {
					width: 56px;
					height: 48px;
					margin-top: 29px;
					display: flex;
					align-items: center;
					.default-image-container {
						width: 100%;
						height: 100%;
						.default-image {
							border-radius: 4px;
						}
					}
				}
				.delete {
					width: 100%;
					height: 100%;
					border-radius: 4px;
				}
				ion-img {
					width: 100%;
					height: 100%;
					border-radius: 4px !important;
				}
				.storage {
					margin-top: 33px;
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 500;
					font-size: 18px;
					line-height: 18px;
					text-align: center;
					color: #000000;
				}
				.message-title {
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 120%;
					text-align: center;
					color: #ff5e5e;
					margin-top: 22px;
				}
				.message-size {
					margin-top: 6px;
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 14px;
					text-align: center;
					color: #858484;
				}
				.clear-msg {
					height: 16px;
					margin-top: 31px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 16px;
					display: flex;
					align-items: center;
					text-align: center;
					color: #000000;
				}
				.storege-msg {
					height: 56px;
					margin-top: 13px;
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 14px;
					color: #858484;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.move-trash {
					display: flex;
					align-self: center;
					align-items: center;
					justify-content: center;
					margin-top: 11px;
					height: 18px;

					ion-button {
						text-transform: capitalize;
						--background: none;
						--box-shadow: none;
						--border: none;
						color: #ff5e5e;

						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 500;
						font-size: 18px;
						line-height: 18px;
					}
				}
				.cancel {
					height: 90px;
					text-align: center;
					margin-top: 21px;
					p {
						font-size: 16px;
						font-weight: 500;
						font-family: 'Metropolis';
						font-style: normal;
						color: #000000;
					}
				}

				.sub-modal {
					width: 100%;
					height: 178px;
					.modal-footer {
						margin-top: 40px;

						display: flex;
						flex-direction: row;
						justify-content: space-evenly;

						.modal-icons {
							display: flex;
							flex-direction: column;
							align-items: center;
							img {
								width: 27px;
								height: 24px;
							}
							.folder {
								margin-top: 19px;
								font-size: 14px;
								font-weight: 400;
							}
						}
					}
				}
			}
		}
	}
}
.manage-contact-action-sheet {
	.action-sheet-wrapper .action-sheet-group {
		padding-bottom: 0 !important;
	}
	.action-sheet-title {
		line-height: 20px;
		padding: 0;
		background: null;
		height: 66px;
		text-align: center;
		display: flex;
		align-items: center;
		padding: 0 10px;
		justify-content: center;
		font-size: 14px;
		font-weight: 500;
		color: #585858;
	}

	.action-sheet-container {
		padding: 0;
	}
	.action-sheet-group {
		height: 216px !important;
		padding-bottom: 57px !important;
		border-radius: 10px 10px 0 0;
		margin-bottom: 0 !important;
	}

	.action-sheet-button {
		padding: 0 10px;
		text-align: center;
		height: 18px;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 21px;
		color: #000000;
	}
	.action-sheet-button.sc-ion-action-sheet-ios:first-child {
		height: 34px;
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 120%;
		text-align: center;
		color: #858484 !important;
		margin-top: 22px !important;
		margin-bottom: 24px !important;
	}

	.action-sheet-button.sc-ion-action-sheet-ios:last-child {
		padding-top: 48px !important;
		padding-bottom: 14px !important;
		font-size: 16px !important;
		font-weight: 500 !important;
	}
	.action-sheet-group-cancel {
		border-radius: 0;
		// padding-top: 44px;
		padding-bottom: 58px !important;
		.cancel {
			width: 50%;
			background-color: #d64747;
			color: #3ebaff;
		}
	}
	.action-sheet-structive {
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500 !important;
		font-size: 16px !important;
		line-height: 16px !important;
		text-align: center;
		color: #ff5e5e !important;
	}

	.action-sheet-destructive {
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 18px;
		text-align: center;
		color: #ff5e5e;
	}
}
.visible-friend-modal {
	.modal-wrapper {
		background: rgba(var(--app-modal-bg-color-code), 0) !important;
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		height: 100% !important;

		.ion-page {
			display: flex;
			// align-items: center;
			justify-content: flex-end;

			.content {
				border-radius: 14px;
				width: 100%;
				background-color: #efefef;
				display: flex;
				flex-direction: column;
				// align-items: center;
				.right-icon {
					width: 20px;
					height: 14px;
				}
				.title {
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 500;
					font-size: 22px;
					line-height: 22px;
					text-align: center;
					color: #000000;
					margin-bottom: 17px;
					margin-top: 39px;
				}

				ion-list {
					// background: var(--app-color-code) !important;
					padding: 0 !important;
					background-color: #efefef !important;
					ion-item {
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 400;
						font-size: 17px;
						line-height: 17px;
						color: #000000;
						--background: #efefef;
						--padding-start: 17px;
						--inner-padding-end: 21px;
						& .active {
							font-weight: 500;
							color: #ff5e5e;
						}
					}
					// .list-item{
					// 	width: 70%;
					// }
				}
				.bottom-buttons {
					width: 100%;
					height: 111px;
					display: flex;
					justify-content: space-evenly;
					align-items: center;
					.cancel {
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 500;
						font-size: 18px;
						line-height: 18px;
						color: #000000;
						--box-shadow: none;
						--background: none;
						text-transform: capitalize;
					}
					.done {
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 600;
						font-size: 18px;
						line-height: 18px;
						text-align: center;
						--box-shadow: none;
						--background: none;
						text-transform: capitalize;
						color: #ff5e5e;

						&.button-disabled {
							color: #c4c4c4;
						}
					}
				}
			}
		}
	}
}
.chat-selectview-thread-modal {
	display: flex;
	align-items: flex-end;
	.modal-wrapper {
		background: rgba(var(--app-modal-bg-color-code), 0) !important;
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		// height: 100% !important;

		.ion-page {
			display: flex;
			// align-items: center;
			justify-content: flex-end;

			// .chat-select-view {
			// 	width: 100%;
			// 	height: 88px;
			// 	background: #efefef;
			// 	padding-bottom: 8px;
			// 	// background: var(--app-color-code);
			// 	// box-shadow: 0px -4px 4px rgba(77, 71, 71, 0.15);
			// 	// position: absolute;
			// 	bottom: 0;
			// 	left: 0;
			// 	right: 0;
			// 	position: relative;

			// 	&::before {
			// 		content: '';
			// 		position: absolute;
			// 		left: 0;
			// 		right: 0;
			// 		top: 2px;
			// 		box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.15);
			// 	}

			// 	ion-toolbar {
			// 		--background: transparent;
			// 		ion-icon {
			// 			width: 28px;
			// 			height: 28px;
			// 		}
			// 		.image-container {
			// 			display: flex;
			// 			justify-content: flex-end;
			// 			margin-right: 18px;
			// 			.image-button {
			// 				width: 40px;
			// 				height: 40px;
			// 				background: rgba(88, 88, 88, 0.2);
			// 				backdrop-filter: blur(10px);
			// 				margin-left: 14px;
			// 				border-radius: 50%;
			// 			}
			// 		}
			// 		ion-title {
			// 			// text-align: center;
			// 			font-style: normal;
			// 			font-weight: normal;
			// 			font-size: 18px;
			// 			line-height: 33px;
			// 			color: #858484;
			// 		}
			// 		.popup-Icon-list {
			// 			display: flex;
			// 			justify-content: right;
			// 			align-items: center;
			// 			width: 298px;
			// 			height: 38px;
			// 			flex: 1;
			// 			margin-right: 0px;
			// 			margin-left: 100px;

			// 			.popup-media {
			// 				border-radius: 100%;
			// 				width: 40px;
			// 				height: 40px;
			// 				min-width: 38px;
			// 				--padding-start: 0;
			// 				--padding-end: 0;
			// 				margin-left: 14px;
			// 				background: rgba(88, 88, 88, 0.6);
			// 				opacity: 0.2;
			// 				backdrop-filter: blur(10px);

			// 				.popup-image-container {
			// 					width: 100%;
			// 					height: 100%;
			// 					position: absolute;
			// 					border-radius: 50%;
			// 					backdrop-filter: blur(20px);
			// 					opacity: 0.2;
			// 					display: flex;
			// 					justify-content: center;
			// 					align-items: center;
			// 					.popup-media-image-right {
			// 						height: 14px;
			// 					}
			// 					.popup-media-image {
			// 						width: 28px;
			// 						height: 28px;
			// 					}
			// 				}
			// 			}
			// 		}
			// 	}
			// }
			.content {
				border-radius: 14px;
				width: 100%;
				background-color: #efefef;
				display: flex;
				flex-direction: column;
				padding-bottom: 0px;
				.bottom-manage-sendThread-popup {
					display: flex;
					flex-direction: column;
					// padding-bottom: 4.5px;
					min-width: 300px;
					width: 100%;
					height: auto;
					right: 0;
					bottom: 88px;
					background: #efefef;
					//   background: rgba(239, 239, 239, 0.7);
					backdrop-filter: blur(20px);
					border-radius: 14px 14px 0px 0px;
					z-index: 999999;

					.select-content {
						width: 100%;
						display: flex;
						flex-direction: column;
						margin-left: 18px;
						z-index: 99999999999;
						.move-to {
							width: 100%;
							height: 42px;
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 500;
							font-size: 18px;
							line-height: 18px;
							color: #585858;
							padding-top: 15px;
							padding-bottom: 9px;
						}
						.thread-container {
							width: 100%;
							padding-top: 8px;
							padding-bottom: 8px;
							display: flex;
							justify-content: center;
							flex-wrap: wrap;
							.chat-button {
								background: #d8d8d8;
								width: 59px;
								height: 30px;
								--background: none;
								--box-shadow: none;
								border-radius: 25px;
								font-family: 'Metropolis';
								font-style: normal;
								font-weight: 600;
								font-size: 16px;
								text-align: center;
								margin-left: 3px;
								margin-bottom: 16px;
								color: #efefef;
								text-transform: none;
								margin-top: 0;
								margin-right: 0;
								padding-top: 8px;
								padding-bottom: 7px;
								padding-left: 8px;
								padding-right: 8px;
							}
							ion-button {
								height: 30px;
								background: #00ff2b;
								--background: none;
								--box-shadow: none;
								border-radius: 25px;
								font-family: 'Metropolis';
								font-style: normal;
								font-weight: 600;
								font-size: 16px;
								text-align: center;
								margin-left: 12px;
								margin-bottom: 16px;
								color: #000000;
								text-transform: none;
								margin-top: 0;
								margin-right: 0;
								--padding-start: 8px;
								--padding-end: 8px;
								--padding-top: 3px;
								.chat-button {
									background: #d8d8d8;
								}
								&.active {
									background: #ff5e5e;
									color: white;
								}
							}

							.chat-thread-container {
								width: 100%;
								position: fixed;
								color: #000;
								z-index: 99;
								background: transparent;
								top: 92px;
								display: flex;
								justify-content: center;
								flex-wrap: wrap;
								margin-left: 12px;
								margin-right: 12px;
								right: 0;
								left: 0;

								.thread-button {
									display: flex;
									flex-direction: row;
									flex-wrap: wrap;
									.thread-btn-container {
										position: relative;
										display: flex;
										flex-direction: row;
										padding-right: 9px;

										.notification-grid {
											position: absolute;
											padding-top: 5px;
											padding-bottom: 5px;
											padding-right: 6px;
											padding-left: 6px;
											min-width: 18px;
											height: 18px;
											right: 2px;
											top: -3px;
											z-index: 2;
											color: white;
											display: flex;
											justify-content: center;
											align-items: center;
											font-size: 12px;
											background: #ff5e5e;
											box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
											border-radius: 21px;
										}
										.notification-grid-3Dot {
											position: absolute;
											padding-top: 7px;
											padding-bottom: 4px;
											padding-right: 6px;
											padding-left: 6px;
											min-width: 18px;
											height: 18px;
											right: 2px;
											top: -3px;
											z-index: 2;
											color: white;
											display: flex;
											justify-content: center;
											align-items: flex-end;
											font-size: 19px;
											background: #ff5e5e;
											box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
											border-radius: 21px;
										}
									}

									ion-button {
										height: 30px;
										background: #00ff2b;
										--background: none;
										--box-shadow: none;
										border-radius: 25px;
										font-family: 'Metropolis';
										font-style: normal;
										font-weight: 600;
										font-size: 16px;
										text-align: center;
										margin-left: 3px;
										margin-bottom: 16px;
										color: #000000;
										text-transform: none;
										margin-top: 0;
										margin-right: 0;
										--padding-start: 8px;
										--padding-end: 8px;
										--padding-top: 3px;

										&.active {
											background: #ff5e5e;
											color: white;
										}
									}
								}
								.thread-longpress {
									display: flex;
									// flex-direction: column;
									justify-content: center;
									align-items: center;
									ion-button {
										height: 30px;
										background: #00ff2b;
										--background: none;
										--box-shadow: none;
										border-radius: 25px;
										font-family: 'Metropolis';
										font-style: normal;
										font-weight: 600;
										font-size: 16px;
										text-align: center;
										margin-bottom: 34px;
										color: #000000;
										text-transform: none;
										margin-top: 0;
										margin-right: 0;
										--padding-start: 8px;
										--padding-end: 8px;
										--padding-top: 8px;
										--padding-bottom: 8px;

										&.active {
											background: #ff5e5e;
											color: white;
										}
									}
									.thread-btn-container {
										position: relative;
										display: flex;
										flex-direction: column;
										padding-right: 9px;

										.notification-list {
											position: absolute;
											padding-top: 5px;
											padding-bottom: 5px;
											padding-right: 6px;
											padding-left: 6px;
											min-width: 18px;
											height: 18px;
											right: 2px;
											top: -3px;
											z-index: 2;
											color: white;
											display: flex;
											justify-content: center;
											align-items: center;
											font-size: 12px;
											background: #ff5e5e;
											box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
											border-radius: 21px;
										}
										.notification-list-3Dot {
											position: absolute;
											padding-top: 7px;
											padding-bottom: 4px;
											padding-right: 6px;
											padding-left: 6px;
											min-width: 18px;
											height: 18px;
											right: 2px;
											top: -3px;
											z-index: 2;
											color: white;
											display: flex;
											justify-content: center;
											align-items: flex-end;
											font-size: 19px;
											background: #ff5e5e;
											box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
											border-radius: 21px;
										}
									}
								}
							}
						}
						.folder-img {
							width: 23px;
							height: 21px;
							fill: #585858;
						}
						p {
							font-family: 'Metropolis';
							font-size: 16px;
							font-weight: 600;
							color: black;
							text-transform: uppercase;
							margin-left: 13px;
							padding: 0px;
							margin-top: 0;
							margin-bottom: 0;
						}
					}

					.file-name {
						width: 100%;
						height: auto;
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 600;
						font-size: 16px;
						line-height: 16px;
						color: #000000;
					}

					.share-files {
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						align-items: center;
						width: 100%;
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 500;
						font-size: 18px;
						line-height: 18px;
						/* identical to box height */

						color: #585858;
						.selected-send-file {
							width: 100%;
							display: flex;
							justify-content: space-between;
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 500;
							font-size: 18px;
							color: #585858;
							padding-left: 17px;
							padding-top: 17px;
						}

						.popup-manage {
							// width: 7.8px;
							// height: 14px;
							right: 0;

							.arrow-right {
								width: 100%;
								height: 100%;
								right: 0;
								.arrow-right-light {
									width: 100%;
									height: 100%;
								}
							}
						}

						.selected-file {
							padding: 17px;
						}
					}

					.manage-share-top {
						display: flex;
						flex-direction: column;
						margin-left: 10px;
						border-radius: 14px 14px 0px 0px;
						.cancel-save {
							width: 100%;
							height: 90px;
							display: flex;
							align-items: center;
							justify-content: space-between;
							padding-left: 8px;
							padding-right: 18px;
							padding-top: 5px;
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 400;
							font-size: 18px;
							line-height: 18px;
							text-align: center;
							letter-spacing: -0.333333px;

							color: #000000;
							.image-container {
								display: flex;
								justify-content: flex-end;
								margin-right: 18px;
								.image-button {
									width: 40px;
									height: 40px;
									background: rgba(88, 88, 88, 0.2);
									backdrop-filter: blur(10px);
									margin-left: 14px;
									border-radius: 50%;
								}
							}
						}
						.cancel {
							width: 100%;
							height: 90px;
							display: flex;
							justify-content: center;
							align-items: center;
							padding-top: 5px;
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 400;
							font-size: 18px;
							line-height: 18px;
							text-align: center;
							letter-spacing: -0.333333px;

							color: #000000;
						}

						.send {
							display: flex;
							flex-direction: column;
						}
						.manage-files {
							width: 100%;
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 500;
							font-size: 18px;
							line-height: 18px;
							padding: 17px;
							color: #585858;
						}

						.selected-send-file {
							width: 100%;
							display: flex;
							justify-content: space-between;
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 500;
							font-size: 18px;
							color: #585858;
							padding-left: 8px;
							padding-top: 19px;
							padding-bottom: 9px;
							.manage {
								display: flex;
								align-items: center;
							}
							.right-icon-thread {
								margin-right: 18.5px;
							}
						}

						.popup-manage {
							// width: 7.8px;
							// height: 14px;
							right: 0;

							.arrow-right {
								width: 100%;
								height: 100%;
								right: 0;
								.arrow-right-light {
									width: 100%;
									height: 100%;
								}
							}
						}
					}
				}
			}
		}
	}
}
.move-thread-modal {
	.modal-wrapper {
		background: rgba(var(--app-modal-bg-color-code), 0) !important;
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		height: 100% !important;

		.ion-page {
			display: flex;
			// align-items: center;
			justify-content: flex-end;

			.content {
				border-radius: 14px;
				width: 100%;
				background-color: #efefef;
				display: flex;
				flex-direction: column;

				.bottom-manage-sendThread-popup {
					display: flex;
					flex-direction: column;
					padding-bottom: 4.5px;
					min-width: 300px;
					width: 100%;
					height: auto;
					right: 0;
					bottom: 88px;
					background: #efefef;
					//   background: rgba(239, 239, 239, 0.7);
					backdrop-filter: blur(20px);
					border-radius: 14px 14px 0px 0px;
					z-index: 999999;

					.select-content {
						display: flex;
						flex-direction: column;
						margin-left: 18px;
						margin-right: 18px;
						z-index: 99999999999;

						.move-to {
							width: 100%;
							height: 50px;
							display: flex;
							align-items: center;
							justify-content: center;
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 600;
							font-size: 18px;
							line-height: 18px;
							color: #000000;
						}

						.thread-container {
							width: 100%;
							padding-top: 8px;
							padding-bottom: 8px;
							display: flex;
							justify-content: center;
							flex-wrap: wrap;
							.chat-button {
								background: #d8d8d8;
								width: 59px;
								height: 30px;
								--background: none;
								--box-shadow: none;
								border-radius: 25px;
								font-family: 'Metropolis';
								font-style: normal;
								font-weight: 600;
								font-size: 16px;
								text-align: center;
								margin-left: 3px;
								margin-bottom: 16px;
								color: #efefef;
								text-transform: none;
								margin-top: 0;
								margin-right: 0;
								padding-top: 8px;
								padding-bottom: 7px;
								padding-left: 8px;
								padding-right: 8px;
							}
							ion-button {
								height: 30px;
								background: #00ff2b;
								--background: none;
								--box-shadow: none;
								border-radius: 25px;
								font-family: 'Metropolis';
								font-style: normal;
								font-weight: 600;
								font-size: 16px;
								text-align: center;
								margin-left: 12px;
								margin-bottom: 16px;
								color: #000000;
								text-transform: none;
								margin-top: 0;
								margin-right: 0;
								--padding-start: 8px;
								--padding-end: 8px;
								--padding-top: 3px;
								.chat-button {
									background: #d8d8d8;
								}
								&.active {
									background: #ff5e5e;
									color: white;
								}
							}
						}

						.cancel {
							width: 100%;
							height: 55px;
							display: flex;
							justify-content: center;
							padding-top: 24px;
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 400;
							font-size: 18px;
							line-height: 18px;
							text-align: center;
							color: #000000;
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 1200px) and (min-width: 300px) {
	div#desktopview {
		display: none;
	}
}
@media only screen and (min-width: 1200px) {
	div#mobileview {
		display: none;
	}
}

#desktopview {
	.modal-icons {
		margin-left: 50px;
	}
}
#mobileview {
	.modal-icons {
		margin-left: none;
	}
}
.preview-file-modal {
	#react-doc-viewer {
		background: var(--app-color-code);
		#header-bar {
			background: var(--app-color-code);
			min-height: 30px;
		}
		#pdf-controls {
			background: transparent;
		}
	}
	.pg-viewer-wrapper {
		padding-top: 100px;
		width: 100vw;
		height: 100vh;
	}

	.close-button-container {
		position: absolute;
		left: 0;
		top: 0px;
		z-index: 10;
		padding-left: 16px;
		padding-top: 30px;
		flex-direction: row;
		display: flex;
		align-items: center;
		.filename-text {
			color: #000000;
		}
		ion-button {
			--background: transparent;
			--background-hover: transparent;
			--background-focused: transparent;
			--padding-top: 0;
			--padding-bottom: 0;
			width: 22px;
			height: 22px;
			--padding-start: 0;
			--padding-end: 0;
		}
	}

	.modal-wrapper {
		// background: rgba(0, 0, 0, 0.5);
		background: rgba(var(--app-modal-bg-color-code), 0.75) !important;
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		height: 100% !important;
	}
	.ion-page {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.previewfile-container {
		width: auto;
		height: auto;
		background: #000000;
		color: red;
		margin-top: 90px;
	}
}

// .share-location-modal {
// 	.share-location-thump {
// 		width: 255px;
// 		height: 149px;
// 		border-radius: 6px;
// 		background-color: rgba(255, 255, 255, 1);
// 		display: flex;
// 		flex-direction: column;
// 		.share-location-thump-content {
// 			width: 100%;
// 			height: 53px;
// 			padding-left: 12px;
// 			padding-top: 12px;
// 			display: flex;
// 			flex-direction: column;

// 			.share-location-thump-content-text {
// 				width: 100%;
// 				height: 17px;
// 				font-family: Metropolis;
// 				font-size: 18px;
// 				font-weight: 400;
// 				line-height: 18px;
// 				letter-spacing: 0em;
// 				text-align: left;
// 				color: #000000;
// 			}
// 			.share-location-thump-content-address {
// 				width: 100%;
// 				height: 14px;
// 				font-family: Metropolis;
// 				font-size: 14px;
// 				font-weight: 400;
// 				line-height: 14px;
// 				letter-spacing: 0em;
// 				text-align: left;
// 				color: rgba(133, 132, 132, 1);
// 				margin-top: 7px;
// 			}
// 		}
// 		.share-location-thump-image {
// 			width: 100%;
// 			height: 96px;
// 			margin-top: 3px;
// 		}
// 	}
// 	.modal-wrapper {
// 		// background: transparent !important;
// 		// background: rgba(var(--app-modal-bg-color-code), 0) !important;
// 		max-width: unset !important;
// 		max-height: unset !important;
// 		width: 100% !important;
// 		height: 100% !important;
// 	}
// 	.ion-page {
// 		display: flex;
// 		align-items: center;
// 		justify-content: flex-end;
// 	}
// 	.share-location-map-container {
// 		width: 100%;
// 		height: 100%;
// 		background-color: transparent !important;
// 		display: flex;
// 		flex-direction: column;
// 		position: relative;
// 	}
// 	.map-content {
// 		width: 100%;
// 		height: 100%;
// 		.map-test {
// 			width: 100%;
// 			height: 100%;
// 			background: transparent !important;
// 		}
// 	}
// 	.location-buttons {
// 		position: absolute;
// 		display: flex;
// 		width: 100%;
// 		flex-direction: column;
// 		background: transparent;
// 		align-items: flex-end;
// 		margin-bottom: 12px;
// 		padding-right: 14px;
// 		bottom: 336px;
// 		.location-network {
// 			width: 50px;
// 			height: 50px;
// 			border-radius: 100%;
// 			background: rgba(88, 88, 88, 1);
// 			display: flex;
// 			align-items: center;
// 			justify-content: center;
// 			margin-bottom: 14px;
// 			.location-icon {
// 				width: 24px;
// 				height: 32px;
// 			}
// 		}
// 		.current-location {
// 			width: 50px;
// 			height: 50px;
// 			border-radius: 100%;
// 			background: rgba(88, 88, 88, 1);
// 			display: flex;
// 			align-items: center;
// 			justify-content: center;
// 			.location-icon {
// 				width: 24px;
// 				height: 32px;
// 			}
// 		}
// 	}
// 	.content {
// 		width: 100%;
// 		height: 336px;
// 		border-top-left-radius: 14px;
// 		border-top-right-radius: 14px;
// 		background-color: rgba(239, 239, 239, 0.9);
// 		display: flex;
// 		flex-direction: column;
// 		padding-bottom: 34px;
// 		position: absolute;
// 		bottom: 0px;

// 		.searchbar-wrapper {
// 			--background: #ffffff;
// 			padding-top: 10px;
// 			padding-bottom: 6px;
// 			padding-left: 8px;
// 			padding-right: 8px;
// 			height: 52px;

// 			.searchbar-input-container {
// 				display: flex;
// 				align-items: center;
// 				justify-content: center;
// 				.searchbar-search-icon.sc-ion-searchbar-ios {
// 					margin-left: calc(50% - 64px) !important;
// 				}
// 				.searchbar-input {
// 					font-size: 16px;
// 					line-height: 16px;
// 					display: flex;
// 					justify-content: center;
// 					color: #000000;
// 					border-radius: 10px;

// 					&::placeholder {
// 						text-align: center;
// 						color: #a5a5a5;
// 						margin-left: 10px;
// 					}

// 					.searchbar-search-icon {
// 						margin-left: calc(50% - 56.5px);
// 						align-items: center;
// 					}
// 				}
// 			}
// 		}
// 		ion-list {
// 			// background: var(--app-color-code) !important;
// 			padding: 0 !important;
// 			// background-color: #efefef !important;
// 			ion-item {
// 				height: 65px;
// 				font-family: 'Metropolis';
// 				font-style: normal;
// 				font-weight: 400;
// 				font-size: 17px;
// 				line-height: 17px;
// 				color: #000000;
// 				--background: #efefef;
// 				--padding-start: 17px;
// 				--inner-padding-end: 21px;
// 			}
// 		}

// 		.location-block {
// 			width: 100%;
// 			height: 68px;
// 			background-color: rgba(239, 239, 239, 0.7);
// 			padding-left: 16px;
// 			padding-right: 18px;
// 			display: flex;
// 			justify-content: space-between;
// 			.location-content {
// 				display: flex;
// 				flex-direction: column;

// 				.location-text {
// 					height: 17px;
// 					font-family: 'Metropolis';
// 					font-style: normal;
// 					font-weight: 500;
// 					font-size: 18px;
// 					line-height: 18px;
// 					color: #000000;
// 					margin-top: 13px;
// 				}
// 				.location-address {
// 					height: 14px;
// 					font-family: 'Metropolis';
// 					font-style: normal;
// 					font-weight: 400;
// 					font-size: 14px;
// 					line-height: 14px;
// 					color: #858484;
// 					margin-top: 7px;
// 				}
// 			}
// 			.location-selected-icon {
// 				padding-top: 26px;
// 			}
// 		}
// 		.bottom-buttons {
// 			width: 100%;
// 			height: 66px;
// 			padding-left: 16px;
// 			padding-right: 16px;
// 			display: flex;
// 			justify-content: space-evenly;
// 			align-items: center;
// 			background: rgba(239, 239, 239, 0.7);
// 			.cancel {
// 				width: 176px;
// 				height: 16px;
// 				text-align: center;
// 				font-size: 18px;
// 				font-weight: 500;
// 				font-family: 'Metropolis';
// 				font-style: normal;
// 				color: #000000;
// 			}
// 			.share {
// 				width: 176px;
// 				height: 16px;
// 				text-align: center;
// 				font-size: 18px;
// 				font-weight: 500;
// 				font-family: 'Metropolis';
// 				font-style: normal;
// 				color: rgba(255, 94, 94, 1);
// 			}
// 		}
// 		.move-trash {
// 			display: flex;
// 			align-self: center;
// 			align-items: center;
// 			justify-content: center;
// 			margin-top: 19px;
// 			height: 18px;

// 			ion-button {
// 				text-transform: capitalize;
// 				--background: none;
// 				--box-shadow: none;
// 				--border: none;
// 				color: #ff5e5e;

// 				font-family: 'Metropolis';
// 				font-style: normal;
// 				font-weight: 500;
// 				font-size: 18px;
// 				line-height: 18px;
// 			}
// 		}

// 		.sub-modal {
// 			width: 100%;
// 			height: 178px;
// 			.modal-footer {
// 				margin-top: 40px;

// 				display: flex;
// 				flex-direction: row;
// 				justify-content: space-evenly;

// 				.modal-icons {
// 					display: flex;
// 					flex-direction: column;
// 					align-items: center;
// 					img {
// 						width: 27px;
// 						height: 24px;
// 					}
// 					.folder {
// 						margin-top: 19px;
// 						font-size: 14px;
// 						font-weight: 400;
// 					}
// 				}
// 			}
// 		}
// 	}
// }
.share-location-modal {
	.testMode {
		background-color: #f00;
		width: 100px;
		height: 50px;
	}
	// 	.share-location-thump {
	// 		width: 255px;
	// 		height: 149px;
	// 		border-radius: 6px;
	// 		background-color: rgba(255, 255, 255, 1);
	// 		display: flex;
	// 		flex-direction: column;
	// 		.share-location-thump-content {
	// 			width: 100%;
	// 			height: 53px;
	// 			padding-left: 12px;
	// 			padding-top: 12px;
	// 			display: flex;
	// 			flex-direction: column;

	// 			.share-location-thump-content-text {
	// 				width: 100%;
	// 				height: 17px;
	// 				font-family: Metropolis;
	// 				font-size: 18px;
	// 				font-weight: 400;
	// 				line-height: 18px;
	// 				letter-spacing: 0em;
	// 				text-align: left;
	// 				color: #000000;
	// 			}
	// 			.share-location-thump-content-address {
	// 				width: 100%;
	// 				height: 14px;
	// 				font-family: Metropolis;
	// 				font-size: 14px;
	// 				font-weight: 400;
	// 				line-height: 14px;
	// 				letter-spacing: 0em;
	// 				text-align: left;
	// 				color: rgba(133, 132, 132, 1);
	// 				margin-top: 7px;
	// 			}
	// 		}
	// 		.share-location-thump-image {
	// 			width: 100%;
	// 			height: 96px;
	// 			margin-top: 3px;
	// 		}
	// 	}
	.modal-wrapper {
		background: rgba(var(--app-modal-bg-color-code), 0) !important;
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		height: 100% !important;

		.ion-page {
			display: flex;
			// align-items: center;
			justify-content: flex-end;
			.share-location-map-container {
				width: 100vw;
				height: 100vh;
				display: flex;
				flex-direction: column;
				position: relative;
				background-color: rgba(239, 239, 239, 0.9);

				.map-content {
					width: 100vw;
					height: 100vh;
					.mapboxgl-canvas {
						width: 100vw !important;
						height: 100vh !important;
					}
					.map-test {
						width: 100% !important;
						height: 100% !important;
						background: transparent !important;
					}
				}
				.location-buttons {
					position: absolute;
					display: flex;
					right: 0;
					flex-direction: column;
					background: transparent;
					align-items: flex-end;
					margin-bottom: 12px;
					padding-right: 14px;
					bottom: 336px;

					.location-network {
						width: 50px;
						height: 50px;
						border-radius: 100%;
						background: rgba(173, 173, 179);
						display: flex;
						margin-bottom: 14px;
						--padding-start: 0;
						--padding-end: 0;
						.location-icon {
							width: 24px;
							height: 32px;
						}
					}
					.current-location {
						width: 50px;
						height: 50px;
						border-radius: 100%;
						background: rgba(173, 173, 179);
						display: flex;
						align-items: center;
						justify-content: center;
						--padding-start: 0;
						--padding-end: 0;
						.location-icon {
							width: 24px;
							height: 32px;
						}
					}
				}
				.view-location-buttons {
					position: absolute;
					display: flex;
					width: 100%;
					flex-direction: column;
					background: transparent;
					align-items: flex-end;
					margin-bottom: 11px;
					padding-right: 14px;
					bottom: 165px;

					.location-network {
						width: 50px;
						height: 50px;
						border-radius: 100%;
						background: rgba(173, 173, 179);
						display: flex;
						margin-top: 10px;
						margin-bottom: 14px;
						align-items: center;
						justify-content: center;
						--padding-start: 0;
						--padding-end: 0;
						.location-icon {
							width: 24px;
							height: 32px;
						}
					}
					.current-location {
						width: 50px;
						height: 50px;
						border-radius: 100%;
						background: rgba(173, 173, 179);
						display: flex;
						align-items: center;
						justify-content: center;
						--padding-start: 0;
						--padding-end: 0;
						.location-icon {
							width: 24px;
							height: 32px;
						}
					}
				}

				.content {
					width: 100%;
					height: 336px;
					border-top-left-radius: 14px;
					border-top-right-radius: 14px;
					background-color: rgba(239, 239, 239, 0.9);
					padding-bottom: 34px;
					position: absolute;
					bottom: 0px;
					.searchbar-wrapper {
						--background: #ffffff;
						padding-top: 0px;
						padding-bottom: 0px;
						padding-left: 8px;
						padding-right: 8px;
						height: 52px;
						margin-top: 10px;
						margin-bottom: 6px;

						.searchbar-input-container {
							display: flex;
							align-items: center;
							justify-content: center;

							.searchbar-input {
								font-size: 16px;
								line-height: 16px;
								display: flex;
								justify-content: center;
								color: #000000;
								border-radius: 10px;
							}
						}
					}
					.right-icon {
						width: 20px;
						height: 14px;
					}
					.title {
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 500;
						font-size: 22px;
						line-height: 22px;
						text-align: center;
						color: #000000;
						margin-bottom: 17px;
						margin-top: 39px;
					}

					.list-container {
						max-height: 195px;
						overflow-y: auto;
					}
					ion-list {
						padding: 0 !important;
						background: rgba(239, 239, 239, 0.7);
						ion-item {
							height: 65px !important;
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 400;
							font-size: 17px;
							line-height: 17px;
							color: #000000;
							--background: #efefef;
							--padding-start: 17px;
							--inner-padding-end: 21px;
						}
						.location-block {
							width: 100%;
							height: 68px;
							padding-left: 16px;
							--background: transparent;
							display: flex;
							justify-content: space-between;
							cursor: pointer;

							.location-content {
								display: flex;
								flex-direction: column;

								.location-text {
									height: 17px;
									font-family: 'Metropolis';
									font-style: normal;
									font-weight: 500;
									font-size: 18px;
									line-height: 18px;
									color: #000000;
									margin-top: 13px;
									display: -webkit-box;
									-webkit-box-orient: vertical;
									overflow: hidden;
									max-width: 200px;
									text-overflow: ellipsis;
								}
								.location-address {
									height: 14px;
									font-family: 'Metropolis';
									font-style: normal;
									font-weight: 400;
									font-size: 14px;
									line-height: 14px;
									color: #858484;
									margin-top: 7px;
									display: -webkit-box;
									-webkit-box-orient: vertical;
									overflow: hidden;
									-webkit-line-clamp: 1;
									text-overflow: ellipsis;
								}
							}
							.location-selected-icon {
								padding-top: 26px;
							}
						}
						// .list-item{
						// 	width: 70%;
						// }
					}
					.bottom-buttons {
						width: 100%;
						height: 111px;
						display: flex;
						justify-content: space-evenly;
						.cancel {
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 500;
							font-size: 18px;
							line-height: 18px;
							color: #000000;
							--box-shadow: none;
							--background: none;
							text-transform: capitalize;
						}
						.share {
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 600;
							font-size: 18px;
							line-height: 18px;
							text-align: center;
							--box-shadow: none;
							--background: none;
							text-transform: capitalize;
							color: #ff5e5e;

							&.button-disabled {
								color: #c4c4c4;
							}
						}
					}
				}
				.view-content {
					width: 100%;
					display: flex;
					flex-direction: column;
					height: 165px;
					border-top-left-radius: 14px;
					border-top-right-radius: 14px;
					background-color: rgba(239, 239, 239, 0.9);
					position: absolute;
					bottom: 0px;
					.searchbar-wrapper {
						--background: #ffffff;
						padding-top: 0px;
						padding-bottom: 0px;
						padding-left: 8px;
						padding-right: 8px;
						height: 52px;
						margin-top: 10px;
						margin-bottom: 6px;

						.searchbar-input-container {
							display: flex;
							align-items: center;
							justify-content: center;
							.searchbar-search-icon.sc-ion-searchbar-ios {
								margin-left: calc(50% - 64px) !important;
							}
							.searchbar-input {
								font-size: 16px;
								line-height: 16px;
								display: flex;
								justify-content: center;
								color: #000000;
								border-radius: 10px;

								&::placeholder {
									text-align: center;
									color: #a5a5a5;
									margin-left: 10px;
								}

								.searchbar-search-icon {
									margin-left: calc(50% - 56.5px);
									align-items: center;
								}
							}
						}
					}
					// align-items: center;
					.right-icon {
						width: 20px;
						height: 14px;
					}
					.title {
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 500;
						font-size: 22px;
						line-height: 22px;
						text-align: center;
						color: #000000;
						margin-bottom: 17px;
						margin-top: 39px;
					}

					ion-list {
						margin-top: 11px;
						padding: 0 !important;
						background: rgba(239, 239, 239, 0.7);
						ion-item {
							height: 65px !important;
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 400;
							font-size: 17px;
							line-height: 17px;
							color: #000000;
							--background: #efefef;
							--padding-start: 17px;
							--inner-padding-end: 21px;
						}
						.location-block {
							width: 100%;
							background-color: rgba(239, 239, 239, 0.7);
							padding-left: 16px;
							display: flex;
							justify-content: space-between;
							.location-content {
								display: flex;
								flex-direction: column;
								margin-bottom: 8px;

								.location-text {
									height: 17px;
									font-family: 'Metropolis';
									font-style: normal;
									font-weight: 500;
									font-size: 18px;
									line-height: 18px;
									color: #000000;
									margin-top: 13px;
									display: -webkit-box;
									-webkit-box-orient: vertical;
									overflow: hidden;
									max-width: 200px;
									text-overflow: ellipsis;
								}
								.location-address {
									height: 14px;
									font-family: 'Metropolis';
									font-style: normal;
									font-weight: 400;
									font-size: 14px;
									line-height: 14px;
									color: #858484;
									margin-top: 7px;
									display: -webkit-box;
									-webkit-box-orient: vertical;
									overflow: hidden;
									max-width: 364px;
									-webkit-line-clamp: 1;
									text-overflow: ellipsis;
								}
							}
							.location-selected-icon {
								padding-top: 26px;
							}
						}
						// .list-item{
						// 	width: 70%;
						// }
					}
					.bottom-buttons {
						width: 100%;
						height: 90px;
						display: flex;
						justify-content: space-evenly;
						.cancel {
							margin-top: 11px;
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 500;
							font-size: 18px;
							line-height: 18px;
							color: #000000;
							--box-shadow: none;
							--background: none;
							text-transform: capitalize;
						}
						.share {
							margin-top: 11px;
							height: 40px;
							font-family: 'Metropolis';
							font-style: normal;
							font-weight: 600;
							font-size: 18px;
							line-height: 18px;
							text-align: center;
							--box-shadow: none;
							--background: none;
							text-transform: capitalize;
							color: #ff5e5e;

							&.button-disabled {
								color: #c4c4c4;
							}
						}
					}
				}
			}
		}
	}
}

// @media screen and (max-width: 1200px) and (min-width: 300px) {
//   div#desktopview {
//     display: none;
//   }
// }
// @media only screen and (min-width: 1200px) {
//   div#mobileview {
//     display: none;
//   }
// }

// #desktopview {
//   .modal-icons {
//     margin-left: 50px;
//   }
// }
// #mobileview {
//   .modal-icons {
//     margin-left: none;
//   }
// }
