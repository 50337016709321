.moments-page {
	.moments-header {
		* {
			/*font-family: ArialBlack;*/
			font-weight: 700;
		}

		position: absolute;
		--background: transparent;
		--color: #fff;

		.toolbar-btn {
			--color: #000;

			&.blurBackground {
				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 5px;
					width: 100%;
					height: 80%;
					background-color: rgba(220, 220, 220, 0.8);
					filter: blur(10px);
					z-index: -1;
					border-radius: 16px;
				}
			}
		}

		ion-segment {
			min-height: 32px;

			&.blurBackground {
				&:before {
					content: '';
					position: absolute;
					width: 100%;
					height: 100%;
					background-color: rgba(220, 220, 220, 0.8);
					filter: blur(10px);
					z-index: -1;
					border-radius: 16px;
				}
			}

			ion-segment-button {
				--margin-top: 0;
				--margin-bottom: 0;
				--padding-top: 0;
				--padding-bottom: 0;
				--indicator-color: #000;
				--indicator-height: 3px;
				--color: #585858;
				--color-checked: #000;
				font-size: 14px;
				text-transform: unset;
				min-height: 32px;
			}
		}
	}

	ion-content {
		* {
			/*font-family: ArialBlack;*/
			font-weight: 700;
		}
		--background: var(--app-color-code);

		button:focus {
			outline: none;
		}

		.icon-btn {
			margin: 10px;
			display: flex;
			flex-direction: column;
			align-items: center;
			background-color: transparent;

			ion-icon {
				height: 24px;
				width: 24px;
				font-size: 24px;
			}

			ion-label {
				font-size: 12px;
				margin-top: 5px;
				color: #000;
			}
		}

		.hscroll-container {
			display: flex;
			flex-direction: row;
			overflow-x: scroll;

			.hscroll-inner {
				width: auto;
				height: 100%;
				display: flex;
				flex-direction: row;
			}
		}

		.top-bg {
			display: flex;
			align-items: center;
			position: relative;
			justify-content: center;
			z-index: 1;
			width: 100%;
			min-height: 200px;
			ion-img {
				width: 100%;
			}

			.overlay {
				position: absolute;
				flex: 1;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.2);
			}
		}

		.name-profileimg-view {
			position: relative;
			z-index: 2;
			display: flex;
			margin-top: -50px;
			flex-direction: row;
			text-align: left;

			.labels-view {
				flex: 1;
				display: flex;
				flex-direction: column;
				padding: 16px;
				padding-bottom: 0px;

				.name {
					// font-weight: bold;
					font-size: 20px;
					color: #fff;
					/*font-family: ArialBlack;*/
					font-weight: 700;
				}

				.link {
					font-size: 14px;
					color: #585858;
					margin-top: 20px;
				}
			}

			ion-thumbnail {
				--size: 100px;
				--border-radius: 5px;
				margin-right: 10px;
			}
		}

		.moments-list {
			display: flex;
			flex-direction: column;
			text-align: left;

			.list-item {
				display: flex;
				flex-direction: row;
				padding: 16px;
				width: 100%;

				.left-content {
					display: flex;
					flex-direction: column;
					align-items: center;

					ion-thumbnail {
						--size: 48px;
						--border-radius: 3px;
					}

					ion-label {
						color: #535353;
						font-size: 10px;
						margin-top: 3px;
					}
				}

				.right-content {
					flex: 1;
					display: flex;
					flex-direction: column;
					padding-left: 10px;

					.name {
						font-size: 22px;
						// font-weight: bold;
						/*font-family: ArialBlack;*/
						font-weight: 700;
					}

					.title {
						font-size: 18px;
						margin: 8px 0;
					}

					.photos-container {
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;

						ion-thumbnail {
							--size: 80px;
							min-width: 80px;
							min-height: 80px;
							margin: 1px;
						}
					}

					.item-bottom-view {
						display: flex;
						flex-direction: row;
						align-items: center;
						min-height: 40px;
						margin: 5px 0;

						.location {
							font-size: 14px;
							margin: 8px 0;
							color: #535353;
							position: absolute;
						}

						.option-container {
							display: flex;
							flex-direction: row;
							flex: 1;
							align-items: center;
							justify-content: flex-end;

							.option-view {
								margin: 0 3px;
								display: flex;
								flex-direction: row;
								overflow: hidden;
								width: 0%;
								align-items: center;
								justify-content: center;
								background-color: #535353;
								height: 40px;
								z-index: 1;
								border-radius: 3px;

								ion-button {
									font-size: 12px;
									font-weight: 600;
									display: flex;
									flex-direction: row;
									color: #fff;
									align-items: center;
									background-color: transparent;
								}

								&.open {
									width: 225px;
									animation-name: reducetime;
									animation-duration: 200ms;
								}
								&.close {
									width: 0;
									animation-name: closereducetime;
									animation-duration: 200ms;
								}
							}
						}

						@keyframes reducetime {
							0% {
								width: 0px;
							}
							100% {
								width: 225px;
							}
						}

						@keyframes closereducetime {
							0% {
								width: 225px;
							}
							100% {
								width: 0px;
							}
						}

						.option-btn {
							background-color: #dadada;
							width: 40px;
							height: 20px;
							border-radius: 3px;
							ion-icon {
								color: #979797;
								font-size: 20px;
							}
						}
					}
				}
			}
		}

		.reposts-list {
			margin-top: 80px;
			display: flex;
			flex-direction: column;
			text-align: left;

			.list-item {
				display: flex;
				flex-direction: column;
				width: 100%;

				.header-view {
					width: 100%;
					display: flex;
					flex-direction: row;
					padding: 16px;
					padding-bottom: 0;

					.left-content {
						display: flex;
						flex-direction: column;
						align-items: center;

						ion-thumbnail {
							--size: 48px;
							--border-radius: 3px;
						}

						.time {
							display: flex;
							flex-direction: row;
							align-items: center;
							justify-content: center;
							margin-top: 5px;
							ion-icon {
								font-size: 12px;
								color: #535353;
							}
							ion-note {
								color: #535353;
								margin-left: 3px;
								font-size: 12px;
							}
						}
					}

					.right-content {
						flex: 1;
						display: flex;
						flex-direction: column;
						padding-left: 10px;

						ion-note {
							margin-left: 4px;
							font-size: 14px;
							font-weight: 600;
							color: #535353;
						}

						.name {
							font-size: 18px;
							/*font-family: ArialBlack;*/
							font-weight: 700;
						}

						.location {
							ion-icon {
								font-weight: 600;
							}
						}

						.title {
							font-size: 18px;
						}
					}
				}

				.cards-container {
					ion-slides {
						ion-slide {
							text-align: left;
							max-width: 300px !important;
							min-width: 300px !important;
							width: 300px !important;
						}
					}
					ion-card {
						min-width: 290px !important;
						border-radius: 15px;
						--background: #fff;

						ion-card-title {
							font-size: 14px;
						}

						.img-view-container {
							position: relative;

							ion-img {
								width: 100%;
								height: 200px;
								object-fit: cover;
							}

							.img-overlay-view {
								padding: 8px 0;
								position: absolute;
								width: 100%;
								height: 100%;
								background-color: rgba(255, 255, 255, 0.4);
								top: 0;
								display: flex;
								flex-direction: row;
								.left {
									flex: 1;
								}
								.right {
									display: flex;
									flex-direction: column;
								}
							}
						}

						.link-view {
							display: flex;
							flex-direction: row;
							align-items: center;
							ion-img {
								width: 25px;
								height: 25px;
								margin-right: 5px;
							}
						}
					}
				}
			}

			.alternet-view-points {
				width: 100%;

				ion-slides {
					ion-slide {
						text-align: left;
						max-width: 230px !important;
						min-width: 230px !important;
						width: 230px !important;
					}
				}
				.title {
					padding: 16px;
					height: 33px;
					font-size: 18px;
					/*font-family: ArialBlack;*/
					font-weight: 700;
				}

				ion-card {
					text-align: left;
					width: 220px !important;
					border-radius: 6px;
					--background: #fff;

					ion-card-header {
						padding: 4px 12px;
						.logo-row {
							display: flex;
							flex-direction: row;
							img {
								max-height: unset;
								max-width: unset;
								margin: 0;
								height: 20px;
							}
						}

						ion-card-subtitle {
							margin: 0;
						}
					}
				}
			}
		}

		.status-list {
			margin-top: 80px;
			margin-bottom: 70px;
			display: flex;
			flex-direction: column;
			text-align: left;

			.option-btn {
				position: absolute;
				right: 0;
				color: #979797;
			}

			.list-item {
				display: flex;
				flex-direction: column;
				width: 100%;

				.header-view {
					width: 100%;
					display: flex;
					flex-direction: row;
					padding: 16px;
					padding-bottom: 0;

					.left-content {
						display: flex;
						flex-direction: column;
						align-items: center;

						ion-thumbnail {
							--size: 48px;
							--border-radius: 3px;
						}

						.time {
							display: flex;
							flex-direction: row;
							align-items: center;
							justify-content: center;
							margin-top: 5px;
							ion-icon {
								font-size: 12px;
								color: #535353;
							}
							ion-note {
								color: #535353;
								margin-left: 3px;
								font-size: 12px;
							}
						}
					}

					.right-content {
						flex: 1;
						display: flex;
						flex-direction: column;
						padding-left: 10px;

						ion-note {
							margin-left: 4px;
							font-size: 14px;
							font-weight: 600;
							color: #535353;
						}

						.name {
							font-size: 18px;
							/*font-family: ArialBlack;*/
							font-weight: 700;
						}

						.location {
							ion-icon {
								font-size: 14px;
							}
						}

						.title {
							font-size: 18px;
						}

						.status-text {
							font-size: 16px;
							color: #000000;
							margin-top: 10px;
						}

						.icon-btns-container {
							display: flex;
							flex-direction: row;
							.icon-btn {
								margin-left: 0;
								margin-right: 20px;
							}
						}
					}
				}
			}
		}
	}
}
