.personal-storage-modal {
	.modal-wrapper {
		background: rgba(var(--app-modal-bg-color-code), 0) !important;
		max-width: unset !important;
		max-height: unset !important;
		width: 100% !important;
		height: 100% !important;
	}
	.ion-page {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.content {
		border-radius: 10px;
		width: 100%;
		height: 259px;
		background-color: #efefefff;

	.select-main {
		display: flex;
		flex-direction: column;
		background-color: #efefef;
		border-radius: 14px 14px 0px 0px;

		width: 100%;

		.storage {
			text-align: center;
			font-size: 16px;
			font-weight: 600;
			margin-top: 29px;
			color: black;
		}
		.cancel {
			margin-top: 45px;
			text-align: center;
			font-size: 16px;
			font-weight: 500;
			color: black;
		}

		.sub-modal {
			width: 100%;
			height: 178px;
			.modal-footer {
				margin-top: 40px;

				display: flex;
				flex-direction: row;
				justify-content: space-evenly;

				.modal-icons {
					display: flex;
					flex-direction: column;
					align-items: center;
					img {
						width: 27px;
						height: 24px;
					}
					.folder {
						margin-top: 19px;
						font-size: 14px;
						color: black;
						font-weight: 400;
					}
				}
			}
		}
	}}
}
@media screen and (max-width: 1200px) and (min-width: 300px) {
	div#desktopview {
		display: none;
	}
}
@media only screen and (min-width: 1200px) {
	div#mobileview {
		display: none;
	}
}

#desktopview {
	.modal-icons {
		margin-left: 50px;
	}
}
#mobileview {
	.modal-icons {
		margin-left: none;
	}
}
