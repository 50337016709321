.group-about-modal {
	.topbar-header {
		ion-title {
			.title {
				font-family: Metropolis;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 18px;
				text-align: center;
				color: #000000;
			}
		}
		.buttons-last-slot {
			ion-button {
				font-family: Metropolis;
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 18px;
				display: flex;
				align-items: center;
				text-align: right;

				--color: #ff5e5e;
				&.button-disabled {
					--color: #c4c4c4;
				}
			}
		}
	}
	.container {
		width: 100%;
		height: 100%;
		ion-textarea {
			width: 100%;
			height: 100%;
			.textarea-wrapper {
				width: 100%;
				height: 100%;
				textarea {
					/* Hide scrollbar for IE, Edge and Firefox */
					-ms-overflow-style: none; /* IE and Edge */
					scrollbar-width: none; /* Firefox */
					/* Hide scrollbar for Chrome, Safari and Opera */
					&::-webkit-scrollbar {
						display: none;
					}
					width: 100%;
					height: 100%;
					padding: 16px 18px;
					font-family: Metropolis;
					font-style: normal;
					font-weight: normal;
					font-size: 20px;
					line-height: 140%;
					display: flex;
					align-items: center;
					color: #000000;
					::placeholder {
						color: #858484;
					}
				}
			}
		}
	}
}
.group-about-modal {
	--height: 100% !important;
}
