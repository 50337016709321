.contact-item {
	display: flex;
	flex-direction: column;
	width: 60px;
	min-width: 60px;
	align-items: center;
	justify-content: flex-start;
	margin-right: 32px;
	position: relative;
	img {
		background: #ffffff;
		border-radius: 4px;
		width: 50px;
		height: 50px;

		object-fit: cover;
	}
	.title {
		margin-top: 7px;
		font-family: Metropolis;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 14px;
		text-align: center;
		color: #585858;
		box-sizing: content-box;
		width: 100%;
		white-space: normal;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.contact-add-remove-item {
		width: 50px;
		height: 50px;
		border: 1px dashed #858484;
		box-sizing: border-box;
		border-radius: 4px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		.horizontal-line,
		.vertical-line {
			position: absolute;
			height: 1px;
			width: 20px;
			background-color: #858484;
			border-radius: 4px;
		}
		.vertical-line {
			transform: rotateZ(90deg);
		}
	}
	.remove-icon {
		position: absolute;
		top: -7px;
		right: -5px;
		--padding-start: 0;
		--padding-end: 0;
		height: 22px;
		width: 22px;
		margin: 0;
		ion-icon {
			width: 100%;
			height: 100%;
		}
	}
}
