.account-setting-page {
	ion-list {
		background: transparent;
		ion-item {
			--background: transparent;
			--min-height: 56px;

			ion-lebal {
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 400;
				font-size: 17px;
				line-height: 17px;
				color: #000000;
			}
		}
	}
	.ct-icon-back {
		color: #000000 !important;
	}
	.profile-list-sec2 {
		.edit-profile-start {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 400;
			font-size: 17px;
			line-height: 17px;
			color: #000000;
		}

		.edit-profile-p {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 120%;
			text-align: right;
			color: #a5a5a5;
		}

		.div-first {
			top: 88px;
			width: 100%;
			// height: 70px;
			display: flex;
			flex-direction: column;
			.storage-space {
				--min-height: 16px;
				margin-bottom: 9px;
				margin-left: 15px;
				.storage {
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					display: flex;
					align-items: flex-end;
					letter-spacing: -0.02em;
					color: #000000;
					margin: 0;
				}
			}

			.graph {
				display: flex;
				margin-left: 24px;
				margin-right: 24px;
				height: 16px;
				background-color: #dadada;
				//   margin-top: 26.35px;

				.box1 {
					display: flex;
					background-color: #ff5e5e;
					width: 59px;
					z-index: 1;
				}
				.smallbox {
					width: 35px;
					background-color: #3ebaff;
					z-index: 1;
				}
				.smallboxyellow {
					width: 35px;
					background-color: #ffcc4d;
					z-index: 1;
				}
			}
			.searchbar-wrapper {
				--background: #ffffff;
				padding-bottom: 0;
				--border-radius: 5px !important;

				.searchbar-input-container {
					border-radius: 5px !important;

					.searchbar-input {
						font-size: 16px;
						line-height: 16px;
						color: #000000;
						margin-left: 21px;

						&::placeholder {
							color: #a5a5a5;
						}
					}
				}
			}

			.category-items {
				display: flex;
				margin-left: 24px;
				//   margin-right: 4%;
				height: 16px;
				margin-top: 7px;
				IonLabel {
					padding-left: 3px;
				}

				.cat-item {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
					margin-right: 10px;
					.square {
						width: 13px;
						height: 13px;
						margin-right: 3px;
						margin-left: 8px;
					}
					.cat-title {
						border-radius: nullpx;
						margin-left: 3px;
						font-family: 'Metropolis';
						font-style: normal;
						font-weight: 500;
						font-size: 13px;
						line-height: 13px;
						display: flex;
						align-items: flex-end;
						letter-spacing: -0.02em;
						color: #858484;
					}
				}
			}
		}
	}
}

.privacy-setting-page {
	.moments {
		width: 100%;
		height: 18px;
		margin-top: 10px;
		background: #efefef;
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 14px;
		color: #858484;
		padding-left: 17px;
		padding-top: 3px;
		padding-bottom: 1px;
	}

	.visible-all {
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 16px;
		text-align: right;
		color: #858484;
		margin-right: 11px;
		padding: 0 !important;
	}

	.set-alias {
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 14px;
		color: #777777;
		margin-top: 20px;
		margin-bottom: 1px;
	}
	.swtich-item {
		ion-toggle.toggle-customised {
			--background: #dcdcdc;
			--background-checked: #3ebaff;
			--handle-background-checked: white;
			--handle-background: white;
			--handle-height: 20px;
			--handle-width: 20px;
			--handle-border-radius: 50%;
			--handle-max-height: 20px;
			--handle-spacing: 1px;
			width: 40px;
			height: 21px;
		}
	}

	ion-list {
		background: transparent;
		ion-list-header {
			--background: #dcdcdc;
			--color: #585858;
			font-size: 15px;
			line-height: 30px;
			min-height: 34px;
			padding-inline-start: 20px;
		}
		ion-item {
			--min-height: 55px;
			--background: transparent;
			color: #000000;
			ion-text {
				font-weight: normal;
				font-size: 16px;
				line-height: 29px;
				color: #585858;
			}
		}
	}
}

.userprivacy-setting-page {
	ion-list {
		padding-top: 0;
		padding-bottom: 0;
	}

	.privacy-item {
		background: #e6e6e6;
		border-radius: 8px;
		padding-left: 19px;
		padding-right: 19px;
		padding-top: 14px;
		padding-bottom: 11px;
		margin-bottom: 16px;
		margin-left: 18px;
		margin-right: 18px;
		ion-item {
			--background: #e6e6e6;

			ion-icon {
				--color: red;
				-webkit-text-stroke-color: red;
			}
		}

		h2 {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 16px;
			color: #000000;
		}
		p {
			margin-top: 7px;
			padding: 0;
			white-space: normal;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 140%;
			color: #585858;
		}

		.profile-container {
			display: flex;
			margin-top: 12px;
			margin-left: 1px;

			.profile-image-container {
				width: 50px;
				height: 50px;
				border-radius: 4px;
				margin-bottom: 16px;
			}
			.profile-title-container {
				display: flex;
				flex-direction: column;
				margin-left: 13px;
				.progile-photo-title {
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 16px;
					color: #585858;
					padding-top: 7px;
				}
				.tap-change {
					font-family: 'Metropolis';
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 140%;
					color: #585858;
					margin-top: 7px;
				}
			}
		}
		&.selected {
			margin-left: 18px;
			margin-right: 18px;
			ion-item {
				--detail-icon-color: red;
				--background: transparent;
			}
			background: rgba(255, 94, 94, 0.25);
			opacity: 0.88;
			.profile-title-container {
				:first-child {
					padding-top: 7px;
					color: #ff5e5e;
				}
				:last-child {
					color: #ff5e5e;
					margin-top: 7px;
				}
			}
			h2,
			p {
				color: #ff5e5e;
				stroke: red;
			}
		}
	}

	.moments {
		width: 100%;
		height: 18px;
		background: #efefef;
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 14px;
		color: #858484;
		padding-left: 17px;
		padding-top: 3px;
		padding-bottom: 1px;
	}
}

.remove-warning-alert {
	.alert-wrapper.sc-ion-alert-ios {
		background: #ffffff;
		min-width: 320px;
		min-height: 173px;
	}
	.alert-message {
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 120%;
		color: #000000;
		margin: 14px 21px 10px 22px;
	}
	.alert-button {
		height: 18px;
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 18px;
		color: #000000;
		border: none;
		margin-bottom: 18px;
		border-top: none !important;
		border-right: none !important;
		&.ok {
			color: #ff5e5e;
		}
	}
}

.delete-account-page {
	.user-image-contaoner {
		width: 100%;
		height: 138px;
		margin-top: 56px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 9px;
		padding-bottom: 9px;
		.user-image {
			width: 120px;
			height: 120px;
		}
	}
	.delete-message {
		width: 100%;
		height: 71px;
		padding-top: 13px;
		padding-bottom: 12px;
		text-align: center;
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 24px;
		text-align: center;
		color: #000000;
	}
	.delete-temp-message {
		width: 100%;
		height: 18px;
		margin-top: 20px;
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 14px;
		color: #000000;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 3px;
		padding-bottom: 1px;
	}
	.delete-message-hidden {
		width: 100%;
		height: 34px;
		padding-top: 5px;
		text-align: center;
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 13px;
		color: #858484;
	}
	.free-space {
		display: flex;
		flex: 1;
		min-height: 150px;
	}
	.deactivate-button {
		display: flex;
		justify-content: center;
		.action-btn {
			width: 50%;
			height: 48px;
			margin: 0;
			--border-radius: 10px;
			--background: #ff5e5e;
			text-transform: none;
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 18px;
			text-align: center;
			color: #e8e8e8;
		}
	}
	.delete-account-button {
		width: 100%;
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 16px;
		text-align: center;
		cursor: pointer;
		color: #585858;
		margin-bottom: 50px;
	}
}
.delete-menu-actionsheet {
	.action-sheet-container {
		padding: 0;
	}

	.action-sheet-group {
		border-radius: 10px 10px 0 0;
		margin-bottom: 0 !important;
		padding: 0 !important;
		padding-top: 18px !important;
		padding-bottom: 37px !important;
		.action-sheet-title {
			font-size: 12px;
			padding-top: 11px;
		}

		.action-sheet-button {
			padding: 0 10px;
			text-align: center;
			height: 56px;
			margin-top: 10px;
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 22px;
			color: #000000;
			--ripple-color: transparent;
			margin-top: 8px;

			&.thread-menu-button {
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 18px;
				text-align: center;
				color: #ff5e5e;
			}
			span {
				justify-content: center;
			}
		}
	}
}
