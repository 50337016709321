ion-content.has-bottom-navbar {
	--padding-bottom: var(--bottom-bar-height) !important;
}
.link {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 999;
}

.bottom-tabs-bar {
	border: none;
	height: 42px;
	padding-bottom: 30px;
	padding-top: 18px;
	background: rgba(var(--app-modal-bg-color-code), 0.7);
	backdrop-filter: blur(20px);
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	@supports not (backdrop-filter: none) {
		background-image: url(./../../../assets/blur-gray-bg.png) !important;
		background-size: 100% 100%;
		background-position: top left;
	}

	.bottom-tabs {
		--color: black;
		border: none;
		background: transparent;
		height: 68px;
		ion-icon {
			margin: 0;
		}
		ion-label {
			margin: 0;
			margin-top: 8px;
			font-size: 12px;
			font-weight: 400;
		}
		.dot-circle {
			margin-left: 3px;
			position: absolute;
		}

		&.large-btn {
			.dot-circle {
				margin-left: 8px;
				top: 5px;
			}
			ion-icon {
				font-size: 40px;
				width: 40px;
				height: 40px;
			}
		}
		.ionIcon1 {
			font-size: 24px;
			display: none;
		}
		.ionIcon2 {
			font-size: 24px;
			display: block;
		}
	}

	&.deselect-alltabs {
		ion-label {
			color: black !important;
		}

		.ionIcon1 {
			display: none !important;
		}
		.ionIcon2 {
			display: block !important;
		}
	}

	.tab-selected {
		color: red !important;

		.ionIcon1 {
			display: block;
		}
		.ionIcon2 {
			display: none;
		}
	}
}
