.scketchpad-textStyle-popover-style {
	.popover-content {
		background: rgba(0, 0, 0, 0.6);

		ion-list {
			padding: 9px 0;
			background-color: transparent;
			ion-item {
				--height: 35.8px !important;
				--min-height: 35.8px !important;
				--background: transparent;
				&.active {
					ion-label {
						text-underline-offset: 3px;
						text-decoration-line: underline;
					}
				}
				ion-label {
					font-family: 'Metropolis';
					color: #ffffff;
					margin: 0;
					display: flex;
					align-items: center;
					height: 100%;
				}
			}
		}
	}
}
