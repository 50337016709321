.group-info-page {
	.starred-wrapper {
		align-items: center;
		justify-content: center;
	}
	.searchbar-wrapper {
		--background: #ffffff;
		margin-top: 5px;
		--box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		padding-bottom: 0;
		--border-radius: 5px !important;
		.searchbar-input-container {
			border-radius: 5px !important;
		}
	}

	ion-item {
		--padding-start: 18px !important;
		--background: transparent;
	}

	.non-admin-container {
		height: 140px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		.nonadmin-img {
			width: 100px;
			height: 100px;
			border-radius: 4px;
			overflow: hidden;
		}
	}
	.non-architecture {
		width: 100%;
		height: 40px;
		padding-top: 7px;
		padding-bottom: 7px;
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 600;
		font-size: 30px;
		line-height: 30px;
		text-align: center;
		color: #000000;
	}
	.non-architecture-text {
		width: 100%;
		height: 77px;
		margin-top: 6px;
		padding-left: 26px;
		padding-right: 25px;
		padding-top: 6px;
		padding-bottom: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 140%;
		text-align: center;
		color: #585858;
	}

	.participants {
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 18px;
		color: #858484;
		margin-left: 17px;
		margin-top: 8px;
		display: inline-block;
	}
	.selected-contacts-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		position: relative;
		background: #efefef;
		.contact-item {
			display: flex;
			flex-direction: column;
			width: 60px;
			min-width: 60px;
			align-items: center;
			justify-content: flex-start;
			margin: 15px 11px 5px;
			img {
				background: #ffffff;
				border-radius: 4px;
				width: 50px;
				height: 50px;

				object-fit: cover;
			}
			.title {
				margin-top: 7px;
				font-family: Metropolis;
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 14px;
				text-align: center;
				color: #585858;
				box-sizing: content-box;
				width: 100%;
				white-space: normal;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.contact-add-remove-item {
				width: 50px;
				height: 50px;
				border: 1px dashed #858484;
				box-sizing: border-box;
				border-radius: 4px;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				.horizontal-line,
				.vertical-line {
					position: absolute;
					height: 1px;
					width: 20px;
					background-color: #858484;
					border-radius: 4px;
				}
				.vertical-line {
					transform: rotateZ(90deg);
				}
			}
		}
	}

	.group-field-item {
		.row-first {
			display: flex;
			flex-direction: row;
			align-items: center;
			min-height: 37px;
			justify-content: space-between;
			.title {
				flex: 1;
				font-family: Metropolis;
				font-style: normal;
				font-weight: normal;
				font-size: 17px;
				line-height: 17px;
				color: #000000;
				max-width: 88%;
			}

			.value {
				display: flex;
				align-items: center;
				font-family: Metropolis;
				font-style: normal;
				font-weight: normal;
				font-size: 17px;
				line-height: 17px;
				text-align: right;

				img {
					width: 36px;
					height: 36px;
					border-radius: 4px;
				}

				ion-icon {
					width: 7px;
					height: 13px;
					margin-left: 12px;
					fill: #a5a5a5;
				}

				.placeholder {
					color: #a5a5a5;
				}

				.data {
					color: #858484;
					font-weight: 500;
				}
			}
			// .value:hover {
			// 	--background-hover: none !important;
			// }
		}

		&.group-name-item {
			height: 46px;
			cursor: pointer;
		}
		&.about-item {
			cursor: pointer;
			padding-top: 8px;
			padding-bottom: 8px;
			.row-second {
				align-items: center;
				.title {
					font-size: 14px;
					line-height: 140%;
				}
			}
		}
	}

	.view-more-members {
		display: flex;
		align-items: center;
		justify-content: center;
		ion-button {
			font-family: 'Metropolis';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 164.8%;
			display: flex;
			align-items: center;
			color: #858484;
			text-transform: none;
			ion-icon {
				width: 10px;
				margin-left: 10px;
			}
		}
	}

	.admin-pic {
		margin-left: 17px;
		width: 60px;
		align-items: center;
		display: flex;
		height: 83px;
		flex-direction: column;
		margin-top: 12px;

		.admin-img {
			width: 50px;
			height: 50px;
			border-radius: 4px;
			overflow: hidden;
		}
		.name {
			margin-top: 7px;
			font-size: 12px;
			text-align: center;
		}
	}
}
