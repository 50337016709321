.info-modal {
	.content {
		width: 203px;
		height: 330px;
		background: #efefef;
		box-shadow: -10px -10px 30px #ffffff, 10px 10px 30px rgba(174, 174, 192, 0.4);
		border-radius: 12px;
		display: flex;
		flex-direction: column;

		.sharefolder {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			width: 100%;
			margin-top: 13px;
			height: 66px;
			margin-left: 12px;
			.shareicon {
				width: 30px;
				height: 30px;
			}
			.sharename {
				font-family: 'Metropolis';
				font-style: normal;
				font-weight: 400;
				font-size: 18px;
				line-height: 18px;
				margin-left: 15px;
				/* identical to box height */

				color: #585858;
			}
		}
	}
}
